import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import PageHeader from "../../../../Core/PageHeader";
import Pagination from "../../../../Core/Pagination";
import DateValue from "../../../../Core/DateValue";
import Panel from "../../../../Core/Panel";
import IconButton from "../../../../Core/IconButton";
import { faFileExcel, faPencil, faPlus, faTimes, faUsers } from "@fortawesome/pro-regular-svg-icons";
import RoundButton from "../../../../Core/RoundButton";
import BusyIndicator from "../../../../Core/BusyIndicator";
import { NavLink, useNavigate } from "react-router-dom";
import confirmDelete from "../../../../../Utilities/Delete";
import { Link } from "react-router-dom";
import UseDocumentTitle from "../../../../../Utilities/UserDocumentTitle";

const UserTypesIndex = observer(function UserTypesIndex(props) {

	UseDocumentTitle('Listing \'User Types\' on Crest');

	const store = useSessionStore();
	const navigate = useNavigate();

	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState({ PageIndex: 0, PageCount: 10, Total: 0 });
	const [userTypes, setUserTypes] = useState(null);
	const [exportingUserTypes, setExportingUserTypes] = useState(false);

	async function getUserTypes(filters) {
		setUserTypes(null);
		let result = await store.UsersService.GetUserTypes(filters.PageIndex, filters.PageCount, search);

		if(result && result.Success) {
			setFilter({...filters, Total: result.Total});
			setUserTypes(result.Data);
		}
	}

	useEffect(() => {
		getUserTypes(filter);
	}, []);

	async function handleExportUserTypesClicked() {
		setExportingUserTypes(true);
		var result = await store.UsersService.ExportUserTypes(search);

		if(result) {
			//save file
			var blob = new Blob([result.Data], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `USER_TYPES_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);

			setExportingUserTypes(false);
		}
	}

	function handlePageChange(pageIndex, pageCount) {
		let filters = {...filter, PageIndex: pageIndex, PageCount: pageCount};

		setFilter(filters);
		getUserTypes(filters);
	}
	
	async function handleUserTypeDelete(userType) {
		let result = await confirmDelete(userType.Name, userType.Id, (id) => store.UsersService.DeleteUserType(id));
		
		if(result) {
			getUserTypes(filter);
		}
	}

	return 	<React.Fragment>
				<PageHeader icon={ faUsers } title={ "User Types" }  helpItemDescriptor={ "Admin/Users/UserTypesIndex" }
							crumbs={[{ Title: "Admin" }, { Title: "User Types" }, { Title: "List" }]}>
						<NavLink to={ "./Add" }><IconButton space icon={ faPlus }>Add New</IconButton></NavLink>
						<IconButton icon={ faFileExcel } disabled={ exportingUserTypes } onClick={handleExportUserTypesClicked}>
							{ exportingUserTypes ? "Exporting..." : "Export All" }
						</IconButton>
				</PageHeader>
				<Panel>
					<h6>Filter User Types</h6>
					<Row className="mb-50">
						<Col xs={4}>
							<Form.Group>
								<Form.Control placeholder="Search..." value={search} onChange={ e => setSearch(e.target.value) } type="search" ></Form.Control>
							</Form.Group>
						</Col>
						<Col className="d-flex justify-content-end align-items-end">
							<Button className="text-nowrap" onClick={ e => getUserTypes(filter) }>Filter</Button>
						</Col>
					</Row>
					
					
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
                                <th>Type</th>
                                <th className="col-2">Date Created</th>
                                <th className="col-1 text-center">Edit</th>
                                <th className="col-1 text-center">Delete</th>
                            </tr>
						</thead>
						<tbody>
							{
								userTypes &&
								userTypes.length > 0 &&
								userTypes.map((userType, index) =>
									<tr key={ index }>
										<td><NavLink to={ `/Admin/UserTypes/View/${userType.Id}`}>{ userType.Name }</NavLink></td>
										<td><DateValue date={ userType.DateCreated }></DateValue></td>
										<td className="text-center"><NavLink to={ `/Admin/UserTypes/Edit/${ userType.Id }/` }><RoundButton icon={ faPencil }></RoundButton></NavLink></td>
										<td className="text-center">
											{
												userType.Linked &&
												<span>Linked</span>
											}
											{
												!userType.Linked &&
												<RoundButton onClick={ e => handleUserTypeDelete(userType) } icon={ faTimes }></RoundButton>
											}
										</td>
									</tr>)
							}
						</tbody>						
					</Table>
					<BusyIndicator show={ userTypes === null } size="2x"></BusyIndicator>
					{
						userTypes &&
						<Pagination filter={ filter } 
								totalResults={ userTypes.length } 
								onPageChanged={ handlePageChange }></Pagination>
					}
					
				</Panel>
			</React.Fragment>
});

export default UserTypesIndex;