import { faFilePdf, faImagePortrait, faSpinnerThird, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Buffer } from "buffer";
import { saveAs } from "file-saver"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { useState } from "react";
import confirmDelete from "../../../Utilities/Delete";

export default function DocumentButton(props) {
	const store = useSessionStore();

	const [downloadingDoc, setDownloadingDoc] = useState(false);

	async function handleDocumentClicked() {
		if(downloadingDoc === false) {
			setDownloadingDoc(true);
		
			if(props.doc.FileId !== undefined){
			let result = await store.DocumentsService.GetDocument(props.doc.FileId);
			setDownloadingDoc(false);

			if(result && result.Success) {
				let buffer = Buffer.from(result.Data.Base64Data, "base64");
				let blob = new Blob([buffer], { type: result.Data.MIMEType });

				saveAs(blob, result.Data.Name);
			}
		}
		else{
		let buffer = Buffer.from(props.doc.Base64Data, "base64");
				let blob = new Blob([buffer], { type: props.doc.MIMEType });

				saveAs(blob, props.doc.Name);
				setDownloadingDoc(false);
		}
		}
	}

	async function handleDelete() {
		await confirmDelete(props.doc.Name, props.doc.Id, (id) => props.onDelete(id));
	}

	return 	<div title={ props.doc.Name } className="border rounded bg-light d-inline-block text-primary text-center pt-1 me-2 overflow-hidden" style={{ cursor: "pointer", width: "8%" }}>
				
				{
					downloadingDoc &&
					<div>
						<FontAwesomeIcon size="4x" icon={ faSpinnerThird } spin></FontAwesomeIcon>
					</div>		
				}
				{
					!downloadingDoc &&
					<OverlayTrigger overlay={ <Tooltip>Description<br />------------<br />{props.doc.Description}</Tooltip> }>
					<div onClick={ handleDocumentClicked }>
						{
							props.doc.MIMEType === "image/jpeg" &&
							
							<FontAwesomeIcon size="4x" icon={ faImagePortrait }></FontAwesomeIcon>
						
						}
						
						{
							props.doc.MIMEType !== "image/jpeg" &&
							
							<FontAwesomeIcon size="4x" icon={ faFilePdf }></FontAwesomeIcon>
							
						}
					</div>
					</OverlayTrigger>
				}
										
				<span className="w-100 overflow-hidden d-block p-1" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{ props.doc.Name }</span>
				{
					(props.allowDelete) &&            
					<Button variant="danger" onClick={ handleDelete } className="d-block w-100"><FontAwesomeIcon icon={ faTimes }></FontAwesomeIcon></Button>
				}
				
			</div>
}