import { faScaleBalanced, faShip } from "@fortawesome/pro-regular-svg-icons";
import { DateTime } from "luxon";
import { makeAutoObservable, runInAction } from "mobx";
import { FacilityTypes } from "../Utilities/Enums";
import swal from "sweetalert"

export default class FinanceStore {
	RetrievingDocuments = false;
	ExportingDocuments = false;
	ImportingDocuments = false;

	RetrievingTransactions = false;
	ExportingTransactions = false;

	RetrievingTransactionRecoveries = false;
	ExportingTransactionRecoveries = false;
	
	RetrievingTransaction = false;
	RetrievingDocument = false;
	SavingDocument = false;

	TransactionDocumentsFilters = {}
	TransactionsFilters = {}
	TransactionRecoveriesFilters = {}
	
	/** @type { import("../Services/TransactionDocumentsService").TransactionDocumentVM[] } */
	TransactionDocuments = [];

	/** @type { import("../Services/TransactionsService").TransactionVM[] } */
	Transactions = [];

	/** @type { import("../Services/TransactionsService").TransactionVM[] } */
	TransactionRecoveries = [];

	/** @type { import("../Services/TransactionDocumentsService").TransactionDocumentsService } */
	TransactionDocumentsService = null;

	/** @type { import("../Services/TransactionsService").TransactionsService } */
	TransactionsService = null;
	
	constructor(facilityType, transactionDocumentsService, transactionsService) {
		this.InitialiseFilters();

		this.FacilityType = facilityType;
		this.TransactionDocumentsService = transactionDocumentsService;
		this.TransactionsService = transactionsService;

		this.LoadStoreFilters();

		makeAutoObservable(this);
	}

	InitialiseFilters() {
		this.TransactionDocumentsFilters = {
			PageIndex: 0,
			PageCount: 10,
			Total: 0,
			Search: "",
			From: DateTime.now().minus({ months: 1 }).startOf("month").toISODate(),
			To: DateTime.now().endOf("month").toISODate()
		}
	
		this.TransactionsFilters = {
			PageIndex: 0,
			PageCount: 10,
			Total: 0,
			Search: "",
			TransactionStatusId: 0,
			From: DateTime.now().minus({ months: 1 }).startOf("month").toISODate(),
			To: DateTime.now().endOf("month").toISODate()
		}
	
		this.TransactionRecoveriesFilters = {
			PageIndex: 0,
			PageCount: 10,
			Total: 0,
			Search: "",
			TransactionRecoveryStatusId: 0,
			From: DateTime.now().minus({ months: 1 }).startOf("month").toISODate(),
			To: DateTime.now().endOf("month").toISODate()
		}
	}

	LoadStoreFilters() {
		let transactionDocumentsFilterJSON = localStorage.getItem(`${this.GetFinanceType()}TransactionDocumentsIndexFilter`);
		let transactionsFilterJSON = localStorage.getItem(`${this.GetFinanceType()}TransactionsIndexFilter`);
		let transactionRecoveriesFilterJSON = localStorage.getItem(`${this.GetFinanceType()}TransactionRecoveriesIndexFilter`);

		this.SetStoredFilter(transactionDocumentsFilterJSON, (f) => this.SetTransactionDocumentsFilters(f));
		this.SetStoredFilter(transactionsFilterJSON, (f) => this.SetTransactionsFilters(f));
		this.SetStoredFilter(transactionRecoveriesFilterJSON, (f) => this.SetTransactionRecoveriesFilters(f));
	}

	ClearStoredFilters() {
		localStorage.removeItem(`${this.GetFinanceType()}TransactionDocumentsIndexFilter`);
		localStorage.removeItem(`${this.GetFinanceType()}TransactionsIndexFilter`);
		localStorage.removeItem(`${this.GetFinanceType()}TransactionRecoveriesIndexFilter`);

		this.InitialiseFilters();
	}

	SetStoredFilter(filterJSON, callback) {
		if(filterJSON) {
			let filter = JSON.parse(filterJSON);
			callback(filter)
		}
	}

	SetTransactionDocumentsFilters(filters) {
		localStorage.setItem(`${this.GetFinanceType()}TransactionDocumentsIndexFilter`, JSON.stringify(filters));
		this.TransactionDocumentsFilters = filters;
	}

	SetTransactionsFilters(filters) {
		localStorage.setItem(`${this.GetFinanceType()}TransactionsIndexFilter`, JSON.stringify(filters));
		this.TransactionsFilters = filters;
	}

	SetTransactionRecoveriesFilters(filters) {
		localStorage.setItem(`${this.GetFinanceType()}TransactionRecoveriesIndexFilter`, JSON.stringify(filters));
		this.TransactionRecoveriesFilters = filters;
	}

	GetIcon(){
		if(this.FacilityType === FacilityTypes.InvoiceDiscounting) {
			return faScaleBalanced;
		} else {
			return faShip;
		}
	}

	GetContactType(){
		if(this.FacilityType === FacilityTypes.InvoiceDiscounting) {
			return "Customer";
		} else {
			return "Vendor";
		}
	}

	GetFinanceType(){
		if(this.FacilityType === FacilityTypes.InvoiceDiscounting) {
			return "Debtor";
		} else {
			return "Trade";
		}
	}

	GetTransactionType(){
		if(this.FacilityType === FacilityTypes.InvoiceDiscounting) {
			return "Debt";
		} else {
			return "Payable";
		}
	}

	async GetTransactionDocuments() {
		if(this.RetrievingDocuments === false) {
			this.RetrievingDocuments = true;
			let results = await this.TransactionDocumentsService.GetTransactionDocuments(this.TransactionDocumentsFilters.Search, 
																							this.TransactionDocumentsFilters.From, 
																							this.TransactionDocumentsFilters.To, 
																							this.FacilityType, 
																							this.TransactionDocumentsFilters.PageIndex, 
																							this.TransactionDocumentsFilters.PageCount);
	
			runInAction(() => {
				if(results && results.Success) {
					this.TransactionDocumentsFilters.Total = results.Total;
					this.TransactionDocuments = results.Data;
					this.RetrievingDocuments = false;
				}
			});
		}
	}

	async GetTransactions() {
		if(this.RetrievingTransactions === false) {
			this.RetrievingTransactions = true;
			let results = await this.TransactionsService.GetTransactions(this.TransactionsFilters.Search,  
																				this.TransactionsFilters.TransactionStatusId,
																				this.TransactionsFilters.From, 
																				this.TransactionsFilters.To, 
																				this.FacilityType, false,
																				this.TransactionsFilters.PageIndex, 
																				this.TransactionsFilters.PageCount);
	
			runInAction(() => {
				if(results && results.Success) {
					this.TransactionsFilters.Total = results.Total;
					this.Transactions = results.Data;
					this.RetrievingTransactions = false;
				}
			});
		}
	}

	async GetTransactionRecoveries() {
		if(this.RetrievingTransactionRecoveries === false) {
			this.RetrievingTransactionRecoveries = true;
			let results = await this.TransactionsService.GetTransactions(this.TransactionRecoveriesFilters.Search,  
																				this.TransactionRecoveriesFilters.TransactionRecoveryStatusId,
																				this.TransactionRecoveriesFilters.From, 
																				this.TransactionRecoveriesFilters.To, 
																				this.FacilityType, true,
																				this.TransactionRecoveriesFilters.PageIndex, 
																				this.TransactionRecoveriesFilters.PageCount);
	
			runInAction(() => {
				if(results && results.Success) {
					this.TransactionRecoveriesFilters.Total = results.Total;
					this.TransactionRecoveries = results.Data;
					this.RetrievingTransactionRecoveries = false;
				}
			});
		}
	}
	

	async GetTransaction(transactionId) {
		this.RetrievingTransaction = true;
		let results = await this.TransactionsService.GetTransaction(transactionId);

		runInAction(() => {
			this.RetrievingTransaction = false;
		});

		if(results && results.Success) {
			return results.Data;
		}

		return null;
	}

	async GetTransactionConverted(transactionId) {
		this.RetrievingTransaction = true;
		let results = await this.TransactionsService.GetTransactionConverted(transactionId);

		runInAction(() => {
			this.RetrievingTransaction = false;
		});

		if(results && results.Success) {
			return results.Data;
		}

		return null;
	}

	async ExportTransactions() {
		this.ExportingTransactions = true;
		let results = await this.TransactionsService.ExportTransactions(this.TransactionsFilters.Search,
																		this.TransactionsFilters.TransactionStatusId, 
																		this.TransactionsFilters.From, 
																		this.TransactionsFilters.To, 
																		this.FacilityType, false);

		runInAction(() => {
			this.ExportingTransactions = false;
		});

		if(results && results.Success) {
			return results.Data;
		}

		return "";
	}

	async ExportTransactionRecoveries() {
		this.ExportingTransactionRecoveries = true;
		let results = await this.TransactionsService.ExportTransactions(this.TransactionRecoveriesFilters.Search,
																		this.TransactionRecoveriesFilters.TransactionRecoveryStatusId, 
																		this.TransactionRecoveriesFilters.From, 
																		this.TransactionRecoveriesFilters.To, 
																		this.FacilityType, true);

		runInAction(() => {
			this.ExportingTransactionRecoveries = false;
		});

		if(results && results.Success) {
			return results.Data;
		}

		return "";
	}

	async GetTransactionDocument(transactionDocumentId) {
		this.RetrievingDocument = true;
		let results = await this.TransactionDocumentsService.GetTransactionDocument(transactionDocumentId);

		runInAction(() => {
			this.RetrievingDocument = false;
		});

		if(results && results.Success) {
			return results.Data;
		}

		return null;
	}

	async ExportTransactionDocuments() {
		this.ExportingDocuments = true;
		let results = await this.TransactionDocumentsService.ExportTransactionDocuments(this.TransactionDocumentsFilters.Search, 
																						this.TransactionDocumentsFilters.From, 
																						this.TransactionDocumentsFilters.To, 
																						this.FacilityType);

		runInAction(() => {
			if(results && results.Success) {				
				this.ExportingDocuments = false;
			}
		});

		if(results && results.Success) {
			return results.Data;
		}

		return "";
	}

	async ImportTransactionDocuments(fileData) {
		this.ImportingDocuments = true;
		let results = await this.TransactionDocumentsService.ImportTransactionDocuments(fileData, this.FacilityType);

		runInAction(() => {
			this.ImportingDocuments = false;
		});

		if(results && results.Success) {
			return results.Data;
		} else {
			swal({ 
				icon: "error", 
				title: "Error Importing Documents", 
				text: "An error occurred attempting to import the documents: " + (results ? results.Message : "") 
			});
		}

		return null;
	}

	async CreateTransactionDocument(transactionDocument) {
		this.SavingDocument = true;
		let result = await this.TransactionDocumentsService.CreateTransactionDocument(transactionDocument, this.FacilityType);

		runInAction(() => {
			this.SavingDocument = false;
		});

		if(result) {
			if(result.Success) {
				swal({ icon: "success", title: "Document Saved", text: "Document Created Successfully", closeOnClickOutside:false});
			} else {
				swal({ icon: "error", title: "Error Saving Document", text: result.Message, closeOnClickOutside:false});
			}

			return result.Data;
		} else {
			swal({ icon: "error", title: "Error", text: "An error ocurred attempting to save the Document", closeOnClickOutside:false});
		}

		return null;
	}
}