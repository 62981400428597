import { Col, Row } from "react-bootstrap";
import CrestLogo from "../../../Images/crest management system-LOGO.jpg";
import TowerLogo from "../../../Images/tower-logo.png";

export default function Header(props) {
	return <Row className="mb-2">
	<Col>
		{
			props.approvalObject.GroupLogoBase64 &&
			<img alt="Group Logo"  style={{ height: "50px" }} src={ `data:${props.approvalObject.GroupLogoMIMEType};base64,${props.approvalObject.GroupLogoBase64}`}></img>
		}
		{
			!props.approvalObject.GroupLogoBase64 && props.showLogo &&
			<img alt="Tower Logo"  style={{ height: "50px" }} src={ TowerLogo }></img>
		}
	</Col>
	<Col className="text-center">
		<h3 class="text-bold mt-1">{ props.title }</h3>
	</Col>
	<Col className="d-flex justify-content-end">
		<img alt="Crest Logo" src={ CrestLogo } style={{ height: "50px" }}></img>
	</Col>
</Row>
}