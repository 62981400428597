import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../../StyleSheet";
import { formatCurrencyValue } from "../../../../../Utilities/Currencies";

export default function TransactionTotals(props){

    const showRecoveries = props.showRecoveries;

    return(
        props.data.map(value =>
        <View style={styles.tableRowWrapping}> 
            <Text style={[styles.textFooters, {textAlign:'left', width: (showRecoveries? '19%': '30%'), paddingLeft:1.5}]}>Totals</Text>
            <Text style={[styles.textFooters, {textAlign:'center', width: (showRecoveries? '6%': '6%')}]}>{value.Currency.Abbreviation}</Text>
            <Text style={[styles.textFooters, {textAlign:'right', width: (showRecoveries? '14%': '18%')}]}>{formatCurrencyValue(value.Currency.Symbol,value.DocumentAmountTotal)}</Text>
            <Text style={[styles.textFooters, {textAlign:'right', width: (showRecoveries? '10%': '10%')}]}>{formatCurrencyValue(value.Currency.Symbol,value.VatTotal)}</Text>
            <Text style={[styles.textFooters, {textAlign:'right', width: (showRecoveries? '14%': '18%')}]}>{formatCurrencyValue(value.Currency.Symbol,value.AmountTotal)}</Text>
            <Text style={[styles.textFooters, {textAlign:'right', width: (showRecoveries? '14%': '18%')}]}>{formatCurrencyValue(value.Currency.Symbol,value.PaymentAmountTotal)}</Text>
            {showRecoveries &&
                <React.Fragment>
                    <Text style={[styles.textFooters, {textAlign:'right', width: '10%'}]}>{formatCurrencyValue(value.Currency.Symbol,(value.FundingAmountTotal - value.PaymentAmountTotal))}</Text>
                    <Text style={[styles.textFooters, {textAlign:'right', width: '13%'}]}>{formatCurrencyValue(value.Currency.Symbol,value.FundingAmountTotal)}</Text>
                </React.Fragment>
            }
        </View>
        )
    )
}