import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import swal from "sweetalert";
import { useRef } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { SettingTypes } from "../../../../Utilities/Enums";
import BusyIndicator from "../../../Core/BusyIndicator";
import AuditLogEntitySummary from "../../AuditLogs/AuditLogEntitySummary";
import Text from "./SettingTypes/Text";
import HTMLText from "./SettingTypes/HTMLText";
import UserSelect from "./SettingTypes/UserSelect";

export default function EditSettingModal(props) {
	const store = useSessionStore();

	const [settingData, setSettingData] = useState({ Setting: null, Value: "" });	
	const [saving, setSaving] = useState(false);

	const editorRef = useRef(null);

	async function handleSaveClicked() {
		settingData.Setting.Value = settingData.Value;

		if(settingData.Setting.SettingType === SettingTypes.HtmlBox) {
			settingData.Setting.Value = editorRef.current.getData();
		}

		setSaving(true);
		var result = await store.SettingsService.UpdateSetting(settingData.Setting);
		setSaving(false);

		if(result && result.Success) {
			swal({ title: "Success", icon: "success", text: "Setting updated successfully.", closeOnClickOutside: false });
			props.onClose(true);
		} else {
			swal({ title: "Error", icon: "error", text: "An error occurred attempting to update the Setting.", closeOnClickOutside: false });
		}
	}
	

	async function getSetting() {
		let result = await store.SettingsService.GetSetting(props.setting.Id);

		if(result && result.Success) {
			setSettingData({ Setting: result.Data, Value: result.Data.Value });
		}
	}
	
	function loadSetting() {
		if(props.setting) {
			if(props.setting.Id === 0) {
				let setting = { 
					Id: 0, 
					Name: props.setting.Name, 
					SettingType: props.setting.SettingType,
					SettingItemId: props.setting.SettingItemId, 
					GroupId: props.groupId, 
					MarketPositionId: props.marketPositionId 
				}

				setSettingData({ Setting: setting, Value: "" });
			} else {
				getSetting();
			}
		}
	}
	
	useEffect(() => {
		if(props.show === false) {
			setSettingData({ Setting: null, Value: "" });
		} else {
			loadSetting();
		}
	}, [props.show])
		
	function getInputControl() {
		if(settingData && settingData.Setting) {
			if(settingData.Setting.SettingType === SettingTypes.TextBox) {
				return 	<Text value={ settingData.Value } updateValue={ e => setSettingData({...settingData, Value: e }) }></Text>
			}

			if(settingData.Setting.SettingType === SettingTypes.HtmlBox) {
				return 	<HTMLText ref={ editorRef } value={ settingData.Value } updateValue={ e => setSettingData({...settingData, Value: e }) }></HTMLText>
			}

			if(settingData.Setting.SettingType === SettingTypes.MultipleUserSelectList) {
				return <UserSelect value={ settingData.Value } updateValue={ e => setSettingData({...settingData, Value: e }) }></UserSelect>
			}
		}

		return false;
	}

	return 	<Modal show={ props.show }  dialogClassName="modal-600">
				<Modal.Header>
					<Modal.Title>
						Edit { (props.name || "") } Setting
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						settingData && settingData.Setting &&
						<React.Fragment>
							{
								settingData.Setting.Id > 0 &&
								<AuditLogEntitySummary table='Settings' entityid={settingData.Setting.Id} auditsummary={ settingData.Setting.AuditSummary }></AuditLogEntitySummary>
							}
							<Row>
								<Form.Label xs={4} column>Setting</Form.Label>
								<Form.Label column>{ settingData.Setting.Name }</Form.Label>
							</Row>
							<Row>
								<Col>
								{
									getInputControl()
								}
								</Col>
							</Row>
						</React.Fragment>
					}
					<BusyIndicator show={ !settingData && !settingData.Setting }></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={ saving } onClick={ e => handleSaveClicked() }>{ (saving ? "Saving..." : "Save") }</Button>
					<Button disabled={ saving } onClick={ e => props.onClose(false) }>Cancel</Button>
				</Modal.Footer>
			</Modal>
}