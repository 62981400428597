import { Col, Form, Row } from "react-bootstrap";

export default function Pagination(props) {
	var numPages = (props.filter.PageCount > 0 ? Math.ceil(props.filter.Total / props.filter.PageCount) : 1);

	function getPageNumbers() {
		var limitShow = 4;
		var startAt = props.filter.PageIndex - limitShow;
		var endAt = props.filter.PageIndex + limitShow;
		var workingCounter = (startAt > 0 ? startAt : 0);
		var workingCounterEnd = (endAt <= numPages ? endAt : numPages);

		var pageNumbers = [];

		while (workingCounter !== workingCounterEnd) {
			let counter = workingCounter;
			workingCounter++
			pageNumbers.push(<li key={ workingCounter } onClick={ e => props.onPageChanged(counter, props.filter.PageCount) } className={`page-item ${workingCounter === (props.filter.PageIndex + 1) ? "active" : ""}`}>
								<button type="button" className="page-link">{workingCounter}</button>
							</li>);
		}

		return pageNumbers;
	}

	return 	<Row className="d-flex align-items-center mt-1">
				<Col>
					<Form>
						<Form.Group as={Row} className="d-flex align-items-center">
							<Form.Label xs={2} column style={{ width: "110px" }}>Show Entries: </Form.Label>
							<div style={{ width: "60px" }} className="p-0">
								<Form.Select style={{ width: "60px", backgroundPositionX:"80%" }} value={ props.filter.PageCount } onChange={ e => props.onPageChanged(0, parseInt(e.target.value))  }>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="0">All</option>
								</Form.Select>
							</div>
							<Col className="ps-50">Showing { (props.filter.PageIndex * props.filter.PageCount) + (props.totalResults > 0 ? 1 : 0 ) } to { (props.filter.PageIndex * props.filter.PageCount) + props.totalResults } of { props.filter.Total } entries</Col>
						</Form.Group>
					</Form>
				</Col>
				<Col className="d-flex align-items-center justify-content-end">
					<ul className="pagination mb-0">
						{
							props.filter.PageIndex > 0 &&
							<li className="page-item" style={{ cursor: "pointer" }}><span className="page-link" onClick={ e => props.onPageChanged(props.filter.PageIndex - 1, props.filter.PageCount) }>Prev</span></li>
						}							
						{
							getPageNumbers()
						}
						{
							(props.filter.PageIndex + 1) < numPages &&
							<li className="page-item" style={{ cursor: "pointer" }}><span className="page-link" onClick={ e => props.onPageChanged(props.filter.PageIndex + 1, props.filter.PageCount) }>Next</span></li>
						}
					</ul>
				</Col>
			</Row>
}