import { useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import NumberValue from "../../../../Core/NumberValue";
import Panel from "../../../../Core/Panel";

export default function Reimbursements(props) {
	const store = useSessionStore();
	const [reimbursements, setReimbursements] = useState(null);

	async function getReimbursements() {
		let result = await store.ReimbursementsService.GetReimbursementsForPaymentInstruction(props.paymentInstructionId);

		if(result && result.Success) {
			setReimbursements(result.Data);
		}
	}

	useEffect(() => {
		if(props.paymentInstructionId) {
			getReimbursements();
		}
	}, [props.paymentInstructionId]);

	return 	<Panel title="Reimbursement Transactions">
				<Table bordered size="sm">
					<thead>
						<tr>
							<th className="text-nowrap">Document No.</th>
							<th className="text-nowrap">Store</th>
							<th className="text-end">Amount</th>
						</tr>
					</thead>
					<tbody>
						{
							reimbursements && reimbursements.length > 0 &&
							reimbursements.map((reimbursement, index) => 
							<tr key={ index }>
								<td>{ reimbursement.DocumentNumber }</td>
								<td>{ reimbursement.Store }</td>
								<td className="text-end"><NumberValue currency={reimbursement.Currency} value={ reimbursement.PaymentAmount }></NumberValue></td>
							</tr>)
						}
					</tbody>
				</Table>
			</Panel>
}