import React from "react";
import { Route } from "react-router-dom";
import AuditDetails from "./AuditDetails";
import AuditLogsIndex from "./AuditLogsIndex";

export default function AuditRoutes() {
	return 	<React.Fragment>
				<Route path="Audits/:id" element={ <AuditDetails></AuditDetails> }></Route>
				<Route path="Audits/:table/:entityId" element={ <AuditLogsIndex></AuditLogsIndex> }></Route>
			</React.Fragment>
}