import { NavLink } from 'react-router-dom';
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';

export default function MenuLink({ item, activeItem }) {
	// ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
	const LinkTag = item.externalLink ? 'a' : NavLink;
	
	return	<li className={ classnames({ 'nav-item': !item.children, disabled: item.disabled, active: item.navLink === activeItem }) }>
				{
					item.onClick &&
					<a className='d-flex align-items-center' onClick={ item.onClick }>
						{item.icon || <div style={{ transform: "scale(0.4)"}}><FontAwesomeIcon icon={ faCircle }></FontAwesomeIcon></div> }
						<span className='menu-item text-truncate'>{item.title}</span>
					</a>
				}
				{
					!item.onClick &&
					<LinkTag className='d-flex align-items-center' target={ item.newTab ? '_blank' : undefined }
						/*eslint-disable */
						{...(item.externalLink === true ? { href: item.navLink || '/', className: 'd-flex align-items-center' } : {
							to: item.navLink || '/', className: ({ isActive }) => { 
								let style = "d-flex align-items-center";
								
								if (isActive && !item.disabled) {
									style += " active";
								}

								return style;
							}
						})}

						onClick={ e => {
							if(item.onClick) {
								item.onClick();
							} else if (item.navLink.length === 0 || item.navLink === '#' || item.disabled === true) {
								e.preventDefault()
							}
						}}>
					
						{item.icon || <div style={{ transform: "scale(0.4)"}}><FontAwesomeIcon icon={ faCircle }></FontAwesomeIcon></div> }
						<span className='menu-item text-truncate'>{item.title}</span>
					</LinkTag>
				}
				
			</li>
}