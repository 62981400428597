import { FacilityTypes } from "./Enums";

export function getContactType(facilityTypeId) {
	if(facilityTypeId === FacilityTypes.TradeFinance) {
		return "Trade Vendor";
	} else if(facilityTypeId === FacilityTypes.InvoiceDiscounting) {
		return "Trade Customer";
	}

	return "";
}