import { Document, Page, View, Text } from "@react-pdf/renderer";
import {styles} from '../StyleSheet';

import Heading from "./Components/Heading";
import ContactsWithBalances from "./Components/ContactsWithBalances";
import LinkedPaymentInstructions from "./Components/LinkedPaymentInstructions";
import FacilityLimits from "./Components/FacilityLimits";

export function FacilityPreview(props) {
    return(
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Heading data={props.data} />
                    <View style={[styles.table, {padding:10}]}>
                        <ContactsWithBalances data={props.data} />
                        <LinkedPaymentInstructions data={props.data} />
                        <FacilityLimits data={props.data}/>
                    </View>
                    <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                        `Page ${pageNumber} of ${totalPages}`
                    )} fixed />
                </View>
            </Page>
        </Document>
    )
}