import { Card } from "react-bootstrap";

export default function Panel(props) {
	return 	<Card style={ props.style } className={`mb-1 ${props.className || ""}`}>
				{
					props.title &&
					<Card.Header>
						<Card.Title style={{ fontSize: "0.92857rem", color: "black" }}>
							{ props.title }
						</Card.Title>
					</Card.Header>
				}
				<Card.Body className={`overflow-auto${(props.bodyClassName ? ` ${props.bodyClassName}` : "")}`}>
					{ props.children }
				</Card.Body>
			</Card>
}