import { makeAutoObservable, runInAction } from "mobx";
import StructureManager from "../Utilities/StructureManager";


export default class FacilitiesStore {
	/**@type { import("../Services/FacilitiesService").FacilitiesService } */
	FacilitiesService = null;

	/** @type { Array<import("../Services/FacilitiesService").FacilityIndexModel> } */
	Facilities = [];
	
	RetrievingFacilities = false;
	ExportingFacilities = false;
	Filter = {
		PageIndex: 0,
		PageCount: 10,
		Total: 0
	}

	Search = "";

	
	

	constructor(facilitiesService, availableStructure) {
		this.FacilitiesService = facilitiesService;
		this.StructureManager = new StructureManager(availableStructure, true);
		this.StructureManager.GroupId = 0
		this.StructureManager.MarketPositionId = 0
		this.StructureManager.StoreId = 0;

		this.LoadStoredFilter();
		
		makeAutoObservable(this, { FacilitiesService: false });
	}

	LoadStoredFilter() {
		let filterJSON = localStorage.getItem("FacilitiesIndexFilter");

		if(filterJSON) {
			let filters = JSON.parse(filterJSON);

			this.Search = filters.Search;
			this.StructureManager.SetSelectedGroup(filters.GroupId);
			this.StructureManager.SetSelectedMarketPosition(filters.MarketPositionId);
			this.StructureManager.SetSelectedStore(filters.StoreId);
		}
	}


	SetFilter(filter) {
		this.Filter = filter;
	}

	ClearStoredFilters() {
		localStorage.removeItem(`FacilitiesIndexFilter`);

		this.Filter = { PageIndex: 0, PageCount: 10, Total: 0 };
		this.Search = "";
		this.StructureManager.GroupId = this.StructureManager.MarketPositionId = this.StructureManager.StoreId = 0;
	}

	async GetFacilities(reset) {
		
		if(reset) {
			this.Filter.PageIndex = 0;
		}
		
		this.RetrievingFacilities = true;
		localStorage.setItem("FacilitiesIndexFilter", JSON.stringify({
			Search: this.Search, 
			GroupId: this.StructureManager.GroupId, 
			MarketPositionId: this.StructureManager.MarketPositionId,
			StoreId: this.StructureManager.StoreId
		}));

		var result = await this.FacilitiesService.GetFacilities(this.Filter.PageIndex, this.Filter.PageCount, this.Search,
																	this.StructureManager.GroupId, this.StructureManager.MarketPositionId,
																	this.StructureManager.StoreId);
		runInAction(() => this.RetrievingFacilities = false);

		if(result.Success) {
			runInAction(() => { this.Facilities = result.Data; this.Filter.Total = result.Total });
		}
	}

	async ExportFacilities() {
		this.ExportingFacilities = true;
		var result = await this.FacilitiesService.ExportFacilities(this.Search, this.StructureManager.GroupId,
																	this.StructureManager.MarketPositionId, this.StructureManager.StoreId);
		runInAction(() => this.ExportingFacilities = false);

		if(result && result.Success) {
			return result.Data;
		}

		return null;
	}
}