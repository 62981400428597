import React, { useState,useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import SettingsList from "./SettingsList";
import PageHeader from "../../../Core/PageHeader";
import Panel from "../../../Core/Panel";
import { useSessionStore } from "../../../../Stores/SessionStore";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

export default function GroupSettings(props) {

	UseDocumentTitle('Group Settings');

	const store = useSessionStore();

	const [selectedGroupId, setSelectedGroupId] = useState(null);
	
	async function getSettings(filters) {
		if(selectedGroupId) {
			return store.SettingsService.GetGroupSettings(selectedGroupId, filters.PageIndex, filters.PageCount);
		} else {
			return { Success: true, Data: [] }
		}
	}
	useEffect(() => {
		store.LoadAuthState();
	});
	
	 
	const groups = store.Session.AuthState.UserAppStructure.AvailableStructure;
	
	const handleChange = (e) =>{
		setSelectedGroupId(e.target.value);
		localStorage.setItem("groupSearchValue", e.target.value);
	};

	useEffect(() => {
		setSelectedGroupId(localStorage.getItem("groupSearchValue"));
	})

	return 	<React.Fragment>
				<PageHeader icon={ faGear } title={ "Settings" }  helpItemDescriptor={ "Admin/GroupSettings" }
							crumbs={[{ Title: "Admin" }, { Title: "Group Settings", Active: true }]}>
				</PageHeader>
				<Panel>
					<h6>Filter Group Settings by Group</h6>
					<Row className="mt-1">
						<Col xs={4}>
							<Form.Group as={Row}>
								<Form.Label xs={4} column>Group</Form.Label>
								<Col>
									<Form.Select onChange={ handleChange } value={ selectedGroupId }>
										<option></option>
										{ groups && groups.map(g => <option value={ g.Id }>{ g.Name }</option>) }
									</Form.Select>
								</Col>
							</Form.Group>
						</Col>
					</Row>
				</Panel>
				<SettingsList name="Group" getSettings={ getSettings } groupId={ selectedGroupId }></SettingsList>
			</React.Fragment>
}