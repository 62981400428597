import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../../Core/BusyIndicator";
import CopyFromUser from "./CopyFromUser";

export default function ControlsTab(props) {
	const store = useSessionStore();
	const formikContext = useFormikContext();

	const [controls, setControls] = useState(null);

	let area = "";
	let subArea = "";

	async function getControls() {
		let results = await store.SystemAppsService.GetControls();

		if(results && results.Success) {
			setControls(results.Data);
		}
	}

	function copyFromUser(data) {
		if(data) {
			formikContext.setValues({...formikContext.values, Controls: data.Controls });
		}
	}

	useEffect(() => {
		getControls();
	}, []);

	function getAreaHeader(permissionArea) {
		if (area !== permissionArea) {
			area = permissionArea;
			return <tr>
						<td colSpan="5">
							<strong className="text-info">{ area }</strong>
						</td>
					</tr>
		}

		return false;
	}

	function getSubAreaHeader(permissionSubArea) {
		if (subArea !== permissionSubArea) {
			subArea = permissionSubArea;
			if (subArea) {
				return 	<tr>
							<td colSpan="5">
								<strong>{ subArea }</strong>
							</td>
						</tr>
			}
		}

		return false;
	}

	function handleControlChecked(controlId, checked) {
		let controls = formikContext.values.Controls;
		let setControls = controls.filter(c => c.ControlId === controlId);

		if(setControls.length === 0) {
			if(checked) {
				let newControl = { ControlId: controlId };
				
				controls.push(newControl);
			}			
		} else {
			if(!checked) {
				for(let i = 0; i < setControls.length; i++) {
					let index = controls.findIndex(c => c.ControlId === setControls[i].ControlId);

					if(index > -1) {
						controls.splice(index, 1);
					}
				}
			}
		}
		
		formikContext.setValues({...formikContext.values, Controls: controls });
	}

	function isControlChecked(controlId) {
		let controls = formikContext.values.Controls;
		let setControl = controls.find(c => c.ControlId === controlId);

		if(setControl) {
			return true;
		}

		return false;
	}

	return 	<div>
				<CopyFromUser copyFromUser={ copyFromUser } users={props.users} systemAppId={ props.selectedSystem ? props.selectedSystem.SystemApp.SystemId : "" }></CopyFromUser>
				<Row>
					<Col>
					{
						controls &&
						<Table size="sm" responsive bordered>
							<tbody>
							<tr>
								<td className="py-50"><b>Section</b></td>								
								<td className="text-center col-1"><b>Allow</b></td>
							</tr>
							{
								
								controls.map((control) => 	
								<React.Fragment>
									{
										getAreaHeader(control.Area)
									}
									{
										getSubAreaHeader(control.SubArea)
									}
									<tr key={ control.ControlId }>
										<td>{control.Name}</td>
										<td className="text-center">
											<input className="mt-50" type="checkbox" checked={ isControlChecked(control.ControlId) } onChange={ e => handleControlChecked(control.ControlId, e.target.checked) } />
										</td>
									</tr>
								</React.Fragment>)
							}
							</tbody>
						</Table>
					}
					</Col>
				</Row>
				<BusyIndicator show={ controls === null }></BusyIndicator>
			</div>
}