import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {Table } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { getPermissions, Permissions, Roles } from "../../../../Utilities/Permissions";
import PageHeader from "../../../Core/PageHeader";
import Pagination from "../../../Core/Pagination";
import DateValue from "../../../Core/DateValue";
import NumberValue from "../../../Core/NumberValue";
import Panel from "../../../Core/Panel";
import BusyIndicator from "../../../Core/BusyIndicator";
import { FacilityTypes, TransactionStatuses } from "../../../../Utilities/Enums";
import IconButton from "../../../Core/IconButton";
import { faFileExcel, faInfoCircle, faPencil, faTimes } from "@fortawesome/pro-regular-svg-icons";
import TransactionsFilters from "./TransactionsFilters";
import { getTransactionStatusText } from "../../../../Utilities/Transactions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoundButton from "../../../Core/RoundButton";
import { FlaggedIcon } from "../../../Core/FlaggedIndicator";
import ResendNotificationModal from "../../../Core/ResendNotificationModal";
import confirmDelete from "../../../../Utilities/Delete";
import PaymentInstructionAssignmentDetails from "./PaymentInstructionAssignmentDetails";
import { NavLink } from "react-router-dom";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";
import { Controls, hasControl } from "../../../../Utilities/Controls";

const TransactionsIndex = observer(function TransactionsIndex(props) {
	


	if(props.facilityType === 1){
		UseDocumentTitle('Listing \'Payable Transactions\' in \'Trade Finance\' on CREST');
	}else{
		UseDocumentTitle('Listing \'Debt Transactions\' in \'Debt Finance\' on CREST');
	}

	const store = useSessionStore();
	const financeStore = store.GetFinanceStore(props.facilityType);
	
	const [showResendModal, setShowResendModal] = useState(false);
	const [selectedApprovalDetails, setSelectedApprovalDetails] = useState(null);
	const canOverride = hasControl(Controls.Areas.CreditManagement, props.facilityType === FacilityTypes.TradeFinance ? Permissions.SubAreas.TradeFinance : Permissions.SubAreas.DebtorFinance, Controls.Sections.TransactionStatusOverride, store.Session);

	useEffect(() => {
		financeStore.GetTransactions();
	}, [financeStore]);

	async function handleExportTransactionsClicked() {
		var result = await financeStore.ExportTransactions();

		if(result) {
			//save file
			var blob = new Blob([result], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `${financeStore.GetTransactionType().toUpperCase()}_TRANSACTIONS_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);
		}
	}

	function handlePageChange(pageIndex, pageCount) {
		financeStore.SetTransactionsFilters({...financeStore.TransactionsFilters, PageIndex: pageIndex, PageCount: pageCount});
		financeStore.GetTransactions();
	}

	const permissions = getPermissions(props.facilityType === FacilityTypes.TradeFinance ? Permissions.Sections.PayableTransactions : Permissions.Sections.DebtTransactions, 
										Permissions.Areas.CreditManagement, 
										props.facilityType === FacilityTypes.TradeFinance ? Permissions.SubAreas.TradeFinance : Permissions.SubAreas.DebtorFinance, 
										store.Session);


	
	function getApprovalUserDetails(transaction) {
		let id = null;
		let name = null;
		let level = 1;

		if(transaction.ResentToUserId) {
			id = transaction.ResentToUserId;
			name = transaction.ResentToUser;
		} else {
			switch (transaction.TransactionStatusId) {
				case TransactionStatuses.Pending:
					id = transaction.FacilityApprovedByUserOneId;
					name = transaction.FacilityApprovedByUserOne;
					break;
				case TransactionStatuses.AwaitingApproval:
					id = transaction.FacilityApprovedByUserTwoId;
					name = transaction.FacilityApprovedByUserTwo;
					break;
				case TransactionStatuses.Approved:
					id = transaction.FacilityVerifiedByUserOneId;
					name = transaction.FacilityVerifiedByUserOne;
					break;
				case TransactionStatuses.AwaitingVerification:
					id = transaction.FacilityVerifiedByUserTwoId;
					name = transaction.FacilityVerifiedByUserTwo;
					break;
				default:
					break;
			}
		}

		switch (transaction.TransactionStatusId) {
			case TransactionStatuses.Pending:
				level = 1;
				break;
			case TransactionStatuses.AwaitingApproval:
				level = 2;
				break;
			case TransactionStatuses.Approved:				
				level = 3;
				break;
			case TransactionStatuses.AwaitingVerification:				
				level = 4;
				break;
			default:
				break;
		}

		return {
			Id: id,
			Name: name,
			Level: level
		}
	}

	function handleResendClicked(transaction, approvalDetails) {
		setSelectedApprovalDetails({ 
			Id: transaction.Id, 
			FacilityId: transaction.FacilityId,
			GroupId: transaction.GroupId,
			MarketPositionId: transaction.MarketPositionId,
			StoreId: transaction.StoreId,
			CurrentUserId: approvalDetails.Id,
			CurrentUser: approvalDetails.Name,
			Level: approvalDetails.Level
		});

		setShowResendModal(true);
	}

	function handleResendModalClosed(update) {
		setShowResendModal(false);

		if(update) {
			financeStore.GetTransactions();
		}
	}

	function transactionDeclined(transaction) {
		return transaction.TransactionStatusId === TransactionStatuses.Declined || transaction.TransactionStatusId === TransactionStatuses.NTU;
	}

	async function handleDelete(transaction) {
		let result = await confirmDelete(transaction.DocumentNumber, transaction.Id, (id) => store.TransactionsService.DeleteTransaction(id));
		
		if(result) {
			financeStore.GetTransactions();
		}
	}

	return 	<React.Fragment>
				<PageHeader icon={ financeStore.GetIcon() } title={ `${financeStore.GetTransactionType()} Transactions` } 
							helpItemDescriptor={ `${financeStore.GetTransactionType()}Transaction/Index` }
								crumbs={[{ Title: "Credit Management" }, { Title: `${financeStore.GetFinanceType()} Finance` }, { Title: `${financeStore.GetTransactionType()} Transactions` }]}>
					{
						permissions.View &&
						<IconButton icon={ faFileExcel } disabled={ financeStore.ExportingTransactions } onClick={handleExportTransactionsClicked}>
							{ financeStore.ExportingTransactions ? "Exporting..." : "Export All" }
						</IconButton>
					}
				</PageHeader>
				<Panel>
					<TransactionsFilters facilityType={ props.facilityType }></TransactionsFilters>
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
                                <th className="text-nowrap">Document Date</th>
                                <th>Trade {financeStore.GetContactType()}</th>
                                <th>Store</th>
                                <th>Document No.</th>
                                <th className="text-end">Payment Amount</th>
                                <th>Due Date</th>
                                <th>Status</th>
                                
								{
									permissions.Edit &&
									<React.Fragment>
										<th className="text-center">Assign</th>
										<th className="text-center">Edit</th>
									</React.Fragment>
								}
								
								{
									permissions.Delete &&
									<th className="text-center">Delete</th>
								}
                            </tr>
						</thead>
						<tbody>
							{
								!financeStore.RetrievingTransactions &&
								financeStore.Transactions.length > 0 &&
								financeStore.Transactions.map((transaction, index) =>
									<tr key={ index }>
										<td><DateValue outputFormat="dd MMM yyyy" date={ transaction.DocumentDate }></DateValue></td>
										<td>
											{
												permissions.View &&
												<NavLink to={`/${financeStore.GetFinanceType()}Finance/${financeStore.GetTransactionType()}Transactions/View/${transaction.Id}`}>{ transaction.ContactName }</NavLink>
											}
											{
												!permissions.View &&
												<span>{ transaction.ContactName }</span>
											}
										</td>
										<td>{ transaction.StructureName }</td>
										<td>{ transaction.DocumentNumber }</td>
										<td className="text-nowrap text-end"><NumberValue currency={ transaction.Currency } value={ transaction.PaymentAmount }></NumberValue></td>
										<td className="text-nowrap"><DateValue outputFormat="dd MMM yyyy" date={ transaction.DueDate }></DateValue></td>
										<td className="text-nowrap" style={{ position: "relative", paddingRight: "40px", overflow: "visible"}}>
											<div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
											{ (() => {
													if(transaction.TransactionStatusId === TransactionStatuses.Pending && transaction.Submitted)
														return <span>Submitted</span>
													else if(transaction.TransactionStatusId >= TransactionStatuses.Processed)
														return <span>Processed</span>
													else 
														return <span>{getTransactionStatusText(transaction.TransactionStatusId)}</span>
													})()}
											</div>
											<div style={{ position: "absolute", right: "5px", top: "10px"}}>
												<FlaggedIcon flagged={ transaction.Flagged } comment={ transaction.Comment } commentUserName={ transaction.CommentUserName }></FlaggedIcon>
												{
													(() => {
														const approvalUser = getApprovalUserDetails(transaction);

														if(approvalUser.Id && approvalUser.Name && transaction.Submitted) {
															return 	<span className="text-primary" style={{ cursor: "pointer" }} onClick={ e => handleResendClicked(transaction, approvalUser) }>
																		<FontAwesomeIcon icon={ faInfoCircle }></FontAwesomeIcon>
																	</span>
														}
													})()
												}
											</div>
										</td>
						
										{
											permissions.Edit &&
											<React.Fragment>
												<td className="text-center">
													<PaymentInstructionAssignmentDetails transaction={ transaction }></PaymentInstructionAssignmentDetails>
												</td>
												<td className="text-center">
													{
														
														(() => {
															if(transaction.Submitted === false && 
																(transaction.TransactionStatusId === TransactionStatuses.Open || 
																(transaction.Flagged && transactionDeclined(transaction) === false) || 
																transaction.TransactionStatusId === TransactionStatuses.Pending))  {
																if(transaction.AutoFinanceOption && transactionDeclined(transaction) === false) {
																	return <NavLink to={ `/${financeStore.GetFinanceType()}Finance/${financeStore.GetTransactionType()}Transactions/Edit/${transaction.Id}/false`}><RoundButton icon={ faPencil }></RoundButton></NavLink>
																}
																 else {
																	return <span>Locked</span>
																}

															}else if(canOverride && (transaction.TransactionStatusId === TransactionStatuses.NTU || transaction.TransactionStatusId === TransactionStatuses.Verified))
																{
																	if( transaction.PaymentInstructionId === null)
																	{
																	return <NavLink to={ `/${financeStore.GetFinanceType()}Finance/${financeStore.GetTransactionType()}Transactions/Edit/${transaction.Id}/true`}><RoundButton icon={ faPencil }></RoundButton></NavLink>
																	}
																	else
																	{
																		return <span>Locked</span>
																	}

																}
															 else {
																if ((store.Session.Role === Roles.Admin || store.Session.AuthState.UserAppStructure.GroupAdminLevel) && (transactionDeclined(transaction) === false)) {
																	return <NavLink to={`/${financeStore.GetFinanceType()}Finance/${financeStore.GetTransactionType()}Transactions/View/${transaction.Id}/true`}><RoundButton icon={ faPencil }></RoundButton></NavLink>
																} else {
																	if (transactionDeclined(transaction)) {
																		return <span>Locked</span>
																	}
																	else {
																		return <span>Submitted</span>
																	}
																}
															}
														})()
													}
												</td>
											</React.Fragment>
										}
										
										{
											permissions.Delete &&
											<td className="text-center">
												{
													(() => {
														if((transaction.TransactionStatusId === TransactionStatuses.Open) || 
															(transaction.Flagged && transactionDeclined(transaction) === false && transaction.TransactionStatusId === TransactionStatuses.Open) || 
															(transaction.Submitted === false && transaction.TransactionStatusId === TransactionStatuses.Pending)) {
															return <RoundButton onClick={ e => handleDelete(transaction) } icon={ faTimes }></RoundButton>
														} else {
															if (transactionDeclined(transaction)) {
																return <span>Locked</span>
															} else {
																return <span>Submitted</span>
															}
														}
													})()
												}
											</td>
										}
									</tr>)
							}
						</tbody>
					</Table>
					<BusyIndicator show={ financeStore.RetrievingTransactions } size="2x"></BusyIndicator>
					{
						!financeStore.RetrievingTransactions &&
						<Pagination filter={ financeStore.TransactionsFilters } 
								totalResults={ financeStore.Transactions.length } 
								onPageChanged={ handlePageChange }></Pagination>
					}
					
				</Panel>
				<ResendNotificationModal subArea={ `${financeStore.GetFinanceType()} Finance` } transaction show={ showResendModal } onClose={ handleResendModalClosed } details={ selectedApprovalDetails }></ResendNotificationModal>
			</React.Fragment>
});

export default TransactionsIndex;