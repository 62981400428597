import { faWrench } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import StructureIndex from "./StructureIndex";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

export default function GroupsIndex(props) {

	UseDocumentTitle('Listing \'Groups\' on Crest');

	const store = useSessionStore();
	const [groups, setGroups] = useState(null);
	const [totalGroups, setTotalGroups] = useState(0);

	async function exportGroups(search) {
		return store.StructureService.ExportGroups(search);
	}

	async function getGroups(search, filter) {
		setGroups(null);
		let results = await store.StructureService.GetGroups(search, filter.PageIndex, filter.PageCount);

		if(results && results.Success) {
			setTotalGroups(results.Data.TotalStructures);
			setGroups(results.Data.Structures);
		}
	}

	async function deleteGroup(groupId) {
		let result = await store.StructureService.DeleteGroup(groupId);

		if(result && result.Success) {
			return true;
		} else {
			return false;
		}
	}

	return <StructureIndex export={ exportGroups } getEntities={ getGroups } deleteEntity={ deleteGroup } 
							entityName="Groups" childrenName="Market Positions" title="Groups"
							icon={ faWrench } entities={ groups } total={totalGroups}>
			</StructureIndex>
}