import NumberFormat from "react-number-format";
import { roundUp } from "../../Utilities/Currencies";

export default function NumberValue(props) {
	
	
	function getValue() {
		let scale = props.decimals || 2;

		if(scale > 0) {
			return roundUp(props.value, scale)
		}
	}

	return <NumberFormat className={ props.className } prefix={ (props.currency ? `${props.currency} ` : "") } value={ `${getValue()}` } thousandSeparator={ ' ' } decimalScale={ props.decimals || 2} displayType="text" fixedDecimalScale></NumberFormat>
}