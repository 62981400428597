import { faEye, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

export default function ImageUploadInput(props) {
	const [selectedFile, setSelectedFile] = useState(null);
	const [selectedFileName, setSelectedFileName] = useState("No File Selected");

	const fileInputRef = useRef(null);

	function handleFileChanged(event) {
		let file = event.target.files[0];

		if(file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');

				setSelectedFileName(file.name);
				setSelectedFile(base64String);

				props.onFileChanged(file, base64String);
			};

			reader.readAsDataURL(file);
		}
	}

	function handleFileRemoved() {
		setSelectedFileName("No File Selected");
		setSelectedFile(null);
		
		props.onFileChanged(null, null);
	}

	function handleFileDownload() {
		const byteString = window.atob(props.base64);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);

		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}

		var blob = new Blob([int8Array], {type: props.mime});
		saveAs(blob, props.name);
	}

	function handleChooseFileClicked() {
		fileInputRef.current.value = null;
		fileInputRef.current.click()
	}

	return 	<React.Fragment>
				<Row>
					<Col xs={2} className="d-flex align-items-center">
						<Form.Label>{props.label}</Form.Label>
					</Col>
					{
						!props.view &&
						<Col xs={6}>
							<input ref={ fileInputRef } hidden type="file" onChange={ handleFileChanged }></input>
							<InputGroup>
								<Form.Control value={ selectedFileName } disabled></Form.Control>
								<Button onClick={ handleChooseFileClicked }>Choose File...</Button>
							</InputGroup>
						</Col>
					}
					</Row>
					{
						props.base64 &&
						<React.Fragment>
							<Row className="mt-2">
								<Col className="offset-2" xs={ 6 }>
									<div className="border rounded border-warning p-50">
										<img style={{ maxWidth: "256px" }} alt="Profile Pic" src={ `data:${props.mime};base64,${props.base64}` }></img>
									</div>
								</Col>
							</Row>
							<Row className="mt-50">
								<Col className="offset-2" xs={ 6 }>
									<Button onClick={ handleFileDownload } className="me-50"><FontAwesomeIcon icon={ faEye }></FontAwesomeIcon> Download File</Button>
									<Button onClick={ handleFileRemoved } variant="danger"><FontAwesomeIcon icon={ faTimes }></FontAwesomeIcon> Remove File</Button>
								</Col>
							</Row>
						</React.Fragment>
						
					}
			</React.Fragment>
}