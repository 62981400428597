import APIBase from "./APIBase";

/**
 * @typedef Reimbursement
 * @property {string} DocumentNumber
 * @property {string} Store
 * @property {string} Group
 * @property {string} Currency
 * @property {number} PaymentAmount
 * @property {string} DateTimeStamp
 * @property {string} Status
 * @property {number} PaymentInstructionId
 * @property {string} PaymentInstructionName
 * @property {string} ProcessedDate
 * @property {string} CurrencyAbbreviation
 */

/**
 * @typedef ReimbursementsService
 * @type {ReimbursementsService}
 */
export default class ReimbursementsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Reimbursements", sessionStore);
    }

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<Reimbursement> }
	 */
	async GetReimbursementsForPaymentInstruction(paymentInstructionId) {
		return this.Get(`GetReimbursementsForPaymentInstruction?paymentInstructionId=${paymentInstructionId}`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<Reimbursement> }
	 */
	 async GetReimbursements(search, statusId, from, to, pageIndex, pageCount) {
		return this.Get(`GetReimbursements?search=${encodeURIComponent(search)}&statusId=${statusId}&from=${encodeURIComponent(from)}
						&to=${encodeURIComponent(to)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<string> }
	 */
	 async ExportReimbursements(search, statusId, from, to, processing, recoveries) {
		return this.Get(`ExportReimbursements?search=${encodeURIComponent(search)}&statusId=${statusId}&
							from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`);
	}
}