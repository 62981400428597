import { faPlus, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import BackButton from "../../../../Core/BackButton";
import BusyIndicator from "../../../../Core/BusyIndicator";
import IconButton from "../../../../Core/IconButton";
import PageHeader from "../../../../Core/PageHeader";
import Pagination from "../../../../Core/Pagination";
import Panel from "../../../../Core/Panel";
import AddCurrencyModal from "./AddCurrencyModal";
import UseDocumentTitle from "../../../../../Utilities/UserDocumentTitle";

export default function CurrenciesIndex(props) {

	UseDocumentTitle('Listing \'Currencies\' on Crest');

	const store = useSessionStore();

	const [currencies, setCurrencies] = useState(null);
	const [filter, setFilter] = useState({ PageIndex: 0, PageCount: 10, Total: 0 });
	const [search, setSearch] = useState("");

	const [showAddModal, setShowAddModal] = useState(false);

	async function getCurrencies(filters) {
		setCurrencies(null);
		let result = await store.CurrenciesService.GetAvailableCurrencies(search, filters.PageIndex, filters.PageCount);

		if(result && result.Success) {
			setCurrencies(result.Data);
			setFilter({...filters, Total: result.Total});
		}
	}
		
	function handleAddModalClosed(update) {
		if(update) {
			getCurrencies(filter);
		}

		setShowAddModal(false);
	}

	function handlePageChange(pageIndex, pageCount) {
		let filters = {...filter, PageIndex: pageIndex, PageCount: pageCount};

		setFilter(filters);
		getCurrencies(filters);
	}

	useEffect(() => {
		getCurrencies(filter);
	}, []);

	return 	<React.Fragment>
				<PageHeader icon={ faGear } title={ "Currencies" }  helpItemDescriptor={ "Admin/Currencies" }
							crumbs={[{ Title: "Admin" }, { Title: "Systems Settings" },{ Title: "Currencies", Active: true }]}>
					<IconButton space icon={ faPlus } onClick={ e => setShowAddModal(true) }>
						Add New
					</IconButton>
					<IconButton space icon={ faRefresh } onClick={ e => getCurrencies(filter) }>
						Refresh
					</IconButton>
					<BackButton></BackButton>
				</PageHeader>
				<Panel>
					<h6>Filter Currencies</h6>
					<Row className="mb-50">
						<Col xs={4}>
							<Form.Group>
								<Form.Control value={search} onChange={ e => setSearch(e.target.value) } placeholder="Search"></Form.Control>
							</Form.Group>
						</Col>
						<Col className="d-flex justify-content-end align-items-end">
							<Button className="text-nowrap" onClick={ e => getCurrencies(filter) }>Filter</Button>
						</Col>
					</Row>
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
								<th>Name</th>
								<th>Abbr.</th>
								<th>Symbol</th>
							</tr>
						</thead>
						<tbody>
							{
								currencies &&
								currencies.length > 0 &&
								currencies.map((currency, index) =>
									<tr key={ index }>
										<td>{ currency.Name }</td>
										<td>{ currency.Abbreviation }</td>
										<td>{ currency.Symbol }</td>
									</tr>)
							}
						</tbody>
					</Table>
					<BusyIndicator show={ currencies === null }></BusyIndicator>
					{
						currencies &&
						<Pagination filter={ filter } 
								totalResults={ currencies.length } 
								onPageChanged={ handlePageChange }></Pagination>
					}
				</Panel>
				<AddCurrencyModal show={showAddModal} onClose={ handleAddModalClosed } ></AddCurrencyModal>
			</React.Fragment>
}