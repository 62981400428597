import React from "react";
import { Table } from "react-bootstrap";
import { getContactType } from "../../../../../../Utilities/Contacts";
import NumberValue from "../../../../../Core/NumberValue";

export default function ContactBalances(props) {
	return 	<React.Fragment>
				<h6>Contacts / Vendors & Customers with Balances</h6>
				<Table bordered className="align-middle" size="sm" responsive>
					<thead>
						<tr>
							<th>Company Name</th>
							<th>Contact Type</th>
							<th className="text-end" style={{ width: "12%" }}>Limit</th>
							<th className="text-end" style={{ width: "12%" }}>Running Bal.</th>
							<th className="text-end" style={{ width: "12%" }}>Actual Bal.</th>
							<th className="text-center" style={{ width: "15%" }}>Status</th>
						</tr>
					</thead>
					<tbody>
						{
							props.contacts.map((value) =>
							<tr key={value.ContactId}>
								<td>{value.Name}</td>
								<td>{ getContactType(props.facilityTypeId) }</td>
								<td className="text-end">
									<NumberValue currency={ value.Currency } value={ value.Limit }></NumberValue>
								</td>
								<td className={ value.Limit !== 0 ?  (value.Balances.RunningTotal > value.Limit ? "text-end fw-bold" : "text-end") : "text-end"}><NumberValue currency={ value.Currency } value={ value.Balances.RunningTotal }></NumberValue></td>
								<td className="text-end"><NumberValue currency={ value.Currency } value={ value.Balances.ActualTotal }></NumberValue></td>
								<td className="text-center">{ value.Active ? "Active" : "Not Active" }</td>
							</tr>)
						}
					</tbody>
				</Table>
			</React.Fragment>
}