	export default class Recovery {
	Initialise (recoveryAmount, theoreticalRecoveryAmount, transactions) {
		this.Transactions = transactions;
		this.RecoveryAmount = recoveryAmount;
		this.TheoreticalRecoveryAmount = theoreticalRecoveryAmount;

		this.ReimbursementFilled = false;
		this.Reimbursement = null;

		this.TheoreticalReimbursement = null;
		this.TheoreticalReimbursementChanged = false;

		this.Transactions = transactions;
	}

	GetTotals () {
		//get the receiving amount
		//calculate what has already been set excluding current selector
		var totalAllocated = 0.0;
		var totalTheoreticalAllocated = 0.0;

		this.Transactions.forEach((transaction) => {
			totalAllocated += transaction.Amount || 0.0;
			totalTheoreticalAllocated += transaction.UnusedAmount || 0.0;                
		});

		totalAllocated += this.Reimbursement || 0.0;
		totalTheoreticalAllocated += this.TheoreticalReimbursement || 0.0;

		//calculate remaining.
		var remaining = this.RecoveryAmount - (+totalAllocated.toFixed(2));
		var remainingTheoretical = this.TheoreticalRecoveryAmount - (+totalTheoreticalAllocated.toFixed(2));

		var totals = {
			RecoveryAmount: this.RecoveryAmount,
			TheoreticalRecoveryAmount: this.TheoreticalRecoveryAmount,

			TotalAllocated: totalAllocated,
			TotalTheoreticalAllocated: totalTheoreticalAllocated,

			TheoreticalRecoveryRemaining: remainingTheoretical,
			RecoveryRemaining: remaining,

			Reimbursement: this.Reimbursement,
			TheoreticalReimbursement: this.TheoreticalReimbursement,
			ReimbursementFilled: this.ReimbursementFilled
		};
					
		return totals;
	}

	FillReimbursement (value, filled) {
		if (filled) {
			this.SetReimbursementValue(value);
			this.ReimbursementFilled = true;
		}
		else {
			this.SetReimbursementValue(null);
			this.ReimbursementFilled = false;
		}
	}

	SetReimbursementValue (newValue) {

		this.Reimbursement = newValue;
		var totals = this.GetTotals();

		if (totals.TotalAllocated > this.RecoveryAmount) {
			this.Reimbursement = 0.0;
			totals = this.GetTotals();

			//there isn't enough money available to assign the total of this new amount to the recovery
			//we need to only take what we can allocate                        
			this.Reimbursement = totals.RecoveryRemaining;                
		}

		if (this.TheoreticalReimbursementChanged === false) {
			this.TheoreticalReimbursement = this.Reimbursement;
		}
	}

	SetTheoreticalReimbursementValue (newValue) {

		this.TheoreticalReimbursement = newValue;
		this.TheoreticalReimbursementChanged = true;

		var totals = this.GetTotals();

		if (totals.TotalTheoreticalAllocated > this.TheoreticalRecoveryAmount) {
			this.TheoreticalReimbursement = 0.0;
			totals = this.GetTotals();

			//there isn't enough money available to assign the total of this new amount to the recovery
			//we need to only take what we can allocate                        
			this.TheoreticalReimbursement = totals.TheoreticalRecoveryRemaining;
		}
	}

	SetTransactionTheoreticalValue (transaction, newValue) {
		transaction.UnusedAmount = newValue;
		transaction.TheoreticalAmountChanged = true;

		var totals = this.GetTotals();

		if (totals.TotalTheoreticalAllocated > this.TheoreticalRecoveryAmount) {
			transaction.UnusedAmount = 0;
			totals = this.GetTotals();

			//there isn't enough money available to assign the total of this new amount to the recovery
			//we need to only take what we can allocate                        
			transaction.UnusedAmount = (this.TheoreticalRecoveryAmount - totals.TotalTheoreticalAllocated);
		}
	}

	SetTransactionValue (transaction, newValue) {
		if(transaction.MaxAmount > 0) {
			newValue = (newValue > transaction.MaxAmount) ? transaction.MaxAmount : newValue;
		} else {
			newValue = (newValue < transaction.MaxAmount) ? transaction.MaxAmount : newValue;
		}

		transaction.Amount = newValue;

		var totals = this.GetTotals();

		if (totals.TotalAllocated > this.RecoveryAmount) {
			transaction.Amount = 0;
			totals = this.GetTotals();

			//there isn't enough money available to assign the total of this new amount to the recovery
			//we need to only take what we can allocate                        
			transaction.Amount = totals.RecoveryRemaining;
		}

		if (transaction.TheoreticalAmountChanged === false) {
			transaction.UnusedAmount = transaction.Amount;
		}
	}

	FillTransaction (transaction) {
		var totals = this.GetTotals();

		if (transaction.Filled) {
			//the checkbox is checked, so add the Max amount for this transaction to it's assigned amount,
			//but only if we have enough allocated recovery money available 
			if (totals.TotalAllocated < this.RecoveryAmount || transaction.MaxAmount < 0) {
				this.SetTransactionValue(transaction, transaction.MaxAmount);
			}
			else {
				//theres no money available, so just uncheck it again
				transaction.Filled = false;
			}
		}
		else {
			//the amount is being unfilled, so remove the applied amount from the total allocated           
			transaction.Amount = null;
		}

		if (transaction.TheoreticalAmountChanged === false) {
			transaction.UnusedAmount = transaction.Amount;
		}
	}
}