import APIBase from "./APIBase";

/**
 * @typedef Currency
 * @property {number} Id
 * @property {string} Name
 * @property {string} Abbreviation
 * @property {string} Symbol
 */

/**
 * @typedef MonetaryValue
 * @property {Currency} OriginalCurrency
 * @property {Currency} ConvertedCurrency
 * @property {Number} OriginalValue
 * @property {Number} ConvertedValue
 */

/**
 * @typedef CurrenciesService
 * @type {CurrenciesService}
 */
export default class CurrenciesService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Currencies", sessionStore);
    }

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<Currency> }
	 */
	async GetAvailableCurrencies(search, pageIndex, pageCount) {
		return this.Get(`GetAvailableCurrencies?search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<MonetaryValue> }
	 */
	 async GetCurrentConversionRatesForCurrency(currencyId, outputCurrencyIds) {
		return this.Post(`GetCurrentConversionRatesForCurrency?currencyId=${currencyId}`, outputCurrencyIds);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<Currency> }
	 */
	 async GetAllSupportedCurrencies() {
		return this.Get(`GetAllSupportedCurrencies`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<Boolean> }
	 */
	 async AddCurrency(currency) {
		return this.Post(`AddCurrency`, currency);
	}
}