import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import DateInput from "../../Core/Forms/DateInput";

export default function UnderutilisationFilters(props) {
	const [facilities, setFacilities] = useState(null);
	const store = useSessionStore();

	useEffect(() => {
		async function getFacilities() {
			var results = await store.UnderutilisationService.GetFacilities();

			if(results && results.Success) {
				setFacilities(results.Data);
			}
		}

		getFacilities();

	}, []);

	return 	<Form className="mb-50">
				{
					facilities &&
					<React.Fragment>
						<h6 className="mb-1">Filter Underutilisation</h6>
						<Row>
							<Col xs={4}>
								<Form.Group>
									
									<Form.Select value={ props.facilityId } onChange={ e => props.onFacilityChanged(parseInt(e.target.value || "0")) }>
											<option value="">Select Facility</option>
											{
												facilities.map((facility) =>
												<option key={ facility.Id } value={ facility.Id }>{facility.Number} - {facility.Name}</option>
												)
											}
										</Form.Select>
								
								</Form.Group>
							</Col>
						</Row>
					</React.Fragment>
				}
				<BusyIndicator show={ !facilities }></BusyIndicator>
				{
					props.showFilters &&
					<Row className="mt-2">
						<Col xs={4}>
							<Form.Group>
								<Form.Label>Status</Form.Label>
								<Col>
									<Form.Select value={ props.status } onChange={ e => props.onStatusChanged(e.target.value) }>
										<option value="">All</option>
										<option value="0">Open</option>
										<option value="1">Partially Recovered</option>
										<option value="2">Fully Recovered</option>									
									</Form.Select>
								</Col>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>From</Form.Label>
								<Col>
									<DateInput date={ props.fromDate } onChange={ e => props.onFromDateChanged(e) }></DateInput>
								</Col>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>To</Form.Label>
								<Col>
									<DateInput date={ props.toDate } onChange={ e => props.onToDateChanged(e) }></DateInput>
								</Col>
							</Form.Group>
						</Col>
						<Col xs={1} className="d-flex align-items-end">
							<Button className="w-100 text-nowrap px-0" onClick={ e => props.onFilter() }>Filter</Button>
						</Col>
					</Row>
				}				
			</Form>
}