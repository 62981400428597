import { Fragment, useState, useRef } from 'react'

import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import MenuItems from './MenuItems'

import 'react-perfect-scrollbar/dist/css/styles.css';
import MenuHeader from './MenuHeader';

export default function Menu(props) {
	const { menuData } = props

	const [groupOpen, setGroupOpen] = useState([]);
	const [groupActive, setGroupActive] = useState([]);
	const [currentActiveGroup, setCurrentActiveGroup] = useState([]);
	const [activeItem, setActiveItem] = useState(null);

	const [menuHover, setMenuHover] = useState(false);

	const shadowRef = useRef(null);
	
	function onMouseEnter() {
		setMenuHover(true);
	}

	const scrollMenu = container => {
		if (shadowRef && container.scrollTop > 0) {
			if (!shadowRef.current.classList.contains('d-block')) {
				shadowRef.current.classList.add('d-block');
			}
		} else {
			if (shadowRef.current.classList.contains('d-block')) {
				shadowRef.current.classList.remove('d-block');
			}
		}
	}

  	return	<div style={{ width: "270px" }} className={classnames('main-menu menu-fixed menu-accordion menu-light border-end', {
				expanded: menuHover })}
				onMouseEnter={onMouseEnter}
				onMouseLeave={() => setMenuHover(false)}>
				<Fragment>
					<MenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
					
					<PerfectScrollbar className='main-menu-content' options={{ wheelPropagation: false }} onScrollY={container => scrollMenu(container)}>
						<ul className='navigation navigation-main'>
							<MenuItems
								items={menuData}
								menuData={menuData}
								menuHover={menuHover}
								groupOpen={groupOpen}
								activeItem={activeItem}
								groupActive={groupActive}
								setGroupOpen={setGroupOpen}
								setActiveItem={setActiveItem}
								setGroupActive={setGroupActive}
								currentActiveGroup={currentActiveGroup}
								setCurrentActiveGroup={setCurrentActiveGroup}/>
						</ul>
					</PerfectScrollbar>
				</Fragment>
			</div>
}