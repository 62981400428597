import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ContextualHelpModal from "./ContextualHelpModal";

export default function HelpButton(props) {
	const [showModal, setShowModal] = useState(false);

	return 	<React.Fragment>
				<Button size="sm" variant="outline-primary" className="btnHelp" onClick={ e => setShowModal(true) }>HELP</Button>
				<ContextualHelpModal onClose={ e => setShowModal(false) } show={ showModal }  descriptor={ props.descriptor }></ContextualHelpModal>
			</React.Fragment>
}