import { useField, useFormikContext } from "formik";
import { Table } from "react-bootstrap";
import SelectField from "../../../../../Core/Forms/SelectField";
import Panel from "../../../../../Core/Panel";

export default function UserAuthorisationGroup(props) {
	const formikContext = useFormikContext();
	const [fieldOne] = useField(`${props.group}${props.stage1}UserOne`);
	const [fieldTwo] = useField(`${props.group}${props.stage2}UserOne`);

	function handleFieldOneChanged(value, group, stage) {
		if(value === "") {
			let values = formikContext.values;
			values[`${group}${stage}UserOne`] = value;
			values[`${group}${stage}UserTwo`] = value;

			formikContext.setValues(values);
			return true;
		}

		return false;
	}

	function getAuthGroupField(group, stage, user, disabled) {
		return 	<SelectField onChange={ e => { if(user === "UserOne") handleFieldOneChanged(e.target.value, group, stage); }} disabled={disabled} name={`${group}${stage}${user}`} view={props.view}>
					<option value="">Not Required</option>
					{
						props.users && props.users.map(u =>
							<option key={u.Id} value={ u.Id }>{ u.Username }</option>
						)
					}
				</SelectField>
	}

	return 	<Panel title={ props.title } className={ (props.disabled ? "bg-light" : "") }>
				<Table className="align-middle" size="sm">
					<tbody>
						<tr className="border-bottom-2">
							<td className="fw-bold">Process</td>
							<td className="fw-bold text-center">1st Level</td>
							<td className="fw-bold text-center">2nd Level</td>
						</tr>
						<tr>
							<td>{props.stage1}</td>
							<td className="text-center">
								{
									getAuthGroupField(props.group, props.stage1, "UserOne", props.disabled)
								}
							</td>
							<td className="text-center">
								{
									getAuthGroupField(props.group, props.stage1, "UserTwo", props.disabled || !fieldOne.value || fieldOne.value === "0")
								}
							</td>
						</tr>
						<tr>
							<td>{props.stage2}</td>
							<td className="text-center">
								{
									getAuthGroupField(props.group, props.stage2, "UserOne", props.disabled)
								}
							</td>
							<td className="text-center">
								{
									getAuthGroupField(props.group, props.stage2, "UserTwo", props.disabled || !fieldTwo.value || fieldTwo.value === "0")
								}
							</td>
						</tr>
					</tbody>
				</Table>
			</Panel>
}