import { useState } from "react";
import { useEffect } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { useSessionStore } from "../../../../../../Stores/SessionStore";
import AssignmentsTab from "./AssignmentsTab";
import PermissionsTab from "./PermissionsTab";
import ControlsTab from "./ControlsTab";
import swal from "sweetalert";

export default function AssignmentsModal (props) {
	const store = useSessionStore();
	const [assignments, setAssignments] = useState(null);
	const [users, setUsers] = useState(null);
	const [saving, setSaving] = useState(false);
	const [key, setKey] = useState('assignments');

	async function getUserAssignments() {
		let results = await store.SystemAppsService.GetUserAssignmentsForSystemApp(props.selectedSystem.User.Id, props.selectedSystem.SystemApp.SystemId);

		if(results && results.Success) {
			setAssignments(results.Data);
		}
	}

	async function getUsers() {
		let results = await store.UsersService.GetUsersOfType(props.selectedSystem.User.UserTypeId);
		
		if(results && results.Success) {
			setUsers(results.Data.filter(u => u.Id !== props.selectedSystem.User.Id));
		}
	}

	useEffect(() => {
		if(props.show && props.selectedSystem) {
			getUserAssignments();
			getUsers();
		} else {
			setAssignments(null);
			setUsers(null);
		}

	}, [props.show]);

	async function handleSaveClicked(values) {
		setSaving(true);
		var result = await store.SystemAppsService.UpdateUserAssignmentsForSystemApp(values);
		setSaving(false);

		if(result && result.Success) {
			if(key === "assignments")
			{props.onClose();}
			swal({ title: "Success", icon: "success", text: `You have saved permissions for ${props.selectedSystem.User.Name} successfully.`, closeOnClickOutside: false });
		} else {
			swal({ title: "Error", icon: "error", text: `An error occurred attempting to save permissions for ${props.selectedSystem.User.Name}.`, closeOnClickOutside: false });
		}
	}

	return 	<Modal show={ props.show } dialogClassName="modal-900">
				<Modal.Header>
					<h4>
						Settings for: { props.selectedSystem && 
										<span className="fw-normal">
											{props.selectedSystem.SystemApp.SystemName} for {props.selectedSystem.User.Name} {(props.selectedSystem.User.UserType ? `- ${props.selectedSystem.User.UserType}` : "")}
										</span> }
					</h4>
				</Modal.Header>
				<Formik initialValues={assignments} enableReinitialize validateOnChange={true} onSubmit={ handleSaveClicked }>
					<FormikForm>
						<Modal.Body>							
							<Tabs defaultActiveKey="assignments" activeKey={key} onSelect={(k) => setKey(k)}>
								<Tab eventKey="assignments" title="Assignments">
									<AssignmentsTab></AssignmentsTab>
								</Tab>
								{
									assignments && !assignments.GroupAdminLevel &&
									<Tab eventKey="permissions" title="Permissions">
										<PermissionsTab selectedSystem={ props.selectedSystem } users={users}></PermissionsTab>
									</Tab>
								}
								{
									assignments && !assignments.GroupAdminLevel &&
									<Tab eventKey="controls" title="Controls">
										<ControlsTab selectedSystem={ props.selectedSystem } users={users}></ControlsTab>
									</Tab>
								}
							</Tabs>							
						</Modal.Body>
						<Modal.Footer>
							<Button disabled={ saving || assignments === null } type="submit">{ saving ? "Saving..." : "Save" }</Button>
							<Button disabled={ saving || assignments === null } onClick={ e => props.onClose() }>Cancel</Button>
						</Modal.Footer>
					</FormikForm>
				</Formik>
			</Modal>
}