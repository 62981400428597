import React from "react";
import { useSessionStore } from "../../../Stores/SessionStore";
import { Route } from "react-router-dom";
import FundsIndex from "./FundsIndex";

export default function FundRoutes(){
    const store = useSessionStore();

    return <React.Fragment>

        <React.Fragment>
            <Route path="/Funds/AllFunds" element={ <FundsIndex></FundsIndex> }></Route>
        </React.Fragment>

    </React.Fragment>
}