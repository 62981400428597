import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";

export default function PaymentInstructionAssignmentModal(props) {
	const store = useSessionStore();
	const financeStore = store.GetFinanceStore(props.facilityTypeId)
	const [assigning, setAssigning] = useState(false);

	const [paymentInstructions, setPaymentInstructions] = useState(null);
	const [selectedPaymentInstruction, setSelectedPaymentInstruction] = useState("");

	useEffect(() => {
		async function getPaymentInstructions() {
			setPaymentInstructions(null);
			var results = await store.PaymentInstructionsService.GetAssignablePaymentInstructionsForTransaction(props.transactionId);

			if(results && results.Success) {
				setPaymentInstructions(results.Data);
			}
		}

		if(props.show) {
			getPaymentInstructions();
		}
	}, [props.show]);

	async function handleSaveClicked() {
		if(selectedPaymentInstruction) {
			setAssigning(true);
			var result = await store.TransactionsService.AssignTransactionToPaymentInstruction(props.transactionId, selectedPaymentInstruction);
			setAssigning(false);

			if(result && result.Success) {
				let pi = paymentInstructions.find(p => p.PaymentInstructionId === selectedPaymentInstruction);

				props.onClose(true, pi);
			}
		}
	}

	return 	<Modal show={ props.show }>
				<Modal.Header>
					<h4>
						Assign Payment Instruction to {props.transactionName}
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						paymentInstructions &&
						<React.Fragment>
							<span className="d-block mb-1">Select the Payment Instruction you wish to assign this transaction to:</span>
							<Form.Select value={ selectedPaymentInstruction } onChange={ e => setSelectedPaymentInstruction(parseInt(e.target.value)) }>
								<option value="">-- SELECT --</option>
								{
									paymentInstructions.map(p =>
									<option key={ p.PaymentInstructionId } value={ p.PaymentInstructionId }>{ p.Name }</option>)
								}
							</Form.Select>
						</React.Fragment>
					}
					<BusyIndicator size="2x" show={!paymentInstructions}></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={ paymentInstructions === null || assigning } onClick={ handleSaveClicked }>Assign</Button>
					<Button disabled={ paymentInstructions === null || assigning } onClick={ e => props.onClose(false) }>Cancel</Button>
				</Modal.Footer>
			</Modal>
}