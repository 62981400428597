import APIBase from "./APIBase";

/**
 * @typedef FacilityIndexModel
 * @property {number} Id
 * @property {string} Name
 * @property {string} Number
 * @property {string} Status
 * @property {string} Type
 * @property {number} FacilityTypeId
 * @property {boolean} Active
 * @property {string} Currency
 */

/**
 * @typedef FacilityContact
 * @property {number} Limit
 * @property {number} RunningTotal
 * @property {number} ActualTotal
 * @property {boolean} Active
 * @property {boolean} Linked
 * @property {string} Name
 * @property {number} ContactId
 */

/**
 * @typedef FacilityBatchDate
 * @property {number} Id
 * @property {number} Limit
 * @property {boolean} Active
 * @property {string} BatchDate
 * @property {boolean} InUse
 */

/**
 * @typedef FacilityAssignment
 * @property {number} Id
 * @property {number} FacilityId
 * @property {number} GroupId
 * @property {number} MarketPositionId
 * @property {number} StoreId
 * @property {string} GroupName
 * @property {string} MarketPositionName
 * @property {string} StoreName
 * @property {boolean} Active
 * @property {boolean} Linked
 */

/**
 * @typedef FacilitiesService
 * @type {FacilitiesService}
 */
export default class FacilitiesService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Facilities", sessionStore);
    }

    /**
     * @returns { import("./APIBase").EnumerableRequestResult<FacilityIndexModel> }
     */
     async GetFacilities(pageIndex, pageCount, search, groupId, marketPositionId, storeId) {
        return this.Get(`GetFacilities?pageIndex=${pageIndex}&pageCount=${pageCount}&search=${encodeURIComponent(search)}
							&groupId=${groupId}&marketPositionId=${marketPositionId}&storeId=${storeId}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<string> }
     */
     async ExportFacilities(search, groupId, marketPositionId, storeId) {
        return this.Get(`ExportFacilities?search=${encodeURIComponent(search)}
							&groupId=${groupId}&marketPositionId=${marketPositionId}&storeId=${storeId}`);
    }

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<FacilityContact> }
     */
     async GetAssignedContactsForFacility(facilityId) {
        return this.Get(`GetAssignedContactsForFacility?facilityId=${facilityId}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async UpdateAssignedFacilityContacts(facilityId, facilityContacts) {
        return this.Post(`UpdateAssignedFacilityContacts?facilityId=${facilityId}`, facilityContacts);
    }

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<FacilityBatchDate> }
     */
     async GetFacilityBatchDates(facilityId) {
        return this.Get(`GetFacilityBatchDates?facilityId=${facilityId}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async UpdateFacilityBatchDates(facilityId, facilityBatchDates) {
        return this.Post(`UpdateFacilityBatchDates?facilityId=${facilityId}`, facilityBatchDates);
    }

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<FacilityAssignment> }
     */
     async GetFacilityAssignments(facilityId) {
        return this.Get(`GetFacilityAssignments?facilityId=${facilityId}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async UpdateFacilityAssignments(facilityId, facilityAssignments) {
        return this.Post(`UpdateFacilityAssignments?facilityId=${facilityId}`, facilityAssignments);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async DeleteFacility(facilityId) {
        return this.Post(`DeleteFacility?facilityId=${facilityId}`);
    }

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<import("./UsersService").User> }
     */
     async GetAssignableUsers(facilityId) {
        return this.Get(`GetAssignableUsers?facilityId=${facilityId}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async CreateFacility(facility) {
        return this.Post(`CreateFacility`, facility);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
     async UpdateFacility(facility) {
        return this.Post(`UpdateFacility`, facility);
    }

	/**
     * @returns { import("./APIBase").RequestResult<object> }
     */
     async GetFacility(facilityId) {
        return this.Get(`GetFacility?facilityId=${facilityId}`);
    }

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<FacilityIndexModel> }
     */
     async GetFacilitiesForStructure(groupId, marketPositionId, storeId, facilityTypeId, includeInactive) {
        return this.Get(`GetFacilitiesForStructure?groupId=${groupId}&marketPositionId=${marketPositionId}&storeId=${storeId}&facilityTypeId=${facilityTypeId}&includeInactive=${includeInactive}`);
    }
}