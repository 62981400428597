import { Button, Col, Form, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { FacilityTypes } from "../../../../Utilities/Enums";
import DateRange from "../../../Core/DateRange";
import { useState } from "react";

export default function TransactionRecoveriesFilters(props) {
	
	const store = useSessionStore();
	let financeStore = store.GetFinanceStore(props.facilityType);
	const [fromDate,setFromDate] = useState(financeStore.TransactionRecoveriesFilters.From);
	const [statusID,setStatusID] = useState(financeStore.TransactionRecoveriesFilters.TransactionRecoveryStatusId)
	const [search,setSearch] = useState(financeStore.TransactionRecoveriesFilters.Search);
	const [toDate,setToDate] = useState(financeStore.TransactionRecoveriesFilters.To);
	
	function handleFilterClicked() {
		financeStore.SetTransactionRecoveriesFilters({...financeStore.TransactionRecoveriesFilters,Search:search,TransactionRecoveryStatusId:statusID,To:toDate,From:fromDate, PageIndex: 0 });
		financeStore.GetTransactionRecoveries();
	}
	
	return 	<Form className="mb-50">
				<h6 className="mb-1">Filter Transactions Recoveries</h6>
				<Row className="align-items-center">
					<Col xs={ 12 } lg={ 2 }>
						<Form.Group>
							<Col>
								<Form.Control value={ search } 
								onChange={ e => {setSearch(e.target.value) } }
								type="search" placeholder="Search..." />
							</Col>
						</Form.Group>
					</Col>	
					<Col xs={ 12 } lg={ 2 }>
						<Form.Group>
							<Col>
								<Form.Select 
									value={ statusID } 
									onChange={ e => { setStatusID(parseInt(e.target.value)) } }>
									<option value="0">All</option>
									<option value="1">Processed</option>
									<option value="2">Partially Recovered</option>
									{
										props.facilityType === FacilityTypes.InvoiceDiscounting &&
										<option value="3">Recovered</option>
									}
									<option value="4">Fully Recovered</option>
								</Form.Select>
							</Col>
						</Form.Group>
					</Col>	
					<DateRange 
								fromDate={fromDate} 
								toDate={toDate}
								onFromChange={ e => { setFromDate(e) } }
								onToChange={ e => {setToDate(e) } }>
					</DateRange>
					<Col xs={ 12 } lg={ 1 } className="d-flex align-items-end">
						<Button className="w-100 text-nowrap px-0" onClick={ handleFilterClicked }>Filter</Button>
					</Col>
				</Row>
			</Form>
}