import React, { useEffect, useState } from "react";
import PageHeader from "../../../../Core/PageHeader";
import { faUsers, faPlus, faFileExcel, faPencil, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import IconButton from "../../../../Core/IconButton";
import Panel from "../../../../Core/Panel";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import RoundButton from "../../../../Core/RoundButton";
import UseDocumentTitle from "../../../../../Utilities/UserDocumentTitle";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import confirmDelete from "../../../../../Utilities/Delete";
import DateValue from "../../../../Core/DateValue";
import Pagination from "../../../../Core/Pagination";
import BusyIndicator from "../../../../Core/BusyIndicator";

export default function CountriesIndex(){

	UseDocumentTitle('Listing \'Countries\' on CREST');

	const store = useSessionStore();
	const navigate = useNavigate();

	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState({PageIndex: 0, PageCount: 0, Total: 0});
	const [countries, setCountries] = useState();

	const [exportingCountries, setExportingCountries] = useState(false);

	async function getCountries(filters){
		setCountries(null);
		let result = await store.CountryService.GetCountries(search, filters.PageIndex, filters.PageCount);

		if(result && result.Success){
			setFilter({...filters, Total: result.Total});
			setCountries(result.Data);
		}
	}

	useEffect(()=>{
		getCountries(filter);
	}, []);

	async function handleExportCountriesClicked(){
		setExportingCountries(true);
		var result = await store.CountryService.ExportCountries(search);

		if(result){
			var blob = new Blob([result.Data], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `COUNTRIES_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);

			setExportingCountries(false);
		}
	}

	function handlePageChange(pageIndex, pageCount){
		let filters = {...filter, PageIndex: pageIndex, PageCount: pageCount};

		setFilter(filters);
		getCountries(filters);
	}

	async function handleCountryDelete(entity){
		let result = await confirmDelete(entity.Name, entity.Id, (id) => store.CountryService.DeleteCountry(id));
		
		if(result){
			getCountries(filter);
		}
	}

    return <React.Fragment>
        <PageHeader icon={ faUsers } title={ "Countries" }  helpItemDescriptor={ "Admin/Users/CountriesIndex" }
					crumbs={[{ Title: "Admin" }, { Title: "Countries" }, { Title: "List" }]}>
            <NavLink to={ "Add" }><IconButton space icon={ faPlus }>Add New</IconButton></NavLink>
            <IconButton icon={ faFileExcel } disabled={ exportingCountries } onClick={handleExportCountriesClicked}>
				{ exportingCountries ? "Exporting..." : "Export All" }
			</IconButton>
        </PageHeader>
        <Panel>
					<h6>Filter Countries</h6>
					<Row className="mb-50">
						<Col xs={4}>
							<Form.Group>
								<Form.Control placeholder="Search..." value={search} onChange={ e => setSearch(e.target.value) } type="search" ></Form.Control>
							</Form.Group>
						</Col>
						<Col className="d-flex justify-content-end align-items-end">
							<Button className="text-nowrap" onClick={ e => getCountries(filter) }>Filter</Button>
						</Col>
					</Row>
					
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
								<th>Country</th>
								<th className="col-2">Date Created</th>
								<th className="col-1 text-center">Edit</th>
								<th className="col-1 text-center">Delete</th>
							</tr>
						</thead>
						<tbody>
							{
								countries &&
								countries.length > 0 &&
								countries.sort( (a,b) => a.Name > b.Name ? 1 : -1) &&
								countries.map((country, index) =>
									<tr key={index}>
										<td><NavLink to={ `/Admin/Countries/View/${country.Id}` }>{country.Name}</NavLink></td>
										<td><DateValue outputFormat="dd MMM y hh:mm a" date={country.DateCreated}></DateValue></td>
										<td className="text-center"><NavLink to={ `/Admin/Countries/Edit/${country.Id}` }><RoundButton icon={ faPencil }></RoundButton></NavLink></td>
										<td className="text-center">
											{
												country.Linked &&
												<span>Linked</span>
											}
											{
												!country.Linked &&
												<RoundButton onClick={ e => { handleCountryDelete(country); } } icon={ faTimes }></RoundButton>
											}
										</td>
									</tr>
								)
							}
						</tbody>
					</Table>
					<BusyIndicator show={ countries === null } size="2x"></BusyIndicator>
					{
						countries &&
						<Pagination filter={ filter } 
								totalResults={ countries.length } 
								onPageChanged={ handlePageChange }></Pagination>
					}
				</Panel>
    </React.Fragment>
}