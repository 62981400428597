import APIBase from "./APIBase";

/**
 * @typedef SystemsService
 * @type {SystemsService}
 */
export default class SystemAppsService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "SystemApps", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<any> }
	 */
	async GetSystemApps() {
		return this.Get(`GetSystemApps`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<any> }
	 */
	 async GetControls() {
		return this.Get(`GetControls`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<any> }
	 */
	 async GetPermissions() {
		return this.Get(`GetPermissions`);
	}

	/**
	 * @param {string} userId The Id of the user
	 * @param {number} systemAppId The Id of the system app the assignments are for
	 * @returns { Promise<import("./APIBase").RequestResult<any>> }
	 */
	 async GetUserAssignmentsForSystemApp(userId, systemAppId) {
		return this.Get(`GetUserAssignmentsForSystemApp?userId=${userId}&systemAppId=${systemAppId}`);
	}

	/**
	 * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
	 */
	async UpdateUserAssignmentsForSystemApp(userAssignments) {
		return this.Post(`UpdateUserAssignmentsForSystemApp`, userAssignments);
	}
}