import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import StructureManager from "../../Utilities/StructureManager";
import { getPermissions, Permissions } from "../../Utilities/Permissions";
import BusyIndicator from "../Core/BusyIndicator";
import { StructureDropDown } from "../Core/StructureFilter";
import { Formik, Form as FormikForm } from "formik";
import SelectField from "../Core/Forms/SelectField";
import Select, { components } from "react-select";
import { DateTime } from "luxon";
import DateField from "../Core/Forms/DateField";
import ReactPDF from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import MaturityDate from "./MaturityDate";
import VendorPayments  from "./VendorPayments";
import PayablesDue from "./PayablesDue";
import Recoveries from "./Recoveries";
import FacilityMovementReport from "./FacilityMovementReport";

export default function ReportModal(props) {
	const store = useSessionStore();
	const initialValues = [{ value: -1, label: 'Select all' }]
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);

	const [groupId, setGroupId] = useState(0);
	const [marketPositionId, setMarketPositionId] = useState("");
	const [storeId, setStoreId] = useState("");
	const [vendor, setVendor] = useState([]);
	const [vendorId, setVendorId] = useState(0);
	const [paymentInstructionId, setPaymentInstructionId] = useState(0);
	const [assignableContacts, setAssignableContacts] = useState([]);
	const [paymentInstructions, setPaymentInstructions] = useState([]);
	const [selectContactIsDisabled, setSelectContactIsDisabled] = useState(true);
	const [facilities, setFacilities] = useState([]);
	const [facilityOptions, setFacilityOptions] = useState(initialValues);
	const [facilityOptionsAll, setFacilityOptionsAll] = useState([]);
	const [detailed, setDetailed] = useState(null);
	const [generatingPreview, setGeneratingPreview] = useState(false);

	const structure = useRef(new StructureManager(store.Session.AuthState.UserAppStructure.AvailableStructure, true));
	const permissions = getPermissions(Permissions.Sections.AllFacilities, Permissions.Areas.CreditManagement, Permissions.SubAreas.Facilities, store.Session);

	useEffect(() => {
		async function getAssignableFacilityContacts() {
			var results = await store.ReportsService.GetContacts();

			if (results && results.Success) {
				setAssignableContacts(results.Data.Contacts);
				setSelectContactIsDisabled(false);
			}
		}

		async function getPaymentInstructions() {
			var results = await store.ReportsService.GetPaymentInstructions();

			if (results && results.Success) {

				setPaymentInstructions(results.Data);
				setSelectContactIsDisabled(false);
			}
		}

		if (props.show) {
			if (props.Name === "Contact Payments Report") {
				getAssignableFacilityContacts();
			} else if (props.Name === "Payables Due Report" || props.Name === "Recoveries Report") {
				getPaymentInstructions();
			}
		}
	}, [props.Name, props.show, store.ReportsService]);

	async function getFacilities(groupId) {
		var result = await store.ReportsService.GetFacilities(groupId);

		if (result && result.Success) {
			var resultData = result.Data;
			setFacilityOptions(initialValues)
			setFacilityOptions(facilityOptions => facilityOptions.concat(resultData.map(item => ({
				value: item.Id,
				label: item.Number + " - " + item.Name
			}))));
			setFacilityOptionsAll(resultData.map(item => ({
				value: item.Id,
				label: item.Number + " - " + item.Name
			})));

			setFacilities([]);
		}
	}

	function setGroup(groupId) {
		structure.current.SetSelectedGroup(groupId);
		setGroupId(groupId);
		setMarketPositionId("");
		setStoreId("");

		if (props.Name === "Facility Movement Report") {
			getFacilities(groupId);
		}
	}

	function setMarketPosition(marketPositionId) {
		structure.current.SetSelectedMarketPosition(marketPositionId);
		setMarketPositionId(marketPositionId);
		setStoreId("");
	}

	function setStore(storeId) {
		structure.current.SetSelectedStore(storeId);
		setStoreId(storeId);
	}

	function handleSelectedContactChanged(value) {
		if (value !== "") {
			var contact = assignableContacts.find((contact) => contact.Id === parseInt(value));
			setPaymentInstructions(contact.vendorInstructions)
			setVendor(contact);
			setVendorId(contact.Id);
			setPaymentInstructionId("");
		} else {
			setVendor(null);
			setVendorId(null);
		}

		return true;
	}

	function handleSelectedPiChanged(value) {
		if (value !== "") {
			var contact = paymentInstructions.find((contact) => contact.Id === parseInt(value));
			setPaymentInstructionId(contact.Id);
		} else {
			setPaymentInstructionId(null);
		}
	}

	var handleSelectedFacility = (facilities) => {
		if (facilities.find(x => x.value === -1)) {
			setFacilities(facilityOptionsAll)
		} else {
			setFacilities(facilities);
		}
	};

	function getFacilitiesIds() {
		let facilityIds = [];

		for (let i = 0; i < facilities.length; i++) {
			facilityIds.push(facilities[i].value);
		}

		return facilityIds;
	}

	function onClose() {
		setSelectContactIsDisabled(true);
		setVendor(null);
		setVendorId(null);
		setFromDate(null);
		setToDate(null);
		setPaymentInstructionId(null);
		setPaymentInstructions([]);
		setGroupId(null);
		setGroup("");
		setMarketPosition("");
		setStore("");
		setFacilities([]);
		setGeneratingPreview(false);

		props.onClose()
	}

	function handleDetails(detailed) {
		if (detailed === "D") {
			setDetailed(true);
		} else if (detailed === "S") {
			setDetailed(false);
		} else {
			setDetailed(null);
		}
	};
	
	const CustomDropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="15"  // Adjust the width here
					height="15" // Adjust the height here
					viewBox="0 0 20 20"
				>
					<path
						d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
						fill="black"
						stroke="white"
					/>
				</svg>
			</components.DropdownIndicator>
		);
	};

	function getReportFields() {
		switch(props.Name) {
			case "Maturity Date Report":
				return <Form className="mb-50">
							<DateField col={ 9 } className="mb-50" required invalid={ !fromDate } label="From" value={ fromDate } onChange={ setFromDate }></DateField>
							<DateField col={ 9 } className="mb-50" required invalid={ !toDate } label="To" value={ toDate } onChange={ setToDate }></DateField>
							<StructureDropDown invalid={ !groupId } label="Group" value={structure.current.GroupId} structure={structure.current.Groups} onChange={setGroup}></StructureDropDown>
							<StructureDropDown invalid={ marketPositionId !== 0 && !marketPositionId } allowAll label="Market Position" value={structure.current.MarketPositionId} structure={structure.current.MarketPositions} onChange={setMarketPosition}></StructureDropDown>
							<StructureDropDown invalid={ storeId !== 0 && !storeId } allowAll label="Store" value={structure.current.StoreId} structure={structure.current.Stores} onChange={setStore}></StructureDropDown>
						</Form>
			case "Contact Payments Report":
				return <React.Fragment>
							{
								selectContactIsDisabled === true &&
								<div className="d-flex w-100 h-100 justify-content-center align-items-center">
									<BusyIndicator size="3x" show></BusyIndicator>
								</div>
							}
							{selectContactIsDisabled === false &&
								<React.Fragment>
									<SelectField className="mb-50" invalid={ !vendorId } label="Contact" value={vendorId} required onChange={e => handleSelectedContactChanged(e.target.value)}>
										<option value="">Select Contact</option>
										{
											assignableContacts.map((value) =>
												<option key={value.Id} value={value.Id}>{value.Name}</option>)
										}
									</SelectField>
									<SelectField className="mb-50" label="Payment Instruction" invalid={ !paymentInstructionId } value={paymentInstructionId} required onChange={e => handleSelectedPiChanged(e.target.value)}>
										<option value="">Select Payment Instruction</option>
										{
											paymentInstructions.map((value) =>
												<option value={value.Id}>{value.piNumber} - {value.Name}</option>
											)
										}
									</SelectField>
								</React.Fragment>
							}
						</React.Fragment>
			case "Payables Due Report":
			case "Recoveries Report":
				return <React.Fragment>
							{
								selectContactIsDisabled === true &&
								<div className="d-flex w-100 h-100 justify-content-center align-items-center">
									<BusyIndicator size="3x" show></BusyIndicator>
								</div>
							}
							{
								selectContactIsDisabled === false &&
								<SelectField label="Payment Instruction" invalid={ !paymentInstructionId } value={ paymentInstructionId } required onChange={e => handleSelectedPiChanged(e.target.value)} disabled={selectContactIsDisabled}>
									<option value="">Select Payment Instruction</option>
									{
										paymentInstructions.map((value) =>
											<option value={value.Id}>{value.piNumber} - {value.Name}</option>)
									}
								</SelectField>
							}
						</React.Fragment>
			case "Facility Movement Report":
				return <React.Fragment>
							<DateField col={ 9 } className="mb-50" required invalid={ !fromDate } label="From" value={ fromDate } onChange={ setFromDate } maxDate={DateTime.fromISO(DateTime.now()).toJSDate()}></DateField>
							<DateField col={ 9 } className="mb-50" required invalid={ !toDate } label="To" value={ toDate } onChange={ setToDate } maxDate={DateTime.fromISO(DateTime.now()).toJSDate()}></DateField>
							<StructureDropDown label="Group" value={structure.current.GroupId} invalid={structure.current.GroupId === ""} structure={structure.current.Groups} onChange={setGroup} required></StructureDropDown>
							<Row className="mb-50">
								<Col xs={3}>
									<label style={{ marginTop: 10 }}>Facility</label>
								</Col>
								<Col>
									<Select options={facilityOptions} isMulti value={facilities} name="SelectFacility" onChange={handleSelectedFacility} className={facilities.length === 0 ? "form-control is-invalid p-0" : "form-control p-0"} placeholder="---Select---" 
											components={{ DropdownIndicator: CustomDropdownIndicator }} 
											styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: "white", "&:hover": { borderColor: "white" } }), 
											dropdownIndicator: (baseIndicator) => ({ ...baseIndicator, paddingRight: 14 }),
											 placeholder: (basePlaceholder) => ({ ...basePlaceholder, color: "black" }) }}>
									</Select>
								</Col>
							</Row>
							<SelectField label="View Preference" required col={9} className="mb-50" invalid={ detailed === null } onChange={e => handleDetails(e.target.value)}>
								<option value="">---Select---</option>
								<option value="D">Detailed</option>
								<option value="S">Summary</option>
							</SelectField>
						</React.Fragment>
			default:
				return false;
		}
	}

	async function handlePreviewClicked() {
		if(generatingPreview === false) {
			setGeneratingPreview(true);
			var result = null;
			if(props.Name === "Maturity Date Report") {
				if(fromDate && toDate && groupId && (marketPositionId === 0 || marketPositionId) && (storeId === 0 || storeId)) {
					result = await store.ReportsService.GetMaturityDateReport(fromDate, toDate, groupId, marketPositionId, storeId);

					if(result && result.Success) {
						const blob = await ReactPDF.pdf(MaturityDate({ data: result.Data })).toBlob();
						saveAs(blob, `Maturity Date Report.pdf`);					
					}
				}
			} else if(props.Name === "Recoveries Report") {
				if(paymentInstructionId) {
					result = await store.ReportsService.GetRecoveriesReport(paymentInstructionId);

					if(result && result.Success) {
						const blob = await ReactPDF.pdf(Recoveries({ data: result.Data })).toBlob();
						saveAs(blob, `Recoveries Report -${result.Data.FinanceDetails.PaymentInstructionBalance.Name}.pdf`);
					}
				}
			} else if(props.Name === "Payables Due Report") {
				if(paymentInstructionId) {
					result = await store.ReportsService.GetPayablesDueReport(paymentInstructionId);

					if(result && result.Success) {
						const blob = await ReactPDF.pdf(PayablesDue({ data: result.Data })).toBlob();
						saveAs(blob, `Payables Due Report - ${result.Data.FinanceDetails.PaymentInstructionBalance.Name}.pdf`);
					}
				}				
			} else if(props.Name === "Contact Payments Report") {
				if(vendorId && paymentInstructionId) {
					result = await store.ReportsService.GetVendorReport(vendorId, paymentInstructionId);

					if(result && result.Success) {
						const blob = await ReactPDF.pdf(VendorPayments({ data: result.Data })).toBlob();
						saveAs(blob, `Contact Payment Report - ${result.Data.FinanceDetails.PaymentInstructionBalance.Name}.pdf`);
					}
				}				
			} else if(props.Name === "Facility Movement Report") {
				if(fromDate && toDate && groupId && detailed !== null && getFacilitiesIds().length > 0) {
					result = await store.ReportsService.GetFacilitiesMovementReport(fromDate, toDate, groupId, getFacilitiesIds(), detailed);

					if(result && result.Success) {
						const blob = await ReactPDF.pdf(FacilityMovementReport({ data: result.Data })).toBlob();
						saveAs(blob, `Facility Movement Report - ${result.Data.Header.GroupName} ${result.Data.Detailed === true ? " (Detailed)" : " (Summary)"}.pdf`);
					}
				}
			}

			setGeneratingPreview(false);
		}
	}

	return <Modal size="lg" enforceFocus={false} show={props.show}>
				<Formik>
					<FormikForm>
						<Modal.Header style={{ backgroundColor: '#38a7df' }}>
							<h4 style={{ color: 'white', fontWeight: '300' }}>
								{props.Name}
							</h4>
						</Modal.Header>
						<Modal.Body>
							{ getReportFields() }
						</Modal.Body>
						<Modal.Footer>
							{
								permissions.Any() &&
								<Button onClick={ handlePreviewClicked } disabled={ generatingPreview } variant="primary">{ generatingPreview ? "Running" :"Run" }</Button>
							}
							<Button onClick={() => onClose()}>Cancel</Button>
						</Modal.Footer>
					</FormikForm>
				</Formik>
			</Modal>
}