import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { getPermissions, Permissions } from "../../../../Utilities/Permissions";
import PageHeader from "../../../Core/PageHeader";
import Pagination from "../../../Core/Pagination";
import TransactionDocumentsFilters from "./TransactionDocumentsFilters";
import DateValue from "../../../Core/DateValue";
import NumberValue from "../../../Core/NumberValue";
import Panel from "../../../Core/Panel";
import BusyIndicator from "../../../Core/BusyIndicator";
import { getDocumentTypeText } from "../../../../Utilities/TransactionDocuments";
import { FacilityTypes } from "../../../../Utilities/Enums";
import IconButton from "../../../Core/IconButton";
import { faFileExcel, faPlus, faUpload } from "@fortawesome/pro-regular-svg-icons";
import { Link, NavLink } from "react-router-dom";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

const TransactionDocumentsIndex = observer(function TransactionDocumentsIndex(props) {

	

	if(props.facilityType === 1){
		UseDocumentTitle('Listing \'Vendor Documents\' in \'Trade Finance\' on CREST');
	}else{
		UseDocumentTitle('Listing \'Customer Documents\' in \'Debt Finance\' on CREST');
	}

	const store = useSessionStore();
	const financeStore = store.GetFinanceStore(props.facilityType);

	const navigate = useNavigate();

	useEffect(() => {
		financeStore.GetTransactionDocuments();
	}, []);

	async function handleExportDocumentsClicked() {
		var result = await financeStore.ExportTransactionDocuments();

		if(result) {
			//save file
			var blob = new Blob([result], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `${financeStore.GetContactType().toUpperCase()}_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);
		}
	}

	function handlePageChange(pageIndex, pageCount) {
		financeStore.SetTransactionDocumentsFilters({...financeStore.TransactionDocumentsFilters, PageIndex: pageIndex, PageCount: pageCount });
		financeStore.GetTransactionDocuments();
	}

	const permissions = getPermissions(props.facilityType === FacilityTypes.TradeFinance ? Permissions.Sections.VendorDocuments : Permissions.Sections.CustomerDocuments, 
										Permissions.Areas.CreditManagement, 
										props.facilityType === FacilityTypes.TradeFinance ? Permissions.SubAreas.TradeFinance : Permissions.SubAreas.DebtorFinance, 
										store.Session);
	
	return 	<React.Fragment>
				<PageHeader icon={ financeStore.GetIcon() } title={ `${financeStore.GetContactType()} Documents` } 
							helpItemDescriptor={ `${financeStore.GetContactType()}Document/Index` }
								crumbs={[{ Title: "Credit Management" }, { Title: `${financeStore.GetFinanceType()} Finance` }, { Title: `${financeStore.GetContactType()} Documents` }]}>
					{
						permissions.Add &&
						<React.Fragment>
							<NavLink to={ "Add" }><IconButton icon={ faPlus } space>Add New</IconButton></NavLink>
							<NavLink to={ "Import" }><IconButton icon={ faUpload } space>Bulk Import</IconButton></NavLink>
						</React.Fragment>
					}
					{
						permissions.View &&
						<IconButton icon={ faFileExcel } disabled={ financeStore.ExportingDocuments } onClick={handleExportDocumentsClicked}>
							{ financeStore.ExportingDocuments ? "Exporting..." : "Export All" }
						</IconButton>
					}
				</PageHeader>
				<Panel>
					<TransactionDocumentsFilters facilityType={ props.facilityType }></TransactionDocumentsFilters>
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
								<th>Document Date</th>
								<th>{ props.facilityType === FacilityTypes.TradeFinance ? "Trade Vendor" : "Customer Name" }</th>
								<th>Store</th>
								<th>Type</th>
								<th>Document No.</th>
								<th className="text-end">Payment Amount</th>
								<th>Due Date</th>
							</tr>
						</thead>
						<tbody>
							{
								!financeStore.RetrievingDocuments &&
								financeStore.TransactionDocuments.length > 0 &&
								financeStore.TransactionDocuments.map((document, index) =>
									<tr key={ index }>
										<td className="text-nowrap"><DateValue outputFormat="d MMM yyyy" date={ document.DocumentDate }></DateValue></td>
										<td>
											{
												permissions.View &&
												<NavLink to={`/${financeStore.GetFinanceType()}Finance/${financeStore.GetContactType()}Documents/View/${document.Id}`}>{document.ContactName}</NavLink>
											}
											{
												!permissions.View &&
												<span>{ document.ContactName }</span>
											}
										</td>										
										<td>{ document.StoreName }</td>
										<td>{ getDocumentTypeText(document.DocumentType) }</td>
										<td>{ document.DocumentNumber }</td>
										<td className="text-nowrap text-end"><NumberValue currency={ document.Currency } value={ document.PaymentAmount }></NumberValue></td>
										<td className="text-nowrap"><DateValue outputFormat="d MMM yyyy" date={ document.DueDate }></DateValue></td>
									</tr>
								)
							}
						</tbody>						
					</Table>
					<BusyIndicator show={ financeStore.RetrievingDocuments }></BusyIndicator>
					{
						!financeStore.RetrievingDocuments &&
						<Pagination filter={ financeStore.TransactionDocumentsFilters } totalResults={ financeStore.TransactionDocuments.length } 
								onPageChanged={ handlePageChange }></Pagination>
					}
					
				</Panel>
			</React.Fragment>
});

export default TransactionDocumentsIndex;