import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import NumberValue from "../../Core/NumberValue";
	
export default function ReimbursementDetailsModal(props) {
	const store = useSessionStore();
	
	const [retrievingData, setRetrievingData] = useState(true);
	const [details, setDetails] = useState(null);

	async function getDetails() {
		setRetrievingData(true);
		var result = await store.ReimbursementsService.GetReimbursementsForPaymentInstruction(props.paymentInstructionId);

		if(result && result.Success) {
			setDetails(result.Data);
		}

		setRetrievingData(false);
	}

	useEffect(() => {
		if(props.show) {
			getDetails();
		}
	}, [props.show]);

	return 	<Modal show={ props.show } size="lg">				
				<Modal.Body>
					<h4>Reimbursements</h4>
					{
						!retrievingData && details &&
						<Table size="sm">
							<thead>
								<tr>
									<th>Document No.</th>
									<th>Store</th>
									<th className="text-end">Payment Amount</th>
								</tr>
							</thead>
							<tbody>
								{
									details && details.length > 0 &&
									details.map((r, index) =>
										<tr key={ index }>
											<td>{r.DocumentNumber}</td>
											<td>{r.Store}</td>
											<td className="text-end"><NumberValue value={r.PaymentAmount} currency={r.Currency}></NumberValue></td>
										</tr>)
								}
							</tbody>
						</Table>
					}
					<BusyIndicator show={ retrievingData }></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={ retrievingData } onClick={ e => props.onClose() }>Cancel</Button>
				</Modal.Footer>
			</Modal>
}