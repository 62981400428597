import FacilityDetails from "./Components/FacilityDetails";
import { Formik, Form as FormikForm } from "formik";
import React, { useState } from "react";
import BusyIndicator from "../../../Core/BusyIndicator";
import UserAuthorisation from "./Components/UserAuthorisation/UserAuthorisation";
import { useEffect } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import FeesAndCosts from "./Components/FeesAndCosts";
import Dates from "./Components/Dates";
import FinancialCovenants from "./Components/FinancialCovenants";
import SecuritiesAndRequirements from "./Components/SecuritiesAndRequirements";
import Status from "./Components/Status";
import { checkMarginsForZeroes, FacilityValidationSchema, getCurrentRate, getNewFacility, isFieldEnabled, validateBaseInterestRateChanges } from "../../../../Utilities/Facilities";
import { Button, Col, Row } from "react-bootstrap";
import { FacilityStatuses, UserRoles } from "../../../../Utilities/Enums";
import { useNavigate, useParams } from "react-router-dom";
import FacilityBalances from "./Components/Balances/FacilityBalances";
import { faBuildingColumns } from "@fortawesome/pro-regular-svg-icons";
import PageHeader from "../../../Core/PageHeader";
import AuditLogEntitySummary from "../../AuditLogs/AuditLogEntitySummary";
import Documents from "../../../Core/Documents/Documents";
import { Roles } from "../../../../Utilities/Permissions";
import swal from "sweetalert";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

export default function Facility(props) {
	const [facilityName, setFacilityName] = useState('');

	if(props.add) {
		UseDocumentTitle('Adding New Facility');
	} else {
		UseDocumentTitle('Editing \'' + facilityName + '\'');
	}

	const store = useSessionStore();
	const params = useParams();
	const navigate = useNavigate();

	const [savingFacility, setSavingFacility] = useState(false);
	const [facility, setFacility] = useState(null);
	const [facilityDetails, setFacilityDetails] = useState(null);	
	const [currencies, setCurrencies] = useState(null);
	const [authUsers, setAuthUsers] = useState(null);
	const [offtakers, setOfftakers] = useState(null);
	const [financialTerms, setFinancialTerms] = useState(null);
	
	function cancelClicked() {
		return navigate(`/Facilities/`);
	}

	useEffect(() => {
		async function getFacility() {
			var result = await store.FacilitiesService.GetFacility(params.facilityId);			

			if(result && result.Success) {
				var facility = result.Data.Facility;
				facility.View = props.view;

				if(facility){
					setFacilityName(facility.Name);
				}

				//make sure null values are reset
				facility.Factor = facility.Factor ? facility.Factor : "";
				facility.OffTakerContactId = facility.OffTakerContactId ? facility.OffTakerContactId : null;
				facility.FixedBaseRateExpiryDate = facility.FixedBaseRateExpiryDate ? facility.FixedBaseRateExpiryDate : "";
				facility.PayableTransactionApprovalUserOne = facility.PayableTransactionApprovalUserOne ? facility.PayableTransactionApprovalUserOne : "";
				facility.PayableTransactionApprovalUserTwo = facility.PayableTransactionApprovalUserTwo ? facility.PayableTransactionApprovalUserTwo : "";
				facility.PayableTransactionVerificationUserOne = facility.PayableTransactionVerificationUserOne ? facility.PayableTransactionVerificationUserOne : "";
				facility.PayableTransactionVerificationUserTwo = facility.PayableTransactionVerificationUserTwo ? facility.PayableTransactionVerificationUserTwo : "";
				facility.PaymentInstructionConfirmationUserOne = facility.PaymentInstructionConfirmationUserOne ? facility.PaymentInstructionConfirmationUserOne : "";
				facility.PaymentInstructionConfirmationUserTwo = facility.PaymentInstructionConfirmationUserTwo ? facility.PaymentInstructionConfirmationUserTwo : "";
				facility.PaymentInstructionAuthorisationUserOne = facility.PaymentInstructionAuthorisationUserOne ? facility.PaymentInstructionAuthorisationUserOne : "";
				facility.PaymentInstructionAuthorisationUserTwo = facility.PaymentInstructionAuthorisationUserTwo ? facility.PaymentInstructionAuthorisationUserTwo : "";
				facility.DebtTransactionApprovalUserOne = facility.DebtTransactionApprovalUserOne ? facility.DebtTransactionApprovalUserOne : "";
				facility.DebtTransactionApprovalUserTwo = facility.DebtTransactionApprovalUserTwo ? facility.DebtTransactionApprovalUserTwo : "";
				facility.DebtTransactionVerificationUserOne = facility.DebtTransactionVerificationUserOne ? facility.DebtTransactionVerificationUserOne : "";
				facility.DebtTransactionVerificationUserTwo = facility.DebtTransactionVerificationUserTwo ? facility.DebtTransactionVerificationUserTwo : "";

				setFacility(facility);
				setFacilityDetails(result.Data);
			}
		}

		async function getCurrencies() {
			var result = await store.CurrenciesService.GetAvailableCurrencies("", 0, 0);

			if(result && result.Success) {
				setCurrencies(result.Data);
			}
		}

		async function getAuthUsers() {
			var result = await store.FacilitiesService.GetAssignableUsers(params.facilityId || 0);

			if(result && result.Success) {
				setAuthUsers(result.Data);
			}
		}
		
		async function getOffTakers() {
			var result = await store.ContactsService.GetOffTakerContacts();

			if(result && result.Success) {
				setOfftakers(result.Data);
			}
		}

		async function getFinancialTerms() {
			var result = await store.FinancialTermsService.GetCurrentFinancialTerms();

			if(result && result.Success) {
				setFinancialTerms(result.Data);
			}
		}

		if(props.add) {
			setFacility(getNewFacility(store.Session.PreferredCurrencyId));
			setFacilityDetails({});
		} else {
			getFacility();
		}

		getCurrencies();
		getAuthUsers();
		getOffTakers();
		getFinancialTerms();
		
	}, []);

	function getFacilityCurrencySymbol(facility) {
		var currency = currencies.find(c => c.Id === parseInt(facility.CurrencyID));

		if(currency) {
			return currency.Symbol;
		}

		return "";
	}
	
	async function saveFacility(values) {
		//set blank strings for nullable fields to null
		values.Comment = values.Comment || "";
		
		values.CostFinancePercentage = values.UseFixedBaseRate ? getCurrentRate(values.BaseInterestRates ): 0;
		values.UnderutilisationFee = values.UseFixedBaseRate ? getCurrentRate(values.BaseInterestRates) : 0;

		values.FacilityStatusID = values.FacilityStatusID === 0 ? FacilityStatuses.Pending : values.FacilityStatusID;
		values.Factor = values.Factor ? values.Factor : null;
		values.OffTakerContactId = values.OffTakerContactId ? values.OffTakerContactId : null;
		values.FixedBaseRateExpiryDate = values.FixedBaseRateExpiryDate ? values.FixedBaseRateExpiryDate : null;
		values.PayableTransactionApprovalUserOne = values.PayableTransactionApprovalUserOne ? values.PayableTransactionApprovalUserOne : null;
		values.PayableTransactionApprovalUserTwo = values.PayableTransactionApprovalUserTwo ? values.PayableTransactionApprovalUserTwo : null;
		values.PayableTransactionVerificationUserOne = values.PayableTransactionVerificationUserOne ? values.PayableTransactionVerificationUserOne : null;
		values.PayableTransactionVerificationUserTwo = values.PayableTransactionVerificationUserTwo ? values.PayableTransactionVerificationUserTwo : null;
		values.PaymentInstructionConfirmationUserOne = values.PaymentInstructionConfirmationUserOne ? values.PaymentInstructionConfirmationUserOne : null;
		values.PaymentInstructionConfirmationUserTwo = values.PaymentInstructionConfirmationUserTwo ? values.PaymentInstructionConfirmationUserTwo : null;
		values.PaymentInstructionAuthorisationUserOne = values.PaymentInstructionAuthorisationUserOne ? values.PaymentInstructionAuthorisationUserOne : null;
		values.PaymentInstructionAuthorisationUserTwo = values.PaymentInstructionAuthorisationUserTwo ? values.PaymentInstructionAuthorisationUserTwo : null;
		values.DebtTransactionApprovalUserOne = values.DebtTransactionApprovalUserOne ? values.DebtTransactionApprovalUserOne : null;
		values.DebtTransactionApprovalUserTwo = values.DebtTransactionApprovalUserTwo ? values.DebtTransactionApprovalUserTwo : null;
		values.DebtTransactionVerificationUserOne = values.DebtTransactionVerificationUserOne ? values.DebtTransactionVerificationUserOne : null;
		values.DebtTransactionVerificationUserTwo = values.DebtTransactionVerificationUserTwo ? values.DebtTransactionVerificationUserTwo : null;
		values.CurrencySymbol = getFacilityCurrencySymbol(values);

		for(let i = 0; i < values.Securities.length; i++) {
			values.Securities[i].Field = values.Securities[i].Field || "";
			values.Securities[i].Value = values.Securities[i].Value || "";
		}

		for(let i = 0; i < values.Covenants.length; i++) {
			values.Covenants[i].Field = values.Covenants[i].Field || "";
			values.Covenants[i].Value = values.Covenants[i].Value || "";
		}

		setSavingFacility(true);

		var result = {};
		if(values.Id) {
			result = await store.FacilitiesService.UpdateFacility(values);
		} else {
			result = await store.FacilitiesService.CreateFacility(values);
		}

		setSavingFacility(false);

		if(result && result.Success) {
			swal({ title: "Saved", text: "You have saved your facility successfully.", icon: "success", closeOnClickOutside: false });
			
			store.FacilitiesStore.GetFacilities();
			store.TradeFinanceStore.GetTransactions();
			store.DebtorFinanceStore.GetTransactions();

			navigate(`/Facilities/`);
		} else {
			if(result) {
				swal({ title: "Error Saving Facility", text: result.Message, icon: "error", closeOnClickOutside: false });
			} else {
				swal({ title: "Error", text: "An error ocurred attempting to save the Facility", icon: "error", closeOnClickOutside: false });
			}
		}
	}

	async function handleFormSubmit(values) {
		if(validateBaseInterestRateChanges(facility, values) === false) {
			swal({ title: "An error occurred saving the facility", text: "If Fixed Base Rate has changed then the End Date also needs to be updated.", icon: "error", closeOnClickOutside: false })
			return;
		}

		//first make sure there is at least one funder with an active limit
		var fundersAvailable = (!values.LinkedFunders ||
									values.LinkedFunders.find(funder => funder.Active && funder.LimitHistory.find(history => history.Active)) !== undefined);

		if(!fundersAvailable) {
			swal({ title: "No Active Funders", text: "Cannot save a facility without any active funders.", icon: "error", closeOnClickOutside: false })
			return;
		}

		//now check to make sure the fixed base rate is set if needed
		if(values.UseFixedBaseRate && !values.FixedBaseRateExpiryDate) {
			swal({ title: "No Fixed Base Rate Expiry", text: "If fixed base rate is used, a fixed base rate expiry date must be provided", icon: "error", closeOnClickOutside: false });
			return;
		}

		//now warn if there are any margins that equal 0, we only check margins on new facilities
		if (!values.Id) {
			var result = await checkMarginsForZeroes(values);

			if(result) {
				saveFacility(values);
			}
		} else {
			saveFacility(values);
		}
	}
	
	const allowDocChanges = facility && (isFieldEnabled([], [FacilityStatuses.Active, FacilityStatuses.Pending], facility.FacilityStatusID, store.Session) || 
											isFieldEnabled([Roles.Admin], [FacilityStatuses.Expired, FacilityStatuses.Cancelled], facility.FacilityStatusID, store.Session));
	let allowAgreements = false;

	if(!props.view) {
		if(props.add) {
			allowAgreements = isFieldEnabled([Roles.Admin, UserRoles.GroupAdmin, UserRoles.UserWithControl], [FacilityStatuses.Active], 0, store.Session);
		} else {								
			allowAgreements = (facility && (isFieldEnabled([Roles.Admin, UserRoles.GroupAdmin, UserRoles.UserWithControl], [FacilityStatuses.Active, FacilityStatuses.Pending], facility.FacilityStatusID, store.Session))) 
		}
	}
	
	return 	<React.Fragment>
				{
					currencies && authUsers && offtakers && financialTerms && facilityDetails &&
					<React.Fragment>
						<PageHeader icon={ faBuildingColumns } helpItemDescriptor={ `Facility/${ facility.Id ? "Edit" : "Add"}` } title="Facilities" crumbs={[{ Title: "Credit Management" }, { Title: "Facilities" }, { Active: true, Title: `Editing ${facility.Name}` }]}></PageHeader>
						{
							facility.Id &&
							<AuditLogEntitySummary table="Facilities" entityid={ facility.Id } auditsummary={facility.AuditSummary}></AuditLogEntitySummary>
						}

						<Formik initialValues={ facility } validateOnChange={false} enableReinitialize validateOnMount validationSchema={ FacilityValidationSchema } onSubmit={ handleFormSubmit }>
							{({ values, handleSubmit }) => (

								<FormikForm>
									<FacilityDetails currency={ getFacilityCurrencySymbol(values) } financialTerms={financialTerms}	currencies={ currencies } offtakers={ offtakers }></FacilityDetails>
									<Dates></Dates>
									<FeesAndCosts currency={ getFacilityCurrencySymbol(values) } values={ values } financialTerms={ financialTerms }></FeesAndCosts>
									<FinancialCovenants name="Covenants" agreements={ values.Covenants } view={!allowAgreements}></FinancialCovenants>
									<SecuritiesAndRequirements name="Securities" agreements={ values.Securities } view={!allowAgreements}></SecuritiesAndRequirements>
									<UserAuthorisation facilityTypeId={ values.FacilityTypeID } users={ authUsers }></UserAuthorisation>
									<Status></Status>
									{
										values.Id &&
										<React.Fragment>
											<FacilityBalances facilityDetails={ facilityDetails } currency={ getFacilityCurrencySymbol(values) }></FacilityBalances>
											<Documents updateEntity={ setFacility }  viewOnly={ props.view } allowDocChanges={ allowDocChanges } entity={ facility }></Documents>
										</React.Fragment>
									}
									<Row>
										<Col className="d-flex justify-content-end">
											{
												!props.view &&
												<Button disabled={ savingFacility } type="submit" className="me-1">{ `${(savingFacility ? "Saving..." : "Save")}`}</Button>
											}
											<Button disabled={ savingFacility } onClick={ () =>  cancelClicked()} >Cancel</Button>
										</Col>
									</Row>
								</FormikForm>)
							}
						</Formik>
					</React.Fragment>
				}
				<BusyIndicator size="2x" show={!currencies || !authUsers || !offtakers || !financialTerms || !facilityDetails}></BusyIndicator>
			</React.Fragment>
}