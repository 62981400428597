import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { Table} from "react-bootstrap";
import { useSessionStore } from "../../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../../Core/BusyIndicator";
import Panel from "../../../../../Core/Panel";

export default function Systems(props) {
	const store = useSessionStore();
	const formik = useFormikContext();

	const [availableSystems, setAvailableSystems] = useState(null);

	const associatedSystems = formik.values.AssociatedSystems;

	async function getSystemApps() {
		let results = await store.SystemAppsService.GetSystemApps();
		
		if(results && results.Success) {
			setAvailableSystems(results.Data);
		}
	}

	useEffect(() => {
		getSystemApps();
	}, []);

	function systemIsChecked(system) {
		let associatedSystem = associatedSystems.find(s => s.SystemId === system.Id);

		if(associatedSystem)
			return true;

		return false;
	}

	function accessLevelIsChecked(system, accessLevel) {
		let associatedSystem = associatedSystems.find(s => s.SystemId === system.Id);

		if(associatedSystem && associatedSystem[accessLevel]) {
			return true;
		}

		return false;
	}

	function setAccessLevel(system, accessLevel) {
		let associatedSystem = associatedSystems.find(s => s.SystemId === system.Id);

		if(associatedSystem) {
			associatedSystem.GroupAdminLevel = false;
			associatedSystem.GroupLevel = false;
			associatedSystem.MarketPositionLevel = false;
			associatedSystem.StoreLevel = false;

			associatedSystem[accessLevel] = true;

			formik.setFieldValue("AssociatedSystems", associatedSystems, true);
		}
	}

	function setSystemAssociation(system, associated) {
		if(associated === false) {
			let associatedSystemIndex = associatedSystems.findIndex(s => s.SystemId === system.Id);

			if(associatedSystemIndex > -1) {
				associatedSystems.splice(associatedSystemIndex, 1);
			}
		} else {
			let associatedSystem = associatedSystems.find(s => s.SystemId === system.Id);

			if(!associatedSystem) {
				associatedSystems.push({ 
					SystemId: system.Id,
					SystemName: system.Name,
					GroupAdminLevel: false,
                    GroupLevel: false,
                    MarketPositionLevel: false,
                    StoreLevel: false,
				});
			}
		}
		
		formik.setFieldValue("AssociatedSystems", associatedSystems, true);
	}

	return 	<Panel title="Systems">
				{
					availableSystems &&
					<Table bordered>
						<tbody>
							<tr>
								<td><b>System Name</b></td>
								<td colSpan={4}><b>Access Level</b></td>
							</tr>
							{
								availableSystems.map((s) =>
								<tr key={ s.SystemId }>
									<td>
										<label className="d-flex">
											<input disabled={ props.view } className="me-1" type="checkbox" checked={ systemIsChecked(s) } onChange={ (e) => setSystemAssociation(s, e.target.checked) }/>
											{ s.Name }
										</label>
									</td>
									<td>
										<label className="d-flex">
											<input disabled={ props.view } className="me-1" type="radio" name={ s.SystemName } onChange={ () => setAccessLevel(s, "GroupAdminLevel") } checked={ accessLevelIsChecked(s, "GroupAdminLevel") } label="Group Admin"/>
											Group Admin
										</label>
									</td>
									<td>
										<label className="d-flex">
											<input disabled={ props.view } className="me-1" type="radio" name={ s.SystemName } onChange={ () => setAccessLevel(s, "GroupLevel") } checked={ accessLevelIsChecked(s, "GroupLevel") } label="Group"/>
											Group
										</label>
									</td>
									<td>
										<label className="d-flex">
											<input disabled={ props.view } className="me-1" type="radio" name={ s.SystemName } onChange={ () => setAccessLevel(s, "MarketPositionLevel") } checked={ accessLevelIsChecked(s, "MarketPositionLevel") } label="Market Position"/>
											Market Position
										</label>
									</td>
									<td>
										<label className="d-flex">
											<input disabled={ props.view } className="me-1" type="radio" name={ s.SystemName } onChange={ () => setAccessLevel(s, "StoreLevel") } checked={ accessLevelIsChecked(s, "StoreLevel") } label="Store"/>
											Store
										</label>
									</td>
								</tr>)
							}
						</tbody>
					</Table>
				}
				<BusyIndicator show={ availableSystems === null }></BusyIndicator>
			</Panel>
}