var KeySize = 6;
var Domain = "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ";
var DomainSize = Domain.length;

export function GetRandomKey() {
	var key = "";

	for(var i = 0; i < KeySize; i++) {
		key += Domain[Math.floor((Math.random() * (DomainSize - 1)))];
	}

	return key;
}

export function removeModalTabIndex() {
	//remove the tab index from the modal so the month select drop down won't break
	//we'll need to wait until modal aniimations are complete
	setTimeout(() => {
		let modal = document.querySelector(".modal");

		if(modal) {
			modal.removeAttribute("tabindex");
		}
	}, 800);
}