import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextValue from "./TextValue";

export function StructureDropDown(props) {
	function getViewValue() {
		if(props.value === 0) {
			return "All";
		}

		let structure = props.structure.find(s => s.Id === props.value);

		if(structure) {
			return structure.Name;
		}

		return ""
	}

	return 	<Form.Group as={ Row} className={ `${ props.row ? "" : "d-flex"} align-items-center mb-50` }>
				<Form.Label xs={3} column>{ props.label }</Form.Label>
				<Col>
					{ 
						!props.view &&
						<Form.Select className={ props.invalid ? "form-control is-invalid" : "form-control" } required={ props.required } value={ props.value } onChange={ e => props.onChange(parseInt(e.target.value)) }>
							{
								props.value === "" &&
								<option value="">-- Select --</option>
							}
							{
								props.allowAll &&
								<option value="0">All</option>
							}
							{
								props.structure.map((s) =>
								<option key={ s.Id } value={ s.Id }>{s.Name}</option>
								)
							}
						</Form.Select>
					}
					{ 
						props.view &&
						<TextValue value={ getViewValue() }></TextValue>
					}
				</Col>
			</Form.Group>
}