import { Col, Form, Row } from "react-bootstrap";
import { Field, FastField, useFormikContext, useField } from "formik";
import TextValue from "../TextValue";

export default function SelectField(props) {
	const { setFieldValue, errors, values } = useFormikContext();
	const [field] = useField(props.name);
	const inputClass = `form-control ${(errors[field.name] || props.invalid ?  "is-invalid" : "")}`;
	
	function resolveValue() {
		var names = props.name.split(".")
		var object = values;

		for(var i = 0; i < names.length; i++) {
			object = object[names[i]];
		}

		return object;
	}

	function compareValue(option) {
		const formValue = resolveValue();

		if(formValue !== null && formValue !== undefined) {
			var value = option ? +option : "";

			if(isNaN(value)) {
				value = option;
			}

			if(value === formValue) {
				return true;
			}
		}

		return false;
	}

	function getViewValue() {
		if(props.view && props.children) {
			for(var i = 0; i < props.children.length; i++) {
				if(Array.isArray(props.children[i])) {
					for(let j = 0; j < props.children[i].length; j++) {
						if(compareValue(props.children[i][j].props.value)) {
							return props.children[i][j].props.children;
						}
					}
				} else {
					if(props.children[i] && compareValue(props.children[i].props.value)) {
						return props.children[i].props.children;
					}
				}
			}
		}

		return "";
	}

	function handleChange(event) {
		if(props.onChange) {
			if(!props.onChange(event)) {
				setFieldValue(props.name, event.target.value);
			}
		} else {
			setFieldValue(props.name, event.target.value);
		}
	}

	return 	<Form.Group as={Row} className={`${props.className || ""} align-items-center`}>
				{
					props.label &&
					<Form.Label column>{ props.label }</Form.Label>
				}
				{
					props.view &&
					<Col xs={ (props.label ?( props.col || 8): 12) }>
						<TextValue value={ getViewValue() }></TextValue>
					</Col>
				}
				{
					!props.view &&
					<Col xs={ (props.label ?( props.col || 8): 12) }>
						{
							props.fast &&
							<FastField required={ props.required } onChange={handleChange} as={ Form.Select } className={ inputClass } name={ props.name } disabled={props.disabled} >
							{
								props.children
							}
							</FastField>
						}
						{
							!props.fast && props.name &&
							<Field required={ props.required } onChange={handleChange} as={ Form.Select } className={ inputClass } name={ props.name } disabled={props.disabled}>
								{
									props.children
								}
							</Field>
						}
						{
							!props.fast && !props.name &&
							<Form.Select value={props.value} required={ props.required } onChange={props.onChange} disabled={props.disabled} className={ inputClass }>
								{
									props.children
								}
							</Form.Select>
						}
					</Col>
				}
			</Form.Group>
}