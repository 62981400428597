import APIBase from "./APIBase";

/**
 * @typedef AppHelpItem
 * @property { number }  Id
 * @property { string }  PageDescriptor
 * @property { string }  Content
 */

/**
 * @typedef AppHelpService
 * @type {AppHelpService}
 */
export default class AppHelpService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "AppHelp", sessionStore);
    }

    /**
     * @returns { import("./APIBase").RequestResult<AppHelpItem> }
     */
     async GetHelpItem(pageDescriptor) {
        return this.Get(`GetHelpItem?pageDescriptor=${encodeURIComponent(pageDescriptor)}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<Boolean> }
     */
	async CreateOrUpdateHelpItem(appHelpItem){ 
		return this.Post(`CreateOrUpdateHelpItem`, appHelpItem);
	}
}