import { Document, Page, View } from "@react-pdf/renderer";
import PayablesDueCustomer from "./Components/PayablesDue/PayablesDueCustomer";
import PayablesDueHeader from "./Components/PayablesDue/PayablesDueHeading";
import PayablesDueTotalsSummary from "./Components/PayablesDue/PayablesDueTotalsSummary";
import { styles } from "./StyleSheet";

export default function PayablesDue(props){

    return(
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <PayablesDueHeader data={props.data} faclity={props.data.Facility}/>
                    <View>
                        <View>
                            <PayablesDueCustomer data={props.data.Vendors} currency={props.data.Facility.Facility.CurrencySymbol} facilityType={props.data.Facility.Facility.FacilityTypeID} />
                        </View>
                    </View>
                    
                    <View>
                        <View style={styles.table}>
                            <PayablesDueTotalsSummary data={props.data} convertedTotals={props.data.ConvertedTotals} currency={props.data.Facility.Facility.CurrencySymbol}/>
                        </View>
                    </View>
                    
                </View>
            </Page>
        </Document>
    )
}