import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import { FacilityTypes, TransactionStatuses } from "../../../Utilities/Enums";
import BusyIndicator from "../../Core/BusyIndicator";
import Panel from "../../Core/Panel";
import ApprovalField from "./ApprovalField";
import AlreadyApproved from "./AlreadyApproved";
import swal from "sweetalert";
import ApprovalCompleted from "./ApprovalCompleted";
import { DateTime } from "luxon";
import Header from "./Header";
import { Formik, Form } from "formik";

export default function ApproveTransaction(props) {
	const store = useSessionStore();
	const location = useLocation();

	const [transaction, setTransaction] = useState(null);
	const [comment, setComment] = useState("");
	
	const [submitting, setSubmitting] = useState(false);
	const [submission, setSubmission] = useState({ Approving: false, Declining: false, Reviewing: false });
	const [submitted, setSubmitted] = useState(false);

	const [error, setError] = useState(false);

	store.ShowSidebar(false);

	async function getTransaction(id) {
		let result = await store.ApprovalsService.GetTransactionForApproval(id);

	

		if(result && result.Success) {
			setTransaction(result.Data);
		} else {
			if(result && !result.Success) {
				setError(true);
			}
		}
	}

	useEffect(() => {
		let params = location.search.split("&");

		let idParam = params[0].split("=");
		let id = idParam[1];

		if(id) {
			getTransaction(id);
		}
	}, []);

	async function submit(response) {
		let result = null;
		setSubmitting(true);

		let params = location.search.split("&");

		let idParam = params.length >1 ?params[1].split("="): null;
		
		let id = idParam != null ?idParam[1] : 0;
		

		if(response === 1) {
			//approve
			setSubmission({ Approving: true, Declining: false, Reviewing: false });
			result = await store.ApprovalsService.ApproveTransaction(transaction.Id, id, comment);
		} else if(response === 2) {
			//decline
			if(comment!==""){
				result = await store.ApprovalsService.DeclineTransaction(transaction.Id, id, false, comment);
			}
		} else if(response === 3) {
			//decline
			if(comment!==""){
				setSubmission({ Approving: false, Declining: false, Reviewing: true });
				result = await store.ApprovalsService.DeclineTransaction(transaction.Id, id, true, comment);
			}
		}

		setSubmitting(false);

		if(result && result.Success) {
			setSubmitted(true);
		} else if(result && !result.Success){
			swal({ icon: "error", title: "Error", text: "An error occurred attempting to update the transaction.", closeOnClickOutside: false });
		}
	}

	function getAction() {
		if(transaction) {
			if(submission.Approving) {
				if(transaction.ApprovalLevel < 3) {
					return "approved"
				} else {
					return "verified";
				}
			} else {
				return "declined";
			}
		}

		return "";
	}

	function getButtonAction() {
		if(transaction) {
			if(transaction.ApprovalLevel < 3) {
				if(submission.Approving)
					return "Approving...";
				else
					return "Approve"
			} else {
				if(submission.Approving)
					return "Verifying...";
				else
					return "Verify"
			}
		}

		return "";
	}

	

	return 	<Container>
				<Row>
					<Col lg={8} className="offset-2">
						{
							error &&
							<AlreadyApproved transaction></AlreadyApproved>
						}
						{
							transaction && !error && !submitted &&
							<React.Fragment>
								<Panel>
									<Header showLogo title={ `${(transaction.TransactionStatusId < TransactionStatuses.Approved ? "Approval" : "Verification")} of ${(transaction.FacilityTypeId === FacilityTypes.TradeFinance ? "Payable" : "Debt")} Transaction no ${transaction.DocumentNumber}` } approvalObject={ transaction }></Header>
									<Row>
										<Col>
											<ApprovalField label="Group" value={transaction.GroupName} labelSize></ApprovalField>
										</Col>
										<Col>
											<ApprovalField label="Market Position" value={transaction.MarketPositionName} labelMP></ApprovalField>
										</Col>
										<Col>
											<ApprovalField label="Store" value={transaction.StoreName} labelSize></ApprovalField>
										</Col>
									</Row>
									<Row>
										<Col>
											<ApprovalField label="Facility" value={transaction.FacilityName} labelSize></ApprovalField>
										</Col>
										<Col>
											<ApprovalField label={(transaction.FacilityTypeId === FacilityTypes.TradeFinance ? "Trade Vendor" : "Customer")} value={transaction.ContactName} labelMP></ApprovalField>
										</Col>
										<Col></Col>
									</Row>
									
								</Panel>
								<Panel>
									<h6 style={{ color: "black"}}>Document Meta Information</h6>
									<Row>
										<Col>
											<ApprovalField label="Account Number" value={transaction.AccountNumber}></ApprovalField>
											<ApprovalField label="Document Type" value={transaction.DocumentType}></ApprovalField>
											<ApprovalField label="Original Invoice Number" value={transaction.OriginalInvoiceNumber}></ApprovalField>
											<ApprovalField label="GRN Number" value={transaction.GRNNumber}></ApprovalField>
											<ApprovalField label="Invoice To Account Number" value={transaction.InvoiceToAccountNumber}></ApprovalField>
											<ApprovalField label="Delivery To Account Number" value={transaction.DeliveryToAccountNumber}></ApprovalField>
											<ApprovalField label="Discount Percentage" value={`${(transaction.DiscountPercentage ? transaction.DiscountPercentage.toFixed(2) : "0")}%`}></ApprovalField>
											<ApprovalField label="Document Date" value={DateTime.fromISO(transaction.DocumentDate).toFormat("dd MMMM yyyy")}></ApprovalField>
										</Col>
										<Col>
											<ApprovalField label="Transmission Source" value={transaction.TransmissionSource}></ApprovalField>
											<ApprovalField label="Document Number" value={transaction.DocumentNumber}></ApprovalField>
											<ApprovalField label="Purchase Order Number" value={transaction.PurchaseOrderNumber}></ApprovalField>
											<ApprovalField label="Document Reference" value={transaction.DocumentReference}></ApprovalField>
											<ApprovalField label="Invoice To Name" value={transaction.InvoiceToName}></ApprovalField>
											<ApprovalField label="Delivery To Name" value={transaction.DeliveryToName}></ApprovalField>
											<ApprovalField label="Discount Amount" value={`${transaction.Currency}${ transaction.DiscountAmount ? transaction.DiscountAmount.toFixed(2) : "0"}`}></ApprovalField>
											<ApprovalField label="Due Date" value={ DateTime.fromISO(transaction.DueDate).toFormat("dd MMMM yyyy")}></ApprovalField>
										</Col>
									</Row>
								</Panel>
								<Panel title="Payment Instruction Information">
									<Row>
										<Col>
											<ApprovalField label="Trading Terms" value={transaction.TradingTerms}></ApprovalField>
											<ApprovalField label="Document Amount" value={`${transaction.Currency}${transaction.DocumentAmount.toFixed(2)}`}></ApprovalField>
											<ApprovalField label="VAT Amount" value={`${transaction.Currency}${transaction.VATAmount.toFixed(2)}`}></ApprovalField>
											<ApprovalField label="Document Total" value={`${transaction.Currency}${(transaction.DocumentAmount + transaction.VATAmount).toFixed(2)}`}></ApprovalField>
										</Col>
										<Col>
											<ApprovalField label="Payment Reference" value={transaction.PaymentReference}></ApprovalField>
											<ApprovalField label="Financing Percentage" value={`${transaction.FinancingPercentage.toFixed(2)}%`}></ApprovalField>
											<ApprovalField label="Payment Amount" value={`${transaction.Currency}${transaction.PaymentAmount.toFixed(2)}`}></ApprovalField>
											<ApprovalField label="Payment Difference" value={`${transaction.Currency}${transaction.PaymentDifference.toFixed(2)}`}></ApprovalField>
										</Col>
									</Row>
								</Panel>
								<Panel title="Special Instructions">
									<Formik>
									<Form>
									<Row>
										<Col>
											<p dangerouslySetInnerHTML={{ __html: transaction.SpecialInstructions ? transaction.SpecialInstructions.replace("\r\n", "<br />") : "" }}></p>
										</Col>
									</Row>
									<Row>
										<Col>
											<Table>
												<thead>
													<tr><th className="text-center">Comments</th></tr>
												</thead>
											</Table>
											<textarea rows="7" className="form-control" required value={ comment } onChange={ e => setComment(e.target.value) }>

											</textarea>
										</Col>
									</Row>
									<Row className="mt-2">
										<Col className="d-flex justify-content-end">
											<Button className="me-50" onClick={ e => submit(1) } disabled={ submitting } variant="success">{ getButtonAction() }</Button>
											<Button type="submit" className="me-50"onClick={ e => submit(2) } disabled={ submitting } variant="danger">{ submission.Declining ? "Declining..." : "Decline" }</Button>
											<Button type="submit" onClick={ e => submit(3) } disabled={ submitting } variant="warning">{ submission.Reviewing ? "Reviewing..." : "Review" }</Button>
										</Col>
									</Row>
									</Form>
									</Formik>
								</Panel>
							</React.Fragment>
						}
						{
							submitted &&
							<ApprovalCompleted transaction label={ transaction.DocumentNumber } review={ submission.Reviewing } action={ getAction() }></ApprovalCompleted>
						}
						<BusyIndicator show={ transaction === null && !error }></BusyIndicator>
					</Col>
				</Row>
			</Container>
}