import { TransactionDocumentTypes } from "./Enums";

/**
 * Returns a text string for the provided document type
 * @returns {string} A string containing the document type text
 */
export function getDocumentTypeText(documentType) {
	if (documentType === TransactionDocumentTypes.Invoice) {
		return "Invoice";
	}
	else if (documentType === TransactionDocumentTypes.CreditNote) {
		return "Credit Note";
	}
	
	return "";
}