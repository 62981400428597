import { Formik, Form as FormikForm } from "formik";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";
import PageHeader from "../../../Core/PageHeader";
import AssignmentLinks from "../Details/AssignmentLinks";
import DocumentMeta from "../Details/DocumentMeta";
import PaymentInformation from "../Details/PaymentInformation";
import * as yup from 'yup';
import SpecialInstructions from "../Details/SpecialInstructions";
import AuditLogEntitySummary from "../../AuditLogs/AuditLogEntitySummary";
import Documents from "../../../Core/Documents/Documents";
import { hasControl, Roles } from "../../../../Utilities/Permissions";
import { hasControl as ControlCheck, Controls } from "../../../../Utilities/Controls";
import { TransactionStatuses } from "../../../../Utilities/Enums";
import RecoveriesTable from "./RecoveriesTable";
import { FlaggedCheckBox } from "../../../Core/FlaggedIndicator";
import swal from "sweetalert"
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";



const { observer } = require("mobx-react-lite");

const TransactionDetails = observer(function TransactionDetails(props) {	

	if(props.facilityTypeId === 1){
		UseDocumentTitle('Editing \'Payable Transactions\'');
	}else{
		UseDocumentTitle('Editing \'Debt Transactions\'');
	}

	const store = useSessionStore();
	const financeStore = store.GetFinanceStore(props.facilityTypeId);
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const back = location.pathname.substring(0,location.pathname.lastIndexOf("V"));
	
	const [transaction, setTransaction] = useState(null);
	const [currencies, setCurrencies] = useState(null);
	const [saving, setSaving] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [isFlagged, setIsFlagged] = useState(false);
	async function getTransaction() {
		let result = await financeStore.GetTransactionConverted(params.transactionId)
		setTransaction(result);
		setIsFlagged(result.Flagged)
	}

	async function getCurrencies() {
		var result = await store.CurrenciesService.GetAvailableCurrencies("", 0, 0);

		if(result && result.Success) {
			setCurrencies(result.Data);
		}
	}

	useEffect(() => {
		getTransaction();
		getCurrencies();
	}, []);

	async function handleSubmitClicked(values) {
		let dueDate = DateTime.fromISO(values.DueDate);
		let documentDate = DateTime.fromISO(values.DocumentDate);

		if(dueDate.diff(documentDate, 'days') < 0) {
			swal({ icon: "warning", title: "Warning", text: "Due Date must be greater than Document Date.", closeOnClickOutside: false});
			return;
		}

		let action = document.activeElement.id;
		let submitClicked = action === "submit";

		let toggleFunc = submitClicked ? setSubmitting : setSaving;

		toggleFunc(true);
		let result = await financeStore.TransactionsService.UpdateTransaction(values, submitClicked);
		toggleFunc(false);

		if(result && result.Success) {
			if(submitClicked) {
				swal({ icon: "success", title: "Sent", text: `You have successfuly submitted this ${financeStore.GetTransactionType()} Transaction.`, closeOnClickOutside: false});
			} else {
				swal({ icon: "success", title: "Saved", text: `You have saved your ${financeStore.GetTransactionType()} Transaction successfully.`, closeOnClickOutside: false});
			}

			financeStore.GetTransactions();
			if(submitClicked) {
				store.GetHasNotifications();
			}

			navigate(`/${financeStore.GetFinanceType()}Finance/${financeStore.GetTransactionType()}Transactions/`);
		} else {
			swal({ icon: "error", title: "Error", text: result.Message ? result.Message : "An error ocurred updating the transaction.", closeOnClickOutside: false});
		}
	}

	const validationSchema = yup.object({
		TransactionStatusId: yup.number().required(),
		AccountNumber: yup.string().required(),
		DocumentNumber: yup.string().required(),
		DocumentDate: yup.date().required(),
		DueDate: yup.date().required(),
		DocumentAmount: yup.number().required(),
		VATAmount: yup.number().required(),
		CurrencyId: yup.number().required(),
		Comment: yup.mixed().when("TransactionStatusId", { is: (val) => val === TransactionStatuses.Declined || val === TransactionStatuses.NTU || val === TransactionStatuses.Pending,
			then: yup.mixed().required()  })
	}).required();

	function canSubmit() {
		let rightStatus = [TransactionStatuses.Open, TransactionStatuses.Pending, TransactionStatuses.Approved,TransactionStatuses.NTU].includes(transaction.TransactionStatusId);
		let rightPermissions = (store.Session.Role === Roles.Admin || 
								store.Session.AuthState.UserAppStructure.GroupAdminLevel || 
								hasControl(`Submit ${financeStore.GetTransactionType()} Transaction`, store.Session))
								// ControlCheck(Controls.Areas.CreditManagement,`${financeStore.GetFinanceType()} Finance`,Controls.TransactionOverride, store.Session));
		
		return (rightStatus || transaction.Flagged) && rightPermissions && !props.view;
	}
	//let canOverride = (ControlCheck(Controls.Areas.CreditManagement,`${financeStore.GetFinanceType()} Finance`,Controls.TransactionOverride, store.Session)&& (transaction.TransactionStatusId === TransactionStatuses.NTU ||transaction.TransactionStatusId === TransactionStatuses.Verified ));

	return 	<React.Fragment>
				<PageHeader icon={ financeStore.GetIcon() } title={ `${financeStore.GetTransactionType()} Transactions` } 
							helpItemDescriptor={ `${financeStore.GetTransactionType()}Transaction/Edit` }
							crumbs={[{ Title: "Credit Management" }, { Title: `${financeStore.GetFinanceType()} Finance` }, 
							{ Title: `${financeStore.GetTransactionType()} Transactions` }, { Active: true, Title: `Editing '${financeStore.GetTransactionType()} Transactions'` }]}>
				</PageHeader>
				{
					transaction && transaction.Id &&
					<AuditLogEntitySummary  table="Transactions" entityid={ transaction.Id } auditsummary={transaction.AuditSummary}></AuditLogEntitySummary>
				}
				{
					transaction &&
					<Formik initialValues={transaction} enableReinitialize validateOnMount validationSchema={ validationSchema } onSubmit={ handleSubmitClicked }>
						{({ handleSubmit}) => (
						<FormikForm>
							<AssignmentLinks edit={ !props.view && params.canoverride} subArea={ `${financeStore.GetFinanceType()} Finance` } transaction view facilityTypeId={ props.facilityTypeId }></AssignmentLinks>
							<DocumentMeta currencies={ currencies } transaction view={props.view} canoverride={params.canoverride === 'true'?true: false} facilityTypeId={ props.facilityTypeId }></DocumentMeta>
							<PaymentInformation currencies={ currencies } canoverride={params.canoverride === 'true'?true: false} view={props.view}></PaymentInformation>
							<SpecialInstructions view={props.view} canoverride={params.canoverride === 'true'?true: false}></SpecialInstructions>
							{
								transaction && transaction.Id && transaction.Recoveries && transaction.Recoveries.length > 0 &&
								<RecoveriesTable transaction={ transaction } currencies={ currencies }></RecoveriesTable>
							}
							<Documents updateEntity={ setTransaction } viewOnly={ props.view && !params.allowDocChanges  } allowDocChanges entity={ transaction }></Documents>
							<FlaggedCheckBox flagged={ isFlagged } view={ props.view && !params.allowDocChanges  }></FlaggedCheckBox>
							<Row>
								<Col className="d-flex justify-content-end">
									{
										(!props.view || params.allowDocChanges)  &&
										<React.Fragment>
											{
												canSubmit() &&
												<Button id="submit" disabled={ submitting || saving } type="submit" className="me-1">{ `${(submitting ? "Submitting..." : "Submit")}`}</Button>
            								}
											<Button id="save" disabled={ submitting || saving } type="submit" className="me-1">{ `${(saving ? "Saving..." : "Save")}`}</Button>
										</React.Fragment>
									}
									<Button disabled={ submitting || saving } onClick={ e => navigate(-1)? navigate(-1) : navigate(back) } >Cancel</Button>
								</Col>
							</Row>
						</FormikForm>)
						}
					</Formik>
				}
				<BusyIndicator show={ !transaction } size="2x"></BusyIndicator>
			</React.Fragment>
});

export default TransactionDetails;