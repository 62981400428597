import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { faAddressBook, faFileExcel, faPencil, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import PageHeader from "../../../Core/PageHeader";
import Pagination from "../../../Core/Pagination";
import DateValue from "../../../Core/DateValue";
import Panel from "../../../Core/Panel";
import IconButton from "../../../Core/IconButton";
import RoundButton from "../../../Core/RoundButton";
import BusyIndicator from "../../../Core/BusyIndicator";
import confirmDelete from "../../../../Utilities/Delete";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

const ContactsIndex = observer(function ContactsIndex(props) {

	UseDocumentTitle('Listing \'Contacts\' on CREST');

	const store = useSessionStore();
	const navigate = useNavigate();

	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState({ PageIndex: 0, PageCount: 10, Total: 0 });
	const [contacts, setContacts] = useState(null);

	const [exportingContacts, setExportingContacts] = useState(false);

	async function getContacts(filters) {
		setContacts(null);
		let result = null;


		if(localStorage.getItem("contactSearchValue")===null){
			result = await store.ContactsService.GetContacts(search, filters.PageIndex, filters.PageCount);
		}
		else{
			result = await store.ContactsService.GetContacts(localStorage.getItem("contactSearchValue"), filters.PageIndex, filters.PageCount);
			setSearch(localStorage.getItem("contactSearchValue"));
		}

		if(result && result.Success) {
			setFilter({...filters, Total: result.Total});
			setContacts(result.Data);
		}

	}

	useEffect(() => {
		// setSearch(localStorage.getItem("contactSearchValue"));
		getContacts(filter);
	}, []);

	async function handleExportContactsClicked() {
		setExportingContacts(true);
		var result = await store.ContactsService.ExportContacts(search);

		if(result) {
			//save file
			var blob = new Blob([result.Data], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `CONTACTS_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);

			setExportingContacts(false);
		}
	}

	function handlePageChange(pageIndex, pageCount) {
		let filters = {...filter, PageIndex: pageIndex, PageCount: pageCount};

		setFilter(filters);
		getContacts(filters);
	}
	
	async function handleContactDelete(entity) {
		let result = await confirmDelete(entity.CompanyName, entity.Id, (id) => store.ContactsService.DeleteContact(id));
		
		if(result) {
			getContacts(filter);
		}
	}

	const handleChange = (e) =>{
		setSearch(e.target.value);
		localStorage.setItem("contactSearchValue", e.target.value);
	};




	return 	<React.Fragment>
				<PageHeader icon={ faAddressBook } title={ "Contacts" }  helpItemDescriptor={ "Admin/Contacts/Index" }
							crumbs={[{ Title: "Admin" }, { Title: "Contacts" }, { Title: "List" }]}>
						<NavLink to={ "Add" }><IconButton space icon={ faPlus }>Add New</IconButton></NavLink>
						<IconButton icon={ faFileExcel } disabled={ exportingContacts } onClick={handleExportContactsClicked}>
							{ exportingContacts ? "Exporting..." : "Export All" }
						</IconButton>
				</PageHeader>
				<Panel>
					<h6>Filter Contacts</h6>
					<Row className="mb-50">
						<Col xs={4}>
							<Form.Group>
								<Form.Control placeholder="Search..." value={search} onChange={ handleChange } type="search" ></Form.Control>
							</Form.Group>
						</Col>
						<Col className="d-flex justify-content-end align-items-end">
							<Button className="text-nowrap" onClick={ e => getContacts({PageIndex:0,PageCount:10}) }>Filter</Button>
						</Col>
					</Row>
					
					
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
								<th>Company</th>
                                <th>Type</th>
								<th>Contact Person</th>
								<th>Email Address</th>
								<th>Telephone Number</th>
                                <th className="col-2">Date Created</th>
                                <th className="col-1 text-center">Edit</th>
                                <th className="col-1 text-center">Delete</th>
                            </tr>
						</thead>
						<tbody>
							{
								contacts &&
								contacts.length > 0 &&
								contacts.map((contact, index) =>
									<tr key={ index }>
										<td><NavLink to={ `/Admin/Contacts/View/${contact.Id}`}>{ contact.CompanyName }</NavLink></td>
										<td>{ contact.ContactType }</td>
										<td>{ contact.ContactPerson }</td>
										<td>{ contact.EmailAddress }</td>
										<td>{ contact.TelephoneNumber }</td>
										<td><DateValue outputFormat="dd MMM y hh:mm a" date={ contact.DateCreated }></DateValue></td>
										<td className="text-center"><NavLink to={`/Admin/Contacts/Edit/${ contact.Id }`}><RoundButton icon={ faPencil }></RoundButton></NavLink></td>
										<td className="text-center">
											{
												contact.Linked &&
												<span>Linked</span>
											}
											{
												!contact.Linked &&
												<RoundButton onClick={ e => { handleContactDelete(contact); } } icon={ faTimes }></RoundButton>
											}
										</td>
									</tr>)
							}
						</tbody>
					</Table>
					<BusyIndicator show={ contacts === null } size="2x"></BusyIndicator>
					{
						contacts &&
						<Pagination filter={ filter } 
								totalResults={ contacts.length } 
								onPageChanged={ handlePageChange }></Pagination>
					}
					
				</Panel>
			</React.Fragment>
});

export default ContactsIndex;