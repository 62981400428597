import { Formik, Form as FormikForm } from "formik";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";
import PageHeader from "../../../Core/PageHeader";
import AssignmentLinks from "../Details/AssignmentLinks";
import DocumentMeta from "../Details/DocumentMeta";
import PaymentInformation from "../Details/PaymentInformation";
import * as yup from 'yup';
import SpecialInstructions from "../Details/SpecialInstructions";
import AuditLogEntitySummary from "../../AuditLogs/AuditLogEntitySummary";
import swal from "sweetalert";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

const { observer } = require("mobx-react-lite");

const TransactionDocumentDetails = observer(function TransactionDocumentDetails(props) {
	
	

	if(props.facilityTypeId === 1){
		UseDocumentTitle('Editing \'Vendor Document\'');
	}else{
		UseDocumentTitle('Editing \'Customer Document\'');
	}

	const store = useSessionStore();
	const financeStore = store.GetFinanceStore(props.facilityTypeId);
	const navigate = useNavigate();
	const params = useParams();

	const [currencies, setCurrencies] = useState(null);
	const [document, setDocument] = useState(null);
	const [saving, setSaving] = useState(false);

	async function getCurrencies() {
		var result = await store.CurrenciesService.GetAvailableCurrencies("", 0, 0);

		if(result && result.Success) {
			setCurrencies(result.Data);
		}
	}

	async function getDocument() {
		let result = await financeStore.GetTransactionDocument(params.transactionDocumentId)
		setDocument(result);
	}

	useEffect(() => {
		if(props.add) {
			setDocument({ AccountNumber: "", SpecialInstructions: "", OriginalInvoiceNumber: "", PurchaseOrderNumber: "",
			GRNNumber: "", DocumentReference: "", InvoiceToAccountNumber: "", InvoiceToName: "",
			DeliveryToAccountNumber: "", DeliveryToName: "", DiscountPercentage: null, DiscountAmount: null, 
			DocumentDate: "", DueDate: "", TradingTerms: "", CurrencyId: "", DocumentAmount: null, VATAmount: null,
			PaymentReference: "", FacilityId: "", GroupId: "", MarketPositionId: "", StoreId: "",
			ContactId: "", DocumentNumber: "", DocumentType: "", TransmissionSource: "MANUAL", TransmissionDate: DateTime.now().toISO(),
			FinancialPercentage: 100, PaymentAmount: 0, PaymentDifference: 0 });
		} else if(props.view) {
			getDocument();
		}

		getCurrencies();
	}, []);

	async function handleSubmitClicked(values) {
		let dueDate = DateTime.fromISO(values.DueDate);
		let documentDate = DateTime.fromISO(values.DocumentDate);

		if(dueDate.diff(documentDate, 'days') < 0) {
			swal({ icon: "warning", title: "Warning", text: "Due Date must be greater than Document Date.", closeOnClickOutside: false});
			return;
		}

		setSaving(true);
		let result = await financeStore.CreateTransactionDocument(values);
		setSaving(false);

		if(result) {
			financeStore.GetTransactionDocuments();
			financeStore.GetTransactions();
			navigate(`/${financeStore.GetFinanceType()}Finance/${financeStore.GetContactType()}Documents/`);
		}
	}

	const validationSchema = yup.object({
		GroupId: yup.number().required(), 
		MarketPositionId: yup.number().required(), 
		StoreId: yup.number().required(),
		FacilityId: yup.number().required(),
		ContactId: yup.number().required(),
		AccountNumber: yup.string().required(),
		DocumentNumber: yup.string().required(),
		DocumentType: yup.string().required(),
		DocumentDate: yup.date().required(),
		DueDate: yup.date().required(),
		DocumentAmount: yup.number().required(),
		VATAmount: yup.number().required(),
		CurrencyId: yup.number().required(),
		OriginalInvoiceNumber: yup.string().when('DocumentType', {
			is: (type) => type === "Credit Note",
			then: yup.string().required(),
			otherwise: yup.string()
		  })
	}).required();

	return 	<React.Fragment>
				<PageHeader icon={ financeStore.GetIcon() } title={ `${financeStore.GetContactType()} Documents` } 
							helpItemDescriptor={ `${financeStore.GetContactType()}Document/${props.add ? "Add" : "Edit"}` }
							crumbs={[{ Title: "Credit Management" }, { Title: `${financeStore.GetFinanceType()} Finance` }, 
							{ Title: `${financeStore.GetContactType()} Documents` }, { Active: true, Title: `Editing '${financeStore.GetContactType()} Documents'` }]}>
				</PageHeader>
				{
					document && document.Id &&
					<AuditLogEntitySummary hideLog auditsummary={document.AuditSummary}></AuditLogEntitySummary>
				}
				{
					document &&
					<Formik initialValues={document} enableReinitialize validateOnMount validationSchema={ validationSchema } onSubmit={ handleSubmitClicked }>
						{({ values, handleSubmit }) => (
						<FormikForm>
							<AssignmentLinks document view={props.view} facilityTypeId={ props.facilityTypeId }></AssignmentLinks>
							<DocumentMeta currencies={ currencies } view={props.view} facilityTypeId={ props.facilityTypeId }></DocumentMeta>
							<PaymentInformation currencies={ currencies } view={props.view}></PaymentInformation>
							<SpecialInstructions view={props.view}></SpecialInstructions>
							<Row>
								<Col className="d-flex justify-content-end">
									{
										!props.view &&
										<Button type="submit" disabled={ saving } className="me-1">{ `${(saving ? "Saving..." : "Save")}`}</Button>
									}
									<Button disabled={ saving } onClick={ e => navigate(`/${financeStore.GetFinanceType()}Finance/${financeStore.GetContactType()}Documents/`) } >Cancel</Button>
								</Col>
							</Row>
						</FormikForm>)
						}
					</Formik>
				}
				<BusyIndicator show={ !document } size="2x"></BusyIndicator>
			</React.Fragment>
});

export default TransactionDocumentDetails;