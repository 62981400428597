import APIBase from "./APIBase";

/**
 * @typedef Document
 * @property {number} Id
 * @property {number} FileId
 * @property {string} Name
 * @property {string} FileName
 * @property {string} MIMEType
 * @property {string} Base64Data
 */

/**
 * @typedef DocumentsService
 * @type {DocumentsService}
 */
export default class DocumentsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Documents", sessionStore);
    }

    /**
     * @returns { import("./APIBase").RequestResult<Document> }
     */
     async GetDocument(fileId) {
        return this.Get(`GetDocument?fileId=${fileId}`);
    }
}