import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { getCurrentRate } from "../../../../../../Utilities/Facilities";
import NumberInput from "../../../../../Core/Forms/NumberInput";
import NumberValue from "../../../../../Core/NumberValue";
import RateHistoryModal from "./RateHistoryModal";

export default function RateHistoryField(props) {
	const [showModal, setShowModal] = useState(false);
	const [rate, setRate] = useState(0);

	useEffect(() => {
		setRate(getCurrentRate(props.rates));
	}, []);

	function handleModalClosed() {
		setRate(getCurrentRate(props.rates));
		setShowModal(false);
	}

	function handleClick () {
		setShowModal(true)
	}

	return 	<React.Fragment>
				<Form.Group as={Row} className="align-items-center" >
					{
						props.label &&
						<Form.Label column>{props.label}</Form.Label>
					}
					<Col xs={(props.label ? (props.col || 8) : 12 )}>
						{
							!props.view &&
							<InputGroup onClick={ handleClick }>
								<NumberInput style={{ cursor: "not-allowed" }} disabled value={rate}></NumberInput>
								<InputGroup.Text>%</InputGroup.Text>
								<Button><FontAwesomeIcon transform="grow-2" icon={ faPlus }></FontAwesomeIcon></Button>
							</InputGroup>
						}
						{
							props.view &&
							<div className="d-flex flex-row align-items-center justify-content-between" onClick={ props.disabled ? handleClick : ''}>
								<div style={{ color: "#004878", cursor: "not-allowed"}}>
									<NumberValue value={ rate }></NumberValue><span>%</span>
								</div>
								{
									props.disabled &&
									<Button><FontAwesomeIcon transform="grow-2" icon={ faPlus }></FontAwesomeIcon></Button>
								}
							</div>
						}
					</Col>
				</Form.Group>
				<RateHistoryModal rateType={ props.rateType } onClose={ handleModalClosed } title={ props.title } fieldName={ props.fieldName } show={ showModal } rates={props.rates} disabled={ props.disabled }></RateHistoryModal>
			</React.Fragment>
}