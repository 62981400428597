import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";

export default function NotificationContentModal(props) {
	const store = useSessionStore();
	const [content, setContent] = useState(null);

	/**@type { React.MutableRefObject<HTMLElement> } */
	const contentDiv = useRef();
	
	async function getContent() {
		let result = await store.MessagesService.GetMessageContent(props.messageId);

		if(result && result.Success) {
			setContent(result.Data);
		}
	}

	useEffect(() => {
		if(props.show && props.messageId) {
			getContent();
		} else {
			setContent(null);
		}
	}, [props.show, props.messageId]);

	useEffect(() => {
		if(content) {
			if(contentDiv.current) {
				//remove the table width attribute
				let table = contentDiv.current.querySelector("table");
	
				if(table) {
					table.attributes.removeNamedItem("width");
					table.style.width = "100%";
				}
			}
		}
		
	}, [content])
		
	return 	<Modal show={ props.show } dialogClassName="modal-600">				
				<Modal.Body>
					{
						content &&
						<div ref={ contentDiv } dangerouslySetInnerHTML={{ __html: content }}></div>
					}
					<BusyIndicator show={ content === null }></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ e => props.onClose() }>Cancel</Button>
				</Modal.Footer>
			</Modal>
}