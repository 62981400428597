import { faFlag } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CheckboxField from "./Forms/CheckboxField";
import Panel from "./Panel";

export function FlaggedCheckBox(props) {
	if(props.flagged) {
		return <Panel>
					<div className="d-flex align-items-center">
						<CheckboxField swap view={props.view} name="Flagged"></CheckboxField>
						<label className="ms-1">Is Flagged</label>
					</div>
				</Panel>
	}

	return <span></span>
}

export function FlaggedIcon(props) {	
	if(props.flagged && props.comment) {
		return 	<OverlayTrigger overlay={ <Tooltip>{props.comment}<br />------------<br />By: {props.commentUserName}</Tooltip> }>
					<span className="text-danger  me-50 text-end" style={{ cursor: "pointer" }}>
						<FontAwesomeIcon icon={ faFlag }></FontAwesomeIcon>
					</span>
				</OverlayTrigger>
	}

	if(props.flagged && !props.comment) {
		return 	<OverlayTrigger overlay={ <Tooltip>No Comment<br />------------<br /></Tooltip> }>
					<span className="text-danger  me-50 text-end" style={{ cursor: "pointer" }}>
						<FontAwesomeIcon icon={ faFlag }></FontAwesomeIcon>
					</span>
				</OverlayTrigger>
	}

	return <span></span>
}