import { faFilePdf, faInfoCircle, faPencil, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useState } from "react";
import {  Table } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { PaymentInstructionStatuses } from "../../../../Utilities/Enums";
import { getPaymentInstructionStatusText, getStatusText } from "../../../../Utilities/PaymentInstructions";
import { getPermissions, Permissions } from "../../../../Utilities/Permissions";
import BusyIndicator from "../../../Core/BusyIndicator";
import ClickableText from "../../../Core/ClickableText";
import DateValue from "../../../Core/DateValue";
import { FlaggedIcon } from "../../../Core/FlaggedIndicator";
import NumberValue from "../../../Core/NumberValue";
import Pagination from "../../../Core/Pagination";
import RoundButton from "../../../Core/RoundButton";
import PaymentInstructionFinanceDetailsModal from "../PaymentInstructionFinanceDetailsModal";
import ReimbursementDetailsModal from "../ReimbursementDetailsModal";
import swal from "sweetalert"
import ResendNotificationModal from "../../../Core/ResendNotificationModal";
import confirmDelete from "../../../../Utilities/Delete";
import PaymentInstructionPreview from "../PaymentInstructionPreview";
import { NavLink } from "react-router-dom";

export default function IndexTable(props) {
	const [showTransactionsModal, setShowTransactionsModal] = useState(false);
	const [showReimbursementsModal, setShowReimbursementsModal] = useState(false);
	const [showResendModal, setShowResendModal] = useState(false);

	const [selectedApprovalDetails, setSelectedApprovalDetails] = useState(null);
	const [selectedPaymentInstruction, setSelectedPaymentInstruction] = useState(null);
	const store = useSessionStore();

	function getApprovalUserDetails(pi) {
		let id = null;
		let name = null;
		let level = 1;

		if(pi.ResentToUserId) {
			id = pi.ResentToUserId;
			name = pi.ResentToUser;
		} else {
			switch (pi.PaymentInstructionStatusId) {
				case PaymentInstructionStatuses.Pending:
					id = pi.FacilityConfirmedByUserOneId;
					name = pi.FacilityConfirmedByUserOne;
					break;
				case PaymentInstructionStatuses.AwaitingConfirmation:
					id = pi.FacilityConfirmedByUserTwoId;
					name = pi.FacilityConfirmedByUserTwo;
					break;
				case PaymentInstructionStatuses.Confirmed:
					id = pi.FacilityAuthorisedByUserOneId;
					name = pi.FacilityAuthorisedByUserOne;
					break;
				case PaymentInstructionStatuses.AwaitingAuthorisation:
					id = pi.FacilityAuthorisedByUserTwoId;
					name = pi.FacilityAuthorisedByUserTwo;
					break;
				default:
					break;
			}
		}

		switch (pi.PaymentInstructionStatusId) {
			case PaymentInstructionStatuses.Pending:				
				level = 1;
				break;
			case PaymentInstructionStatuses.AwaitingConfirmation:				
				level = 2;
				break;
			case PaymentInstructionStatuses.Confirmed:				
				level = 3;
				break;
			case PaymentInstructionStatuses.AwaitingAuthorisation:				
				level = 4;
				break;
			default:
				break;
		}

		return {
			Id: id,
			Name: name,
			Level: level
		}
	}

	function handlePageChange(pageIndex, pageCount) {
		props.updateCallback({ ...props.filters, PageIndex: pageIndex, PageCount: pageCount });
		props.fetchCallback();
	}

	function handlePITransactionsClicked(pi) {
		setSelectedPaymentInstruction(pi);
		if(pi.IsReimbursement) {
			setShowReimbursementsModal(true);
		} else {
			setShowTransactionsModal(true);
		}
	}

	function getUrl() {
		if(props.processing) {
			return "PaymentProcessing";
		} else if (props.recoveries) {
			return "PaymentRecoveries";
		}
		if(props.reimbursements) {
			return "Reimbursements";
		}


		return "PaymentInstructions";
	}

	let piPermissions = getPermissions(Permissions.Sections.PaymentInstructions, Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement, store.Session);

	if(props.processing) {
		piPermissions = getPermissions(Permissions.Sections.PaymentProcessing, Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement, store.Session);
	}

	if(props.recoveries) {
		piPermissions = getPermissions(Permissions.Sections.PaymentRecoveries, Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement, store.Session);
	}

	function getPaymentInstructionEdit(pi) {
		if (pi.Submitted) {
			return <span>Submitted</span>
		} else if (pi.PaymentInstructionStatusId === PaymentInstructionStatuses.Declined) {
			return <span>Declined</span>
		} else if (pi.PaymentInstructionStatusId === PaymentInstructionStatuses.NTU) {
			return <span>Locked</span>
		} else {
			return <NavLink to={`/PaymentManagement/${getUrl()}/Edit/${ pi.PaymentInstructionId }/`}><RoundButton icon={ faPencil }></RoundButton></NavLink>
		}
	}

	function getPaymentProcessingEdit(pi) {
		if(pi.PaymentInstructionStatusId === PaymentInstructionStatuses.Processed) {
			return <span>Processed</span>
		} else if((pi.PaymentInstructionStatusId === PaymentInstructionStatuses.Open || pi.PaymentInstructionStatusId === PaymentInstructionStatuses.Authorised || 
					(pi.Flagged && pi.PaymentInstructionStatusId !== PaymentInstructionStatuses.Declined) || (!pi.Submitted && pi.PaymentInstructionStatusId === PaymentInstructionStatuses.Pending))) {
			return <NavLink to={`/PaymentManagement/${getUrl()}/Edit/${ pi.PaymentInstructionId }/`}><RoundButton icon={ faPencil }></RoundButton></NavLink>
		} else {
			return <span>Submitted</span>
		}
	}

	function getPaymentRecoveriesEdit(pi) {
		let paymentDate = DateTime.fromISO(pi.PaymentDate);
		let isBeforePaymentDate = paymentDate.diffNow("days").days >= 0;

		if(isBeforePaymentDate) {
			return <RoundButton onClick={ e => swal({ icon: "error", title: "Recovery not Allowed", text: `Recovery cannot happen before ${paymentDate.toFormat("dd MMM yyyy")}`, closeOnClickOutside: false}) } icon={ faPencil }></RoundButton>
		} else {
			return <NavLink to={`/PaymentManagement/${getUrl()}/Edit/${ pi.PaymentInstructionId }/`}><RoundButton icon={ faPencil }></RoundButton></NavLink>
		}
	}

	function handleResendClicked(paymentInstruction, approvalDetails) {
		setSelectedApprovalDetails({ 
			Id: paymentInstruction.PaymentInstructionId, 
			FacilityId: paymentInstruction.FacilityId,
			GroupId: paymentInstruction.GroupId,
			MarketPositionId: paymentInstruction.MarketPositionId,
			StoreId: paymentInstruction.StoreId,
			CurrentUserId: approvalDetails.Id,
			CurrentUser: approvalDetails.Name,
			Level: approvalDetails.Level
		});

		setShowResendModal(true);
	}

	function handleResendModalClosed(update) {
		setShowResendModal(false);

		if(update) {
			store.PaymentManagementStore.GetPaymentInstructions();
		}
	}

	async function handleDelete(pi) {
		let result = await confirmDelete(pi.Name, pi.PaymentInstructionId, (id) => store.PaymentInstructionsService.DeletePaymentInstruction(id));
		
		if(result) {
			props.fetchCallback();
		}
	}

	function handleFinanceDetailModalClosed(result) {
		setShowTransactionsModal(false); 
		setSelectedPaymentInstruction(null);

		if(result) {
			props.fetchCallback();
		}
	}

	return 	<React.Fragment>
				<Table bordered size="sm" responsive>
					<thead>
						<tr>
							<th>Number</th>
							<th>Name</th>
							<th>Group</th>
							<th>Facility</th>
							<th className="text-end no-truncation">Payment Amount</th>
							<th>Payment Date</th>
							<th>Status</th>
							{
								piPermissions.View &&
								<th>Transactions</th>
							}
							{
								piPermissions.Edit &&
								<th className="text-center">Edit</th>
							}
							{
								piPermissions.Delete && !props.recoveries &&
								<th className="text-center">Delete</th>
							}
							{
								piPermissions.View &&
								<th className="text-center">Preview</th>
							}
						</tr>
					</thead>
					<tbody>
						{
							!props.retrievingData  &&
							props.data.length > 0 &&
							props.data.map((pi, index) =>
								<tr key={ index }>
									<td>{ pi.Number }</td>
									<td>
										{
											piPermissions.View &&
											<NavLink to={`/PaymentManagement/${getUrl()}/View/${pi.PaymentInstructionId}/`}>{ pi.Name }</NavLink>
										}
										{
											!piPermissions.View &&
											<span>{ pi.Name }</span>
										}
									</td>
									<td>{ pi.Group  }</td>
									<td>{ pi.FacilityNumber }</td>
									<td className="text-nowrap text-end"><NumberValue currency={ pi.Currency } value={ pi.PaymentAmount }></NumberValue></td>
									<td className="text-nowrap">{ (pi.PaymentDate ? <DateValue outputFormat="dd MMM yyyy" date={ pi.PaymentDate }></DateValue> : "N/A" )}</td>
									<td className="text-nowrap no-truncation" style={{ position: "relative", paddingRight: "15px", overflow: "visible"}}>
										<div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
										{ (() => {
											if(props.recoveries) {
												return getStatusText(pi);
											} else {
												if(pi.PaymentInstructionStatusId === PaymentInstructionStatuses.Pending && !pi.AuthorisedByUserOneId && !pi.ConfirmedByUserOneId && pi.Submitted)
													return "Submitted";
												else if(pi.PaymentInstructionStatusId >= PaymentInstructionStatuses.Processed)
													return "Processed";
												else 
													return getPaymentInstructionStatusText(pi.PaymentInstructionStatusId);
												}
										})()}
										</div>
										<div style={{ position: "absolute", right: "5px", top: "10px"}}>
											<FlaggedIcon flagged={ pi.Flagged } comment={ pi.Comment } commentUserName={ pi.CommentUserName }></FlaggedIcon>
											{
												(() => {
													const approvalUser = getApprovalUserDetails(pi);

													if(approvalUser.Id && approvalUser.Name && pi.Submitted) {
														return 	<span className="text-primary" style={{ cursor: "pointer" }} onClick={ e => handleResendClicked(pi, approvalUser) }>
																	<FontAwesomeIcon icon={ faInfoCircle }></FontAwesomeIcon>
																</span>
													}
												})()
											}	
										</div>						
									</td>
									{
										piPermissions.View &&
										<td>
											{
												pi.FacilityId &&
												<ClickableText onClick={ e => handlePITransactionsClicked(pi) }>Transactions</ClickableText>
											}
											{
												!pi.FacilityId &&
												<span>Not assigned</span>
											}
										</td>
									}
									{
										piPermissions.Edit &&
										<td className="text-center">
											{
												(() => {
													if(props.processing) {
														return getPaymentProcessingEdit(pi);
													} else if (props.recoveries) {
														return getPaymentRecoveriesEdit(pi);
													} else {
														return getPaymentInstructionEdit(pi);
													}
												})()
											}
										</td>
									}
									
									{
										piPermissions.Delete && !props.recoveries &&
										<td className="text-center">
											{
												(() => {
													if (pi.Submitted) {
														return <span>Submitted</span>
													} else if (pi.FacilityId && pi.Linked && pi.PaymentInstructionStatusId <= PaymentInstructionStatuses.Pending) {
														return <span>Linked</span>
													} else if (pi.PaymentInstructionStatusId === PaymentInstructionStatuses.Declined) {
														return <span>Declined</span>
													} else if (pi.PaymentInstructionStatusId === PaymentInstructionStatuses.NTU) {
														return <span>Locked</span>
													} else if (pi.PaymentInstructionStatusId === PaymentInstructionStatuses.Open || 
																(pi.Flagged && pi.PaymentInstructionStatusId !== PaymentInstructionStatuses.Declined) || 
																(!pi.Submitted && pi.PaymentInstructionStatusId === PaymentInstructionStatuses.Pending) || 
																(!pi.FacilityId)) {
														return <RoundButton onClick={ e => handleDelete(pi) } icon={ faTimes }></RoundButton>
													}
												})()
											}
										</td>
									}
									{
										piPermissions.View &&
										<td className="text-center">
											<PaymentInstructionPreview Pi={pi} recoveries={props.recoveries} ></PaymentInstructionPreview>
										</td>
									}
								</tr>)
						}
					</tbody>						
				</Table>
				<BusyIndicator show={ props.retrievingData } size="2x"></BusyIndicator>
				{
					!props.retrievingData &&
					<Pagination filter={ props.filters } 
							totalResults={ props.data.length } 
							onPageChanged={ handlePageChange }></Pagination>
				}
				<PaymentInstructionFinanceDetailsModal paymentInstructionId={ (selectedPaymentInstruction ? selectedPaymentInstruction.PaymentInstructionId : 0) } 
														facilityId={ (selectedPaymentInstruction ? selectedPaymentInstruction.FacilityId : 0) } 
														show={ showTransactionsModal } allowSave={props.allowSave}
														onClose={ handleFinanceDetailModalClosed }>
				</PaymentInstructionFinanceDetailsModal>
				<ReimbursementDetailsModal paymentInstructionId={ (selectedPaymentInstruction ? selectedPaymentInstruction.PaymentInstructionId : 0) }
											show={ showReimbursementsModal } onClose={  e => { setShowReimbursementsModal(false); setSelectedPaymentInstruction(null); } }>

				</ReimbursementDetailsModal>
				<ResendNotificationModal subArea="Payment Management" paymentInstruction show={ showResendModal } onClose={ handleResendModalClosed } details={ selectedApprovalDetails }></ResendNotificationModal>
			</React.Fragment>
}