import APIBase from "./APIBase";

/**
 * @typedef TransactionDocumentVM
 * @property { number }  Id
 * @property { string }  DocumentDate
 * @property { string }  ContactName
 * @property { string }  StoreName
 * @property { number }  DocumentType
 * @property { string }  DocumentNumber
 * @property { number } PaymentAmount
 * @property { string } DueDate
 */

/**
 * @typedef TransactionDocumentImportResult	
 * @property { number } Total
 * @property { number } TotalFailed
 * @property { number } TotalSuccess
 * @property { number } TotalDuplicates
 * @property { string[] } Errors
 * @property { string } ResultFile
 */

/**
 * @typedef TransactionDocumentsService
 * @type {TransactionDocumentsService}
 */
export default class TransactionDocumentsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "TransactionDocuments", sessionStore);
    }

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<TransactionDocumentVM> }
	 */
	async GetTransactionDocuments(search, from, to, facilityType, pageIndex, pageCount) {
		return this.Get(`GetTransactionDocuments?search=${encodeURIComponent(search)}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&facilityType=${facilityType}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetTransactionDocument(transactionDocumentId) {
		return this.Get(`GetTransactionDocument?transactionDocumentId=${transactionDocumentId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<string> }
	 */
	 async ExportTransactionDocuments(search, from, to, facilityType) {
		return this.Get(`ExportTransactionDocuments?search=${encodeURIComponent(search)}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&facilityType=${facilityType}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<string> }
	 */
	 async GetImportTemplate(facilityTypeId) {
		return this.Get(`GetImportTemplate?facilityTypeId=${facilityTypeId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<TransactionDocumentImportResult> }
	 */
	 async ImportTransactionDocuments(fileData, facilityTypeId) {
		return this.Post(`ImportTransactionDocuments?facilityTypeId=${facilityTypeId}`, fileData);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	async CreateTransactionDocument(transactionDocument, facilityTypeId) {
		return this.Post(`CreateTransactionDocument?facilityTypeId=${facilityTypeId}`, transactionDocument);
	}
}