import { useFormikContext, FieldArray } from "formik";
import React, { useState, useEffect } from "react";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../Core/BusyIndicator";
import Panel from "../../../../Core/Panel";
import Funder from "./Funder";

export default function Funders(props) {
	const store = useSessionStore();
	const formikContext = useFormikContext(); 

	const [availableFunders, setAvailableFunders] = useState(null);

	async function getAvailableFunders() {
		let results = await store.PaymentInstructionsService.GetAvailableFundersForPaymentInstruction(props.facilityId);

		if(results && results.Success) {
			setAvailableFunders(results.Data);
		}
	}

	useEffect(() => {
		if(props.facilityId) {
			getAvailableFunders();
		}

		

		if(!formikContext.values["Funders"] || formikContext.values["Funders"].length === 0) {
			if(!props.view) {
				formikContext.setFieldValue("Funders", [{
					BankAccountId: "",
					BatchReferenceNumber: "",
					BeneficiaryStatementDescription: "",
					ContactId: "",
					FromAccountStatementDescription: "",
					PayableAmount: "",
					PayablePercent: "",
					PaymentInstructionId: formikContext.values["Id"],
					ReferenceNumber: "",
					
				}]);
			}
		}
	}, [props.facilityId]);

	return 	<Panel title="Payment Processing Details">
				{
					availableFunders &&
					<FieldArray name="Funders" validateOnChange={true}>
						{({ push, remove }) => (
							<div>
							{
								formikContext.values["Funders"] &&
								formikContext.values["Funders"].map((funder, index) => 
								<Funder view={props.view} addFunder={ push } removeFunder={ remove } funders={availableFunders} funder={funder} key={ index } index={ index }></Funder>)
							}
							</div>)}
					</FieldArray>
				}
				{
					availableFunders && formikContext.values["Funders"].length === 0 &&
					<div className="text-center text-muted">No Funders have been added to this Payment Instruction</div>
				}
				<BusyIndicator show={ availableFunders === null }></BusyIndicator>
			</Panel>
				
					
			
}