import { useSessionStore } from '../../../../Stores/SessionStore'
import CrestLogo from "../../../../Images/crest management system-LOGO.jpg"
import { observer } from 'mobx-react-lite';

const MenuHeader = observer(function MenuHeader() {
	const store = useSessionStore();

	function getProfilePic() {
		if(store.Session.ProfilePictureBase64) {
			// return `data:${store.Session.ProfilePictureMIMEType};base64,${store.Session.ProfilePictureBase64}`
			return <img width={220} height={80} style={{objectFit:'contain'}} alt='ProfilePic' src={ `data:${store.Session.ProfilePictureMIMEType};base64,${store.Session.ProfilePictureBase64}` }></img>
		} else {
			// return CrestLogo;
			return <img width={220} height={80} style={{objectFit:'contain'}} alt='ProfilePic' src={ CrestLogo }></img>
		}
	}

	return (
		<div className='navbar-header' style={{height:"90px", width:"150px"}}>
			{/* <img className='h-100' alt='ProfilePic' src={ getProfilePic() }></img> */}
			{getProfilePic()}
		</div>
	)
});

export default MenuHeader;