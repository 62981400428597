export function getTransactionStatusText(transactionStatusId) {
	switch(transactionStatusId) {
		case 1:
			return "Open";
		case 2:
			return "Pending";
		case 3:
			return "Awaiting Approval";
		case 4:
			return "Approved";
		case 5:
			return "Declined";
		case 6:
			return "Awaiting Verification";
		case 7:
			return "Verified";
		case 8:
			return "NTU";
		case 9:
			return "Processed";
		case 10:
			return "Paid Out";
		case 11:
			return "Recovered";
		default:
			return "N/A"
	}
}

export function getRecoveryStatus(transaction) {
	if(transaction.IsFullyRecovered)
	{
		return "Fully Recovered";
	}
	else if(transaction.IsRecovered)
	{
		return "Recovered";
	}
	else if(transaction.HasRecoveries)
	{
		return "Partially Recovered";
	}
	else
	{
		return "Processed";
	}
}