import { PaymentInstructionStatuses } from "./Enums";

/**
 * Returns a text string for the provided payment instructions status
 * @returns {string} A string containing the status text for the PI
 */
export function getStatusText(paymentInstruction) {
	if (paymentInstruction.IsOverRecovered) {
		return "Over Recovered";
	}
	else if (paymentInstruction.IsFullyRecovered) {
		return "Fully Recovered";
	}
	else if (paymentInstruction.IsRecovered) {
		return "Recovered";
	}
	else if (paymentInstruction.HasRecoveries) {
		return "Partially Recovered";
	}
	else if (paymentInstruction.PaymentInstructionStatusId === PaymentInstructionStatuses.Pending && paymentInstruction.Submitted) {
		return "Submitted";
	} else {
		return getPaymentInstructionStatusText(paymentInstruction.PaymentInstructionStatusId);
	}
}

export function getPaymentInstructionStatusText(paymentInstructionStatusId) {
	switch(paymentInstructionStatusId) {
		case 1:
			return "Open";
		case 2:
			return "Pending";
		case 3:
			return "Awaiting Confirmation";
		case 4:
			return "Confirmed";
		case 5:
			return "Declined";
		case 6:
			return "Awaiting Authorisation";
		case 7:
			return "Authorised";
		case 8:
			return "NTU";
		case 9:
			return "Processed";
		default:
			return "";
	}
}