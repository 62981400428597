import { faFilePdf } from "@fortawesome/pro-regular-svg-icons";
import ReactPDF from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useState } from "react";
import { useSessionStore } from "../../../Stores/SessionStore";
import RoundButton from "../../Core/RoundButton";
import { FacilityPreview as FacilityPreviewPDF } from "../../Reports/Facilities/FacilityPreview"

export default function FacilityPreview(props) {
	const store = useSessionStore();

	const [generatingPreview, setGeneratingPreview] = useState(false);

	async function handlePreviewClicked() {
		if(generatingPreview === false) {
			setGeneratingPreview(true);

			const result = await store.ReportsService.GetFacilityPreview(props.facility.Id);

			if(result && result.Success) {
				const blob = await ReactPDF.pdf(FacilityPreviewPDF({ data: result.Data })).toBlob()

				saveAs(blob, `Facility Preview - ${props.facility.Name}.pdf`);

				setGeneratingPreview(false);
			}
		}
	}

	return <RoundButton onClick={handlePreviewClicked} busy={ generatingPreview } variant="primary" icon={ faFilePdf }></RoundButton>
}