import { Document, Page, View, Text } from "@react-pdf/renderer";
import FacilityMovementHeader from "./Components/FacilityMovement/FacilityMovementHeader";
import FacilityMovementTransactions from "./Components/FacilityMovement/FacilityMovementTransactions";
import { styles } from "./StyleSheet";
import { DateTime } from "luxon";
import FacilityMovementClosingBalnce from "./Components/FacilityMovement/FacilityMovementClosingBalance";
import React from "react";

export default function FacilityMovementReport(props){

    var dateTimeSamp = DateTime.now();

    return(
        <Document>
            <Page size="A4" style={[styles.page, {paddingBottom: 30}]}>
                <View style={styles.section}>
                    <FacilityMovementHeader data={props.data.Header} detailed={props.data.Detailed} facilities={props.data.Facilities}></FacilityMovementHeader>

                    {
                        props.data.Facilities.map((values, index) =>
                            <View style={[styles.table, {paddingBottom:25, marginBottom:10}]} key={index}>
                                <FacilityMovementTransactions header={values.Header} transactions={values.Transactions} detailed={props.data.Detailed}></FacilityMovementTransactions>
                                <View style={[styles.pageDate, { bottom:0, paddingRight:10, paddingLeft:10}]}>
                                    <FacilityMovementClosingBalnce footer={values.Footer} detailed={props.data.Detailed} currency={values.Header.Currency}></FacilityMovementClosingBalnce>
                                </View>
                            </View>
                        )
                    }

                    <View style={{ position: 'absolute', bottom: -25, alignSelf: 'center'}} render={({ pageNumber, totalPages }) => (
                        <View>
                            <Text style={{ fontSize: 7, fontFamily: 'Roboto', fontWeight: 700, paddingBottom: 3, left:'20%'}}>
                                Page {pageNumber} of {totalPages}
                            </Text>
                            <Text style={{ fontSize: 7}}>
                                {DateTime.fromISO(dateTimeSamp).toFormat("dd/MM/yyyy HH:mma")}
                            </Text>
                        </View>
                    )} fixed />
                </View>
            </Page>
        </Document>
    )
}