import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import PageHeader from "../../../../Core/PageHeader";
import Pagination from "../../../../Core/Pagination";
import DateValue from "../../../../Core/DateValue";
import Panel from "../../../../Core/Panel";
import IconButton from "../../../../Core/IconButton";
import { faAddressBook, faFileExcel, faPencil, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import RoundButton from "../../../../Core/RoundButton";
import BusyIndicator from "../../../../Core/BusyIndicator";
import { NavLink, useNavigate } from "react-router-dom";
import confirmDelete from "../../../../../Utilities/Delete";
import UseDocumentTitle from "../../../../../Utilities/UserDocumentTitle";

const ContactCategoriesIndex = observer(function ContactCategoriesIndex(props) {

	UseDocumentTitle('Listing \'Contact Categories\' on Crest');

	const store = useSessionStore();
	const navigate = useNavigate();

	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState({ PageIndex: 0, PageCount: 10, Total: 0 });
	const [contactCategories, setContactCategories] = useState(null);

	const [exportingContactCategories, setExportingContactCategories] = useState(false);

	async function getContactCategories(filters) {
		setContactCategories(null);
		let result = await store.ContactCategoriesService.GetContactCategories(search, filters.PageIndex, filters.PageCount);

		if(result && result.Success) {
			setFilter({...filters, Total: result.Total});
			setContactCategories(result.Data);
		}
	}

	useEffect(() => {
		getContactCategories(filter);
	}, []);

	async function handleExportContactCategoriesClicked() {
		setExportingContactCategories(true);
		var result = await store.ContactCategoriesService.ExportContactCategories(search);

		if(result) {
			//save file
			var blob = new Blob([result.Data], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `CONTACT_CATEGORIES_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);

			setExportingContactCategories(false);
		}
	}

	function handlePageChange(pageIndex, pageCount) {
		let filters = {...filter, PageIndex: pageIndex, PageCount: pageCount};

		setFilter(filters);
		getContactCategories(filters);
	}
	
	async function handleContactCategoryDelete(entity) {
		let result = await confirmDelete(entity.Name, entity.Id, (id) => store.ContactCategoriesService.DeleteContactCategory(id));
		
		if(result) {
			getContactCategories(filter);
		}
	}

	return 	<React.Fragment>
				<PageHeader icon={ faAddressBook } title={ "Contact Categories" }  helpItemDescriptor={ "Admin/ContactCategories/Index" }
							crumbs={[{ Title: "Admin" }, { Title: "Contact Categories" }, { Title: "List" }]}>
						<NavLink to={ "Add" }><IconButton space icon={ faPlus }>Add New</IconButton></NavLink>
						<IconButton icon={ faFileExcel } disabled={ exportingContactCategories } onClick={handleExportContactCategoriesClicked}>
							{ exportingContactCategories ? "Exporting..." : "Export All" }
						</IconButton>
				</PageHeader>
				<Panel>
					<h6>Filter Contact Categories</h6>
					<Row className="mb-50">
						<Col xs={4}>
							<Form.Group>
								<Form.Control placeholder="Search..." value={search} onChange={ e => setSearch(e.target.value) } type="search" ></Form.Control>
							</Form.Group>
						</Col>
						<Col className="d-flex justify-content-end align-items-end">
							<Button className="text-nowrap" onClick={ e => getContactCategories(filter) }>Filter</Button>
						</Col>
					</Row>
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
								<th>Category</th>
                                <th>Name</th>
                                <th className="col-2">Date Created</th>
                                <th className="col-1 text-center">Edit</th>
                                <th className="col-1 text-center">Delete</th>
                            </tr>
						</thead>
						<tbody>
							{
								contactCategories &&
								contactCategories.length > 0 &&
								contactCategories.map((contactCategory, index) =>
									<tr key={ index }>
										<td>Category { contactCategory.Number }</td>
										<td><NavLink to={`/Admin/ContactCategories/View/${contactCategory.Id}`}>{ contactCategory.Name }</NavLink></td>
										<td><DateValue outputFormat="dd MMM y hh:mm a" date={ contactCategory.DateCreated }></DateValue></td>
										<td className="text-center"><NavLink to={`/Admin/ContactCategories/Edit/${ contactCategory.Id }/`}><RoundButton icon={ faPencil }></RoundButton></NavLink></td>
										<td className="text-center">
											{
												contactCategory.Linked &&
												<span>Linked</span>
											}
											{
												!contactCategory.Linked &&
												<RoundButton onClick={ e => { handleContactCategoryDelete(contactCategory); } } icon={ faTimes }></RoundButton>
											}
										</td>
									</tr>)
							}
						</tbody>						
					</Table>
					<BusyIndicator show={ contactCategories === null } size="2x"></BusyIndicator>
					{
						contactCategories &&
						<Pagination filter={ filter } 
								totalResults={ contactCategories.length } 
								onPageChanged={ handlePageChange }></Pagination>
					}
					
				</Panel>
			</React.Fragment>
});

export default ContactCategoriesIndex;