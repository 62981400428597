import { DateTime } from "luxon";
import { Col, Row } from "react-bootstrap";
import logo from "../../Images/crest management system-LOGO.png"
import { observer } from "mobx-react-lite"
import { useSessionStore } from "../../Stores/SessionStore";

const PageFooter = observer(function PageFooter() {
	const store = useSessionStore();

	if(store.HideSidebar === false) {
		return 	<footer style={{ marginLeft: "240px", paddingLeft: "3rem", paddingBottom: "0.55rem", height: "71px" }} className="footer bg-white text-muted shadow">
			<Row className="d-flex align-items-center">
				<Col>&copy; CREST Management Systems Programmed by appstage. All Rights Reserved { DateTime.now().year }</Col>
				<Col className="d-flex justify-content-end align-items-center"><img alt="Crest" style={{ maxHeight:"50px"}} src={ logo } /></Col>
			</Row>
		</footer>
	} else {
		return false;
	}	
});

export default PageFooter;