import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import SettingsList from "./SettingsList";
import PageHeader from "../../../Core/PageHeader";
import Panel from "../../../Core/Panel";
import { useSessionStore } from "../../../../Stores/SessionStore";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";
export default function MarketPositionSettings(props) {

	UseDocumentTitle('Market Position Settings');

	const store = useSessionStore();

	const [selectedGroupId, setSelectedGroupId] = useState(null);
	const [selectedMarketPositionId, setSelectedMarketPositionId] = useState(null);
	
	async function getSettings(filters) {
		if(selectedGroupId && selectedMarketPositionId) {
			return store.SettingsService.GetMarketPositionSettings(selectedMarketPositionId, filters.PageIndex, filters.PageCount);
		} else {
			return { Success: true, Data: [] }
		}
	}

	const groups = store.Session.AuthState.UserAppStructure.AvailableStructure;
	let marketPositions = [];

	if(selectedGroupId) {
		let selectedGroup = groups.find(g => g.Id === parseInt(selectedGroupId));

		if(selectedGroup) {
			marketPositions = selectedGroup.Children;
		}
	}

	const handleGroupChange = (e) =>{
		setSelectedGroupId(e.target.value);
		localStorage.setItem("groupSearchValue", e.target.value);
	};

	const handleMarketPositionChange = (e) =>{
		setSelectedMarketPositionId(e.target.value);
		localStorage.setItem("marketPositionSearchValue", e.target.value);
	};

	useEffect(() => {
		setSelectedGroupId(localStorage.getItem("groupSearchValue"));
		setSelectedMarketPositionId(localStorage.getItem("marketPositionSearchValue"));
	})


	return 	<React.Fragment>
				<PageHeader icon={ faGear } title={ "Settings" }  helpItemDescriptor={ "Admin/MarketPositionSettings" }
							crumbs={[{ Title: "Admin" }, { Title: "Market Position Settings", Active: true }]}>
				</PageHeader>
				<Panel>
					<h6>Filter Market Position Settings by Market Position</h6>
					<Row className="mt-1">
						<Col xs={4}>
							<Form.Group as={Row} className="mb-50">
								<Form.Label xs={4} column>Group</Form.Label>
								<Col>
									<Form.Select onChange={ handleGroupChange } value={ selectedGroupId }>
										<option></option>
										{ groups && groups.map(g => <option value={ g.Id }>{ g.Name }</option>) }
									</Form.Select>
								</Col>
							</Form.Group>
							<Form.Group as={Row}>
								<Form.Label xs={4} column>Market Position</Form.Label>
								<Col>
									<Form.Select onChange={ handleMarketPositionChange } value={ selectedMarketPositionId }>
										<option></option>
										{ marketPositions && marketPositions.map(mp => <option value={ mp.Id }>{ mp.Name }</option>) }
									</Form.Select>
								</Col>
							</Form.Group>
						</Col>
					</Row>
				</Panel>
				<SettingsList name="Market Position" getSettings={ getSettings } marketPositionId={ selectedMarketPositionId }></SettingsList>
			</React.Fragment>
}