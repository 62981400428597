import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { Formik, Form as FormikForm } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import * as yup from 'yup';
import swal from "sweetalert"
import { Button, Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import PageHeader from "../../Core/PageHeader";
import BusyIndicator from "../../Core/BusyIndicator";
import Preferences from "../Admin/Users/Users/Details/Preferences";
import UserProfileInformation from "../Admin/Users/Users/Details/UserProfileInformation";
import InputField from "../../Core/Forms/InputField";
import Panel from "../../Core/Panel";
import { NavLink, useNavigate } from "react-router-dom";
import UseDocumentTitle from "../../../Utilities/UserDocumentTitle";

export default function UserProfile(props) {

	UseDocumentTitle('Profile');

	const store = useSessionStore();
	const navigate = useNavigate();

	const [user, setUser] = useState(null);
	const [saving, setSaving] = useState(false);

	async function getUser() {
		let result = await store.UsersService.GetProfile();

		if(result && result.Success) {
			setUser(result.Data);
		}
	}
	
	useEffect(() => {
		getUser();
	}, []);

	async function handleSaveClicked(values) {
		setSaving(true);
		let result = null;

		if(props.add) {
			result = await store.UsersService.CreateUser(values);
		} else {
			result = await store.UsersService.UpdateUser(values);
		}

		setSaving(false);

		if(result && result.Success) {
			store.SetProfileName(values.Name,values.Surname)
			if(values.ProfilePicChanged) {
				store.SetProfilePic(values.ProfilePictureBase64, values.ProfilePictureMIMEType);
			}
			swal({ icon: "success", title: "Profile Saved", text: "User Profile Saved Successfully!", closeOnClickOutside: false });

		} else {
			swal({ icon: "error", title: "Error", text: "An error occurred attempting to save your User Profile", closeOnClickOutside: false });
		}
	}

	const validationSchema = yup.object({
		Name: yup.mixed().required(),
		Surname: yup.mixed().required(),
		EmailAddress: yup.string().email().required(),
		PhoneNumber: yup.string().matches(/^\+\d{2} \(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid').required(),
		PreferredLanguageId: yup.mixed().required(),
		PreferredCurrencyId: yup.mixed().required()
	}).required();

	return <React.Fragment>
				<PageHeader icon={ faUsers } title={ "User Profile" }  helpItemDescriptor={ "Account/UserProfile" }
							crumbs={[{ Active: true, Title: "Profile" }]}>
				</PageHeader>
				{
					user &&
					<Formik initialValues={user} enableReinitialize validateOnMount validationSchema={ validationSchema } onSubmit={ handleSaveClicked }>
						<FormikForm>
							<UserProfileInformation profile></UserProfileInformation>
							<Panel title="Security Information">
								<InputField className="mb-50" type="password" name="Password" label="Password" labelCol></InputField>
								<Row className="mb-50">
									<Col className="offset-2">
										<span class="text-white p-25 px-50 rounded w-100 d-block" style={{ backgroundColor: "#999", width: "100%" }}>Leave this blank if you do not wish to change this account's password</span>
									</Col>
								</Row>	
								<Row className="mb-1">
									<Col className="offset-2">
										<span class="text-white p-25 px-50 rounded w-100 d-block" style={{ backgroundColor: "#999", width: "100%" }}>Password must contain at least 6 characters</span>
									</Col>
								</Row>
							</Panel>
							<Preferences profile></Preferences>
							<Row>
								<Col className="d-flex justify-content-end">
									{
										!props.view &&
										<Button disabled={ saving } type="submit" className="me-1">{ `${(saving ? "Saving..." : "Save")}`}</Button>
									}
									<NavLink to={'/Facilities/'}><Button disabled={ saving } >Cancel</Button></NavLink>
								</Col>
							</Row>
						</FormikForm>
					</Formik>
				}
				<BusyIndicator show={ user === null }></BusyIndicator>
			</React.Fragment>
}