import * as yup from 'yup';

export var ContactableEntityTypes = {
	Structure: 1,
	Contact: 2,
	Store : 3
}

export function getValidation(type) {
	let validationObject = {
		CompanyName: yup.string().required(),
		TelephoneNumber: yup.string().matches(/^\+\d{2} \(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid').required(),
		AddressLine1: yup.string().required(),
		AddressLine2: yup.string().required(),
		RegistrationNumber: yup.string().required(),
		VATNumber: yup.string().required(),
		AreaCode: yup.string().required(),
		ContactName: yup.string().required(),
		DirectLine: yup.string().matches(/^\+\d{2} \(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid').required(),
		EmailAddress: yup.string().email().required(),
		CountryId: yup.string().required(),
		Region: yup.string().required(),
		BankAccounts: yup.array().of(yup.object().shape({
			BankAccountHolder: yup.string().required(),
			BankAccountNumber: yup.string().required(),
			BranchName: yup.string().required(),
			BankInstitution: yup.string().required(),
			BankAccountType: yup.string().required(),
			BranchCode: yup.string().required()
		}))
	}
	if(type === ContactableEntityTypes.Store)
	{	
	validationObject.OwnerContactName = yup.string().required();
	validationObject.OwnerDirectLine = yup.string().required();
	validationObject.OwnerEmailAddress = yup.string().email().required();
	validationObject.ManagerContactName = yup.string().required();
	validationObject.ManagerDirectLine = yup.string().required();
	validationObject.ManagerEmailAddress = yup.string().email().required();
	}

	if(type === ContactableEntityTypes.Contact) {
		validationObject.ContactTypeId = yup.number().required();
		validationObject.AccountContactName = yup.string().required();
		validationObject.AccountDirectLine = yup.string().required();
		validationObject.AccountEmailAddress = yup.string().email().required();
	}

	return yup.object(validationObject).required();
}

export function getNewObject(type) {
	let entity = {
		ContactName: "",
		EmailAddress: "",
		TelephoneNumber: "",
		FaxNumber: "",
		AddressLine1: "",
		AddressLine2: "",
		AddressLine3: "",
		CompanyName: "",
		TradingName: "",
		AccountingCode: "",
		RegistrationNumber: "",
		ContactReference: "",
		VATNumber: "",
		Region: "",
		Area: "",
		AreaCode: "",
		Designation: "",
		DirectLine: "",
		MobileNumber: "",
		NCRNumber: "",
		FSPNumber: "",
		ImportNumber: "",
		ExportNumber: "",
		BBBEELevel: "",
		BBBEECertNumber: "",
		Comments: "",
		WebsiteAddress: "",
		OwnerContactName: "",
		OwnerDirectLine: "",
		OwnerMobileNumber: "",
		OwnerEmailAddress: "",
		OwnerFaxNumber: "",
		ManagerContactName: "",
		ManagerDirectLine: "",
		ManagerMobileNumber: "",
		ManagerEmailAddress: "",
		ManagerFaxNumber: "",
		AccountContactName: "",
		AccountDirectLine: "",
		AccountMobileNumber: "",
		AccountEmailAddress: "",
		AccountFaxNumber: "",
		AccountDesignation: "",
		OwnerDesignation: "",
		ManagerDesignation: "",
		ContactCategory1Id: "",
		ContactCategory2Id: "",
		ContactCategory3Id: "",
		ContactCategory4Id: "",
		LogoBase64: "",
		LogoMIMEType: "",
		BankAccounts: [{
				Id: 0,
				BankAccountHolder: "",
				BankInstitution: "",
				BankAccountNumber: "",
				BankAccountType: "",
				BranchName: "",
				BranchCode: "",
				IBANNumber: "",
				SwiftCode: "",
				IsDefault: true
		}]
	}

	if(type === ContactableEntityTypes.Structure) {
		entity.Active = true;
		entity.AllowChildren = false;
	} else if (type === ContactableEntityTypes.Contact) {
		entity.ContactTypeId = "";
		entity.Active = true;
	}

	return entity;
}