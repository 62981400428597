import APIBase from "./APIBase";
/**
 * @typedef User
 * @property {string} Id
 * @property {string} Username
 * @property {string} Email
 * @property {string} Password
 * @property {string} ConfirmPassword
 * @property {UserSystemAccess[]} AssociatedSystems
 */

/**
 * @typedef UserSystemAccess
 * @property {number} SystemId
 * @property {string} SystemName
 * @property {boolean} GroupAdminLevel
 * @property {boolean} GroupLevel
 * @property {boolean} MarketPositionLevel
 * @property {boolean} StoreLevel
 */

/**
 * @typedef UserVM
 * @property {string} Id
 * @property {string} Name
 * @property {string} Username
 * @property {string} EmailAddress
 * @property {string} PhoneNumber
 * @property {string} Role
 * @property {boolean} Inactive
 * @property {UserSystemAccess[]} AssociatedSystems
 */

/**
 * @typedef UserType
 * @property {string} Id
 * @property {string} Name
 * @property {string} DateCreated
 * @property {boolean} Linked
 */

/**
 * @typedef SystemAppPermission
 * @property {string} Area
 * @property {string} SubArea
 * @property {string} Section
 * @property {boolean} View
 * @property {boolean} Add
 * @property {boolean} Edit
 * @property {boolean} Delete
 * @property {number} SystemAppId
 */

/**
 * @typedef SystemAppControl
 * @property {string} Name
 * @property {string} Area
 * @property {string} SubArea
 * @property {string} Section
 * @property {number} SystemAppId
 */

/**
 * @typedef SystemAppAuthState
 * @property {import("./StructureService").UserAppStructure} UserAppStructure
 * @property {Array<SystemAppPermission>} Permissions
 * @property {Array<SystemAppControl>} Controls
 */

/**
 * @typedef Session
 * @property {string} Name
 * @property {string} Role
 * @property {string} UserId
 * @property {string} BearerToken
 * @property {SystemAppAuthState} AuthState
 * @property {number} PreferredCurrencyId
 * @property {string} ProfilePictureBase64
 * @property {string} ProfilePictureMIMEType
 */

/**
 * @typedef UsersService
 * @type {UsersService}
 */
export default class UsersService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Users", sessionStore);
    }

    /**
     * @param {string} email The email address of the user
     * @param {string} password The user's password
     * @returns { import("./APIBase").RequestResult<import("../Stores/SessionStore").Session> }
     */
    async Login(username, password) {
        return this.Post("login", { Username: username, Password: password });
    }

    /**
     * @param {User} user The User object to create
     * @returns { Promise<import("./APIBase").RequestResult<string>> }
     */
    async CreateUser(user) {
        return this.Post("CreateUser", user);
    }

    /**
     * @param {User} user The User object to update
     */
    async UpdateUser(user) {
        return this.Post("UpdateUser", user);
    }

    /**
     * @param {string} userId The Guid of the user to delete
     */
    async DeleteUser(userId) {
        return this.Post("DeleteUser", userId);
    }

	/**
	 * @param {string} search String containing a string to filter results by
	 * @param {number} pageIndex The 0 based start page index
	 * @param {number} pageCount The total amount of results to return for the page
	 * @returns { import("./APIBase").EnumerableRequestResult<UserVM> }
	 */
	async GetUsers(search, pageIndex, pageCount, ) {
		return this.Get(`GetUsers?pageIndex=${pageIndex}&pageCount=${pageCount}&search=${encodeURIComponent(search)}`);
	}

	/**
	 * @param {number} pageIndex The 0 based page page index
	 * @param {number} pageCount The total amount of results to return for the page
	 * @param {string} search String containing a string to filter results by
	 * @returns { import("./APIBase").EnumerableRequestResult<UserType> }
	 */
	async GetUserTypes(pageIndex, pageCount, search) {
		return this.Get(`GetUserTypes?pageIndex=${pageIndex}&pageCount=${pageCount}&search=${encodeURIComponent(search)}`);
	}

	/**
	 * @param {string} userId The Id of the user to retrieve
	 * @returns { Promise<import("./APIBase").RequestResult<User>> }
	 */
	async GetUser(userId) {
		return this.Get(`GetUser?userId=${userId}`);
	}

	/**
	 * @returns { Promise<import("./APIBase").RequestResult<SystemAppAuthState>> }
	 */
	async GetSystemAppAuthState() {
		return this.Get(`GetSystemAppAuthState`);
	}

	async ExportUsers(search) {
		return this.Get(`ExportUsers?search=${encodeURIComponent(search)}`);
	}

	async ExportUserTypes(search) {
		return this.Get(`ExportUserTypes?search=${encodeURIComponent(search)}`);
	}
	
	async CreateUserType(name) {
		return this.Post(`CreateUserType?name=${name}`);
	}

	async UpdateUserType(userType) {
		return this.Post("UpdateUserType", userType);
	}

	async DeleteUserType(userTypeId) {
		return this.Post(`DeleteUserType?userTypeId=${userTypeId}`);
	}

	/**
	 * @param {string} userId The Id of the user type to retrieve
	 * @returns { Promise<import("./APIBase").RequestResult<UserType>> }
	 */
	 async GetUserType(userTypeId) {
		return this.Get(`GetUserType?userTypeId=${userTypeId}`);
	}

	async GetUsersOfType(userTypeId) {
		return this.Get(`GetUsersOfType?userTypeId=${userTypeId}`);
	}

	async GetProfile() {
		return this.Get(`GetProfile`);
	}
}