import React, { useState } from "react";
import { useRef } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../../Stores/SessionStore";
import { StructureDropDown } from "../../../../../Core/StructureFilter";
import StructureManager from "../../../../../../Utilities/StructureManager";
import RoundButton from "../../../../../Core/RoundButton";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useFormikContext } from "formik";
import swal from "sweetalert";

export default function AssignmentsTab(props) {
	const formikContext = useFormikContext();
	const store = useSessionStore();
	const structure = useRef(new StructureManager(store.Session.AuthState.UserAppStructure.AvailableStructure));

	const [groupId, setGroupId] = useState(0);
	const [marketPositionId, setMarketPositionId] = useState(0);
	const [storeId, setStoreId] = useState(0);

	function setGroup(groupId) {
		structure.current.SetSelectedGroup(groupId);
		setGroupId(groupId);
	}

	function setMarketPosition(marketPositionId) {
		structure.current.SetSelectedMarketPosition(marketPositionId);
		setMarketPositionId(marketPositionId);
	}

	function setStore(storeId) {
		structure.current.SetSelectedStore(storeId);
		setStoreId(storeId);
	}

	function handleAddAssignmentClicked() {
		let assignments = formikContext.values.Assignments;

		let selectedMarketPositionId = structure.current.MarketPositionId || null;
		let selectedStoreId = structure.current.StoreId || null;

		//first check to make sure this user doesn't already have this assignment
		let existingAssignment = assignments.find(a => a.GroupId === structure.current.GroupId &&
														a.MarketPositionId === selectedMarketPositionId &&
														a.StoreId === selectedStoreId);

		if(existingAssignment) {
			swal({ icon: "error", title: "Error", text: "You have already assigned this user to the requested assignment.", closeOnClickOutside: false });
			return;
		}

		var group = structure.current.Groups.find((g) => g.Id === structure.current.GroupId);

		if(group) {
			var marketPosition = structure.current.MarketPositions.find((mp) => mp.Id === structure.current.MarketPositionId);
			var store = structure.current.Stores.find((s) => s.Id === structure.current.StoreId);

			if((formikContext.values.MarketPositionLevel || formikContext.values.StoreLevel) && !structure.current.MarketPositionId) {
				return;
			}

			if(formikContext.values.StoreLevel && !structure.current.StoreId) {
				return;
			}

			var newAssignment = {
				Id: 0,
				GroupId: structure.current.GroupId,
				GroupName: group.Name,
				MarketPositionId: marketPosition ? structure.current.MarketPositionId : null,
				MarketPositionName: marketPosition ? marketPosition.Name : "N/A",
				StoreId: store ? structure.current.StoreId : null,
				StoreName: store ? store.Name : "N/A"
			}

			
			assignments.push(newAssignment);
			formikContext.setFieldValue("Assignments", [...assignments]);

			setGroup("");
			setMarketPosition("");
			setStore("");
		}
	}

	function handleAssignmentDeleteClicked(index) {
		swal({ 
			title: "Are you sure?", 
			text: "You are about to delete this assignment, are you sure?",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
					visible: true,
					closeModal: true,
			},
				confirm: {
					text: "OK",
					value: true,
					visible: true,
					closeModal: true
				}
			}, 
			closeOnClickOutside: false
		}).then(result => {
			if(result) {
				let assignments = formikContext.values.Assignments;
				assignments.splice(index, 1);
				formikContext.setFieldValue("Assignments", [...assignments]);
			}
		});
	}

	const values = formikContext.values;

	return 	<div>
			{
				values &&
				<React.Fragment>
					<span className="d-block mb-1">Because this user is not a SYSTEM LEVEL user, please assign the relevant access below:</span>
					
					<StructureDropDown label="Group" value={ structure.current.GroupId } invalid={ !structure.current.GroupId } structure={ structure.current.Groups } onChange={ setGroup }></StructureDropDown>

					{
						(values.MarketPositionLevel || values.StoreLevel) &&
						<StructureDropDown label="Market Position" invalid={ !structure.current.MarketPositionId } value={ structure.current.MarketPositionId } structure={ structure.current.MarketPositions } onChange={ setMarketPosition }></StructureDropDown>
					}
					{
						values.StoreLevel &&
						<StructureDropDown label="Store" invalid={ !structure.current.StoreId } value={ structure.current.StoreId } structure={ structure.current.Stores } onChange={ setStore }></StructureDropDown>
					}
					<Row className="mt-50">
						<Col className="d-flex justify-content-end">
							<Button onClick={ handleAddAssignmentClicked }>Add</Button>			
						</Col>
					</Row>
					
					<hr />
					<h6>Current Assignments</h6>
					<Table className="align-middle" size="sm" responsive bordered>
						<tbody>
							<tr >
								<td className="py-50"><b>Group</b></td>
								{
									(values.MarketPositionLevel || values.StoreLevel) &&
									<td><b>Market Position</b></td>
								}
								{
									values.StoreLevel &&
									<td><b>Store</b></td>
								}
								<td className="text-center"><b>Delete</b></td>
							</tr>
							{
								formikContext.values.Assignments.map((value, index) =>
								<tr key={value.Id}>
									<td>{structure.current.GetGroupName(value.GroupId)}</td>
									{
										(values.MarketPositionLevel || values.StoreLevel) &&
										<td>{structure.current.GetMarketPositionName(value.GroupId, value.MarketPositionId)}</td>
									}
									{
										values.StoreLevel &&
										<td>{structure.current.GetStoreName(value.GroupId, value.MarketPositionId, value.StoreId)}</td>
									}
									<td className="text-center">
										<RoundButton onClick={ e => handleAssignmentDeleteClicked(index) } icon={ faTimes }></RoundButton>
									</td>
								</tr>)
							}
						</tbody>
					</Table>
				</React.Fragment>
			}
			</div>
}