import APIBase from "./APIBase";

export default class ReportsService extends APIBase{

     /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
      constructor(apiUrl, sessionStore) {
        super(apiUrl + "Reports", sessionStore);
    }

	async GetFacilityPreview(facilityId) {
		return this.Get(`GetFacilityPreview?facilityId=${facilityId}`);
	}

  async GetPaymentInstructionPreview(PaymentInstructionId,showRecoveries) {
		return this.Get(`GetPaymentInstructionPreview?PaymentInstructionId=${PaymentInstructionId}&showRecoveries=${showRecoveries}`);
	}
  
  async GetMaturityDateReport(FromDate,ToDate,GroupId, MarketPositionId, StoreId) {
		return this.Get(`GetMaturityDateReport?FromDate=${FromDate}&ToDate=${ToDate}&GroupId=${GroupId}&MarketPositionId=${MarketPositionId}&StoreId=${StoreId}`);
	}
	async GetFacilitiesMovementReport(FromDate,ToDate,GroupId, FacilitiesIds, Detailed) {
		return this.Post(`GetFaciltiesMovementReport?FromDate=${FromDate}&ToDate=${ToDate}&GroupId=${GroupId}&Detailed=${Detailed}`,FacilitiesIds);
	}

  async GetRecoveriesReport(PaymentInstructionId) {
		return this.Get(`GetRecoveriesReport?PaymentInstructionId=${PaymentInstructionId}`);
	}

  async GetPayablesDueReport(PaymentInstructionId) {
		return this.Get(`GetPayablesDueReport?PaymentInstructionId=${PaymentInstructionId}`);
	}

  async GetVendorReport(VendorId,PaymentInstructionId) {
		return this.Get(`GetVendorReport?VendorId=${VendorId}&PaymentInstructionId=${PaymentInstructionId}`);
	}

  async GetContacts() {
		return this.Get(`GetContacts`);
	}
  async GetPaymentInstructions() {
		return this.Get(`GetPaymentInstructions`);
	}

 	async GetFacilities(GroupId){
		return this.Get(`GetFacilities?GroupId=${GroupId}`);
	}
}