import React from "react"
import { useEffect } from "react";
import { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import swal from "sweetalert"
import { useSessionStore } from "../../../../../Stores/SessionStore";

export default function CreateFinancialTermModal(props) {
	const store = useSessionStore();

	const [name, setName] = useState("");	
	const [saving, setSaving] = useState(false);

	async function save() {
		if(name) {
			setSaving(true);
			let result = await store.FinancialTermsService.CreateFinancialTerm(name);
			setSaving(false);
	
			if(result && result.Success) {
				swal({ title: "Success", icon: "success", text: "Financial Term created successfully", closeOnClickOutside: false });
				props.onClose(true);
			} else {
				swal({ title: "Error", icon: "error", text: "An error occurred attempting to create the Financial Term", closeOnClickOutside: false });
			}
		}
	}

	useEffect(() => {
		if(props.show === false) {
			setName("");
		}
	}, [props.show]);

	return <Modal show={props.show}>
				<Modal.Header>
					<Modal.Title>
						Financial Term
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>				
					<Form>
						<Form.Group>
							<Form.Label>Name</Form.Label>
							<Form.Control value={ name } onChange={ (e) => setName(e.target.value) }></Form.Control>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={ saving } onClick={ save }>{ (saving ? "Saving..." : "Save") }</Button>
					<Button disabled={ saving } onClick={ () => props.onClose(false) }>Close</Button>
				</Modal.Footer>
			</Modal>
}