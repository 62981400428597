import React from 'react';
import {View, Text} from '@react-pdf/renderer';
import { styles } from '../../../StyleSheet';
import { formatCurrencyValue } from "../../../../../Utilities/Currencies";
export default function TransactionCurrencyConverstion(props){

    const showRecoveries = props.showRecoveries;

    return(
        <View style={styles.tableRowWrapping}> 
            <Text style={[styles.textFooters, {textAlign:'left', width: (showRecoveries ? '19%' : '30%'), borderTop:0.5, borderColor: 'lightgrey'}]}>Totals ({props.data.Currency.Abbreviation} Conversion)</Text>
            <Text style={[styles.textFooters, {textAlign:'center', width:(showRecoveries ? '6%' : '6%'), borderTop:0.5, borderColor: 'lightgrey'}]}>{props.data.Currency.Abbreviation}</Text>
            <Text style={[styles.textFooters, {textAlign:'right', width: (showRecoveries ? '14%' : '18%'), borderTop:0.5, borderColor: 'lightgrey'}]}></Text>
            <Text style={[styles.textFooters, {textAlign:'right', width: (showRecoveries ? '10%' : '10%'), borderTop:0.5, borderColor: 'lightgrey'}]}></Text>
            <Text style={[styles.textFooters, {textAlign:'right', width: (showRecoveries ? '14%' : '18%'), borderTop:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(props.data.Currency.Symbol,props.data.AmountTotal)}</Text>
            <Text style={[styles.textFooters, {textAlign:'right', width: (showRecoveries ? '14%' : '18%'), borderTop:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(props.data.Currency.Symbol,props.data.PaymentAmountTotal)}</Text>
            {showRecoveries &&
                <React.Fragment>
                    <Text style={[styles.textFooters, {textAlign:'right', width: '10%', borderTop:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(props.data.Currency.Symbol,(props.data.FundingAmountTotal - props.data.PaymentAmountTotal))}</Text>
                    <Text style={[styles.textFooters, {textAlign:'right', width: '13%', borderTop:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(props.data.Currency.Symbol,props.data.FundingAmountTotal)}</Text>
                </React.Fragment>
            }
        </View>
    )
}