import { Form, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { PaymentInstructionStatuses } from "../../../Utilities/Enums";
import { getPermissions, Permissions } from "../../../Utilities/Permissions";
import MonetaryValue from "../../Core/MonetaryValue"

export default function TransactionFinanceDetailsTable(props) {
	const store = useSessionStore();
	const permissions = getPermissions(Permissions.Sections.PaymentInstructions, Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement, store.Session);

	function showUnlink() {
		if(permissions.Edit) {
			if(props.paymentInstruction && props.paymentInstruction.PaymentInstructionStatusId < PaymentInstructionStatuses.Processed) {
				if(props.paymentInstruction.PaymentInstructionStatusId <= PaymentInstructionStatuses.Open || 
				(props.paymentInstruction.PaymentInstructionStatusId === PaymentInstructionStatuses.Pending && props.paymentInstruction.Submitted === false)) {
					return true;
				}
			} 
		}

		return false;
	}

	return 	<Table size="sm" bordered>
				<thead>
					<tr>
						<th>Document No.</th>
						<th>Store</th>
						<th className="text-end">Amount</th>
						{
							props.paymentInstruction && props.paymentInstruction.PaymentInstructionStatusId >= PaymentInstructionStatuses.Processed &&
							<th className="text-end">Projected Funding Amount</th>
						}
						
						{
							showUnlink() &&
							<th className="text-center">Unlink</th>
						}
					</tr>
				</thead>
				<tbody>
					{
						props.transactions  &&
						props.transactions.length > 0 &&
						props.transactions.map((t, index) =>
							<tr key={ index }>
								<td>{t.DocumentNumber}</td>
								<td>{t.Store}</td>
								<td className="text-end"><MonetaryValue value={ t.PaymentAmount } ></MonetaryValue></td>
								{
									props.paymentInstruction && props.paymentInstruction.PaymentInstructionStatusId >= PaymentInstructionStatuses.Processed &&
									<td className="text-end"><MonetaryValue value={ t.ProjectedFundingAmount } ></MonetaryValue></td>
								}
								{
									showUnlink() &&
									<td className="text-center"><Form.Check value={ t.TransactionId } onChange={ props.onTransactionChecked } checked={ props.checkedTransactions.includes(t.TransactionId)}></Form.Check></td>
								}
							</tr>)
					}
				</tbody>
			</Table>
}