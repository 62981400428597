import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, ModalDialog, Table } from "react-bootstrap";
import BusyIndicator from "../BusyIndicator";
import { useSessionStore } from "../../../Stores/SessionStore"
import { Roles } from "../../../Utilities/Permissions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Draggable from "react-draggable"

export default function ContextualHelpModal(props) {
	const [busy, setBusy] = useState(true);
	const [saving, setSaving] = useState(false);
	const [editorState, setEditorState] = useState("");
	const [appHelpItem, setAppHelpItem] = useState(null);
	const editorRef = useRef(null);
	const [saved,setSaved] = useState("Save");
	const store = useSessionStore();

	const delay = ms => new Promise(
		resolve => setTimeout(resolve, ms)
	  );

	useEffect(() => {
		async function loadAppHelpItem() {
			var result = await store.AppHelpService.GetHelpItem(props.descriptor);

			if(result) {				
				setEditorState(result.Data.Content);
				setAppHelpItem(result.Data);
				setBusy(false);
			}
		}

		if(props.show) {
			loadAppHelpItem();
		}
	}, [props.show]);

	async function handleSaveClicked() {
		if(editorRef.current) {
			appHelpItem.Content = editorRef.current.getData();

			setEditorState(appHelpItem.Content);
			setSaving(true);
			
			
			
			await store.AppHelpService.CreateOrUpdateHelpItem(appHelpItem);
			await delay(1000);
			setSaving(false);
			setSaved("Saved");
			
		}		
	}

	function onEditorReady(editor) {
		editor.editing.view.change((writer) => {
			writer.setStyle("height", "500px", editor.editing.view.document.getRoot());
			
		});

		editorRef.current = editor;
	}
	function onEditorReadyUser(editor) {
		
		editor.editing.view.change((writer) => {
			
			writer.setStyle("height", "500px", editor.editing.view.document.getRoot());
			editor.enableReadOnlyMode( 'my-feature-id' );
		});

		editorRef.current = editor;
	}


	return 	<Modal className="no-transition" dialogAs={ dprops => <Draggable cancel=".ck-editor"><ModalDialog {...dprops}></ModalDialog></Draggable> } show={ props.show } size="lg" style={{zIndex: 1050}}>
					<Modal.Body>
						{
							!busy && appHelpItem && store.Session.Role !== Roles.Admin &&
							<div style={{ height: "550px", overflow: "hidden" }}>
							
								<CKEditor config={{ height: '500px' ,toolbar:[]}} plugin={Table}  editor={ Editor } data={ editorState } onReady={ editor => onEditorReadyUser(editor) }  />
							</div>
							/* <div dangerouslySetInnerHTML={{ __html: appHelpItem.Content } }>
								
							</div> */
						}

						{
							appHelpItem && store.Session.Role === Roles.Admin &&
							<div id="editorDiv" style={{ height: "550px", overflow: "hidden" }}>
							
								<CKEditor config={{ height: '500px'}}  editor={ Editor } data={ editorState } onReady={ editor => onEditorReady(editor) } onChange={( event, editor ) => setSaved("Save") } 
								 />
								 
							</div>
						}
							
						<BusyIndicator show={ busy && appHelpItem === null || saving === true }></BusyIndicator>
					</Modal.Body>
					<Modal.Footer>
						{
							store.Session.Role === Roles.Admin &&
							<Button disabled={ busy || saving } onClick={ handleSaveClicked }>{saved}</Button>
						}
						<Button disabled={ busy } onClick={ e => props.onClose() }>Close</Button>
					</Modal.Footer>
				</Modal>
			
			
}