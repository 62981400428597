import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BusyIndicator(props) {
	if(props.show) {
		return 	<div className="w-100 text-center text-primary my-50">
					<FontAwesomeIcon size={props.size || "2x" } spin icon={ faSpinnerThird }></FontAwesomeIcon>
				</div>
	} else {
		return false;
	}
}