import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import Crestlogo from '../../../../Images/crest management system-LOGO.png';
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";

export default function FacilityMovementHeader(props){ 

    return(
        <View>
            <View style={{display: 'flex', flexDirection: 'row', justifyContent:'center', paddingLeft: 4, paddingRight: 4, paddingBottom: 15}}>
                <View style={{width:'20%', padding:1}}>
                    {
                        !props.data.AssignmentPictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-start"}} src={ Crestlogo } />
                    }
                    {
                        props.data.AssignmentPictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-start", paddingLeft:1}} src={ `data:${props.data.AssignmentPictureMIMEType};base64,${props.data.AssignmentPictureBase64}` } />
                    }
                </View>
                <View style={{width:'60%', padding:1}}>
                    <Text style={[styles.Title, {textAlign:'center', paddingTop:10}]}>Facility Movement Report - {props.detailed ? "Detailed" : "Summary"}</Text>
                </View>
                <View style={{width:'20%', padding:1}}>
                    {
                        !props.data.ProfilePictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-end"}} src={ Crestlogo } />
                    }
                    {
                        props.data.ProfilePictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-end"}} src={ `data:${props.data.ProfilePictureMIMEType};base64,${props.data.ProfilePictureBase64}` } />
                    }
                </View>
            </View>

            <View style={[styles.tableRowWrapping, {fontSize:7, padding:10, marginBottom:-15}]}>
                <Text style={{textAlign:'left', width:'25%'}}>From Date: <Text style={styles.boldFont}>{ DateTime.fromISO(props.data.FromDate).toFormat(DateFormats.LongDate) }</Text></Text>
                <Text style={{textAlign:'left', width:'25%'}}>To Date: <Text style={styles.boldFont}>{ DateTime.fromISO(props.data.ToDate).toFormat(DateFormats.LongDate) }</Text></Text>
                <Text style={{textAlign:'left', width:'25%'}}></Text>
                <Text style={{textAlign:'left', width:'25%'}}></Text>
            </View>
            <View style={[styles.tableRowWrapping, {fontSize:7, padding:10}]}>
                <Text style={{textAlign:'left', width:'25%'}}>Group: <Text style={styles.boldFont}>{ props.data.GroupName }</Text></Text>
                <Text style={{textAlign:'left', width:'25%'}}>Facility: <Text style={styles.boldFont}>{  }</Text></Text>
            </View>
            {
                props.facilities.map(values => 
                    <View style={[styles.tableRowWrapping, {fontSize:7, paddingLeft:10, marginTop:-18, paddingBottom:18}]}>
                        <Text style={{textAlign:'left', width:'25%'}}></Text>
                        <Text style={{textAlign:'left', width:'25%', marginLeft:30}}><Text style={styles.boldFont}>{ values.Header.Name }</Text></Text>
                        <Text style={{textAlign:'left', width:'25%'}}></Text>
                        <Text style={{textAlign:'left', width:'25%'}}></Text>
                    </View>
                )
            }
        </View>
    )
}