import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import DateInput from "./Forms/DateInput";

export default function DateRange(props) {
	return <React.Fragment>
				{
					!props.maturityDateRange &&
					<Col xs={ 12 } lg={ props.size || 7}>
						<Col>
							<Row>
								<Col xs={ 12 } lg={ 6 }>
									<Form.Group as={Row}>
										<Form.Label column xs={2} className="text-end">From</Form.Label>
										<Col className="px-0">
											<DateInput date={ props.fromDate } onChange={ props.onFromChange } autoFocus></DateInput>
										</Col>
									</Form.Group>
								</Col>
								<Col xs={ 12 } lg={ 6 }>
									<Form.Group as={Row}>
										<Form.Label column xs={2} className="text-end">To</Form.Label>
										<Col className="px-0">
											<DateInput date={ props.toDate} onChange={ props.onToChange }  maxDate={props.maxDate} autoFocus></DateInput>
										</Col>
									</Form.Group>
								</Col>
							</Row>
						</Col>
					</Col>
				}
				{
					props.maturityDateRange &&
					<Col xs={12} className="mb-50">
						<Row>
							<Col>
								<Form.Group as={Row} className="mb-50">
									<Form.Label column xs={3}>From</Form.Label>
									<Col className="px-0 ps-1 pe-1">
										<DateInput date={ props.fromDate } onChange={ props.onFromChange } xs={ 7 }></DateInput>
									</Col>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group as={Row}>
									<Form.Label column xs={3}>To</Form.Label>
									<Col className="px-0 ps-1 pe-1">
										<DateInput date={ props.toDate} onChange={ props.onToChange }></DateInput>
									</Col>
								</Form.Group>
							</Col>
						</Row>
					</Col>
				}
			</React.Fragment>
}