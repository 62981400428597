import React from "react";
import { Table } from "react-bootstrap";
import NumberValue from "../../../../../Core/NumberValue";

export default function FacilityLimits(props) {
	return 	<React.Fragment>
				<h6>Facility Limits</h6>
				<Table bordered className="align-middle" size="sm" responsive>
					<thead>
						<tr>
							<th>Number</th>
							<th>Name</th>
							<th className="text-end" style={{ width: "12%" }}>Limit</th>
							<th className="text-end" style={{ width: "12%" }}>Running Bal.</th>
							<th className="text-end" style={{ width: "12%" }}>Actual Bal.</th>
							<th className="text-end" style={{ width: "15%" }}>Available Bal.</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{ props.facility.FacilityNumber}</td>
							<td>{ props.facility.Name }</td>
							<td className="text-end"><NumberValue currency={ props.currency } value={ props.facilityBalances.Limit }></NumberValue></td>
							<td className={ props.facilityBalances.Balances.RunningTotal > props.facilityBalances.Limit ? "text-end fw-bold" : "text-end"}><NumberValue currency={ props.currency } value={ props.facilityBalances.Balances.RunningTotal }></NumberValue></td>
							<td className="text-end"><NumberValue currency={ props.currency } value={ props.facilityBalances.Balances.ActualTotal }></NumberValue></td>
							<td className="text-end"><NumberValue currency={ props.currency } value={ props.facilityBalances.AvailableBalance }></NumberValue></td>
						</tr>
					</tbody>
				</Table>
			</React.Fragment>
}