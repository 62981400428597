import { faChartArea, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { useEffect } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import PageHeader from "../../Core/PageHeader";
import Panel from "../../Core/Panel";
import Plus from "../../../Images/plus.png"
import Watch from "../../../Images/watch.png"
import People from "../../../Images/people.png"
import Calendar from "../../../Images/calendar.png"
import Cash from "../../../Images/cash.png"
import List from "../../../Images/list.png"
import trafficSources from "./TrafficSources";
import "./d3.css"
import appSalesLines from "./SalesStatistics";
import monthlySalesArea from "./MonthlySalesStats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Evaluator(props) {
	useEffect(() => {
		trafficSources('#traffic-sources', 330);
		appSalesLines("#sales-statistics", 255);
		monthlySalesArea("#monthly-sales-stats", 100)
	}, []);

	const dataToggle = React.forwardRef(({ children, onClick }, ref) => (
		<span style={{ cursor: "pointer" }} ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} > {children} <FontAwesomeIcon className="ms-50" size="xs" icon={ faChevronDown }></FontAwesomeIcon> </span>
	));
	
	return <React.Fragment>
				<PageHeader icon={ faChartArea } title={ "Home" }  helpItemDescriptor={ "MyZone/Home" }
							crumbs={[{ Title: "My Zone" }, { Active: true, Title: "Home" }]}>
				</PageHeader>
				<Row>
					<Col lg={7}>
						<Panel title="Traffic sources">
							<Row>
								<Col lg={4}>
									<div className="d-flex align-items-center justify-content-center">
										<div className="d-flex me-1">
											<img src={ Plus } alt="plus"></img>
										</div>
										<div className="d-flex flex-column">
											<div className="text-semibold">New visitors</div>
											<div className="text-muted">2,349 avg</div>
										</div>
									</div>
								</Col>

								<Col lg={4}>
									<div className="d-flex align-items-center justify-content-center">
										<div className="d-flex me-1">
											<img src={ Watch } alt="watch"></img>
										</div>
										<div className="d-flex flex-column">
											<div className="text-semibold">New sessions</div>
											<div className="text-muted">08:20 avg</div>
										</div>
									</div>
								</Col>

								<Col lg={4}>
									<div className="d-flex align-items-center justify-content-center">
										<div className="d-flex me-1">
											<img src={ People } alt="people"></img>
										</div>
										<div className="d-flex flex-column">
											<div className="text-semibold">Total online</div>
											<div className="text-muted"><span style={{ display: "inline-block", width: "8px", height: "8px", borderRadius: "50%", border: "2px solid #4CAF50" }}></span> 5,378 avg</div>
										</div>
									</div>
								</Col>
							</Row>
							<Row className="mt-5">
								<Col>
									<div id="traffic-sources"></div>
								</Col>
							</Row>
						</Panel>
					</Col>
					<Col lg={5}>
						<Card>
							<Card.Header>
								<Card.Title style={{ fontSize: "0.92857rem", color: "black" }}>Sales statistics</Card.Title>
								<div className="d-flex align-items-center">
									<span className="me-50" style={{ display: "inline-block", width: "8px", height: "8px", borderRadius: "50%", border: "2px solid #FF5722" }}></span>
									<Dropdown >
										<Dropdown.Toggle as={ dataToggle }>
											June, 22 - June 28
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item>June, 29 - July, 5</Dropdown.Item>
											<Dropdown.Item>June, 22 - June 28</Dropdown.Item>
											<Dropdown.Item>June, 15 - June, 21</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</Card.Header>							
							<Card.Body>
								<Row className="text-center">
									<Col md={4}>
										<div className="content-group">
											<h5 className="text-semibold mb-50"><img className="pe-50" src={ List } alt="list"></img> 5,689</h5>
											<span className="text-muted text-size-small">orders weekly</span>
										</div>
									</Col>

									<Col md={4}>
										<div className="content-group">
											<h5 className="text-semibold mb-50"><img className="pe-50" src={ Calendar } alt="calendar"></img> 32,568</h5>
											<span className="text-muted text-size-small">orders monthly</span>
										</div>
									</Col>

									<Col md={4}>
										<div className="content-group">
											<h5 className="text-semibold mb-50"><img className="pe-50" src={ Cash } alt="cash"></img> $23,464</h5>
											<span className="text-muted text-size-small">average revenue</span>
										</div>
									</Col>
								</Row>
								<Row className="mt-5">
									<Col>
										<div id="sales-statistics"></div>
									</Col>
								</Row>
								<Row className="mt-2">
									<Col>
										<div id="monthly-sales-stats"></div>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</React.Fragment>
}