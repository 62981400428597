import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";

export default function PaymentRecoveryPaymentInstructionTotalReimbursementAmount(props){
    return(
        <View style={styles.table}>
            <View style={styles.tableRowWrapping} >
                <Text style={[styles.textHeaders, {textAlign:'center', width:'23%'}]}></Text>
                <Text style={[styles.textHeaders, {textAlign:'center', width:'17%'}]}>Currency</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'60%'}]}>Reimbursement Amount</Text>
            </View>
            {props.data.Reimbursements.map(value =>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.textFooters, {textAlign:'left', width:'23%'}]}>Payment Instruction Total</Text>
                <Text style={[styles.textFooters, {textAlign:'center', width:'17%'}]}>{value.CurrencyAbbreviation}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'60%'}]}>{formatCurrencyValue(value.Currency,value.PaymentAmount)}</Text>
            </View>
            )}
        </View>
    )
}