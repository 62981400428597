import APIBase from "./APIBase";


/**
 * @typedef FinancialTermRate
 * @property {number} Id
 * @property {number} Rate
 * @property {string} Date
 * @property {boolean} Active
 */

/**
 * @typedef FinancialTerm
 * @property {number} Id
 * @property {string} Name
 * @property {FinancialTermRate} CurrentRate
 */

/**
 * @typedef FinancialTermsService
 * @type {FinancialTermsService}
 */
export default class FinancialTermsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "FinancialTerms", sessionStore);
    }

	/**
	 * @returns { import("./APIBase").RequestResult<FinancialTerm[]> }
	 */
	async GetCurrentFinancialTerms() {
		return this.Get(`GetCurrentFinancialTerms`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<FinancialTermRate[]> }
	 */
	 async GetFinancialTermRates(termId) {
		return this.Get(`GetFinancialTermRates?termId=${termId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async CreateFinancialTerm(name) {
		return this.Post(`CreateFinancialTerm?name=${encodeURIComponent(name)}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async UpdateRatesForFinancialTerm(termId, rates) {
		return this.Post(`UpdateRatesForFinancialTerm?termId=${termId}`, rates);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async DeleteFinancialTerm(termId) {
		return this.Post(`DeleteFinancialTerm?termId=${termId}`);
	}
}