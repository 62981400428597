import APIBase from "./APIBase";

/**
 * @typedef UnderutilisationResult
 * @property { string } Date
 * @property { number } FacilityLimit
 * @property { number } UnderutilisationFeePercentage
 * @property { number } InterestOnLimit
 * @property { number } UtilisedInterest
 * @property { number } UnderutilisedInterest
 * @property { string } RecoveredDate
 * @property { number } Status
 */

/**
 * @typedef UnderutilisationService
 * @type {UnderutilisationService}
 */
export default class UnderutilisationService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Underutilisation", sessionStore);
    }

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<UnderutilisationResult> }
	 */
	async GetUnderutilisationForFacility(facilityId, pageCount, pageIndex, from, to, funderId, status) {
		return this.Get(`GetUnderutilisationForFacility?facilityId=${facilityId}&pageCount=${pageCount}&pageIndex=${pageIndex}&
													from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&funderId=${funderId}&status=${status}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<string> }
	 */
	 async ExportUnderutilisationForFacility(facilityId, from, to, funderId, status) {
		return this.Get(`ExportUnderutilisationForFacility?facilityId=${facilityId}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&funderId=${funderId}&status=${status}`);
	}


	/**
     * @returns { import("./APIBase").EnumerableRequestResult<import("./FacilitiesService").FacilityIndexModel> }
     */
	async GetFacilities(){ 
		return this.Get(`GetFacilities`);
	}
}