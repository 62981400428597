import { observer } from "mobx-react-lite";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import React, {useState,useEffect } from "react";

const FacilitiesIndexFilters = observer(function FacilitiesIndexFilters(props) {
	const [group,setGroup] = useState();
	const [markPos , setMarkPos] = useState();
	const [stores,setStores] = useState();
	const [search,setSearch] = useState();
	const store = useSessionStore();

	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
			store.FacilitiesStore.GetFacilities(true)
		}
	}
	function handleFilterClicked()
	{	store.FacilitiesStore.Search = search;
		store.FacilitiesStore.StructureManager.SetSelectedGroup(parseInt(group));
		store.FacilitiesStore.StructureManager.SetSelectedMarketPosition(parseInt(markPos));
		store.FacilitiesStore.StructureManager.SetSelectedStore(parseInt(stores));
		store.FacilitiesStore.GetFacilities(true) 
	}
	useEffect(() => {
		setSearch(store.FacilitiesStore.Search)
		setGroup(store.FacilitiesStore.StructureManager.GroupId)
		setMarkPos(store.FacilitiesStore.StructureManager.MarketPositionId)
		setStores(store.FacilitiesStore.StructureManager.StoreId )
	}, []);
	

	return 	<Form className="mb-50">
				<h6 className="mb-1">Filter Facilities</h6>
				<Row>
					<Col>
						<Form.Group as={Row}>
							<Col lg={2}>
								<Form.Control onKeyPress={ e => handleKeyPress(e) } value={ search } onChange={ e => setSearch(e.target.value) } type="search" placeholder="Search..." />
							</Col>
							<Form.Label className="text-end px-0" column lg={1}>Group</Form.Label>
							<Col lg={2}>
								<Form.Select value={ group } onChange={ e => setGroup(parseInt(e.target.value)) }>
									<option value="0">All</option>
									{
										store.FacilitiesStore.StructureManager.Groups.map((group) =>
										<option key={ group.Id } value={ group.Id }>{group.Name}</option>
										)
									}
								</Form.Select>
							</Col>
							<Form.Label className="text-end px-0"  column lg={1}>Market Position</Form.Label>
							<Col lg={2}>
								<Form.Select value={ markPos } onChange={ e => setMarkPos(parseInt(e.target.value)) }>
									<option value="0">All</option>
									{
										store.FacilitiesStore.StructureManager.MarketPositions.map((mp) =>
										<option key={ mp.Id } value={ mp.Id }>{mp.Name}</option>
										)
									}
								</Form.Select>
							</Col>
							<Form.Label className="text-end px-0"  column lg={1}>Store</Form.Label>
							<Col lg={2}>
								<Form.Select value={ stores } onChange={ e => setStores(parseInt(e.target.value)) }>
									<option value="0">All</option>
									{
										store.FacilitiesStore.StructureManager.Stores.map((s) =>
										<option key={ s.Id } value={ s.Id }>{s.Name}</option>
										)
									}
								</Form.Select>
							</Col>
							<Col xs={12} lg={ 1 } className="d-flex align-items-center">
								<Button className="w-100 text-nowrap px-0" onClick={ e => handleFilterClicked()}>Filter</Button>
							</Col>
						</Form.Group>
					</Col>
				</Row>
			</Form>
});

export default FacilitiesIndexFilters;