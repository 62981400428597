import React from "react";
import { Table } from "react-bootstrap";
import { DateFormats } from "../../../../../../Utilities/Enums";
import { getStatusText } from "../../../../../../Utilities/PaymentInstructions";
import DateValue from "../../../../../Core/DateValue";
import NumberValue from "../../../../../Core/NumberValue";
import {NavLink,useLocation} from "react-router-dom";

export default function LinkedPaymentInstructions(props) {
	const {pathname} = useLocation();
	localStorage.setItem("FacilityPath", pathname );
	return 	<React.Fragment>
				<h6>{ props.title || "Linked Payment Instructions" }</h6>
				<Table bordered className="align-middle" size="sm" responsive>
					<thead>
						<tr>
							<th>Number</th>
							<th>Name</th>
							<th>Payment Date</th>
							<th className="text-end" style={{ width: "12%" }}>Limit</th>
							<th className="text-end" style={{ width: "12%" }}>Running Bal.</th>
							<th className="text-end" style={{ width: "12%" }}>Actual Bal.</th>
							<th className="text-center" style={{ width: "15%" }}>Status</th>
						</tr>
					</thead>
					<tbody>
						{
							props.paymentInstructions.map((value) =>
							<tr key={value.PaymentInstructionId}>
								<td>{ value.Number }</td>
								<td><NavLink to={ `/PaymentManagement/PaymentInstructions/View/${value.PaymentInstructionId}` }>{ value.Name }</NavLink></td>
								<td>
									{
										value.PaymentDate &&
										<DateValue date={ value.PaymentDate } outputFormat={ DateFormats.FullLongDate }></DateValue>
									}
									{
										!value.PaymentDate &&
										<span>N/A</span>
									}
								</td>
								<td className="text-end"><NumberValue currency={ value.Currency || props.currency } value={ value.Limit || 0 }></NumberValue></td>
								<td className="text-end"><NumberValue currency={ value.Currency || props.currency } value={ value.Balances.RunningTotal }></NumberValue></td>
								<td className="text-end"><NumberValue currency={ value.Currency || props.currency } value={ value.Balances.ActualTotal }></NumberValue></td>
								<td className="text-center">{ getStatusText(value) }</td>
							</tr>)
						}
						{
							props.unlinkedTransactionBalances &&
							<tr>
								<td colSpan="4"><span>Unlinked Transactions</span></td>
								<td className="text-end" colSpan="1"><NumberValue currency={ props.currency } value={ props.unlinkedTransactionBalances.RunningTotal }></NumberValue></td>
								<td className="text-end" colSpan="1"><NumberValue currency={ props.currency } value={ props.unlinkedTransactionBalances.ActualTotal || 0.0 }></NumberValue></td>
								<td></td>
							</tr>
						}
						
					</tbody>
				</Table>
			</React.Fragment>
}