import { faGear } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import StructureIndex from "./StructureIndex";
import { useParams } from "react-router-dom"
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

export default function StoresIndex(props) {

	UseDocumentTitle('Listing \'Stores\' for Crest');

	const store = useSessionStore();
	const params = useParams();

	const [stores, setStores] = useState(null);
	const [totalStores, setTotalStores] = useState(0);
	const [hierarchy, setHierarchy] = useState(null);

	async function exportStores(search) {
		return store.StructureService.ExportStoresForMarketPosition(params.parentId, search);
	}

	async function getStores(search, filter) {
		setStores(null);
		let results = await store.StructureService.GetStoresForMarketPosition(params.parentId, search, filter.PageIndex, filter.PageCount);

		if(results && results.Success) {
			setHierarchy(results.Data)
			setTotalStores(results.Data.TotalStructures);
			setStores(results.Data.Structures);
		}
	}

	async function deleteStore(storeId) {
		let result = await store.StructureService.DeleteStore(storeId);

		if(result && result.Success) {
			return true;
		} else {
			return false;
		}
	}
	
	return <StructureIndex export={ exportStores } getEntities={ getStores } deleteEntity={ deleteStore }
							entityName="Stores" childrenName="" title={ `Stores for '${(hierarchy ? hierarchy.ParentName : "")}' in '${(hierarchy ? hierarchy.GrandparentName : "")}'` }
							icon={ faGear } entities={ stores }  total={totalStores}>
			</StructureIndex>
}