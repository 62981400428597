import { FormControl } from "react-bootstrap";
import NumberFormat from "react-number-format";

export default function NumberInput(props) {

	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
		}
	}

	return <NumberFormat style={ props.style } onKeyPress={ handleKeyPress } onValueChange={ e => props.onChange && props.onChange(e.floatValue) }
							customInput={ FormControl } prefix={ props.prefix } value={ props.value } thousandSeparator={ ' ' }
								decimalScale={2} fixedDecimalScale disabled={ props.disabled } placeholder={ props.placeholder }></NumberFormat>
}