import APIBase from "./APIBase";

/**
 * @typedef MessagesService
 * @type {MessagesService}
 */
export default class MessagesService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Messages", sessionStore);
    }

	/**
	 * @returns { import("./APIBase").RequestResult<Number> }
	 */
	async GetTotalUnreadMessages() {
		return this.Get(`GetTotalUnreadMessages`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async MarkMessageAsRead(messageId) {
		return this.Post(`MarkMessageAsRead?messageId=${messageId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<string> }
	 */
	 async GetMessageContent(messageId) {
		return this.Get(`GetMessageContent?messageId=${messageId}`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<any> }
	 */
	 async GetUnreadMessages() {
		return this.Get(`GetUnreadMessages`);
	}
}