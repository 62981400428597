import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { getPermissions, Permissions } from "../../../../Utilities/Permissions";
import PageHeader from "../../../Core/PageHeader";
import Panel from "../../../Core/Panel";
import IconButton from "../../../Core/IconButton";
import { faFileExcel,  faWallet } from "@fortawesome/pro-regular-svg-icons";
import PaymentInstructionsFilters from "./PaymentInstructionsFilters";
import IndexTable from "./IndexTable";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

const PaymentProcessingIndex = observer(function PaymentProcessingIndex(props) {

	UseDocumentTitle('Listing \'Payment Processing\' on CREST');

	const store = useSessionStore();

	useEffect(() => {
		store.PaymentManagementStore.GetPaymentProcessingInstructions();
	}, []);

	async function handleExportPaymentInstructionsClicked() {
		var result = await store.PaymentManagementStore.ExportPaymentProcessing();

		if(result) {
			//save file
			var blob = new Blob([result], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `PAYMENT_PROCESSING_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);
		}
	}

	function handleFilterUpdate(property, value) {
		let filters = { ...store.PaymentManagementStore.PaymentProcessingFilters };
		filters[property] = value;

		store.PaymentManagementStore.SetPaymentProcessingFilters(filters);
	}

	const piPermissions = getPermissions(Permissions.Sections.PaymentProcessing, Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement, store.Session);
	
	return 	<React.Fragment>
				<PageHeader icon={ faWallet } title="Payment Processing" helpItemDescriptor="PaymentInstruction/Processing"
								crumbs={[{ Title: "Credit Management" }, { Title: "Payment Management" }, { Title: "Payment Processing" }]}>
					{
						piPermissions.View &&
						<IconButton icon={ faFileExcel } disabled={ store.PaymentManagementStore.ExportingPaymentProcessing } onClick={ handleExportPaymentInstructionsClicked }>
							{ store.PaymentManagementStore.ExportingPaymentProcessing ? "Exporting..." : "Export All" }
						</IconButton>
					}
				</PageHeader>
				<Panel>
					<PaymentInstructionsFilters filters={ store.PaymentManagementStore.PaymentProcessingFilters } processing
												updateCallback={ handleFilterUpdate }
												fetchCallback={ e => store.PaymentManagementStore.GetPaymentProcessingInstructions() }>
					</PaymentInstructionsFilters>
					
					<IndexTable data={ store.PaymentManagementStore.PaymentProcessing } retrievingData={ store.PaymentManagementStore.RetrievingPaymentProcessing }
								filters={ store.PaymentManagementStore.PaymentProcessingFilters } processing
								updateCallback={ e => store.PaymentManagementStore.SetPaymentProcessingFilters(e) }
								fetchCallback={ e => store.PaymentManagementStore.GetPaymentProcessingInstructions() }>
					</IndexTable>
				</Panel>
				
			</React.Fragment>
});

export default PaymentProcessingIndex;