import { Button, Col, Form, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import DateRange from "../../../Core/DateRange";
import { useState } from "react";

export default function TransactionDocumentsFilters(props) {
	const store = useSessionStore();
	const financeStore = store.GetFinanceStore(props.facilityType);
	const [fromDate,setFromDate] = useState(financeStore.TransactionDocumentsFilters.From);
	const [search,setSearch] = useState(financeStore.TransactionDocumentsFilters.Search);
	const [toDate,setToDate] = useState(financeStore.TransactionDocumentsFilters.To);
	function handleFilterClicked() {
		
		financeStore.SetTransactionDocumentsFilters({...financeStore.TransactionDocumentsFilters, Search:search,To:toDate,From:fromDate, PageIndex: 0 });
		financeStore.GetTransactionDocuments();
	}

	return 	<Form className="mb-50">
				<h6 className="mb-1">Filter { financeStore.GetContactType() } Documents</h6>
				<Row className="align-items-center">
					<Col>
						<Form.Group>
							<Col>
								<Form.Control value={ search} type="search" placeholder="Search..."
								onChange={ e => {setSearch(e.target.value) } } />
							</Col>
						</Form.Group>
					</Col>	
					<DateRange 	fromDate={fromDate} toDate={toDate}
								onFromChange={ e => { setFromDate(e) } }
								onToChange={ e => {setToDate(e) } }>
					</DateRange>
					<Col xs={1} className="d-flex align-items-end justify-content-end">
						<Button className="w-100 text-nowrap px-0" onClick={ handleFilterClicked }>Filter</Button>
					</Col>
				</Row>
			</Form>
}