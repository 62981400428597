import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import HelpButton from "./ContextualHelp/HelpButton";
import Panel from "./Panel";

export default function Breadcrumb(props) {
    const items = props.crumbs.map((value, index) => {
        if (value.HRef)
            return <span key={ index }> / <a style={{ color: "#333" }} href={ value.HRef}>{ value.Title }</a></span>
        else
            return <span style={{ color: (value.Active ? "#999" : "#333" ) }} key={ index }> / {value.Title}</span>
    });

    return	<Panel className="p-0 mx-n1 rounded-0 shadow-none border-top border-bottom" bodyClassName="py-0 px-1">
				<Row className="align-items-center" style={{ paddingTop: "4.5px", paddingBottom: "5px" }}>
					<Col xs={ 8 } style={{ whiteSpace: "nowrap" }}>
						<a style={{ color: "#333" }} href="/#/Facilities"><FontAwesomeIcon icon={ faHome }></FontAwesomeIcon> Home</a>
						{items}
					</Col>
					<Col className="d-flex justify-content-end">
						{
							props.pageDescriptor &&
							<HelpButton descriptor={ props.pageDescriptor }></HelpButton>
						}
					</Col>
				</Row>				
			</Panel>

}