import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../../../Stores/SessionStore";

export default function CopyFromUser(props) {
	const store = useSessionStore();

	const [selectedUser, setSelectedUser] = useState("");
	const [retrievingCopy, setRetrievingCopy] = useState(false);

	async function copyClicked() {
		if(selectedUser) {
			setRetrievingCopy(true);
			let results = await store.SystemAppsService.GetUserAssignmentsForSystemApp(selectedUser, props.systemAppId);
			setRetrievingCopy(false);

			if(results && results.Success) {
				props.copyFromUser(results.Data);
			}
		}
	}

	return 	<Row className="my-2">
				<Col xs={9}>
					<Form.Group as={ Row }>
						<Form.Label column>Copy From User</Form.Label>
						<Col xs={8}>
							<Form.Select disabled={ retrievingCopy } value={selectedUser} onChange={ e => setSelectedUser(e.target.value) }>
								<option value=""></option>
								{
									props.users && props.users.map((u) => 
									<option value={ u.Id }>{u.Name}</option>)
								}
							</Form.Select>
						</Col>						
					</Form.Group>					
				</Col>
				<Col>
					<Button disabled={ retrievingCopy } onClick={ copyClicked }>{ retrievingCopy ? "Copying..." : "Copy" }</Button>
				</Col>
			</Row>
}