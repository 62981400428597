import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import BusyIndicator from "../BusyIndicator";
import Panel from "../Panel";
import DocumentButton from "./DocumentButton";
import UploadDocumentModal from "./UploadDocumentModal";

export default function Documents(props) {
	const [showUploadModal, setShowUploadModal] = useState(false);

	function handleDocumentDelete(id) {
		let index = props.entity.Documents.findIndex(d => d.Id === id);

		if(index > -1) {
			props.entity.Documents.splice(index, 1);

			props.updateEntity({...props.entity });

			return { Success: true }
		}

		return { Success: false }
	}

function handleDocumentUpload(docs) {
			props.entity.Documents.push(...docs);
		props.updateEntity({...props.entity });
	
}
	
	return 	<Panel title="Documents">
				
				{
					
					props.entity && props.entity.Documents &&
					
					<Row>
						<Col>
							{
								
								props.entity.Documents.map(doc => <DocumentButton key={ doc.FileId }  onDelete={ handleDocumentDelete } doc={doc} allowDelete={ ((!props.viewOnly && props.allowDocChanges) && !props.preventDelete) }></DocumentButton>)
							}
						</Col>
					</Row>
				}
				<Row className="mt-2">
					<Col>
						{
							(!props.viewOnly && props.allowDocChanges) &&
							<Button onClick={ e => setShowUploadModal(true) }>Upload Document</Button>
						}
					</Col>
				</Row>
				<UploadDocumentModal onUpload={ handleDocumentUpload } onClose={ e => setShowUploadModal(false) } show={ showUploadModal }></UploadDocumentModal>
				<BusyIndicator show={ props.entity === null || props.entity.Documents === null }></BusyIndicator>
			</Panel>
}