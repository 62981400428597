import { DateTime } from "luxon";
import React from "react";
import { Row } from "react-bootstrap";
import NumberValue from "../../../Core/NumberValue";
import ApprovalValue from "../ApprovalValue";

export default function FinanceDetails(props) {

	

	return	<React.Fragment>
				<Row className="mt-3">
					<ApprovalValue label="Facility">
						{ props.paymentInstruction.Facility }
					</ApprovalValue>
					<ApprovalValue label="Finance %">
						{ `${props.paymentInstruction.FinancePercentage.toFixed(2)}%` }
					</ApprovalValue>
					<ApprovalValue label="Facility Limit">
						<NumberValue currency={ props.paymentInstruction.CurrencySymbol } value={props.paymentInstruction.FacilityLimit  }></NumberValue>
					</ApprovalValue>
					<ApprovalValue label="Facility Balance">
						<NumberValue currency={ props.paymentInstruction.CurrencySymbol } value={props.paymentInstruction.FacilityBalance  }></NumberValue>
					</ApprovalValue>
				</Row>
				<Row className="mb-3">
					<ApprovalValue label="Number">{ props.paymentInstruction.FacilityNumber }</ApprovalValue>
					<ApprovalValue label="Payment Date">
						{ DateTime.fromISO(props.paymentInstruction.PaymentDate).toFormat("dd MMMM yyyy") }
					</ApprovalValue>
					<ApprovalValue label="Payment Limit">
						{
							props.paymentInstruction.PaymentLimit === 0 && <span>N/A</span>
						}
						{
							props.paymentInstruction.PaymentLimit > 0 && 
							<NumberValue currency={ props.paymentInstruction.CurrencySymbol } value={props.paymentInstruction.PaymentLimit  }></NumberValue>
						}
					</ApprovalValue>
					<ApprovalValue label="Payment Balance">
						{
							props.paymentInstruction.PaymentLimit === 0 && <span>N/A</span>
						}
						{
							props.paymentInstruction.PaymentLimit > 0 && 
							<NumberValue currency={ props.paymentInstruction.CurrencySymbol } value={props.paymentInstruction.Balance  }></NumberValue>
						}
					</ApprovalValue>
				</Row>
			</React.Fragment> 
	
}