import { useParams } from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import StructureDetails from "./StructureDetails";
import { useState,useEffect } from "react";

export default function MarketPositionDetails(props) {
	
	const [hierarchy, setHierarchy] = useState(null);

	const store = useSessionStore();
	const params = useParams();
	
	useEffect(() => {
		check()
		return () => {
		  setHierarchy({}); // This worked for me
		};
	}, []);
	
	async function getMarketPosition(id) {
		return store.StructureService.GetMarketPosition(id);
	}

	async function check() {
		let results = await store.StructureService.GetMarketPositionsForGroup(params.parentid, '', 0, 10);

		if(results && results.Success) {
			setHierarchy(results.Data);
		}
	}

	async function createMarketPosition(marketPosition) {
		var mpCheck = await store.StructureService.CreateMarketPosition(marketPosition, params.id);
		await store.LoadAuthState();

		return mpCheck;
	}

	async function updateMarketPosition(marketPosition) {
		return store.StructureService.UpdateMarketPosition(marketPosition);
	}
	
	return 	<StructureDetails 	getStructure={ getMarketPosition }
								updateStructure={ updateMarketPosition } 
								createStructure={ createMarketPosition } 
								view={ props.view } 
								title={ `Market Position for '${(hierarchy ? hierarchy.ParentName : "")}'`}
								
								name="Market Position" 
								childName="Stores"
								add={props.add}>
			</StructureDetails>
}