import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SelectField from "../../../../Core/Forms/SelectField";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import StructureManager from "../../../../../Utilities/StructureManager"
import { StructureDropDown } from "../../../../Core/StructureFilter";
import Panel from "../../../../Core/Panel";
import { FacilityStatuses } from "../../../../../Utilities/Enums";
import TextValue from "../../../../Core/TextValue";
import TextValueField from "../../../../Core/Forms/TextValueField";

export default function AssignmentLinks(props) {
	const formikContext = useFormikContext();
	const store = useSessionStore();

	const [facilities, setFacilities] = useState(null);
	const structure = useRef(new StructureManager(store.Session.AuthState.UserAppStructure.AvailableStructure, true));
		
	async function loadFacilities() {
		setFacilities(null);
		
		if(structure.current.GroupId && structure.current.MarketPositionId !== "" && structure.current.StoreId !== "") {
			let includeInactive = props.view || false;
			let result = await store.FacilitiesService.GetFacilitiesForStructure(structure.current.GroupId, 
																structure.current.MarketPositionId, 
																structure.current.StoreId, 0, includeInactive);
	
			if(result && result.Success) {
				setFacilities(result.Data.filter(f => includeInactive || f.FacilityStatusId === FacilityStatuses.Active));				
			}
		}
	}

	async function handleGroupChanged(groupId) {
		structure.current.SetSelectedGroup(groupId);
		formikContext.setValues({
			...formikContext.values, 
			GroupId: groupId, 
			MarketPositionId: 
			structure.current.MarketPositionId, 
			StoreId: structure.current.StoreId,
			FacilityId: ""
		}, true);

		loadFacilities();
	}

	function handleMarketPositionChanged(marketPositionId) {
		structure.current.SetSelectedMarketPosition(marketPositionId);
		formikContext.setValues({
			...formikContext.values, 
			MarketPositionId: marketPositionId, 
			StoreId: structure.current.StoreId,
			FacilityId: "" 
		}, true);
		
		loadFacilities();
	}

	function handleStoreChanged(storeId) {
		structure.current.SetSelectedStore(storeId);
		formikContext.setValues({
			...formikContext.values, 
			StoreId: storeId,
			FacilityId: "" 
		}, true);
		
		loadFacilities();
	}

	useEffect(() => {		
		structure.current.SetSelectedGroup(formikContext.values["GroupId"] || "");
		structure.current.SetSelectedMarketPosition(formikContext.values["MarketPositionId"] || 0);
		structure.current.SetSelectedStore(formikContext.values["StoreId"] || 0);
		
		if(!facilities) {
			loadFacilities();
		}
	},[])

	function handleFacilityChanged(facilityId) {
		if(facilityId) {
			let facility = facilities.find(f => f.Id === parseInt(facilityId));
	
			if(facility) {
				formikContext.setValues({...formikContext.values, 
					FacilityId: facilityId, 
					CurrencyId: facility.CurrencyId, 
					BatchDates: facility.BatchDates,
					Currency: facility.Currency,
					Currencies: []
				}, true);
			}
		}
	}

	return 	<React.Fragment>
				<Panel title="Assignment">
					{
						(props.view || formikContext.values.HasTransactions) &&
						<Row>
							<Col>

								<TextValueField label="Group" value={ formikContext.values.GroupName }></TextValueField>
							</Col>
							<Col>
								<TextValueField label="Market Position" value={ formikContext.values.MarketPositionName }></TextValueField>
							</Col>
							<Col>
								<TextValueField label="Store" value={ formikContext.values.StoreName }></TextValueField>
							</Col>
						</Row>
					}
					{
						!(props.view || formikContext.values.HasTransactions) &&
						<Row>
							<Col>
					
								<StructureDropDown view={props.view || formikContext.values.HasTransactions} invalid={ formikContext.errors["GroupId"] } required row label="Group" value={ structure.current.GroupId } structure={ structure.current.Groups } onChange={ handleGroupChanged }></StructureDropDown>
							</Col>
							<Col>
					
								<StructureDropDown view={props.view || formikContext.values.HasTransactions} invalid={ formikContext.errors["MarketPositionId"] } allowAll label="Market Position" value={ structure.current.GroupId === "" ? "" : structure.current.MarketPositionId } structure={ structure.current.MarketPositions } onChange={ handleMarketPositionChanged }></StructureDropDown>
							</Col>
							<Col>
						
								<StructureDropDown view={props.view || formikContext.values.HasTransactions} invalid={ formikContext.errors["StoreId"] } allowAll label="Store" value={ structure.current.GroupId === "" ? "" : structure.current.StoreId } structure={ structure.current.Stores } onChange={ handleStoreChanged }></StructureDropDown>
							</Col>
						</Row>
					}
					
					<Row>						
						<Col xs={4}>
							<Form.Group as={Row}>
								<Form.Label xs={3} column>Facility</Form.Label>
								<Col >
								{
									(props.view || formikContext.values.HasTransactions) &&
									<div className="d-flex align-items-center h-100">
										<TextValue value={ formikContext.values.FacilityName }></TextValue>
									</div>
								}
								{
									!props.view && !formikContext.values.HasTransactions &&
									<SelectField invalid={ formikContext.errors["FacilityId"] } onChange={ e => handleFacilityChanged(e.target.value) } required disabled={ facilities === null } name="FacilityId" >
										<option value="">-- Select --</option>
										{
											facilities && facilities.map(facility => 
												<option key={facility.Id} value={facility.Id}>{ facility.Number } - { facility.Name }</option>)
										}
									</SelectField>
								}
								</Col>
							</Form.Group>
						</Col>
					</Row>
				</Panel>
			</React.Fragment> 	
	
}