
import { useFormikContext } from "formik";
import { useState,useEffect } from "react";
import { Table } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { getCurrencySymbol } from "../../../../Utilities/Currencies";
import { getRecoveryStatus } from "../../../../Utilities/Transactions";
import DateValue from "../../../Core/DateValue";
import NumberValue from "../../../Core/NumberValue";
import Panel from "../../../Core/Panel";

export default function RecoveriesTable(props) {
	const formikContext = useFormikContext();
	const store = useSessionStore()
	const [facilityCurrencyID,setFaciltyCurrencyID] = useState(0);
	function getCurrency() {
		
		return getCurrencySymbol(facilityCurrencyID, props.currencies);
	}
	useEffect(() => {
		geFacCurID();
		
		
	}, []);

	async function geFacCurID()
	{
	
		var result = await store.FacilitiesService.GetFacility(formikContext.values["FacilityId"]);
	
			if(result && result.Success) {
				var facility = result.Data.Facility;
			
					setFaciltyCurrencyID(facility.currencyID);
			}
	}

	function getTotalReceived() {
		let result = props.transaction.Recoveries.reduce((total, recovery) => total + recovery.Amount, 0);
		return result;
	}

	return 	<Panel title="Transaction Recoveries">
				<Table bordered size="sm" responsive>
					<thead>
						<tr>
							<th>Document No.</th>
							<th className="no-truncation">Payment Instruction No.</th>
							<th className="text-end">Document Total</th>
							<th className="text-end">Interest</th>
							<th className="text-end">Funding Amount</th>
							<th>Status</th>
							<th>Recovery Date</th>
							<th className="text-end">Recovered Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td rowSpan={2 + props.transaction.Recoveries.length} className="align-top">{ props.transaction.DocumentNumber }</td>
							<td rowSpan={2 + props.transaction.Recoveries.length} className="align-top">{ props.transaction.PaymentInstruction }</td>
							<td rowSpan={2 + props.transaction.Recoveries.length} className="text-end align-top"><NumberValue currency={ getCurrency() } value={ props.transaction.ConvertedDocumentAmount + props.transaction.ConvertedVATAmount }></NumberValue></td>
							<td rowSpan={2 + props.transaction.Recoveries.length} className="text-end align-top"><NumberValue currency={ getCurrency() } value={ props.transaction.Interest }></NumberValue></td>
							<td rowSpan={2 + props.transaction.Recoveries.length} className="text-end align-top"><NumberValue currency={ getCurrency() } value={ props.transaction.ConvertedFundingAmount }></NumberValue></td>
							<td rowSpan={2 + props.transaction.Recoveries.length} className="align-top">{ getRecoveryStatus(props.transaction) }</td>
							<td><DateValue outputFormat="dd MMMM yyyy" date={ props.transaction.Recoveries[0].RecoveryDate }></DateValue></td>
							<td className="text-end"><NumberValue currency={ getCurrency() } value={ props.transaction.Recoveries[0].Amount }></NumberValue></td>
						</tr>
						{
							props.transaction.Recoveries.map((recovery, index) => {
								return (index > 0) ? <tr>
														<td><DateValue outputFormat="dd MMMM yyyy" date={ recovery.RecoveryDate }></DateValue></td>
														<td className="text-end"><NumberValue currency={	getCurrency() } value={ recovery.Amount }></NumberValue></td>
													</tr> : false;
							})
						}
						<tr>
							<td><b>Received</b></td>
							<td className="text-end"><b><NumberValue currency={ getCurrency() } value={ getTotalReceived() }></NumberValue></b></td>
						</tr>
						<tr>
							<td><b>Outstanding</b></td>
							<td className="text-end"><b><NumberValue currency={ getCurrency() } value={ props.transaction.OutstandingAmount }></NumberValue></b></td>
						</tr>
					</tbody>
				</Table>
			</Panel>
	
	
}