import { Col, Form, InputGroup, Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import NumberValue from "../../../../Core/NumberValue";
import currency from "currency.js";

export default function TransactionsTable(props) {
	function handleTransactionUpdated () {
		if(props.onTransactionUpdated) {
			props.onTransactionUpdated();
		}
	}

	function handleFillAmountClicked (checked, transaction) {
		transaction.Filled = checked;
		props.recovery.FillTransaction(transaction);        
		handleTransactionUpdated();
	}

	function handleAmountChanged (transaction, newValue) {
		props.recovery.SetTransactionValue(transaction, newValue);
		handleTransactionUpdated();
	}

	function handleTheoreticalAmountChanged (transaction, newValue, sourceInfo) {
		if(sourceInfo.source === "event") {
			props.recovery.SetTransactionTheoreticalValue(transaction, newValue);
			handleTransactionUpdated();
		} 
	}

	function handleReimbursementChanged(value) {		
		props.recovery.SetReimbursementValue(value);
		handleTransactionUpdated();
	}

	function handleTheoreticalReimbursementChanged(value, sourceInfo) {
		if(sourceInfo.source === "event") {
			props.recovery.SetTheoreticalReimbursementValue(value);
			handleTransactionUpdated();
		}
	}

	function handleFillReimbursementClicked (checked) {		
		props.recovery.FillReimbursement(props.recoveryTotals.RecoveryRemaining, checked);
		handleTransactionUpdated();
	}
          
    return 	<Table size="sm" responsive bordered>
				<thead>
					<tr>
						<th>Invoice Number</th>
						<th className="text-end">Document Total</th>
						<th className="text-end">Payment Amount</th>
						<th className="text-end">Projected Funding Amount</th>
						<th className="text-end">Current Interest</th>
						<th className="text-end">Remaining Capital</th>
						<th style={{ minWidth: "120px" }}>Theoretical Amount</th>
						<th style={{ minWidth: "120px" }}>Outstanding Amount</th>
					</tr>
				</thead>
				<tbody>
					{
						props.transactions.map((value, index) => {
							
						value.TheoreticalAmountChanged = value.TheoreticalAmountChanged || false;                            
						value.Index = index;
						
						var addon = <input checked={ value.Filled } type="checkbox" onChange={ (e) => handleFillAmountClicked(e.target.checked, value) } /> 
						
						return <tr key={ index }>
							<td> { value.DocumentNumber } </td>
							<td className="text-end"><NumberValue currency={ props.currencySymbol } value={ value.DocumentTotal }></NumberValue></td>
							<td className="text-end"><NumberValue currency={ props.currencySymbol } value={ value.PaymentAmount }></NumberValue></td>
							<td className="text-end"><NumberValue currency={ props.currencySymbol } value={ value.FundingAmount }></NumberValue></td>
							<td className="text-end"><NumberValue currency={ props.currencySymbol } value={ value.CurrentInterest + value.ShortfallInterest }></NumberValue></td>
							<td className="text-end"><NumberValue currency={ props.currencySymbol } value={ value.RemainingCapital }></NumberValue></td>
							<td>
								<Form.Group>
									<Col>
										<InputGroup>
											<InputGroup.Text>{props.currencySymbol}</InputGroup.Text>
											<NumberFormat customInput={ Form.Control } onValueChange={ (e, s) => handleTheoreticalAmountChanged(value, e.floatValue, s) } value={ (value.UnusedAmount ? value.UnusedAmount : "" ) } thousandSeparator={ ' ' } 
											decimalScale={2} fixedDecimalScale placeholder={ `${currency(value.MaxAmount, { separator: ' ', symbol: `${props.currencySymbol} ` }).format()}` }></NumberFormat>
										</InputGroup>
									</Col>
								</Form.Group>
							</td>
							<td>
								<Form.Group>
									<Col>
										<InputGroup>
											<InputGroup.Text>{props.currencySymbol}</InputGroup.Text>
											<NumberFormat customInput={ Form.Control } onValueChange={ (e) => handleAmountChanged(value, e.floatValue) } value={ (value.Amount ? value.Amount : "" ) } thousandSeparator={ ' ' } 
											decimalScale={2} fixedDecimalScale placeholder={ `${currency(value.MaxAmount, { separator: ' ', symbol: `${props.currencySymbol} ` }).format()}` }></NumberFormat>
											<InputGroup.Text>{addon}</InputGroup.Text>
										</InputGroup>
									</Col>
								</Form.Group>
							</td>
						</tr>})
					}

					{
						props.isOverRecovered &&
						<tr>
							<td colSpan="6">Over Payment Amount</td>
							<td>
								<Form.Group>
									<Col>
										<InputGroup>
											<InputGroup.Text>{props.currencySymbol}</InputGroup.Text>
											<NumberFormat customInput={ Form.Control } onValueChange={ (e, s) => handleTheoreticalReimbursementChanged(e.floatValue, s) } value={ (props.recoveryTotals.TheoreticalReimbursement ? props.recoveryTotals.TheoreticalReimbursement : "" ) } 
											thousandSeparator={ ' ' } decimalScale={2} fixedDecimalScale placeholder={ `${currency(props.recoveryTotals.TheoreticalRecoveryRemaining, { separator: ' ', symbol: `${props.currencySymbol} ` }).format()}` }></NumberFormat>
										</InputGroup>
									</Col>
								</Form.Group>
							</td>
							<td>
								<Form.Group>
									<Col>
										<InputGroup>
											<InputGroup.Text>{props.currencySymbol}</InputGroup.Text>
											<NumberFormat customInput={ Form.Control } onValueChange={ (e) => handleReimbursementChanged(e.floatValue) } value={ (props.recoveryTotals.Reimbursement ? props.recoveryTotals.Reimbursement : "" ) } 
											thousandSeparator={ ' ' } decimalScale={2} fixedDecimalScale placeholder={ `${currency(props.recoveryTotals.RecoveryRemaining , { separator: ' ', symbol: `${props.currencySymbol} ` }).format()}` }></NumberFormat>
											<InputGroup.Text>{<input checked={ props.recoveryTotals.ReimbursementFilled } type="checkbox" onChange={ (e) => handleFillReimbursementClicked(e.target.checked) } />}</InputGroup.Text>
										</InputGroup>
									</Col>
								</Form.Group>
							</td>
						</tr>
					}
				</tbody>
			</Table>
}