import APIBase from "./APIBase";

/**
 * @typedef CountryService
 * @type {CountryService}
 */
export default class CountryService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore){
        super(apiUrl + "Countries", sessionStore);
    }

    async GetCountries(search, pageIndex, pageCount){
        return this.Get(`GetCountries?search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
    }

    async ExportCountries(search){
        return this.Get(`ExportCountries?search=${encodeURIComponent(search)}`);
    }

    async CreateCountry(country){
        return this.Post(`CreateCountry`, country);
    }

    async UpdateCountry(country){
        return this.Post(`UpdateCountry`, country);
    }

    async DeleteCountry(countryId){
        return this.Post(`DeleteCountry?countryId=${countryId}`);
    }

    /**
	 * @param {number} countryId The Id of the Contact type to retrieve
	 * @returns { Promise<import("./APIBase").RequestResult<any>> }
	 */
    async GetCountry(countryId){
        return this.Get(`GetCountry?countryId=${countryId}`);
    }
}