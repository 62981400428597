import InputField from "../../../../../Core/Forms/InputField";
import PhoneNumberField from "../../../../../Core/Forms/PhoneNumberField";
import Panel from "../../../../../Core/Panel";

export default function UserProfileInformation(props) {
	return 	<Panel title="User Profile Information" name="UserProfileInformation">
				{
					props.profile &&
					<InputField className="mb-50" required name="Username" label="Username" disabled labelCol col={2}></InputField>
				}
				<InputField className="mb-50" required name="Name" label="Name" labelCol view={props.view} col={ props.view ? 2 : 8}></InputField>
				<InputField className="mb-50" required name="Surname" label="Surname" labelCol view={props.view} col={ props.view ? 2 : 8}></InputField>
				<InputField type="email" className="mb-50" required name="EmailAddress" label="Email address" labelCol view={props.view} col={ props.view ? 2 : 8}></InputField>
				<PhoneNumberField className="mb-50" required name="PhoneNumber" label="Contact number" labelCol view={props.view} col={ props.view ? 2 : 8}></PhoneNumberField>
				<PhoneNumberField name="CellphoneNumber" label="Cellphone" labelCol view={props.view} col={ props.view ? 2 : 8}></PhoneNumberField>
			</Panel>
}