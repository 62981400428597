import APIBase from "./APIBase";

/**
 * @typedef PaymentInstructionVM
 * @property { number } PaymentInstructionId
 * @property { string } Name
 * @property { string } Number
 * @property { number } PaymentInstructionStatusId
 * @property { Date } PaymentDate
 * @property { number } Limit
 * @property { string } Currency
 * @property { boolean } IsRecovered
 * @property { boolean } IsOverRecovered
 * @property { boolean } HasRecoveries
 * @property { boolean } IsFullyRecovered
 * @property { boolean } Submitted
 * @property { string } Status
 * @property { boolean } IsReimbursement
 * @property { boolean } Flagged
 * @property { string } Group
 * @property { string } Facility
 * @property { boolean } Linked
 * @property { number } FacilityId
 * @property { string } ResentToUserId
 * @property { string } AuthorisedByUserOneId
 * @property { string } ConfirmedByUserOneId
 * @property { string } FacilityConfirmedByUserOneId
 * @property { string } FacilityConfirmedByUserTwoId
 * @property { string } FacilityAuthorisedByUserOneId
 * @property { string } FacilityAuthorisedByUserTwoId
 * @property { string } CommentUserId
 * @property { string } Comment
 * @property { string } CommentUserName
 * @property { string } ResentToUser
 * @property { string } FacilityConfirmedByUserOne
 * @property { string } FacilityConfirmedByUserTwo
 * @property { string } FacilityAuthorisedByUserOne
 * @property { string } FacilityAuthorisedByUserTwo
 * @property { number } PaymentAmount
 */

/**
 * @typedef PaymentInstructionsService
 * @type {PaymentInstructionsService}
 */
export default class PaymentInstructionsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "PaymentInstructions", sessionStore);
    }

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<PaymentInstructionVM> }
	 */
	async GetAssignablePaymentInstructionsForTransaction(transactionId) {
		return this.Get(`GetAssignablePaymentInstructionsForTransaction?transactionId=${transactionId}`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<PaymentInstructionVM> }
	 */
	 async GetPaymentInstructions(search, paymentInstructionStatusId, from, to, pageIndex, pageCount, processing, recoveries) {
		return this.Get(`GetPaymentInstructions?search=${encodeURIComponent(search)}&paymentInstructionStatusId=${paymentInstructionStatusId}&
							from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&pageIndex=${pageIndex}&pageCount=${pageCount}
							&processing=${processing}&recoveries=${recoveries}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<string> }
	 */
	 async ExportPaymentInstructions(search, paymentInstructionStatusId, from, to, processing, recoveries) {
		return this.Get(`ExportPaymentInstructions?search=${encodeURIComponent(search)}&paymentInstructionStatusId=${paymentInstructionStatusId}&
							from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&processing=${processing}&recoveries=${recoveries}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async DeletePaymentInstruction(paymentInstructionId) {
		return this.Post(`DeletePaymentInstruction?paymentInstructionId=${paymentInstructionId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetPaymentInstructionFinanceDetails(paymentInstructionId, piFacilityId) {
		return this.Get(`GetPaymentInstructionFinanceDetails?paymentInstructionId=${paymentInstructionId}&piFacilityId=${piFacilityId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async UnlinkTransactionsFromPaymentInstruction(paymentInstructionId, transactionIds) {
		return this.Post(`UnlinkTransactionsFromPaymentInstruction?paymentInstructionId=${paymentInstructionId}`, transactionIds);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	async CreatePaymentInstruction(paymentInstruction) {
		return this.Post(`CreatePaymentInstruction`, paymentInstruction);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetPaymentInstruction(paymentInstructionId) {
		return this.Get(`GetPaymentInstruction?paymentInstructionId=${paymentInstructionId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async UpdatePaymentInstruction(paymentInstruction, submit, processing, hasChanges) {
		return this.Post(`UpdatePaymentInstruction?submit=${submit}&processing=${processing}&hasChanges=${hasChanges}`, paymentInstruction);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetAvailableFundersForPaymentInstruction(facilityId) {
		return this.Get(`GetAvailableFundersForPaymentInstruction?facilityId=${facilityId}`);
	}
}