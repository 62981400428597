import { Col, Row, Table } from "react-bootstrap";
import DateValue from "../../../../Core/DateValue";
import NumberValue from "../../../../Core/NumberValue";

export default function RecoverySummary(props) {
    const tableStyle = { padding: "5px" };

    return 	<Row>
				<Col>
					<div className="form-group">
						<label className="control-label">Date Received: <DateValue date={ props.dateReceived }></DateValue></label>
					</div>
				</Col>
				<Col xs={4} className="text-end">
					<Table borderless className="border">                            
						<tbody className="p-2">
							<tr>
								<td></td>
								<td style={tableStyle}><b>Theoretical Amount</b></td>
								<td style={tableStyle}><b>Outstanding Amount</b></td>
							</tr>
							<tr>
								<td className="text-start"><b>Received</b></td>
								<td className="text-end" style={tableStyle}><NumberValue currency={ props.currencySymbol } value={ props.recoveryTotals.TheoreticalRecoveryAmount }></NumberValue></td>
								<td className="text-end" style={tableStyle}><NumberValue currency={ props.currencySymbol } value={ props.recoveryTotals.RecoveryAmount }></NumberValue></td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td className="text-start"><b>Allocated</b></td>                                    
								<td className="text-end" style={tableStyle}><NumberValue currency={ props.currencySymbol } value={ props.recoveryTotals.TotalTheoreticalAllocated }></NumberValue></td>
								<td className="text-end" style={tableStyle}><NumberValue currency={ props.currencySymbol } value={ props.recoveryTotals.TotalAllocated }></NumberValue></td>
							</tr>
							<tr>
								<td className="text-start"><b>Remaining</b></td>                                                
								<td className="text-end" style={tableStyle}><NumberValue currency={ props.currencySymbol } value={ props.recoveryTotals.TheoreticalRecoveryRemaining }></NumberValue></td>
								<td className="text-end" style={tableStyle}><NumberValue currency={ props.currencySymbol } value={ props.recoveryTotals.RecoveryRemaining }></NumberValue></td>
							</tr>
						</tbody>
					</Table>
				</Col>
        	</Row>
}