import { Field, useFormikContext } from "formik";

import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../../../Stores/SessionStore";
import CheckboxField from "../../../../../Core/Forms/CheckboxField";
import InputField from "../../../../../Core/Forms/InputField";
import SelectField from "../../../../../Core/Forms/SelectField";
import Panel from "../../../../../Core/Panel";
import TextValue from "../../../../../Core/TextValue";

export default function SecurityInformation(props) {
	const store = useSessionStore();
	const formikContext = useFormikContext();
	const [userTypes, setUserTypes] = useState(null);

	const roleId = formikContext.values["RoleId"];

	async function getUserTypes() {
		let result = await store.UsersService.GetUserTypes(0, 0, "");

		if(result && result.Success) {
			setUserTypes(result.Data);
		}
	}

	useEffect(() => {
		getUserTypes();
	},[]);

	return 	<Panel title="Security Information">
				<InputField autocomplete="off" className="mb-50" required name="Username" label="User name" labelCol view={props.view} col={ props.view ? 2 : 8} ></InputField>
				
				{
					 !props.view &&
					<Col>
					<InputField autocomplete="off" className="mb-50" type="password" required={ !formikContext.values["Id"] } name="Password" label="Password" labelCol view={props.view}></InputField>
						<Row className="mb-50">
							<Col className="offset-2">
								<span className="text-white p-25 px-50 rounded d-block" style={{ backgroundColor: "#999", width: "100%" }}>Leave this blank if you do not wish to change this account's password</span>
							</Col>
						</Row>
						<Row className="mb-1">
							<Col className="offset-2">
								<span className="text-white p-25 px-50 rounded d-block" style={{ backgroundColor: "#999", width: "100%" }}>Password must contain at least 6 characters</span>
							</Col>
						</Row>
					</Col>
				}
				
				<Row className="mb-50">
					<Col>
						<Form.Label column>Role</Form.Label>
					</Col>
					{
						props.view &&
						<Col xs={10}>
						
							<div className="d-flex align-items-center h-100">
								<TextValue value={ (formikContext.values["RoleId"] === '1' ? "Admin" : "User") }></TextValue>
							</div>
						</Col>
					}
					{
						!props.view &&
						<Col xs={10}>
							<div role="group">
								<Row className="mb-50">
									<Col xs={2}>
										<label>
											<Field type="radio" name="RoleId" value="1" required />
											<span className="ms-50">Admin</span>
										</label>
									</Col>
								</Row>
								<Row>
									<Col xs={2}>
										<label>
											<Field type="radio" name="RoleId" value="2" required />
											<span className="ms-50">User</span>
										</label>
									</Col>
								</Row>						
							</div>
						</Col>
					}
					
				</Row>
				{
					userTypes && roleId === "2" &&
					<SelectField className="mb-50" required view={props.view} label="User Type" labelCol name="UserTypeId" col={ 10 } >
						<option value=""></option>
						{
							userTypes.map((c) => 
								<option value={c.Id}>{c.Name}</option>
							)
						}
					</SelectField>
				}
				<CheckboxField view={props.view} name="ShowAllMenu" label="Show all Menu" labelCol col={ 10 }></CheckboxField>
			</Panel>
}