import { Col, Form, Row } from "react-bootstrap";
import TextValue from "../TextValue";

export default function TextValueField(props) {
	return 	<Form.Group as={Row}>
				<Form.Label xs={ props.col || 3} column>{props.label}</Form.Label>
				<Col>
					<div className="d-flex align-items-center h-100">
						<TextValue value={ props.value }></TextValue>
					</div>		
				</Col>
			</Form.Group>
}