import React from "react";
import { Route } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import { getPermissions, Permissions } from "../../../Utilities/Permissions";
import PaymentInstructionDetails from "./PaymentInstructions/PaymentInstructionDetails";
import PaymentInstructionsIndex from "./PaymentInstructions/PaymentInstructionsIndex";
import PaymentProcessingIndex from "./PaymentInstructions/PaymentProcessingIndex";
import PaymentInstructionRecoveries from "./Recoveries/PaymentInstructionRecoveries";
import PaymentRecoveriesIndex from "./Recoveries/PaymentRecoveriesIndex";
import ReimbursementsIndex from "./Reimbursements/ReimbursementsIndex";

export default function PaymentManagementRoutes() {
	const store = useSessionStore();

	const piPermissions = getPermissions(Permissions.Sections.PaymentInstructions,Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement,  store.Session);
	const piProcessingPermissions = getPermissions(Permissions.Sections.PaymentProcessing,Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement,  store.Session);
	const piRecoveriesPermissions = getPermissions(Permissions.Sections.PaymentRecoveries,Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement,  store.Session);
	const reimbursementsPermissions = getPermissions(Permissions.Sections.PaymentReimbursements, Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement,  store.Session);
	
	return 	<React.Fragment>
				{
					piPermissions &&
					<React.Fragment>
						<Route path="/PaymentManagement/PaymentInstructions" element={ <PaymentInstructionsIndex></PaymentInstructionsIndex> }></Route>
						<Route path="/PaymentManagement/PaymentInstructions/View/:paymentInstructionId" element={ <PaymentInstructionDetails view></PaymentInstructionDetails> }></Route>
						<Route path="/PaymentManagement/Reimbursements/View/:paymentInstructionId/" element={ <PaymentInstructionDetails reimbursements view></PaymentInstructionDetails> }></Route>
						
						
					</React.Fragment>
				}
				{
					piProcessingPermissions &&
					<React.Fragment>
						<Route path="/PaymentManagement/PaymentProcessing/" element={ <PaymentProcessingIndex></PaymentProcessingIndex> }></Route>
						<Route path="/PaymentManagement/PaymentProcessing/View/:paymentInstructionId" element={ <PaymentInstructionDetails processing view></PaymentInstructionDetails> }></Route>
					</React.Fragment>
				}
				{
					piRecoveriesPermissions &&
					<React.Fragment>
						<Route path="/PaymentManagement/PaymentRecoveries/" element={ <PaymentRecoveriesIndex></PaymentRecoveriesIndex> }></Route>
						<Route path="/PaymentManagement/PaymentRecoveries/View/:paymentInstructionId/" element={ <PaymentInstructionDetails recoveries view></PaymentInstructionDetails> }></Route>
					</React.Fragment>
				}
				{
					reimbursementsPermissions &&
					<React.Fragment>
						<Route path="/PaymentManagement/Reimbursements/" element={ <ReimbursementsIndex></ReimbursementsIndex> }></Route>
						
						
					</React.Fragment>
				}
				{
					piPermissions.Add &&
					<Route path="/PaymentManagement/PaymentInstructions/Add" element={ <PaymentInstructionDetails add></PaymentInstructionDetails > }></Route>
				}
				{
					piPermissions.Edit &&
					<Route path="/PaymentManagement/PaymentInstructions/Edit/:paymentInstructionId/" element={ <PaymentInstructionDetails processing={false} edit></PaymentInstructionDetails > }></Route>
				}
				{
					piProcessingPermissions.Edit &&
					<Route path="/PaymentManagement/PaymentProcessing/Edit/:paymentInstructionId/" element={ <PaymentInstructionDetails processing edit></PaymentInstructionDetails > }></Route>
				}
				{
					piRecoveriesPermissions.Edit &&
					<Route path="/PaymentManagement/PaymentRecoveries/Edit/:paymentInstructionId/" element={ <PaymentInstructionRecoveries></PaymentInstructionRecoveries > }></Route>
				}
			</React.Fragment>
}