import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import SettingsList from "./SettingsList";
import PageHeader from "../../../Core/PageHeader";
import Panel from "../../../Core/Panel";
import { useSessionStore } from "../../../../Stores/SessionStore";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

export default function SystemSettings(props) {

	UseDocumentTitle('System Settings');

	const store = useSessionStore();
	const navigate = useNavigate();

	const [selectedSystemId] = useState(1);
	
	async function getSettings(filters) {
		return store.SettingsService.GetSystemSettings(filters.PageIndex, filters.PageCount);
	}

	function handleCustomSettingClicked(setting) {
		if(setting.Name === "Cost of Finance Terms Table") { //financial terms setting
			navigate("FinancialTerms");
		} else if(setting.Name === "Currencies") { //currencies setting
			navigate("Currencies");
		} 
	}

	return 	<React.Fragment>
				<PageHeader icon={ faGear } title={ "Settings" }  helpItemDescriptor={ "Admin/SystemSettings" }
							crumbs={[{ Title: "Admin" }, { Title: "System Settings", Active: true }]}>
				</PageHeader>
				<Panel>
					<h6>Filter System Settings by System</h6>
					<Row className="mt-1">
						<Col xs={4}>
							<Form.Group as={ Row }>
								<Form.Label xs={4} column>System</Form.Label>
								<Col>
									<Form.Select value={ selectedSystemId }>
										<option value="1">SC-Trader</option>
									</Form.Select>
								</Col>
							</Form.Group>
						</Col>
					</Row>
				</Panel>
				<SettingsList name="System" getSettings={ getSettings } systemId={ selectedSystemId } onEditCustomSetting={ handleCustomSettingClicked }></SettingsList>
			</React.Fragment>
}