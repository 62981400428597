import { faCheck, faPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField, useFormikContext } from "formik";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {  Col, FormLabel, Row } from "react-bootstrap";
import { roundUp } from "../../../../../Utilities/Currencies";
import InputField from "../../../../Core/Forms/InputField";
import NumberField from "../../../../Core/Forms/NumberField";
import SelectField from "../../../../Core/Forms/SelectField";


export default function Funder(props) {
	const formikContext = useFormikContext();
	const preventUpdate = useRef(false);

	function handleContactChanged(contactId) {
		if(contactId) {
			let selectedFunder = props.funders.find(f => f.ContactId === parseInt(contactId));

			if(selectedFunder) {				
				//set the selected bank account to the default bank account
				let defaultBankAccount = selectedFunder.BankAccounts.find(b => b.IsDefault === true);
				
				let newValues = { ...formikContext.values };
				newValues.Funders[props.index].BankAccounts = selectedFunder.BankAccounts;
				newValues.Funders[props.index].ContactId = contactId;

				if(defaultBankAccount) {
					newValues.Funders[props.index].BankAccountId = defaultBankAccount.BankAccountId;
					newValues.Funders[props.index].AccountNumber = defaultBankAccount.BankAccountNumber;
					newValues.Funders[props.index].IsDefaultBankAccount = true;
				}
				
				formikContext.setValues(newValues);

				return true;
			}
		}
	}
	
	function handleBankAccountChanged(bankAccountId) {
		let bankAccount = formikContext.values.Funders[props.index].BankAccounts.find(b => b.BankAccountId === parseInt(bankAccountId));

		if(bankAccount) {
			let newValues = { ...formikContext.values };
				
			newValues.Funders[props.index].BankAccountId = bankAccount.BankAccountId;
			newValues.Funders[props.index].AccountNumber = bankAccount.BankAccountNumber;
			newValues.Funders[props.index].IsDefaultBankAccount = bankAccount.IsDefault;

			formikContext.setValues(newValues);
			
			return true;
		}
	}

	function handlePercentageChanged(percentage) {
		if(preventUpdate.current === false) {
			preventUpdate.current = true;
			let transactionTotal = formikContext.values["TransactionsTotal"];
			
			let amount = 0.00;
	
			if(percentage) {
				amount = transactionTotal * (percentage / 100.00);
			}
		
			formikContext.setFieldValue(`Funders.${props.index}.PayableAmount`,roundUp(amount,2));
		} else {
			preventUpdate.current = false;
		}
	}

	function handleAmountChanged(amount) {
		if(preventUpdate.current === false) {
			preventUpdate.current = true;
			let transactionTotal = formikContext.values["TransactionsTotal"];
			let percentage = 0;

			if(amount) {
				percentage = (amount / transactionTotal) * 100.00;

				
			}

			formikContext.setFieldValue(`Funders.${props.index}.PayablePercent`, roundUp(percentage,2));
		} else {
			preventUpdate.current = false;
		}		
	}

	useEffect(() => {
		if(props.funders) {
			let newValues = { ...formikContext.values };
			let selectedFunder = props.funders.find(f => f.ContactId === newValues.Funders[props.index].ContactId);

			if(selectedFunder) {
				newValues.Funders[props.index].BankAccounts = selectedFunder.BankAccounts;
				
				let selectedBankAccount = selectedFunder.BankAccounts.find(b => b.BankAccountId === newValues.Funders[props.index].BankAccountId);

				if(selectedBankAccount) {
					newValues.Funders[props.index].AccountNumber = selectedBankAccount.BankAccountNumber;
					newValues.Funders[props.index].IsDefaultBankAccount = selectedBankAccount.IsDefault;
				}
			}
				
			formikContext.setValues(newValues);
		}
		
	}, [props.funders]);

	function handleAddFunderClicked() {
		if(funder.ContactId && funder.BankAccountId && funder.BeneficiaryStatementDescription &&
			funder.BatchReferenceNumber && funder.FromAccountStatementDescription && funder.ReferenceNumber ) {
				props.addFunder({ 
					BankAccountId: "", 
					BatchReferenceNumber: "", 
					BeneficiaryStatementDescription: "",
					ContactId: "", 
					FromAccountStatementDescription: "", 
					PayableAmount: "", 
					PayablePercent: "",
					PaymentInstructionId: formikContext.values["Id"], 
					ReferenceNumber: "", 
				});
			}
	}

	const funder = useField(`Funders.${props.index}`)[0].value;
	const isSelectedBankDefault = useField(`Funders.${props.index}.IsDefaultBankAccount`)[0].value;
	let bankAccounts = useField(`Funders.${props.index}.BankAccounts`)[0].value;

	//if we aren't viewing and can make changes, remove deleted bank accounts
	if(!props.view && bankAccounts) {
		bankAccounts = bankAccounts.filter(b => !b.Deleted);
	}

	return 	<React.Fragment>
				{
					props.index > 0 &&
					<hr className="my-2"/>
				}
				<Row>
					<Col>
						<SelectField className="mb-50" view={props.view} required label="Funder" onChange={ e => handleContactChanged(e.target.value) } name={`Funders.${props.index}.ContactId`}>
							<option value="">-- SELECT --</option>
							{
								props.funders && props.funders.map(f => 
									<option key={ f.ContactName } value={f.ContactId}>{ f.ContactName }</option>)
							}
						</SelectField>
					</Col>
					<Col>
						<InputField fast className="mb-50" view={props.view} required label="Batch Reference Number" name={`Funders.${props.index}.BatchReferenceNumber`}></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField className="mb-50" view={props.view} required label="Bank" name={`Funders.${props.index}.BankAccountId`} onChange={ e => handleBankAccountChanged(e.target.value) }>
							{
								bankAccounts && bankAccounts.map(ba => 
								<option key={ ba.BankAccountNumber } value={ba.BankAccountId}>{ ba.BankInstitution }</option>)
							}
						</SelectField>
					</Col>
					<Col>
						<InputField fast className="mb-50" view={props.view} required label="Reference Number" name={`Funders.${props.index}.ReferenceNumber`}></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField suffix={ (isSelectedBankDefault ? <span className="text-success"><FontAwesomeIcon icon={ faCheck }></FontAwesomeIcon></span> : null) } className="mb-50" 
									view={props.view} disabled required label="Account Number" name={ `Funders.${props.index}.AccountNumber` } >
						</InputField>
					</Col>
					<Col>
						<NumberField className="mb-50" view={props.view} label="Percent"  name={`Funders.${props.index}.PayablePercent` } invalid={ (props.funder.PayablePercent === '') || (props.funder.PayablePercent === null) } required={ props.index === 0 } onChange={ handlePercentageChanged } suffix="%" ></NumberField>
					
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField fast className="mb-50" view={props.view} required label="From Account Stmt Desc" name={`Funders.${props.index}.FromAccountStatementDescription`}></InputField>
					</Col>
					<Col>
						<NumberField className="mb-50" prefix={ formikContext.values["Currency"] } view={props.view} invalid={ (props.funder.PayableAmount === '') || (props.funder.PayableAmount === null) } required={ props.index === 0 } label="Amount" onChange={ handleAmountChanged }  name={`Funders.${props.index}.PayableAmount`}></NumberField>
					</Col>
					{/* {
						props.funder.PayableAmount === '' || props.funder.PayableAmount === null &&
						<FormLabel style={{color: 'red'}}>Please enter funder contribution.</FormLabel>
					}
					{
						console.log("Payable amount: ", props.funder.PayableAmount)
					} */}
				</Row>
				<Row>
					<Col>
						<InputField fast view={props.view} required label="Beneficiary Stmt Desc" name={`Funders.${props.index}.BeneficiaryStatementDescription`}></InputField>
					</Col>
					<Col>
						{
							!props.view && (props.index + 1) === formikContext.values["Funders"].length &&
							<Row>
								<Col className="d-flex justify-content-end mt-50 fw-bold">
									{
										props.index > 0 &&
										<span onClick={() => props.removeFunder(props.index)} className="btn bg-danger text-white me-50" style={{ padding: "6px 10px", lineHeight: "1.5", cursor: "pointer" }}>
											<FontAwesomeIcon transform="grow-4" fixedWidth icon={ faTimes } ></FontAwesomeIcon>
										</span>
									}
									<span onClick={() => handleAddFunderClicked()} className="btn text-white" style={{ padding: "6px 10px", lineHeight: "1.5", backgroundColor: "#2f8ebe", cursor: "pointer" }}>
										<FontAwesomeIcon transform="grow-4" fixedWidth icon={ faPlus } ></FontAwesomeIcon>
									</span>
								</Col>
							</Row>
						}
					</Col>
				</Row>
			</React.Fragment>
}