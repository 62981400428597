import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import SelectField from "../../../../Core/Forms/SelectField";
import Panel from "../../../../Core/Panel";

export default function Categories(props) {
	const store = useSessionStore();

	const [categories, setCategories] = useState(false);

	async function getContactCategories() {
		let result = await store.ContactsService.GetContactCategories();

		if(result && result.Success) {
			setCategories(result.Data);
		}
	}

	function getCategoriesList(number) {
		if(categories) {

			let categoryList = null;

			if(props.view){
			
				let categoriesById = categories.filter(c => {
				
					return c.Id === props.contact.ContactCategory1Id || c.Id === props.contact.ContactCategory2Id || 
					c.Id === props.contact.ContactCategory3Id || c.Id === props.contact.ContactCategory4Id
				});

				categoryList = categoriesById.filter(c => c.Number === number);
			}
			else{

				categoryList = categories.filter(c => c.Number === number);

			}


			return categoryList.map(c => <option key={ c.Id} value={ c.Id }>{c.Name}</option>)
						
		}
		return <option value=""></option>;
	}



	useEffect(() => {
		getContactCategories();
	}, []);

	return 	<Panel title="Categories">
				<Row>
					<Col>
						<SelectField view={props.view} className="mb-50" label="Category 1" name="ContactCategory1Id">
						<option value="">--SELECT--</option>
							{
								getCategoriesList(1)
							}
						</SelectField>
					</Col>
					<Col>
						<SelectField view={props.view} className="mb-50" label="Category 2" name="ContactCategory2Id">
						<option value="">--SELECT--</option>
							{
								getCategoriesList(2)
							}
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>						
						<SelectField view={props.view} className="mb-50" label="Category 3" name="ContactCategory3Id">
						<option value="">--SELECT--</option>
							{
								getCategoriesList(3)
							}
						</SelectField>
					</Col>
					<Col>						
						<SelectField view={props.view} className="mb-50" label="Category 4" name="ContactCategory4Id">
						<option value="">--SELECT--</option>
							{
								getCategoriesList(4)
							}
						</SelectField>
					</Col>
				</Row>
			</Panel>
}