import APIBase from "./APIBase";

/**
 * @typedef Audit
 * @property {string} Id
 * @property {string} Date
 * @property {string} User
 * @property {string} Table
 * @property {string} Type
 */

/**
 * @typedef AuditLogResult
 * @property {string} StartDate
 * @property {string} EndDate
 * @property {Audit[]} Audits
 * @property {number} TotalAudits
 * @property {string} DisplayName
 */

/**
 * @typedef AuditChange
 * @property { string } Column
 * @property { string } OldValue
 * @property { string } NewValue
 */

/**
 * @typedef AuditChanges
 * @property { string }  DisplayName
 * @property { string }  TableName
 * @property { string }  Type
 * @property { string }  Date
 * @property { long }  EntityId
 * @property { string }  Label
 * @property { AuditChange[] } Changes 
 */

/**
 * @typedef AuditsService
 * @type {AuditsService}
 */
export default class AuditsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Audits", sessionStore);
    }

    /**
     * @returns { import("./APIBase").RequestResult<AuditLogResult> }
     */
     async GetAudits(table, entityId, search, startDate, endDate, changeType, pageIndex, pageCount) {
        return this.Get(`GetAudits?table=${table}&entityId=${entityId}&search=${encodeURIComponent(search)}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}&changeType=${changeType}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<AuditChanges> }
     */
	async GetAuditChanges(auditId){ 
		return this.Get(`GetAuditChanges?auditId=${auditId}`);
	}
}