import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import InputField from "../../../../Core/Forms/InputField";

export default function BankAccount(props) {	
	const formikContext = useFormikContext();

	let isChecked = formikContext.values.BankAccounts[props.index].IsDefault;

	return 	<Row className={ `pb-1${(props.index > 0 ? " mt-2" : "")}` }>
				<Col>
					<Row>
						<Col>
							<InputField view={props.view} className="mb-50" required label="Account Holder" name={ `BankAccounts[${props.index}].BankAccountHolder` }></InputField>
						</Col>
						<Col>
							<InputField view={props.view} className="mb-50" required label="Institution" name={ `BankAccounts[${props.index}].BankInstitution` }></InputField>
						</Col>
					</Row>
					<Row>
						<Col>
							<InputField view={props.view} className="mb-50" required label="Account Number" name={ `BankAccounts[${props.index}].BankAccountNumber` }></InputField>
						</Col>
						<Col>
							<InputField view={props.view} className="mb-50" required label="Account Type" name={ `BankAccounts[${props.index}].BankAccountType` }></InputField>
						</Col>
					</Row>
					<Row>
						<Col>
							<InputField view={props.view} className="mb-50" required label="Branch Name" name={ `BankAccounts[${props.index}].BranchName` }></InputField>
						</Col>
						<Col>
							<InputField view={props.view} className="mb-50" required label="Branch Code" name={ `BankAccounts[${props.index}].BranchCode` }></InputField>
						</Col>
					</Row>
					<Row>
						<Col>
							<InputField view={props.view} className="mb-50" label="IBAN Number" name={ `BankAccounts[${props.index}].IBANNumber` }></InputField>
						</Col>
						<Col>
							<InputField view={props.view} className="mb-50" label="Swift Code" name={ `BankAccounts[${props.index}].SwiftCode` }></InputField>
						</Col>
					</Row>
					<Row style={{paddingTop: 9}}>
						<Col xs={2}>
							<label className="d-flex mb-50"> Set as Default </label>
						</Col>
						<Col xs={5}>
							<input type="checkbox" onChange={ e => props.onIsDefaultChanged(e.target.checked) } checked={ isChecked } className="mb-50 me-1"></input>
						</Col>
					</Row>
					<Row>
						<Col>
							<Row>
								<Col className="d-flex justify-content-end mt-50 fw-bold">
									<Button variant="light"  onClick={props.onDeleteClicked}>
										<FontAwesomeIcon icon={ faClose }></FontAwesomeIcon>
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
}