import { Col, Row } from "react-bootstrap";
import Panel from "../../Core/Panel";
import CrestLogo from "../../../Images/crest management system-LOGO.jpg";

export default function ApprovalCompleted(props) {

	function getTypeName() {
		if(props.transaction) {
			return "Transaction";
		} else if(props.paymentInstruction) {
			return "Payment Instruction";
		}

		return "";
	}

	return 	<Row>
				<Col className="offset-lg-3" lg={6}>
					<Panel>
						<Row>
							<Col>
								<div className="p-20">
									<Row>
										<Col lg={4}>
											<img src={CrestLogo} style={{ maxHeight :"70px" }} alt="Crest Logo" />
										</Col>
										<Col>
											<h4 className="text-end my-2">Thank you</h4>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
						<hr/>
						<Row className="mt-3 mb-2">
							<Col>
								<h3 class="text-center">
									{
										!props.review &&
										<text>You have successfully { props.action } { getTypeName() } { props.label }</text>
									}
									
									{
										props.review &&
										<text>You have successfully submitted { getTypeName() } { props.label } for review</text>
									}

								</h3>
								<p class="text-center">CREST have been notified via email.</p>
								<p class="text-center text-muted"><small>You may close this window</small></p>
							</Col>
						</Row>
					</Panel>
				</Col>
			</Row>
}