import React from "react";
import { Route } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import { FacilityTypes } from "../../../Utilities/Enums";
import { getPermissions, Permissions } from "../../../Utilities/Permissions";
import { GetRandomKey } from "../../../Utilities/React";
import BulkImport from "./TransactionDocuments/BulkImport";
import TransactionDocumentsIndex from "./TransactionDocuments/TransactionDocumentsIndex";
import TransactionsIndex from "./Transactions/TransactionsIndex";
import TransactionDocumentDetails from "./TransactionDocuments/TransactionDocumentDetails";
import TransactionDetails from "./Transactions/TransactionDetails";
import TransactionRecoveriesIndex from "./TransactionRecoveries/TransactionRecoveriesIndex";

export default function  FinanceRoutes() {
	const store = useSessionStore();

	const tradeDocPermissions = getPermissions(Permissions.Sections.VendorDocuments, Permissions.Areas.CreditManagement, Permissions.SubAreas.TradeFinance,  store.Session);
	const debtDocPermissions = getPermissions(Permissions.Sections.CustomerDocuments,Permissions.Areas.CreditManagement, Permissions.SubAreas.DebtorFinance,  store.Session);

	const payableTransPermissions = getPermissions(Permissions.Sections.PayableTransactions,Permissions.Areas.CreditManagement, Permissions.SubAreas.TradeFinance,  store.Session);
	const debtTransPermissions = getPermissions(Permissions.Sections.DebtTransactions,Permissions.Areas.CreditManagement, Permissions.SubAreas.DebtorFinance,  store.Session);

	return 	<React.Fragment>
				{
					tradeDocPermissions &&
					<React.Fragment>
						<Route path="/TradeFinance/VendorDocuments" element={ <TransactionDocumentsIndex key={ GetRandomKey() } facilityType={ FacilityTypes.TradeFinance }></TransactionDocumentsIndex> }></Route>
						<Route path="/TradeFinance/VendorDocuments/View/:transactionDocumentId/" element={ <TransactionDocumentDetails view facilityTypeId={ FacilityTypes.TradeFinance }></TransactionDocumentDetails> }></Route>
					</React.Fragment>
				}
				{
					tradeDocPermissions.Add &&
					<React.Fragment>
						<Route path="/TradeFinance/VendorDocuments/Import" element={ <BulkImport key={ GetRandomKey() } facilityType={ FacilityTypes.TradeFinance }></BulkImport> }></Route>
						<Route path="/TradeFinance/VendorDocuments/Add" element={ <TransactionDocumentDetails key={ GetRandomKey() } add facilityTypeId={ FacilityTypes.TradeFinance }></TransactionDocumentDetails> }></Route>
					</React.Fragment>
				}

				{
					payableTransPermissions &&
					<React.Fragment>
						<Route path="/TradeFinance/PayableTransactions" element={ <TransactionsIndex key={ GetRandomKey() } facilityType={ FacilityTypes.TradeFinance }></TransactionsIndex> }></Route>
						<Route path="/TradeFinance/TransactionRecoveries" element={ <TransactionRecoveriesIndex key={ GetRandomKey() } facilityType={ FacilityTypes.TradeFinance }></TransactionRecoveriesIndex> }></Route>
						<Route path="/TradeFinance/TransactionRecoveries/View/:transactionId" element={ <TransactionDetails key={ GetRandomKey() } view facilityTypeId={ FacilityTypes.TradeFinance }></TransactionDetails> }></Route>
						<Route path="/TradeFinance/PayableTransactions/View/:transactionId" element={ <TransactionDetails key={ GetRandomKey() } view facilityTypeId={ FacilityTypes.TradeFinance }></TransactionDetails> }></Route>
						<Route path="/TradeFinance/PayableTransactions/View/:transactionId/:allowDocChanges" element={ <TransactionDetails key={ GetRandomKey() } view facilityTypeId={ FacilityTypes.TradeFinance }></TransactionDetails> }></Route>
					</React.Fragment>
				}

				{
					payableTransPermissions.Edit &&
					<React.Fragment>
						<Route path="/TradeFinance/PayableTransactions/Edit/:transactionId/:canoverride" element={ <TransactionDetails key={ GetRandomKey() } facilityTypeId={ FacilityTypes.TradeFinance }></TransactionDetails> }></Route>
					</React.Fragment>
				}

				{
					debtDocPermissions &&
					<React.Fragment>
						<Route path="/DebtorFinance/CustomerDocuments" element={ <TransactionDocumentsIndex key={ GetRandomKey() } facilityType={ FacilityTypes.InvoiceDiscounting }></TransactionDocumentsIndex> }></Route>
						<Route path="/DebtorFinance/CustomerDocuments/View/:transactionDocumentId" element={ <TransactionDocumentDetails key={ GetRandomKey() } view facilityTypeId={ FacilityTypes.InvoiceDiscounting }></TransactionDocumentDetails> }></Route>
					</React.Fragment>
				}
				{
					debtDocPermissions.Add &&
					<React.Fragment>
						<Route path="/DebtorFinance/CustomerDocuments/Import" element={ <BulkImport key={ GetRandomKey() } facilityType={ FacilityTypes.InvoiceDiscounting }></BulkImport> }></Route>
						<Route path="/DebtorFinance/CustomerDocuments/Add" element={ <TransactionDocumentDetails key={ GetRandomKey() } add facilityTypeId={ FacilityTypes.InvoiceDiscounting }></TransactionDocumentDetails> }></Route>
					</React.Fragment>
				}

				{
					debtTransPermissions &&
					<React.Fragment>
						<Route path="/DebtorFinance/DebtTransactions" element={ <TransactionsIndex key={ GetRandomKey() } facilityType={ FacilityTypes.InvoiceDiscounting }></TransactionsIndex> }></Route>
						<Route path="/DebtorFinance/TransactionRecoveries" element={ <TransactionRecoveriesIndex key={ GetRandomKey() } facilityType={ FacilityTypes.InvoiceDiscounting }></TransactionRecoveriesIndex> }></Route>
						<Route path="/DebtorFinance/TransactionRecoveries/View/:transactionId/" element={ <TransactionDetails key={ GetRandomKey() } view facilityTypeId={ FacilityTypes.InvoiceDiscounting }></TransactionDetails> }></Route>
						<Route path="/DebtorFinance/DebtTransactions/View/:transactionId/" element={ <TransactionDetails key={ GetRandomKey() } view facilityTypeId={ FacilityTypes.InvoiceDiscounting }></TransactionDetails> }></Route>
						<Route path="/DebtorFinance/DebtTransactions/View/:transactionId/:allowDocChanges/" element={ <TransactionDetails key={ GetRandomKey() } view facilityTypeId={ FacilityTypes.InvoiceDiscounting }></TransactionDetails> }></Route>
					</React.Fragment>
				}

				{
					debtTransPermissions.Edit &&
					<React.Fragment>
						<Route path="/DebtorFinance/DebtTransactions/Edit/:transactionId/:canoverride" element={ <TransactionDetails key={ GetRandomKey() } facilityTypeId={ FacilityTypes.InvoiceDiscounting }></TransactionDetails> }></Route>
					</React.Fragment>
				}
			</React.Fragment>
}