import { Col, Row } from "react-bootstrap";
import InputField from "../../../../Core/Forms/InputField";
import PhoneNumberField from "../../../../Core/Forms/PhoneNumberField";
import Panel from "../../../../Core/Panel";

export function ContactDetails(props) {
	return 	<Panel title={ props.title }>
				<Row>
					<Col>
						<InputField fast view={props.view} className="mb-50" required={ props.required } label="Name" name={`${props.field}ContactName`}></InputField>
					</Col>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="Designation" name={ `${props.field}Designation` }></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<PhoneNumberField fast view={props.view} className="mb-50" required={ props.required } label="Direct Line" name={`${props.field }DirectLine`}></PhoneNumberField>
					</Col>
					<Col>
						<PhoneNumberField fast view={props.view} className="mb-50" label="Mobile Number" name={ `${props.field}MobileNumber` }></PhoneNumberField>
					</Col>
				</Row>				
				<Row>
					<Col>
						<InputField fast view={props.view} className="mb-50" required={ props.required } label="Email Address" name={`${props.field}EmailAddress`}></InputField>
					</Col>
					<Col>
						<PhoneNumberField fast view={props.view} className="mb-50" label="Fax Number" name={ `${props.field}FaxNumber` }></PhoneNumberField>
					</Col>
				</Row>
			</Panel>
}