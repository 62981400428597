import APIBase from "./APIBase";

/**
 * @typedef Structure
 * @property {number} Id
 * @property {string} Name
 * @property {number} ParentId
 * @property {number} StructureType
 * @property {Array<Structure>} Children
 */

/**
 * @typedef UserAppStructure
 * @property { string } Role 
 * @property { number } SystemAppId 
 * @property { boolean } GroupAdminLevel 
 * @property { boolean } GroupLevel 
 * @property { boolean } MarketPositionLevel 
 * @property { boolean } StoreLevel 
 * @property {Array<Structure>} AvailableStructure 
 */

/**
 * @typedef StructureService
 * @type {StructureService}
 */
export default class StructureService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Structure", sessionStore);
    }

	/**
	 * @returns { import("./APIBase").RequestResult<UserAppStructure> }
	 */
	async GetAvailableStructure() {
		return this.Get(`GetAvailableStructure`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetGroups(search, pageIndex, pageCount) {
		return this.Get(`GetGroups?search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetMarketPositionsForGroup(groupId, search, pageIndex, pageCount) {
		return this.Get(`GetMarketPositionsForGroup?groupId=${groupId}&search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetStoresForMarketPosition(marketPositionId, search, pageIndex, pageCount) {
		return this.Get(`GetStoresForMarketPosition?marketPositionId=${marketPositionId}&search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<string> }
	 */
	async ExportGroups(search) {
		return this.Get(`ExportGroups?search=${encodeURIComponent(search)}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<string> }
	 */
	async ExportMarketPositionsForGroup(groupId, search) {
		return this.Get(`ExportMarketPositionsForGroup?groupId=${groupId}&search=${encodeURIComponent(search)}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<string> }
	 */
	 async ExportStoresForMarketPosition(marketPositionId, search) {
		return this.Get(`ExportStoresForMarketPosition?marketPositionId=${marketPositionId}&search=${encodeURIComponent(search)}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async CreateGroup(group) {
		return this.Post(`CreateGroup`, group);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async UpdateGroup(group) {
		return this.Post(`UpdateGroup`, group);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetGroup(groupId) {
		return this.Get(`GetGroup?groupId=${groupId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async CreateMarketPosition(marketPosition, groupId) {
		return this.Post(`CreateMarketPosition?groupId=${groupId}`, marketPosition);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async UpdateMarketPosition(marketPosition) {
		return this.Post(`UpdateMarketPosition`, marketPosition);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetMarketPosition(marketPositionId) {
		return this.Get(`GetMarketPosition?marketPositionId=${marketPositionId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async CreateStore(store, marketPositionId) {
		return this.Post(`CreateStore?marketPositionId=${marketPositionId}`, store);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async UpdateStore(store) {
		return this.Post(`UpdateStore`, store);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetStore(storeId) {
		return this.Get(`GetStore?storeId=${storeId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async DeleteGroup(groupId) {
		return this.Post(`DeleteGroup?groupId=${groupId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async DeleteMarketPosition(marketPositionId) {
		return this.Post(`DeleteMarketPosition?marketPositionId=${marketPositionId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async DeleteStore(storeId) {
		return this.Post(`DeleteStore?storeId=${storeId}`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<any> }
	 */
	async GetAssignedUsers(facilityId, groupId, marketPositionId, storeId) {
		return this.Get(`GetAssignedUsers?facilityId=${facilityId}&groupId=${groupId}&marketPositionId=${marketPositionId || ""}&storeId=${storeId || ""}`);
	}
}