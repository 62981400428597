import {View, Text, Image} from '@react-pdf/renderer';
import {styles} from '../../StyleSheet';
import Crestlogo from '../../../../Images/crest management system-LOGO.png';
import { getCurrentRate } from '../../../../Utilities/Facilities';
import { DateTime } from 'luxon';
import { DateFormats } from '../../../../Utilities/Enums';

export default function PayablesDueHeader(props){

   var CostFinancePercentage = (getCurrentRate(props.faclity.Facility.CostofFinanceFundersMargins) + getCurrentRate(props.faclity.Facility.CostofFinanceTradingMargins) + props.faclity.Facility.CostFinancePercentage)
    return(
        <View>
            <View style={{display: 'flex', flexDirection: 'row', justifyContent:'center', paddingLeft: 4, paddingRight: 4, paddingBottom: 15}}>
                <View style={{width:'20%', padding:1}}>
                    {/* {
                        !props.data.AssignmentPictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-start"}} src={ Crestlogo } />
                    }    */}
                    {
						props.data.AssignmentPictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-start", paddingLeft:1}} src={ `data:${props.data.AssignmentPictureMIMEType};base64,${props.data.AssignmentPictureBase64}` } />
					}
                </View>
                <View style={{width:'60%', padding:1}}>
                    <Text style={[styles.Title, {textAlign:'center', paddingTop:10}]}>Payables Due Report</Text>
                </View>
                <View style={{width:'20%', padding:1}}>
                    {
						!props.data.ProfilePictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-end"}}  src={ Crestlogo } />
					}
					{
						props.data.ProfilePictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-end"}}  src={ `data:${props.data.ProfilePictureMIMEType};base64,${props.data.ProfilePictureBase64}` } />
					}
                </View>
            </View>

            <View style={{padding:10}}>
                <View style={[styles.tableRowWrapping, {fontSize:7}]}>
                    <Text style={{width: '33.3%', textAlign:'left', paddingBottom:7}}>Payment Instruction No.: <Text style={styles.boldFont}>{props.data.FinanceDetails.PaymentInstructionBalance.Number}</Text></Text>
                    <Text style={{width: '33.3%', textAlign:'left', paddingBottom:7}}>Name: <Text style={styles.boldFont}>{props.data.FinanceDetails.PaymentInstructionBalance.Name}</Text></Text>
                    <Text style={{width: '33.3%', textAlign:'left', paddingBottom:7}}>Payment Date: <Text style={styles.boldFont}>{DateTime.fromISO(props.data.FinanceDetails.PaymentInstructionBalance.PaymentDate).toFormat(DateFormats.LongDate)}</Text></Text>
                </View>
                <View style={[styles.tableRowWrapping, {fontSize:7, marginTop: -3}]}>
                    <Text style={{width: '33.3%', textAlign:'left'}}>Maturity Date: <Text style={styles.boldFont}>{DateTime.fromISO(props.data.FinanceDetails.PaymentInstructionBalance.MaturityDate).toFormat(DateFormats.LongDate)}</Text></Text>
                    <Text style={{width: '33.3%', textAlign:'left'}}>Funding Days: <Text style={styles.boldFont}>{props.data.FundingDays}</Text></Text>
                    <Text style={{width: '33.3%', textAlign:'left'}}>Cost of Finance: <Text style={styles.boldFont}>{(CostFinancePercentage).toFixed(2)} %</Text></Text>
                </View>
            </View>
        </View>
    )
}