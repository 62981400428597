import { faAddressBook } from "@fortawesome/pro-regular-svg-icons";
import { Formik, Form as FormikForm } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../Core/BusyIndicator";
import InputField from "../../../../Core/Forms/InputField";
import PageHeader from "../../../../Core/PageHeader";
import Panel from "../../../../Core/Panel";
import * as yup from 'yup';
import swal from "sweetalert"
import SelectField from "../../../../Core/Forms/SelectField";
import UseDocumentTitle from "../../../../../Utilities/UserDocumentTitle";

export default function ContactCategoryDetails(props) {
	const store = useSessionStore();
	const params = useParams();
	const navigate = useNavigate();

	const [saving, setSaving] = useState(false);
	const [contactCategory, setContactCategory] = useState(null);
	const [categoryName, setCategoryName] = useState('');

	UseDocumentTitle('Editing \'' + categoryName + '\'');

	async function getContactCategory() {
		if(params.id) {
			let result = await store.ContactCategoriesService.GetContactCategory(params.id);

		
			setCategoryName(result.Data.Name);

			if(result && result.Success) {
				setContactCategory(result.Data);
			}
		}
	}

	async function handleSaveClicked(values) {
		setSaving(true);
		let result = null;

		if(props.add) {
			result = await store.ContactCategoriesService.CreateContactCategory(values);
		} else {
			result = await store.ContactCategoriesService.UpdateContactCategory(values);
		}

		if(result && result.Success) {
			swal({ icon: "success", title: "Contact Category Saved", "text": "Contact Category Saved Successfully!", closeOnClickOutside: false });
			navigate(-1);
		}
	}

	useEffect(() => {
		if(props.add) {
			setContactCategory({ Name: "", Number: "1" });
		} else {
			getContactCategory();
		}
	}, []);

	const validationSchema = yup.object({
		Name: yup.mixed().required()
	}).required();

	return 	<React.Fragment>
				<PageHeader icon={ faAddressBook } title="Contact Categories" helpItemDescriptor="Admin/ContactCategory/Edit"
							crumbs={[{ Title: "Admin" }, { Title: "Contact Categories" }, 
							{ Active: true, Title: (props.add ? "Adding Contact Category" : `Editing Contact Category`) }]}>
				</PageHeader>
				{
					contactCategory &&
						<Formik initialValues={contactCategory} enableReinitialize validateOnMount validationSchema={ validationSchema } onSubmit={ handleSaveClicked }>
							{() => (
								<FormikForm>
									<Panel title="Contact Category Details">
										<SelectField className="mb-50" view={props.view} label="Category" name="Number">
											<option value="1">Category 1</option>
											<option value="2">Category 2</option>
											<option value="3">Category 3</option>
											<option value="4">Category 4</option>
										</SelectField>
										<InputField view={props.view} label="Name" required name="Name"></InputField>
									</Panel>
									<Row>
										<Col className="d-flex justify-content-end">
											{
												!props.view &&
												<Button disabled={ saving } type="submit" className="me-1">{ `${(saving ? "Saving..." : "Save")}`}</Button>
											}
											<Button disabled={ saving } onClick={ e =>  navigate(`/Admin/ContactCategories`) } >Cancel</Button>
										</Col>
									</Row>
								</FormikForm>)
							}
						</Formik>
				}
				<BusyIndicator show={ contactCategory === null }></BusyIndicator>
			</React.Fragment>
}