import {View, Text} from '@react-pdf/renderer';
import { styles } from '../../StyleSheet';
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import { DateTime } from 'luxon';
import { DateFormats } from '../../../../Utilities/Enums';

export default function PaymentInstructionFunderDetails(props){
   
    
    return(
        
        <View style={{paddingBottom: 10}}>
{       
        props.data.Funders.map(c =>
            <View style={styles.table}>
                <View style={styles.tableRowWrapping}>
                    <Text style={styles.funderDetails}>From Account Name: <Text style={styles.boldFont}>{c.ContactName}</Text></Text>
                    <Text style={styles.funderDetails}>Date: <Text style={styles.boldFont}>{DateTime.fromISO(props.data.FinanceDetails.PaymentInstructionBalance.PaymentDate).toFormat(DateFormats.LongDate)}</Text></Text>
                </View>
                <View style={styles.tableRowWrapping}>
                    <Text style={styles.funderDetails}>Bank: <Text style={styles.boldFont}>{c.BankAccount.BankInstitution}</Text></Text>
                    <Text style={styles.funderDetails}>Batch Reference Number: <Text style={styles.boldFont}>{c.BatchReferenceNumber}</Text></Text>
                </View>
                <View style={styles.tableRowWrapping}>
                    <Text style={styles.funderDetails}>Account Number: <Text style={styles.boldFont}>{c.BankAccount.BankAccountNumber}</Text></Text>
                    <Text style={styles.funderDetails}>Reference Number: <Text style={styles.boldFont}>{c.ReferenceNumber}</Text></Text>
                </View>
                <View style={styles.tableRowWrapping}>
                    <Text style={styles.funderDetails}>From Account Statement Description: <Text style={styles.boldFont}>{c.FromAccountStatementDescription}</Text></Text>
                    <Text style={styles.funderDetails}>Percentage: <Text style={styles.boldFont}>{(c.PayablePercent).toFixed(2)} %</Text></Text>
                </View>
                <View style={styles.tableRowWrapping}>
                    <Text style={styles.funderDetails}>Beneficiary Statement Description: <Text style={styles.boldFont}>{c.BeneficiaryStatementDescription}</Text></Text>
                    <Text style={styles.funderDetails}>Amount: <Text style={styles.boldFont}>{formatCurrencyValue(props.data.Facility.CurrencySymbol,c.PayableAmount)}</Text></Text>
                </View>
             </View>)
}
        </View>

    )
}