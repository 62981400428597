import { Button, Col, Form, Row } from "react-bootstrap";
import DateInput from "../../Core/Forms/DateInput";
import { useSessionStore }  from "../../../Stores/SessionStore";

export default function AuditLogsIndexFilters(props) {
	const store = useSessionStore();
	return 	<Form className="mb-50">
				<h6 className="mb-1">Filter Audit Logs</h6>
				<Row>
					<Col>
						<Form.Group>
							<Form.Label>Search Text</Form.Label>
							<Col>
								<Form.Control disabled={props.disabled} value={ props.search } onChange={ e => store.AuditStore.Search = e.target.value } type="search" placeholder="Search..." />
							</Col>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>Type</Form.Label>
							<Col>
								<Form.Select disabled={props.disabled} value={ props.type } onChange={ e => store.AuditStore.type = e.target.value }>
									<option value="">All</option>
									<option value="U">Change</option>
									<option value="I">Create</option>
								</Form.Select>
							</Col>
						</Form.Group>
					</Col>
				</Row>
				<Row className="mt-2">
					<Col>
						<Form.Group as={Row}>
							<Form.Label>Date Range</Form.Label>
							<Col>
								<DateInput disabled={props.disabled} date={ props.fromDate } onChange={ e => store.AuditStore.startDate = e }></DateInput>
							</Col>
							<Col>
								<DateInput disabled={props.disabled} date={ props.toDate } onChange={ e => store.AuditStore.endDate = e }></DateInput>
							</Col>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex justify-content-end mt-2">
						<Button disabled={props.disabled} onClick={ props.onFilter }>Filter</Button>
					</Col>
				</Row>
			</Form>
}