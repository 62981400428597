import { DateTime } from "luxon";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DateFormats } from "../../../Utilities/Enums";
import ClickableText from "../../Core/ClickableText";
import Panel from "../../Core/Panel";

export default function AuditLogEntitySummary(props) {
	const navigate = useNavigate();
	

	function handleViewAuditClicked() {
		navigate(`/Audits/${props.table}/${props.entityid}`)
	}

	return 	<Panel style={{ backgroundColor: "#FCFCD1" }}>
				<Row>
					<Col>
						Created By <b>{ props.auditsummary.CreatedBy }</b> on <b>{ DateTime.fromISO(props.auditsummary.CreatedOn).toFormat(DateFormats.FullLongDate) }</b> at <b>{ DateTime.fromISO(props.auditsummary.CreatedOn).toFormat("hh:mm a") }</b>
					</Col>
				</Row>
				<Row>
					<Col>
						Last Edited By <b>{ props.auditsummary.EditedBy }</b> on <b>{ DateTime.fromISO(props.auditsummary.EditedOn).toFormat(DateFormats.FullLongDate) }</b> at <b>{ DateTime.fromISO(props.auditsummary.EditedOn).toFormat("hh:mm a") }</b>
					</Col>
					{!props.hideLog &&
					<Col xs={3} className="d-flex justify-content-end">
						<ClickableText onClick={ handleViewAuditClicked }>View Audit Log</ClickableText>
					</Col>}
				</Row>
			</Panel>
}