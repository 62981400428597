import React, { useEffect, useState } from "react";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { useNavigate, useParams } from "react-router-dom";
import UseDocumentTitle from "../../../../../Utilities/UserDocumentTitle";
import { Formik, Form as FormikForm } from "formik";
import * as yup from 'yup';
import { faMap } from "@fortawesome/pro-regular-svg-icons";
import BusyIndicator from "../../../../Core/BusyIndicator";
import PageHeader from "../../../../Core/PageHeader";
import Panel from "../../../../Core/Panel";
import { Button, Col, Row } from "react-bootstrap";
import InputField from "../../../../Core/Forms/InputField";
import swal from "sweetalert"

export default function CountryDetails(props){

	if(props.add){
		UseDocumentTitle('Adding New Country');
	}else{
		UseDocumentTitle('Editing Country');
	}
	

	const store = useSessionStore();
	const params = useParams();
	const navigate = useNavigate();

	const [saving, setSaving] = useState(false);
	const [country, setCountry] = useState(null);
	const [countryName, setCountryName] = useState('');

	async function getCountry(){

		if(params.id){
			let result = await store.CountryService.GetCountry(params.id);
			
			setCountryName(result.Data.Name);

			if(result && result.Success){
				setCountry(result.Data);
			}
		}
	}

	async function handleSaveClicked(values){
		setSaving(true);
		let result = null;
		
		if(props.add){
			result = await store.CountryService.CreateCountry(values);
		}else{
			result = await store.CountryService.UpdateCountry(values);
		}

		if(result && result.Success){
			swal({icon: "success", title: "Country Saved.", "text": "Country Saved Successfully!", closeOnClickOutside: false});
			navigate("/Admin/Countries");
		}
	}

	useEffect(()=>{
		if(props.add){
			setCountry( {Name: ""} );
		}else{
			getCountry();
		}
	}, []);

	const validationSchema = yup.object({
		Name: yup.mixed().required()
	}).required();
	
	return <React.Fragment>
				<PageHeader icon={ faMap } title="Country" helpItemDescriptor="Admin/Country/Edit"
							crumbs={[{ Title: "Admin" }, { Title: "Countries" }, 
							{ Active: true, Title: (props.add ? "Adding Country Type" : `Editing Country Type`) }]}>
				</PageHeader>
				{
					country &&
					<Formik initialValues={country} enableReinitialize validateOnMount validationSchema={ validationSchema } onSubmit={handleSaveClicked}>
						{()=>(
							<FormikForm>
								<Panel title="Country Details">
									<InputField view={props.view} label="Country" required name="Name"></InputField>
								</Panel>
								<Row>
									<Col className="d-flex justify-content-end">
										{
											!props.view &&
											<Button disabled={ saving } type="submit" className="me-1">{ `${(saving ? "Saving..." : "Save")}`}</Button>
										}
										<Button disabled={ saving } onClick={ e => navigate(`/Admin/Countries`) } >Cancel</Button>
									</Col>
								</Row>
							</FormikForm>
						)}
					</Formik>
				}
				<BusyIndicator show={country === null}></BusyIndicator>
	</React.Fragment>
}