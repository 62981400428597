import APIBase from "./APIBase";

/**
 * @typedef ApprovalsService
 * @type {ApprovalsService}
 */
export default class ApprovalsService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "Approvals", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	async ApproveTransaction(transactionId, messageId, comment){ 
		return this.Post(`ApproveTransaction?transactionId=${transactionId}&messageId=${messageId}&comment=${encodeURIComponent(comment)}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async DeclineTransaction(transactionId, messageId, review, comment){ 
		return this.Post(`DeclineTransaction?transactionId=${transactionId}&review=${review}&messageId=${messageId}&comment=${encodeURIComponent(comment)}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async ApprovePaymentInstruction(paymentInstructionId, messageId, comment){ 
		return this.Post(`ApprovePaymentInstruction?paymentInstructionId=${paymentInstructionId}&messageId=${messageId}&comment=${encodeURIComponent(comment)}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async DeclinePaymentInstruction(paymentInstructionId, messageId, review, comment){ 
		return this.Post(`DeclinePaymentInstruction?paymentInstructionId=${paymentInstructionId}&messageId=${messageId}&review=${review}&comment=${encodeURIComponent(comment)}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async ResendPaymentInstructionApproval(paymentInstructionId, userId, level){ 
		return this.Post(`ResendPaymentInstructionApproval?paymentInstructionId=${paymentInstructionId}&userId=${userId}&level=${level}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async ResendTransactionApproval(transactionId, userId, level) { 
		return this.Post(`ResendTransactionApproval?transactionId=${transactionId}&userId=${userId}&level=${level}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetTransactionForApproval(approvalToken){ 
		return this.Get(`GetTransactionForApproval?approvalToken=${approvalToken}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetPaymentInstructionForApproval(approvalToken){ 
		return this.Get(`GetPaymentInstructionForApproval?approvalToken=${approvalToken}`);
	}
}