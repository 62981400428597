/**
 * @param { Array<import("../Services/FinancialTermsService").FinancialTerm> } financialTerms
 * @param { number } financialTermId
 */
export function getRateForFinancialTerm(financialTerms, financialTermId) {
	var term = financialTerms.find(ft => ft.Id === financialTermId);

	if(term && term.CurrentRate) {
		return term.CurrentRate.Rate;
	}

	return null;
}