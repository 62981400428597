import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";
import Panel from "../../../Core/Panel";
import AlreadyApproved from "../AlreadyApproved";
import swal from "sweetalert";
import ApprovalCompleted from "../ApprovalCompleted";
import FinanceDetails from "./FinanceDetails";
import Header from "../Header";
import Contact from "./Contact";
import Totals from "./Totals";
import { FacilityTypes } from "../../../../Utilities/Enums";
import InvoiceDiscountingBankDetails from "./InvoiceDiscountingBankDetails";
import Reimbursement from "./Reimbursement";
import { Formik, Form } from "formik";

export default function ApprovePaymentInstruction(props) {
	const store = useSessionStore();
	const location = useLocation();

	const [paymentInstruction, setPaymentInstruction] = useState(null);
	const [comment, setComment] = useState("");
	
	const [submitting, setSubmitting] = useState(false);
	const [submission, setSubmission] = useState({ Approving: false, Declining: false, Reviewing: false });
	const [submitted, setSubmitted] = useState(false);

	const [error, setError] = useState(false);

	//const [response, setResponse] = useState();

	store.ShowSidebar(false);

	async function getPaymentInstruction(id) {
		let result = await store.ApprovalsService.GetPaymentInstructionForApproval(id);

		if(result && result.Success) {
			setPaymentInstruction(result.Data);
		} else {
			if(result && !result.Success) {
				setError(true);
			}
		}
	}

	useEffect(() => {
		let params = location.search.split("&");

		let idParam = params[0].split("=");
		let id = idParam[1];

		if(id) {
			getPaymentInstruction(id);
		}
	}, []);

	async function submit(response) {
		let result = null;
		setSubmitting(true);

		let params = location.search.split("&");

		let idParam = params[1].split("=");
		let id = idParam[1];

		if(response === 1) {
			//approve
			setSubmission({ Approving: true, Declining: false, Reviewing: false });
			result = await store.ApprovalsService.ApprovePaymentInstruction(paymentInstruction.Id, id, comment);
		} else if(response === 2) {
			//decline
			if(comment!==""){
				setSubmission({ Approving: false, Declining: true, Reviewing: false });
				result = await store.ApprovalsService.DeclinePaymentInstruction(paymentInstruction.Id, id, false, comment);
			}
		} else if(response === 3) {
			if(comment!==""){
				setSubmission({ Approving: false, Declining: false, Reviewing: true });
				result = await store.ApprovalsService.DeclinePaymentInstruction(paymentInstruction.Id, id, true, comment);
			}
		}

		setSubmitting(false);

		if(result && result.Success) {
			setSubmitted(true);
		} else if(result && !result.Success) {
			swal({ icon: "error", title: "Error", text: "An error occurred attempting to update the payment instruction." });
		}
	}

	function getAction() {
		if(paymentInstruction) {
			if(submission.Approving) {
				if(paymentInstruction.ApprovalLevel < 3) {
					return "confirmed"
				} else {
					return "authorised";
				}
			} else {
				return "declined";
			}
		}

		return "";
	}

	

	return 	<Container>
				<Row>
					<Col lg={8} className="offset-2">
						{
							error &&
							<AlreadyApproved paymentInstruction></AlreadyApproved>
						}
						{
							paymentInstruction && !error && !submitted &&
							<Panel>
								<Formik>
									<Form>
										<Header title={ `Payment Instruction - ${paymentInstruction.Number}` } approvalObject={ paymentInstruction }></Header>
										<FinanceDetails paymentInstruction={ paymentInstruction }></FinanceDetails>
										{
											paymentInstruction.IsReimbursement === false &&
											<React.Fragment>
												{
													paymentInstruction.FacilityTypeId === FacilityTypes.InvoiceDiscounting &&
													<InvoiceDiscountingBankDetails paymentInstruction={paymentInstruction}></InvoiceDiscountingBankDetails>
												}
												{
													paymentInstruction.Contacts.map(contact => <Contact contact={contact} paymentInstruction={paymentInstruction}></Contact>)
												}
												<Totals paymentInstruction={paymentInstruction}></Totals>
											</React.Fragment>
										}
										{
											paymentInstruction.IsReimbursement &&
											<Reimbursement paymentInstruction={paymentInstruction}></Reimbursement>
										}
										<Row>
											<Col>
												<Table>
													<thead>
														<tr><th className="text-center">Comments</th></tr>
													</thead>
												</Table>
												<textarea rows="7" className="form-control" required value={ comment } onChange={ e => setComment(e.target.value) }>

												</textarea>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col className="d-flex justify-content-end">
												
												<Button className="me-50" onClick={ e => submit(1) } disabled={ submitting } variant="success">{ paymentInstruction.ApprovalLevel < 3 ? (submission.Approving ? "Confirming..." : "Confirm") : (submission.Approving ? "Authorising..." : "Authorise") }</Button>
												<Button type="submit" className="me-50"onClick={ e => submit(2) } disabled={ submitting } variant="danger">{ submission.Declining ? "Declining..." : "Decline" }</Button>
												<Button type="submit" onClick={ e => submit(3) } disabled={ submitting } variant="warning">{ submission.Reviewing ? "Reviewing..." : "Review" }</Button>
											</Col>
										</Row>
									</Form>
								</Formik>
							</Panel>
						}
						{
							submitted &&
							<ApprovalCompleted paymentInstruction label={ paymentInstruction.Number } review={ submission.Reviewing } action={ getAction() }></ApprovalCompleted>
						}
						<BusyIndicator show={ paymentInstruction === null && !error }></BusyIndicator>
					</Col>
				</Row>
			</Container>
}