import { Col, Row, Table } from "react-bootstrap";
import NumberValue from "../../../Core/NumberValue";

export default function Totals(props) {
	return 	<Row className="border mb-1">
				<Col>
					<Table size="sm">
						<tbody>
							<tr>
								<td colSpan={2}></td>
								<td width="80" style={{ maxWidth: "80px" }} className="text-center fw-bold">Currency</td>
								<td width="150" style={{ maxWidth: "150px" }} className="text-end fw-bold">Document Amount</td>
								<td width="150" style={{ maxWidth: "150px" }} className="text-end fw-bold">VAT</td>
								<td width="150" style={{ maxWidth: "150px" }} className="text-end fw-bold">Document Total</td>
								<td width="150" style={{ maxWidth: "150px" }} className="text-end fw-bold">Payment Amount</td>
							</tr>
							{
								props.paymentInstruction.TransactionTotals.map((invoice, index) =>
								<tr>
									<td className="fw-bolder" colSpan={2}>{ (index === 0 ? "Payment Instruction Totals" : "") }</td>
									<td className="text-center fw-bolder">{ invoice.CurrencyAbbreviation }</td>
									<td className="text-end fw-bolder"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.DocumentAmount  }></NumberValue></td>
									<td className="text-end fw-bolder"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.VATAmount  }></NumberValue></td>
									<td className="text-end fw-bolder"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.DocumentTotal  }></NumberValue></td>
									<td className="text-end fw-bolder"><NumberValue currency={ invoice.CurrencySymbol } value={invoice.PaymentAmount  }></NumberValue></td>
								</tr>)
							}
							{
								(
								(props.paymentInstruction.TransactionTotals.find((x) => x.CurrencySymbol !== props.paymentInstruction.CurrencySymbol))) &&
								<tr>
									
									<td className="fw-bolder" colSpan={2}>Payment Instruction Totals ({props.paymentInstruction.FacilityInvoiceTotal.CurrencyAbbreviation } conversion)</td>
									<td className="text-center fw-bolder">{ props.paymentInstruction.FacilityInvoiceTotal.CurrencyAbbreviation }</td>
									<td className="text-end fw-bolder"></td>
									<td className="text-end fw-bolder"></td>
									<td className="text-end fw-bolder"><NumberValue currency={ props.paymentInstruction.FacilityInvoiceTotal.CurrencySymbol } value={props.paymentInstruction.FacilityInvoiceTotal.DocumentTotal  }></NumberValue></td>
									<td className="text-end fw-bolder"><NumberValue currency={ props.paymentInstruction.FacilityInvoiceTotal.CurrencySymbol } value={props.paymentInstruction.FacilityInvoiceTotal.PaymentAmount  }></NumberValue></td>
								</tr>
							}
						</tbody>
					</Table>
			</Col>
		</Row>
}