import MenuLink from './MenuLink'
import MenuGroup from './MenuGroup'
import MenuSectionHeader from './MenuSectionHeader'

import { resolveMenuItemComponent } from '../../LayoutUtils'

export default function MenuItems(props) {
	const Components = {
		MenuLink,
		MenuGroup,
		MenuSectionHeader
	};

	const RenderNavItems = props.items.map((item, index) => {
		const TagName = Components[resolveMenuItemComponent(item)];

		if (item.children) {
			return <TagName item={item} index={index} key={item.id} {...props} />
		}

		return <TagName key={item.id || item.header} item={item} {...props} />
	});

	return RenderNavItems;
}