import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap"
import Logo from "../../Images/crest management system-LOGO.png"
import Background from "../../Images/Binary.png"
import { useSessionStore } from "../../Stores/SessionStore"
import { useState } from "react";
import UseDocumentTitle from "../../Utilities/UserDocumentTitle";

export default function Login() {

	UseDocumentTitle('CREST - Account Sign In');

	const [loggingIn, SetLoggingIn] = useState(false);
	const [loginFailed, setLoginFailed] = useState(false);

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(true);

	const store = useSessionStore();

	async function handleLoginClicked(e) {
		setLoginFailed(false);
		SetLoggingIn(true);
		var result = await store.UsersService.Login(username, password);
		SetLoggingIn(false);

		if(result && result.Success) {
			store.SetSession(result.Data, rememberMe);
		} else {
			setLoginFailed(true);
		}
	}

	return 	<div style={{ backgroundImage: `url(${ Background })` }} className="w-100 h-100 pt-2">
				<Container fluid>
					<Row className="justify-content-center">
						<Col xs={ 12 } md={6} xl={ 3 }>
							<div className="p-2 border bg-white shadow">
								<div className="d-flex justify-content-center m-3">
									<img alt="Logo" src={ Logo } style={{ height: "120px" }}></img>
								</div>
								{
									loginFailed &&
									<Alert variant="danger"><ul className="m-0"><li><small>Invalid Login Attempt</small></li></ul></Alert>
								}
								<Form onSubmit={ e => { handleLoginClicked(); e.preventDefault(); } }>
									<Form.Group>
										<Form.Label>Username</Form.Label>
										<Col>
											<Form.Control required placeholder="Username" value={ username } onChange={ e => setUsername(e.target.value) }></Form.Control>
										</Col>
									</Form.Group>
									<Form.Group className="mt-2">
										<Form.Label>Password</Form.Label>
										<Col>
											<Form.Control required type="password" placeholder="Password" value={ password }  onChange={ e => setPassword(e.target.value) }></Form.Control>
										</Col>
									</Form.Group>
									<Form.Check checked={ rememberMe } onChange={ e => setRememberMe(e.target.checked) } className="my-3" label="Remember Me"></Form.Check>
									<div className="d-flex justify-content-end">
										<Button disabled={ loggingIn } type="submit">{ loggingIn ? "Logging In..." : "Login" }</Button>
									</div>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
}