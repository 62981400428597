import {View, Text} from '@react-pdf/renderer';
import {styles} from '../../StyleSheet';

export default function PaymentInstructionGroupBankDeatils(props){
    var bank_acc =props.piBankAccount !== null ? props.piBankAccount: props.data.BankAccounts.find(x => x.IsDefault === true);

    return(
        <View style={{paddingBottom: 10}}>
            <View style={styles.table}>
                <View style={styles.tableRowWrapping}>
                    <Text style={[styles.groupBankDetails, {maxLines:1, overflow:'hidden', textOverflow:'ellipsis'}]}>Pay to Group: <Text style={styles.boldFont}>{props.data.CompanyName}</Text></Text>
                    <Text style={styles.groupBankDetails}>Bank: <Text style={styles.boldFont}>{bank_acc.BankInstitution}</Text></Text>
                    <Text style={styles.groupBankDetails}>Branch Code: <Text style={styles.boldFont}>{bank_acc.BranchCode}</Text></Text>
                    <Text style={styles.groupBankDetails}>Account Number: <Text style={styles.boldFont}>{bank_acc.BankAccountNumber}</Text></Text>
                </View>
            </View>
        </View>
    )
}