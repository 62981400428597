import APIBase from "./APIBase";

/**
 * @typedef ContactTypesService
 * @type {ContactTypesService}
 */
export default class ContactTypesService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "ContactTypes", sessionStore);
    }

	async GetContactTypes(search, pageIndex, pageCount) {
		return this.Get(`GetContactTypes?search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	async ExportContactTypes(search) {
		return this.Get(`ExportContactTypes?search=${encodeURIComponent(search)}`);
	}
	
	async CreateContactType(contactType) {
		return this.Post(`CreateContactType`, contactType);
	}

	async UpdateContactType(contactType) {
		return this.Post("UpdateContactType", contactType);
	}

	async DeleteContactType(contactTypeId) {
		return this.Post(`DeleteContactType?contactTypeId=${contactTypeId}`);
	}

	/**
	 * @param {number} contactTypeId The Id of the Contact type to retrieve
	 * @returns { Promise<import("./APIBase").RequestResult<any>> }
	 */
	 async GetContactType(contactTypeId) {
		return this.Get(`GetContactType?contactTypeId=${contactTypeId}`);
	}
}