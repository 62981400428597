import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { DateTime } from "luxon"
import React, { useEffect } from "react"
import { useState } from "react"
import { Button, Col, Form, InputGroup, Modal, Row, Table } from "react-bootstrap"
import swal from "sweetalert"
import { useSessionStore } from "../../../../../Stores/SessionStore"
import { DateFormats } from "../../../../../Utilities/Enums"
import { GetRandomKey, removeModalTabIndex } from "../../../../../Utilities/React"
import BusyIndicator from "../../../../Core/BusyIndicator"
import DateValue from "../../../../Core/DateValue"
import DateInput from "../../../../Core/Forms/DateInput"
import NumberField from "../../../../Core/Forms/NumberField"
import NumberValue from "../../../../Core/NumberValue"
import RoundButton from "../../../../Core/RoundButton"
import { Formik } from "formik"
import * as yup from "yup"

export default function TermRatesModal(props) {
	const [date, setDate] = useState("");
	const [rate, setRate] = useState("");

	const [currentRates, setCurrentRates] = useState(null);
	const [savingRates, setSavingRates] = useState(false);

	const store = useSessionStore();

	async function getRates() {
		if(props.termId) {
			let result = await store.FinancialTermsService.GetFinancialTermRates(props.termId);

			if(result && result.Success) {
				setCurrentRates(result.Data);
			}
		}
	}

	async function saveRates() {
		setSavingRates(true);
		let result = await store.FinancialTermsService.UpdateRatesForFinancialTerm(props.termId, currentRates);
		setSavingRates(false);

		if(result && result.Success) {
			swal({ title: "Success", icon: "success", text: "Financial Term Rates updated successfully", closeOnClickOutside: false });
			props.onClose(true);
		} else {
			swal({ title: "Error", icon: "error", text: "An error occurred attempting to update the Financial Term Rates", closeOnClickOutside: false});
		}
	}

	useEffect(() => {
		if(props.show === false) {
			setDate("");
			setRate("");
		} else {
			getRates();
			removeModalTabIndex();
		}
	}, [props.show])

	function handleAddRateClicked() {
		if(date && (rate || rate === 0)) {
			currentRates.push({ Date: date, Rate: rate, Active: false })
			
			setCurrentRates([...currentRates]);
		}
	}

	function handleDeleteRateClicked(index) {
		currentRates.splice(index, 1);
		setCurrentRates([...currentRates]);
	}

	function getSortedRates() {
		if(currentRates) {
			currentRates.sort((a, b) => {
				var aDate = DateTime.fromISO(a.Date);
				var bDate = DateTime.fromISO(b.Date);
	
				var result = bDate - aDate;
	
				return result;
			})
	
			return currentRates;
		}
		
		return null;
	}

	function handleActiveChecked(rate, checked) {
		rate.Active = checked;
		setCurrentRates([...currentRates]);
	}

	const rates = getSortedRates();

	const validationSchema = yup.object({
		date: yup.string().required("Date is required."),
		rateValue: yup.number().required("Rate is required")
	}).required();

	return <Modal show={props.show} size="lg">
				<Modal.Header>
					<Modal.Title>
						Rates
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<Form.Group as={Row} className="d-flex align-items-center mb-50">
								<Form.Label xs={4} column>Date</Form.Label>
								<Col>
									<DateInput date={date} onChange={ e => setDate(e) }></DateInput>
								</Col>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Formik initialValues={{ rateValue : null}} validateOnMount validationSchema={ validationSchema }>
								<Form.Group as={Row} className="d-flex align-items-center mb-50">
									<Row  className="ms-1 p-0">
										<Form.Label xs={2} column  className="ms-n1">Rate</Form.Label>
										<Col className="ms-5 ps-5">
											<InputGroup className="ps-2">
												<NumberField className="w-100 p-0 ms-0" name="rateValue" required fast value={ rate } onChange={ e => setRate(e) } suffix="%" ></NumberField>
											</InputGroup>
										</Col>
									</Row>
									<Row className="ms-0 p-0">
										<Col className="d-flex justify-content-end mb-2 pt-1 me-n2">
											<Button onClick={ e => handleAddRateClicked() }>Add</Button>
										</Col>
									</Row>
								</Form.Group>
							</Formik>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table className="align-middle" size="sm" responsive>
								<thead>
									<tr>
										<th>Date</th>
										<th>Rate</th>
										<th>Status</th>
										<th className="text-center">Delete</th>
									</tr>
								</thead>
								<tbody>
									{ 
										rates &&
										rates.map((rate, index) => (
										<tr key={ GetRandomKey() }>
											<td><DateValue date={ rate.Date } outputFormat={ DateFormats.FullLongDate }></DateValue> </td>
											<td><NumberValue value={ rate.Rate }></NumberValue>%</td>
											<td>
												<Form.Check onChange={ e => handleActiveChecked(rate, e.target.checked) } checked={ rate.Active }></Form.Check>
											</td>
											<td className="text-center">
												<RoundButton onClick={ e => handleDeleteRateClicked(index) } icon={ faTimes }></RoundButton>
											</td>
										</tr>))
									}
								</tbody>
							</Table>
							<BusyIndicator show={ currentRates === null }></BusyIndicator>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={ savingRates } onClick={ saveRates }>{ (savingRates ? "Saving..." : "Save") }</Button>
					<Button disabled={ savingRates } onClick={ () => props.onClose(false) }>Close</Button>
				</Modal.Footer>
			</Modal>
}