import { Button, Col, Form, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import DateRange from "../../../Core/DateRange";
import {useState} from 'react'

export default function TransactionsFilters(props) {
	const store = useSessionStore();
	let financeStore = store.GetFinanceStore(props.facilityType);
	const [fromDate,setFromDate] = useState(financeStore.TransactionsFilters.From);
	const [statusID,setStatusID] = useState(financeStore.TransactionsFilters.TransactionStatusId)
	const [search,setSearch] = useState(financeStore.TransactionsFilters.Search);
	const [toDate,setToDate] = useState(financeStore.TransactionsFilters.To);
	
	function handleFilterClicked() {
		financeStore.SetTransactionsFilters({...financeStore.TransactionsFilters,Search:search,To:toDate,From:fromDate,TransactionStatusId:statusID, PageIndex: 0 });
		financeStore.GetTransactions();
	}

	return 	<Form className="mb-50">
				<h6 className="mb-1">Filter { financeStore.GetTransactionType() } Transactions</h6>
				<Row className="align-items-center">
					<Col xs={ 12 } lg={ 2 }>
						<Form.Group>
							<Col>
								<Form.Control value={ search} 
								onChange={ e => {setSearch(e.target.value) } }
								type="search" placeholder="Search..." />
							</Col>
						</Form.Group>
					</Col>	
					<Col xs={ 12 } lg={ 2 }>
						<Form.Group>
							<Col>
								<Form.Select 
									value={ statusID} 
									onChange={ e => { setStatusID(parseInt(e.target.value) )} }>
									
									<option value="0">All</option>
									<option value="1">Open</option>
									<option value="2">Pending</option>
									<option value="-1">Submitted</option>
									<option value="3">Awaiting Approval</option>
									<option value="4">Approved</option>
									<option value="5">Declined</option>
									<option value="6">Awaiting Verification</option>
									<option value="7">Verified</option>
									<option value="8">NTU</option>
									<option value="9">Processed</option>
								</Form.Select>
							</Col>
						</Form.Group>
					</Col>
					<DateRange 	fromDate={fromDate} toDate={toDate}
								onFromChange={ e =>  setFromDate(e)  }
								onToChange={ e => setToDate(e) }>
					</DateRange>
					<Col xs={ 12 } lg={ 1 } className="d-flex align-items-end">
						<Button className="w-100 text-nowrap px-0" onClick={ handleFilterClicked }>Filter</Button>
					</Col>
				</Row>
			</Form>
}