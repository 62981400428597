import { View, Text } from "@react-pdf/renderer"
import { styles } from "../../StyleSheet";

export default function PaymentInstructionAuthorisation(){


    const AuthorisationFields = () =>{
        return(
            <View style={{padding: 10}}>
                <Text style={styles.fieldText}>Signed: </Text>
                <Text style={{paddingLeft: 50}}>____________________________________________________________________</Text>
                <Text style={{paddingLeft: 90, paddingBottom:10 , fontSize: 5}}>(Who warrants that he/she is duly authorised hereto)</Text>

                <Text style={styles.fieldText}>Full Name/s: </Text>
                <Text style={{paddingLeft: 50}}>____________________________________________________________________</Text>

                <Text style={styles.fieldText}>Capacity: </Text>
                <Text style={{paddingLeft: 50}}>____________________________________________________________________</Text>

                <Text style={styles.fieldText}>Date: </Text>
                <Text style={{paddingLeft: 50}}>____________________________________________________________________</Text>
            </View>
        )
    }

    return(
        <View style={{paddingBottom:10}} wrap={false}>
            <View style={styles.viewAuthorisation}>
                <Text style={[styles.textUnderline, {paddingLeft:3}]}>AUTHORISATION</Text>
                <View style={[styles.tableRowWrapping, {alignSelf:'center'}]}>
                    <AuthorisationFields />
                    <AuthorisationFields />
                </View>
            </View>
        </View>
    )
}