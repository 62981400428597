import { View,Text } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";

export default function FacilityMovementClosingBalnce(props){
    return(
        <View style={[styles.tableRowWrapping, {paddingTop:3, paddingBottom:10}]}>
            <Text style={[styles.textFooters,{textAlign:'left', width:'24%', borderTop:0.5, borderTopColor:'grey'}]}>Closing Balances as at {DateTime.fromISO(props.footer.ToDate).toFormat(DateFormats.LongDate)}</Text>
            <Text style={[styles.textFooters,{textAlign:'left', width:'18%', borderTop:0.5, borderTopColor:'grey'}]}></Text>
            <Text style={[styles.textFooters,{textAlign:'left', width:'6%', borderTop:0.5, borderTopColor:'grey'}]}></Text>
            <Text style={[styles.textFooters,{textAlign:'left', width:'16%', borderTop:0.5, borderTopColor:'grey'}]}></Text>
            <Text style={[styles.textFooters,{textAlign:'right', width:'12%', borderTop:0.5, borderTopColor:'grey'}]}><Text style={{marginLeft:(props.detailed ? 0 : 5)}}>{formatCurrencyValue(props.currency, props.footer.ClosingDebit)}</Text></Text>
            <Text style={[styles.textFooters,{textAlign:'right', width:'12%', borderTop:0.5, borderTopColor:'grey'}]}>{formatCurrencyValue(props.currency, props.footer.ClosingCredit)}</Text>
            <Text style={[styles.textFooters,{textAlign:'right', width:'12%', borderTop:0.5, borderTopColor:'grey'}]}>{formatCurrencyValue(props.currency, props.footer.ClosingBalance)}</Text>
        </View>
    )
}