import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { getPermissions, Permissions } from "../../../Utilities/Permissions";
import ClickableText from "../../Core/ClickableText";
import RoundButton from "../../Core/RoundButton";
import NumberInput from "../../Core/Forms/NumberInput";
import NumberValue from "../../Core/NumberValue";
import BusyIndicator from "../../Core/BusyIndicator";
import { getContactType } from "../../../Utilities/Contacts";
import swal from "sweetalert";

export default function FacilityContactsModal(props) {
	const store = useSessionStore();
	const permissions = getPermissions(Permissions.Sections.AllFacilities, Permissions.Areas.CreditManagement, Permissions.SubAreas.Facilities, store.Session);

	const [contacts, setContacts] = useState(null);
	const [assignableContacts, setAssignableContacts] = useState(null);
	const [selectedContact, setSelectedContact] = useState(null);

	const [updatingContacts, setUpdatingContacts] = useState(false);
	const [faciltyName, setFacilityName] = useState("");
	useEffect(() => {
		async function getFacilityContacts() {
			setContacts(null);
			var results = await store.FacilitiesService.GetAssignedContactsForFacility(props.facility.Id);
			
			if(results && results.Success) {
				setContacts(results.Data);
			}
		}

		async function getAssignableFacilityContacts() {
			setAssignableContacts(null);
			var results = await store.ContactsService.GetAssignableContactsForFacility(props.facility.Id, props.facility.FacilityTypeId);
		
			if(results && results.Success) {
				setAssignableContacts(results.Data);
			}
		}

		if(props.show) {
			getFacilityContacts();
			getAssignableFacilityContacts();
			setFacilityName(props.facility.Name)
		}
	}, [props.show]);

	function handleAddContactClicked() {
		if(selectedContact) {
			var newContact = {
				ContactId: selectedContact.Id,
				Name: selectedContact.Name,
				Limit: 0,
				Linked: false,
				Active: false,
				Currency: props.facility.Currency,
				Balances: {
					RunningTotal: 0,
					ActualTotal: 0
				}
			}

			var index = assignableContacts.findIndex((value) => value.Id === selectedContact.Id);

			if(index !== -1) {
				assignableContacts.splice(index, 1);
			}

			contacts.push(newContact);

			setAssignableContacts([...assignableContacts]);
			setContacts([...contacts]);
			setSelectedContact(null);
		}
	}

	function handleSelectedContactChanged(value) {
		if(value !== "") {
			var contact = assignableContacts.find((contact) => contact.Id === parseInt(value));
			setSelectedContact(contact);
		} else {
			setSelectedContact(null);
		}
	}

	function handleContactDeleteClicked(contactId) {
		var index = contacts.findIndex((value) => value.ContactId === contactId);

		if(index !== -1) {
			assignableContacts.push({ Id:contactId, Name: contacts[index].Name  });
			contacts.splice(index, 1);

			setContacts([...contacts]);
			setAssignableContacts([...assignableContacts]);
		}

	}

	function handleContactLimitChanged(contact, newLimit) {
		if(newLimit) {
			contact.Limit = newLimit;
		} else {
			contact.Limit = 0;
		}

		setContacts([...contacts]);
	}

	function handleContactActiveChecked(contact, active) {
		contact.Active = active;
		setContacts([...contacts]);
	}

	async function handleSaveClicked() {
		setUpdatingContacts(true);
		var result = await store.FacilitiesService.UpdateAssignedFacilityContacts(props.facility.Id, contacts);
		setUpdatingContacts(false);

		if(result.Success) {
			store.FacilitiesStore.GetFacilities();
			swal({ icon: "success", title: "Contacts Assigned", text: "Contacts have been assigned successfully.", closeOnClickOutside: false })
		} else {
			swal({ icon: "error", title: "Error", text: "An error occurred assigning the contacts.", closeOnClickOutside: false })
		}
	}

	return 	<Modal dialogClassName="modal-1000" show={ props.show }>
				<Modal.Header>
					<h4>
						Linked Contacts / Vendors & Customers for {faciltyName}
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						contacts && assignableContacts && props.facility &&
						<React.Fragment>
							{
								permissions.Add &&
								<Form className="mb-50">
									<Form.Group as={Row} className="align-items-center">
										<Form.Label xs={4} column>Company Name</Form.Label>
										<Col>
											<Form.Select value={ selectedContact ? selectedContact.Id : "" } onChange={ e => handleSelectedContactChanged(e.target.value) }>
												<option value="">Select Contact</option>
												{
													assignableContacts.map((value) =>
													<option value={value.Id}>{value.Name}</option>
													)
												}
											</Form.Select>
										</Col>
									</Form.Group>
									<Row className="mt-50">
										<Col className="d-flex justify-content-end">
											<Button onClick={handleAddContactClicked}>Add</Button>
										</Col>
									</Row>
								</Form>
							}

							<h6>Linked Contacts / Vendors & Customers</h6>
							<Table bordered className="align-middle" size="sm" responsive>
								<thead>
									<tr>
										<th>Company Name</th>
										<th>Contact Type</th>
										<th className="text-center" style={{ maxWidth: "200px", width: "200px"}}>Limit</th>
										<th className="text-end">Running Bal.</th>
										<th className="text-end">Actual Bal.</th>
										{
											permissions.Edit &&
											<th className="text-center">Status</th>
										}
										{
											permissions.Delete &&
											<th className="text-center">Delete</th>
										}
									</tr>
								</thead>
								<tbody>
									{
										contacts.map((value) =>
										<tr key={value.ContactId}>
											<td>{value.Name}</td>
											<td>{ getContactType(props.facility.FacilityTypeId) }</td>
											<td className="text-center">
												{
													permissions.Edit &&
													<InputGroup>
														<InputGroup.Text>{value.Currency}</InputGroup.Text>
														<NumberInput onChange={ e => handleContactLimitChanged(value, e) } value={ value.Limit } ></NumberInput>
													</InputGroup>
												}
												{
													permissions.Edit === false &&
													<NumberValue currency={ value.Currency } value={ value.Limit }></NumberValue>
												}
											</td>
											<td className="text-end"><NumberValue currency={ value.Currency } value={ value.Balances.RunningTotal }></NumberValue></td>
											<td className="text-end"><NumberValue currency={ value.Currency } value={ value.Balances.ActualTotal }></NumberValue></td>
											{
												permissions.Edit &&
											<td className="text-center">
												<span><Form.Check onChange={ e => handleContactActiveChecked(value, e.target.checked) } checked={ value.Active } inline /> Active</span>
											</td>
											}
											{
												permissions.Delete && value.Linked && <td className="text-center">Linked</td>
											}
											{
												permissions.Delete && value.Linked === false &&
												<td className="text-center">
													<ClickableText onClick={ e => handleContactDeleteClicked(value.ContactId) }>
														<RoundButton icon={ faTimes }></RoundButton>
													</ClickableText>
												</td>
											}
										</tr>)
									}
								</tbody>
							</Table>
						</React.Fragment>
					}
					<BusyIndicator size="2x" show={(!contacts || !assignableContacts)}></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					{
						permissions.Any() &&
						<Button disabled={ contacts === null || assignableContacts === null || updatingContacts } onClick={ handleSaveClicked }>Save</Button>
					}
					<Button disabled={ contacts === null || assignableContacts === null || updatingContacts } onClick={ props.onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}