
import { makeAutoObservable, runInAction } from "mobx";


export default class AuditStore {
	/**@type { import("../Services/AuditService").AuditService } */
	AuditService = null;

	/** @type { Array<import("../Services/AuditService").AuditLogResult> } */
	Audits = [];
	table = 0;
	RetrievingAudits = false;
	DisplayName = "";
	TotalAudits = 0;
	Filter = {
		PageIndex: 0,
		PageCount: 10,
		Total: 0
	}
	endDate = "";
	startDate = "";
	Search = "";
	type = "";


	

	constructor(AuditService) {
		this.AuditService = AuditService;
	
		

		this.LoadStoredFilter();

		makeAutoObservable(this, { AuditService: false });
	}

	LoadStoredFilter() {
		let filterJSON = localStorage.getItem("AuditsIndexFilter");

		if(filterJSON) {
			let filters = JSON.parse(filterJSON);
			
			this.Search = filters.Search;
			
		}
	}


	SetFilter(filter) {
		this.Filter = filter;
	}

	ClearStoredFilters() {
		localStorage.removeItem(`AuditsIndexFilter`);

		this.Filter = { PageIndex: 0, PageCount: 10, Total: 0 };
		this.Search = "";
		this.endDate = "";
		this.startDate = "";
		this.type = "";
		this.DisplayName = "";
	}

	async GetAudits(table,entityId,reset) {
		if(reset) {
			this.Filter.PageIndex = 0;
		}
		this.table =entityId;
		this.RetrievingAudits = true;
		localStorage.setItem("AuditsIndexFilter", JSON.stringify({
			Search: this.Search, 
			
		}));

		var result = await this.AuditService.GetAudits(table,entityId,this.Search,this.startDate,this.endDate,this.type,this.Filter.PageIndex, this.Filter.PageCount );
		runInAction(() =>  this.RetrievingAudits = false);
	

		if(result.Success) {
			runInAction(() => { this.Audits = result.Data.Audits; this.TotalAudits = result.Data.TotalAudits;this.DisplayName =result.Data.DisplayName;this.endDate=result.Data.EndDate;this.startDate=result.Data.StartDate;this.Filter.Total=result.Data.TotalAudits });
			

		}
	}

	
}