import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import CheckboxField from "../../../../Core/Forms/CheckboxField";
import InputField from "../../../../Core/Forms/InputField";
import PhoneNumberField from "../../../../Core/Forms/PhoneNumberField";
import SelectField from "../../../../Core/Forms/SelectField";
import Panel from "../../../../Core/Panel";
import { ContactableEntityTypes } from "./ContactableEntity";

export default function CompanyDetails(props) {
	const store = useSessionStore();
	const [contactTypes, setContactTypes] = useState([]);
	const [countries, setCountries] = useState([]);

	async function getContactTypes() {
		let result = await store.ContactTypesService.GetContactTypes("", 0, 0);

		if(result && result.Success) {
			setContactTypes(result.Data);
		}
	}

	async function getCountries(){
		let result = await store.CountryService.GetCountries("", 0, 0);

		if(result && result.Success){
			setCountries(result.Data);
		}
	}

	useEffect(() => {
		if(props.type === ContactableEntityTypes.Contact) {
			getContactTypes();
		}
		getCountries();
	}, [])

	return 	<Panel title="Company Details">
				{
					props.type === ContactableEntityTypes.Contact &&
					<Row>
						<Col>							
							<SelectField className="mb-50" view={props.view} label="Contact Type" required name="ContactTypeId">
								<option value="">--SELECT--</option>
								{
									contactTypes && contactTypes.map(c => <option value={ c.Id }>{ c.Name }</option>)
								}
							</SelectField>							
						</Col>
						<Col>
							
						</Col>
					</Row>
				}
				<Row>
					<Col>
						<InputField fast view={props.view} className="mb-50" required label="Company Name" name="CompanyName"></InputField>
					</Col>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="Trading Name" name="TradingName"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="Accounting Code" name="AccountingCode"></InputField>
					</Col>
					<Col>
						<InputField fast view={props.view} className="mb-50" required label="Registration Number" name="RegistrationNumber"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="Contact Reference" name="ContactReference"></InputField>
					</Col>
					<Col>
						<InputField fast view={props.view} className="mb-50" required label="VAT Number" name="VATNumber"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<PhoneNumberField fast view={props.view} className="mb-50" required label="Telephone Number" name="TelephoneNumber"></PhoneNumberField>
					</Col>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="Website Address" name="WebsiteAddress"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField fast view={props.view} className="mb-50" required label="Address Line 1" name="AddressLine1"></InputField>
					</Col>
					<Col>
						<InputField fast view={props.view} className="mb-50" required label="Address Line 2" name="AddressLine2"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="Address Line 3" name="AddressLine3"></InputField>
					</Col>
					<Col>
					<InputField fast view={props.view} className="mb-50" label="Area" name="Area"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField fast view={props.view} className="mb-50" required label="Region" name="Region"></InputField>
					</Col>
					<Col>
						<InputField fast view={props.view} className="mb-50" required label="Area Code" name="AreaCode"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField view={props.view} required label="Country" name="CountryId" invalid={props.countryId === 0 ? true : false} >
							<option value="">--SELECT--</option>
								{
									countries && countries.map(c => <option value={ c.Id }>{ c.Name }</option>)
								}
						</SelectField>
					</Col>
					<Col>
						{
							props.type === ContactableEntityTypes.Structure && props.childName &&
							<CheckboxField view={props.view} label={  props.childName } name="AllowChildren"></CheckboxField>
						}
					</Col>
				</Row>
			</Panel>
}