import React from "react";
import { Route } from "react-router-dom";
import NotificationsIndex from "./Notifications/NotificationsIndex";
import UserProfile from "./UserProfile";

export default function AccountRoutes() {
	return 	<React.Fragment>
				<Route path="/Account/Profile" element={ <UserProfile></UserProfile> }></Route>
				<Route path="/Account/Notifications" element={ <NotificationsIndex></NotificationsIndex> }></Route>
			</React.Fragment>
}