import { faFilePdf } from "@fortawesome/pro-regular-svg-icons";
import ReactPDF from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useState } from "react";
import { useSessionStore } from "../../../Stores/SessionStore";
import RoundButton from "../../Core/RoundButton";
import { PaymentInstruction as PaymentInstructionPreviewPDF } from "../../Reports/PaymentInstruction"

export default function PaymentInstructionPreview(props) {



	const store = useSessionStore();

	const [generatingPreview, setGeneratingPreview] = useState(false);

	async function handlePreviewClicked() {
		if(generatingPreview === false) {
			setGeneratingPreview(true);
		
			const result = await store.ReportsService.GetPaymentInstructionPreview(props.Pi.PaymentInstructionId,props.recoveries !== undefined ?props.recoveries:false);

			if(result && result.Success) {
				const blob = await ReactPDF.pdf(PaymentInstructionPreviewPDF({ data: result.Data })).toBlob()

				saveAs(blob, `Payment ${props.recoveries ? "Recoveries" : "Instruction"} Preview - ${props.Pi.Name}.pdf`);

				setGeneratingPreview(false);
			}
		}
	}

	return <RoundButton onClick={handlePreviewClicked} busy={ generatingPreview } variant="primary" icon={ faFilePdf }></RoundButton>
}