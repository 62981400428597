import { faGear } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import StructureIndex from "./StructureIndex";
import { useParams } from "react-router-dom"
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

export default function MarketPositionsIndex(props) {

	UseDocumentTitle('Listing \'Market Position\' on CREST');

	const store = useSessionStore();
	const params = useParams();

	const [marketPositions, setMarketPositions] = useState(null);
	const [totalMarketPositions, setTotalMarketPositions] = useState(0);
	const [hierarchy, setHierarchy] = useState(null);

	async function exportMarketPositions(search) {
		return store.StructureService.ExportMarketPositionsForGroup(params.parentId, search);
	}

	async function getMarketPositions(search, filter) {
		setMarketPositions(null);
		let results = await store.StructureService.GetMarketPositionsForGroup(params.parentId, search, filter.PageIndex, filter.PageCount);

		if(results && results.Success) {
			setHierarchy(results.Data)
		
			setTotalMarketPositions(results.Data.TotalStructures);
			setMarketPositions(results.Data.Structures);
		}
	}

	async function deleteMarketPosition(marketPositionId) {
		let result = await store.StructureService.DeleteMarketPosition(marketPositionId);

		if(result && result.Success) {
			return true;
		} else {
			return false;
		}
	}

	return <StructureIndex export={ exportMarketPositions } getEntities={ getMarketPositions } deleteEntity={ deleteMarketPosition }
							entityName="Market Positions" childrenName="Stores" title={ `Market Positions for '${(hierarchy ? hierarchy.ParentName : "")}'` }
							icon={ faGear } entities={ marketPositions } parent={params.parentId} total={totalMarketPositions}>
			</StructureIndex>
}