import {Text, View, Image} from '@react-pdf/renderer';
import { styles } from '../../StyleSheet';
import Crestlogo from '../../../../Images/crest management system-LOGO.png';

export default function PaymentInstructionHeader(props){


    return(
        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 15}}>
            <View style={{width:'20%', padding:1}}>
                {/* {
                    !props.group.LogoBase64 &&
                    <Image style={{height:35, alignSelf: "flex-start"}} src={ Crestlogo } />
                } */}
                {
                    props.group.LogoBase64 &&
                    <Image style={{height:35, alignSelf: "flex-start", paddingLeft:1}} src={ `data:${props.group.LogoMIMEType};base64,${props.group.LogoBase64}` } />
                }
            </View>
            <View style={{width:'60%', padding:1}}>
                <Text style={[styles.Title, {textAlign: 'center'}]}>Payment {props.showRecoveries === false ? "Instruction" : "Recoveries"} - {props.Pi.PaymentInstructionBalance.Number} </Text>
            </View>
            <View style={{width:'20%', padding:1}}>
                {
                    !props.data.ProfilePictureBase64 &&
                    <Image style={{height:35, alignSelf: "flex-end"}} src={ Crestlogo } />
                }
                {
                    props.data.ProfilePictureBase64 &&
                    <Image style={{height:35, alignSelf: "flex-end"}} src={ `data:${props.data.ProfilePictureMIMEType};base64,${props.data.ProfilePictureBase64}` } />
                }
            </View>
            

        </View>
    )
}