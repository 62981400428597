import {View} from '@react-pdf/renderer';
import { styles } from '../../StyleSheet';
import TransactionsDetails from './Transactions/TransactionsDetails';
import TransactionCurrencyConverstion from '../PaymentInstruction/Transactions/TransactionCurrencyConversion';
import Transactions from './Transactions/Transactions';
import TransactionTotals from './Transactions/TransactionTotals';

export default function PaymentInstructionCustomerTransactions(props){

    const showConversion = props.data.Totals.find((x) => x.Currency.Symbol !== props.currency);
    
    return(
        <View style={{paddingBottom: 10}}>
            <View style={styles.table}>
            
                <TransactionsDetails data={props.data} facilityType ={props.facilityType} showRecoveries={props.showRecoveries}  currency={props.currency}/>
                
                <Transactions data={props.data} facilityType ={props.facilityType}  currency={props.currency} showRecoveries={props.showRecoveries}/>
                <TransactionTotals data={props.data.Totals} facilityType ={props.facilityType} currency={props.currency} showRecoveries={props.showRecoveries}/>
                {showConversion &&
                    <TransactionCurrencyConverstion data={props.data.ConvertedTotals} facilityType ={props.facilityType}  showRecoveries={props.showRecoveries} currency={props.currency}/>
                }
            </View>
        </View>
    )
}