import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DateRange from "../../../Core/DateRange";
import { useState } from "react";

export default function PaymentInstructionsFilters(props) {		
	const [fromDate,setFromDate] = useState(props.filters.From );
	const [statusID,setStatusID] = useState(props.filters.PaymentInstructionStatusId)
	const [search,setSearch] = useState(props.filters.Search);
	const [toDate,setToDate] = useState(props.filters.To);
	
	function handleFilterClicked() {
		props.updateCallback("Search",search);
		props.updateCallback("PaymentInstructionStatusId",statusID);
		props.updateCallback("From",fromDate);
		props.updateCallback("To",toDate);
		props.updateCallback("PageIndex", 0);
		props.fetchCallback();
	}

	function getTitle() {
		if(props.reimbursements) {
			return "Reimbursements"
		} else if(props.recoveries) {
			return "Payment Recoveries"
		} else if(props.processing) {
			return "Payment Processing"
		}

		return "Payment Instructions"
	}

	return 	<Form className="mb-50">
				<h6 className="mb-1">{`Filter ${getTitle()}`}</h6>
				<Row className="align-items-center">
					<Col xs={ 12 } lg={ 2 }>
						<Form.Group>
							<Col>
								<Form.Control value={ search } 
								onChange={ e => setSearch(e.target.value) }
								type="search" placeholder="Search..." />
							</Col>
						</Form.Group>
					</Col>	
					<Col xs={ 12 } lg={ 2 }>
						<Form.Group>
							<Col>
								<Form.Select 
									value={ statusID } 
									onChange={ e => setStatusID(parseInt(e.target.value))}>
									<option value="0">All</option>
									{
										!props.processing && !props.recoveries && !props.reimbursements &&
										<React.Fragment>
											<option value="1">Open</option>
											<option value="2">Pending</option>
											<option value="-1">Submitted</option>
											<option value="3">Awaiting Confirmation</option>
											<option value="4">Confirmed</option>
											<option value="5">Declined</option>
											<option value="6">Awaiting Authorisation</option>
										</React.Fragment>
									}
									
									{
										!props.recoveries && !props.reimbursements &&
										<React.Fragment>
											<option value="7">Authorised</option>
											<option value="8">NTU</option>
											<option value="9">Processed</option>
										</React.Fragment>
									}

									{
										props.recoveries &&
										<React.Fragment>
											<option value="1">Processed</option>
											<option value="2">Partially Recovered</option>
											<option value="3">Recovered</option>
											<option value="4">Fully Recovered</option>
											<option value="5">Over Recovered</option>
										</React.Fragment>
									}

{
										props.reimbursements &&
										<React.Fragment>
											<option value="1">Verified</option>
											<option value="2">Processed</option>
											<option value="8">NTU</option>
										</React.Fragment>
									}
                    
								</Form.Select>
							</Col>
						</Form.Group>
					</Col>	
					<DateRange 	fromDate={ fromDate} toDate={ toDate }
								onFromChange={ e => setFromDate(e) } onToChange={ e =>setToDate(e) }>
					</DateRange>
					<Col xs={ 12 } lg={ 1 } className="d-flex align-items-end">
						<Button className="w-100 text-nowrap px-0" onClick={ handleFilterClicked }>Filter</Button>
					</Col>
				</Row>
			</Form>
}