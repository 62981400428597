import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { useSessionStore } from "../../Stores/SessionStore";
import { Controls, hasControl } from "../../Utilities/Controls";
import { Roles } from "../../Utilities/Permissions";
import BusyIndicator from "./BusyIndicator";

export default function ResendNotificationModal(props) {
	const store = useSessionStore();
	const [users, setUsers] = useState(null);
	const [selectedUserId, setSelectedUserId] = useState("");
	const [resending, setResending] = useState(false);

	let canResend = store.Session.Role === Roles.Admin || store.Session.AuthState.UserAppStructure.GroupAdminLevel;

	if(canResend === false) {
		canResend = hasControl(Controls.Areas.CreditManagement, props.subArea, Controls.Sections.ResendEmailToAlternativeUser, store.Session);
		
	}

	async function getAssignableUsers() {
		if(canResend) {
			let result = await store.StructureService.GetAssignedUsers(props.details.FacilityId, props.details.GroupId, props.details.MarketPositionId, props.details.StoreId);

			if(result && result.Success) {
				setUsers(result.Data);
			}
		}
	}

	async function resend() {
		setResending(true);

		let result = null;
		
		if(props.paymentInstruction) {
			result = await store.ApprovalsService.ResendPaymentInstructionApproval(props.details.Id, selectedUserId, props.details.Level);
		} else if(props.transaction) {
			result = await store.ApprovalsService.ResendTransactionApproval(props.details.Id, selectedUserId, props.details.Level);
		}

		setResending(false);

		if(result && result.Success) {
			props.onClose(true);
			swal({ title: "Success", icon: "success", text: "Email Resent Successfully.", closeOnClickOutside: false });
		} else {
			swal({ title: "Error", icon: "error", text: "An error occurred attempting to resend the email.", closeOnClickOutside: false });
		}
	}

	useEffect(() => {
		if(props.show && props.details) {
			getAssignableUsers();
		} else {
			setSelectedUserId("");
			setUsers(null);
		}
	}, [props.show, props.details])

	return 	<Modal show={props.show}>
				<Modal.Header>
					<h4>
						Waiting on { props.details ? props.details.CurrentUser : "" }
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						canResend && users &&
						<React.Fragment>
							<Form.Group>
								<Form.Label>Select a user to resend this to:</Form.Label>
								<Form.Select value={ selectedUserId } onChange={ e => setSelectedUserId(e.target.value) }>
									<option value=''>Resend to original selected user</option>
									{
										users && users.map(u => <option value={ u.Id }>{ u.Username }</option>)
									}
								</Form.Select>
							</Form.Group>
						</React.Fragment>
					}
					{
						!canResend &&
						<span>You do not have permission to resend this notification</span>
					}
					<BusyIndicator show={ canResend && !users }></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ resend } disabled={ resending }>{ resending ? "Sending..." : "Resend" }</Button>
					<Button onClick={ e => props.onClose(true) } disabled={ resending }>Cancel</Button>
				</Modal.Footer>
			</Modal>
}