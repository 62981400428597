import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import { Html } from "react-pdf-html";

export default function PaymentInstructionTermsAndConditions(props){

    const HtmlToDisplay = () =>{

        const htmlText = props.data;

        return(
            <Html style={{fontSize:7}}>{htmlText}</Html>
        )

    }

    return(
        <View style={{paddingBottom: 10}} wrap={false}>
            <View style={styles.viewTermsAndConditions}>

                <Text style={styles.textUnderline}>Terms and Conditions</Text>
                
                <HtmlToDisplay />

            </View>
        </View>
    )
}