import swal from "sweetalert";

async function deleteEntity(entityName, entityId, deleteCallback) {
	let result = await deleteCallback(entityId);

	if(result && result.Success) {
		swal({ title: "Success", icon: "success", text: `${entityName} has been deleted.`, closeOnClickOutside:false});
	} else {
		swal({ title: "Error", icon: "error", text: `An error occurred attempting to delete ${entityName}`, closeOnClickOutside:false});
	}

	return result.Success;
}

export default async function confirmDelete(entityName, entityId, deleteEntityCallback) {
	let result = await swal({ 
		title: "Are you sure?", 
		text: `You are about to delete ${entityName}, are you sure?`,
		buttons: {
			cancel: {
				text: "Cancel",
				value: null,
				visible: true,
				closeModal: true,
		},
			confirm: {
				text: "OK",
				value: true,
				visible: true,
				closeModal: false
			}
		},
		closeOnClickOutside: false
	});

	if(result) {
		return deleteEntity(entityName, entityId, deleteEntityCallback);
	}

	return false;
}