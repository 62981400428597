import APIBase from "./APIBase";

/**
 * @typedef TransactionVM
 * @property {number} Id
 * @property {boolean} Flagged
 * @property {boolean} Submitted
 * @property {number} TransactionStatusId
 * @property {number} PaymentAmount
 * @property {string} Currency
 * @property {string} DueDate
 * @property {string} DocumentDate
 * @property {string} DocumentNumber
 * @property {boolean} AutoFinanceOption
 * @property {string} ContactName
 * @property {string} StructureName
 * @property {string} Comment
 * @property {string} CommentUserName 
 * @property {string}  PaymentInstruction
 * @property {number} PaymentInstructionId
 * @property {string} FacilityApprovedByUserOneId
 * @property {string} FacilityApprovedByUserTwoId
 * @property {string} FacilityVerifiedByUserOneId
 * @property {string} FacilityVerifiedByUserTwoId
 * @property {string} FacilityApprovedByUserOne
 * @property {string} FacilityApprovedByUserTwo
 * @property {string} FacilityVerifiedByUserOne
 * @property {string} FacilityVerifiedByUserTwo
 * @property {string} ResentToUserId
 * @property {string} ResentToUser
 * @property { string } PaymentInstruction
 * @property { long } PaymentInstructionId
 * @property { bool } AutoFinanceOption
 * @property { bool } IsRecovered
 * @property { bool } IsFullyRecovered
 * @property { bool } HasRecoveries
 * @property { decimal } DocumentTotal
 * @property { DateTime } LastRecoveryDate
*/

/**
 * @typedef TransactionsService
 * @type {TransactionsService}
 */
export default class TransactionsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Transactions", sessionStore);
    }

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<TransactionVM> }
	 */
	async GetTransactions(search, transactionStatusId, from, to, facilityTypeId, onlyRecoveries, pageIndex, pageCount) {
		return this.Get(`GetTransactions?search=${encodeURIComponent(search)}&transactionStatusId=${transactionStatusId}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&facilityTypeId=${facilityTypeId}&onlyRecoveries=${onlyRecoveries}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<string> }
	 */
	 async ExportTransactions(search, transactionStatusId, from, to, facilityTypeId, includeRecoveries) {
		return this.Get(`ExportTransactions?search=${encodeURIComponent(search)}&transactionStatusId=${transactionStatusId}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&facilityTypeId=${facilityTypeId}&includeRecoveries=${includeRecoveries}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async DeleteTransaction(transactionId) {
		return this.Post(`DeleteTransaction?transactionId=${transactionId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async UpdateTransaction(transaction, submit) {
		return this.Post(`UpdateTransaction?submit=${submit}`, transaction);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async GetTransaction(transactionId) {
		return this.Get(`GetTransaction?transactionId=${transactionId}`);
	}

		/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
		 async GetTransactionConverted(transactionId) {
			return this.Get(`GetTransactionConverted?transactionId=${transactionId}`);
		}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<any> }
	 */
	 async GetTransactionsForPaymentInstruction(paymentInstructionId) {
		return this.Get(`GetTransactionsForPaymentInstruction?paymentInstructionId=${paymentInstructionId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async AssignTransactionToPaymentInstruction(transactionId, paymentInstructionId) {
		return this.Post(`AssignTransactionToPaymentInstruction?transactionId=${transactionId}&paymentInstructionId=${paymentInstructionId}`);
	}
}