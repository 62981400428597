import { DateTime } from "luxon";
import { makeAutoObservable, runInAction } from "mobx";

export default class PaymentManagementStore {
	RetrievingPaymentInstructions = false;
	ExportingPaymentInstructions = false;

	RetrievingPaymentProcessing = false;
	ExportingPaymentProcessing = false;

	RetrievingPaymentRecoveries = false;
	ExportingPaymentRecoveries = false;

	RetrievingReimbursements = false;
	ExportingReimbursements = false;
		
	PaymentInstructionsFilters = {}
	PaymentProcessingFilters = {}
	PaymentRecoveriesFilters = {}
	ReimbursementsFilters = {}
	
	/** @type { import("../Services/PaymentInstructionsService").PaymentInstructionVM[] } */
	PaymentInstructions = [];

	/** @type { import("../Services/PaymentInstructionsService").PaymentInstructionVM[] } */
	PaymentProcessing = [];

	/** @type { import("../Services/PaymentInstructionsService").PaymentInstructionVM[] } */
	PaymentRecoveries = [];

	/** @type { import("../Services/ReimbursementsService").Reimbursement[] } */
	Reimbursements = [];

	/** @type { import("../Services/PaymentInstructionsService").PaymentInstructionsService } */
	PaymentInstructionsService = null;

	/** @type { import("../Services/ReimbursementsService").ReimbursementsService } */
	ReimbursementsService = null;
	
	constructor(paymentInstructionsService, reimbursementsService) {
		this.InitialiseFilters();

		this.PaymentInstructionsService = paymentInstructionsService;
		this.ReimbursementsService = reimbursementsService;

		this.LoadStoreFilters();

		makeAutoObservable(this);
	}

	InitialiseFilters() {
		this.PaymentInstructionsFilters = {
			PageIndex: 0,
			PageCount: 10,
			Total: 0,
			Search: "",
			From: DateTime.now().minus({ months: 1 }).startOf("month").toISODate(),
			To: DateTime.now().endOf("month").toISODate(),
			PaymentInstructionStatusId: 0
		}
	
		this.PaymentProcessingFilters = {
			PageIndex: 0,
			PageCount: 10,
			Total: 0,
			Search: "",
			From: DateTime.now().minus({ months: 1 }).startOf("month").toISODate(),
			To: DateTime.now().endOf("month").toISODate(),
			PaymentInstructionStatusId: 0
		}
	
		this.PaymentRecoveriesFilters = {
			PageIndex: 0,
			PageCount: 10,
			Total: 0,
			Search: "",
			From: DateTime.now().minus({ months: 1 }).startOf("month").toISODate(),
			To: DateTime.now().endOf("month").toISODate(),
			PaymentInstructionStatusId: 0
		}
	
		this.ReimbursementsFilters = {
			PageIndex: 0,
			PageCount: 10,
			Total: 0,
			Search: "",
			From: DateTime.now().minus({ months: 1 }).startOf("month").toISODate(),
			To: DateTime.now().endOf("month").toISODate(),
			PaymentInstructionStatusId: 0
		}
	}

	LoadStoreFilters() {
		let paymentInstructionsFilterJSON = localStorage.getItem(`PaymentInstructionsFilter`);
		let paymentProcessingFilterJSON = localStorage.getItem(`PaymentProcessingFilter`);
		let paymentRecoveriesFilterJSON = localStorage.getItem(`PaymentRecoveriesFilter`);
		let reimbursementsFilterJSON = localStorage.getItem(`ReimbursementsFilter`);

		this.SetStoredFilter(paymentInstructionsFilterJSON, (f) => this.SetPaymentInstructionFilters(f));
		this.SetStoredFilter(paymentProcessingFilterJSON, (f) => this.SetPaymentProcessingFilters(f));
		this.SetStoredFilter(paymentRecoveriesFilterJSON, (f) => this.SetPaymentRecoveriesFilters(f));
		this.SetStoredFilter(reimbursementsFilterJSON, (f) => this.SetReimbursementsFilters(f));
	}

	ClearStoredFilters() {
		localStorage.removeItem(`PaymentInstructionsFilter`);
		localStorage.removeItem(`PaymentProcessingFilter`);
		localStorage.removeItem(`PaymentRecoveriesFilter`);
		localStorage.removeItem(`ReimbursementsFilter`);

		this.InitialiseFilters();
	}

	SetStoredFilter(filterJSON, callback) {
		if(filterJSON) {
			let filter = JSON.parse(filterJSON);
			callback(filter)
		}
	}

	SetPaymentInstructionFilters(filters) {
		localStorage.setItem(`PaymentInstructionsFilter`, JSON.stringify(filters));
		this.PaymentInstructionsFilters = filters;
	}

	SetPaymentProcessingFilters(filters) {
		localStorage.setItem(`PaymentProcessingFilter`, JSON.stringify(filters));
		this.PaymentProcessingFilters = filters;
	}

	SetPaymentRecoveriesFilters(filters) {
		localStorage.setItem(`PaymentRecoveriesFilter`, JSON.stringify(filters));
		this.PaymentRecoveriesFilters = filters;
	}

	SetReimbursementsFilters(filters) {
		localStorage.setItem(`ReimbursementsFilter`, JSON.stringify(filters));
		this.ReimbursementsFilters = filters;
	}
	
	async GetPaymentInstructions() {
		if(this.RetrievingPaymentInstructions === false) {
			this.RetrievingPaymentInstructions = true;
			let results = await this.PaymentInstructionsService.GetPaymentInstructions(this.PaymentInstructionsFilters.Search, 
																						this.PaymentInstructionsFilters.PaymentInstructionStatusId,
																						this.PaymentInstructionsFilters.From, 
																						this.PaymentInstructionsFilters.To, 
																						this.PaymentInstructionsFilters.PageIndex, 
																						this.PaymentInstructionsFilters.PageCount, false, false);
	
			runInAction(() => {
				if(results && results.Success) {
					this.PaymentInstructionsFilters.Total = results.Total;
					this.PaymentInstructions = results.Data;
					this.RetrievingPaymentInstructions = false;
				}
			});
		}
	}

	async GetPaymentProcessingInstructions() {
		if(this.RetrievingPaymentProcessing === false) {
			this.RetrievingPaymentProcessing = true;
			let results = await this.PaymentInstructionsService.GetPaymentInstructions(this.PaymentProcessingFilters.Search, 
																						this.PaymentProcessingFilters.PaymentInstructionStatusId,
																						this.PaymentProcessingFilters.From, 
																						this.PaymentProcessingFilters.To, 
																						this.PaymentProcessingFilters.PageIndex, 
																						this.PaymentProcessingFilters.PageCount, true, false);
	
			runInAction(() => {
				if(results && results.Success) {
					this.PaymentProcessingFilters.Total = results.Total;
					this.PaymentProcessing = results.Data;
					this.RetrievingPaymentProcessing = false;
				}
			});
		}
	}

	async GetPaymentRecoveries() {
		if(this.RetrievingPaymentRecoveries === false) {
			this.RetrievingPaymentRecoveries = true;
			let results = await this.PaymentInstructionsService.GetPaymentInstructions(this.PaymentRecoveriesFilters.Search, 
																						this.PaymentRecoveriesFilters.PaymentInstructionStatusId,
																						this.PaymentRecoveriesFilters.From, 
																						this.PaymentRecoveriesFilters.To, 
																						this.PaymentRecoveriesFilters.PageIndex, 
																						this.PaymentRecoveriesFilters.PageCount, false, true);
	
			runInAction(() => {
				if(results && results.Success) {
					this.PaymentRecoveriesFilters.Total = results.Total;
					this.PaymentRecoveries = results.Data;
					this.RetrievingPaymentRecoveries = false;
				}
			});
		}
	}

	async GetReimbursements() {
		if(this.RetrievingReimbursements === false) {
			this.RetrievingReimbursements = true;
			let results = await this.ReimbursementsService.GetReimbursements(this.ReimbursementsFilters.Search, 
																						this.ReimbursementsFilters.PaymentInstructionStatusId,
																						this.ReimbursementsFilters.From, 
																						this.ReimbursementsFilters.To, 
																						this.ReimbursementsFilters.PageIndex, 
																						this.ReimbursementsFilters.PageCount);
	
			runInAction(() => {
				if(results && results.Success) {
					this.ReimbursementsFilters.Total = results.Total;
					this.Reimbursements = results.Data;
					this.RetrievingReimbursements = false;
				}
			});
		}
	}


	async ExportPaymentInstructions() {
		if(this.ExportingPaymentInstructions === false) {
			this.ExportingPaymentInstructions = true;
			let results = await this.PaymentInstructionsService.ExportPaymentInstructions(this.PaymentInstructionsFilters.Search, 
																						this.PaymentInstructionsFilters.PaymentInstructionStatusId,
																						this.PaymentInstructionsFilters.From, 
																						this.PaymentInstructionsFilters.To, false, false);
	
			runInAction(() => {				
				this.ExportingPaymentInstructions = false;
			});

			if(results && results.Success) {
				return results.Data;
			}

			return null;
		}
	}

	async ExportPaymentProcessing() {
		if(this.ExportingPaymentProcessing === false) {
			this.ExportingPaymentProcessing = true;
			let results = await this.PaymentInstructionsService.ExportPaymentInstructions(this.PaymentProcessingFilters.Search, 
																						this.PaymentProcessingFilters.PaymentInstructionStatusId,
																						this.PaymentProcessingFilters.From, 
																						this.PaymentProcessingFilters.To, true, false);
	
			runInAction(() => {				
				this.ExportingPaymentProcessing = false;
			});

			if(results && results.Success) {
				return results.Data;
			}

			return null;
		}
	}

	async ExportPaymentRecoveries() {
		if(this.ExportingPaymentRecoveries === false) {
			this.ExportingPaymentRecoveries = true;
			let results = await this.PaymentInstructionsService.ExportPaymentInstructions(this.PaymentRecoveriesFilters.Search, 
																						this.PaymentRecoveriesFilters.PaymentInstructionStatusId,
																						this.PaymentRecoveriesFilters.From, 
																						this.PaymentRecoveriesFilters.To, false, true);
	
			runInAction(() => {				
				this.ExportingPaymentRecoveries = false;
			});

			if(results && results.Success) {
				return results.Data;
			}

			return null;
		}
	}

	async ExportReimbursements() {
		if(this.ExportingReimbursements === false) {
			this.ExportingReimbursements = true;
			let results = await this.ReimbursementsService.ExportReimbursements(this.ReimbursementsFilters.Search, 
																						this.ReimbursementsFilters.PaymentInstructionStatusId,
																						this.ReimbursementsFilters.From, 
																						this.ReimbursementsFilters.To);
	
			runInAction(() => {				
				this.ExportingReimbursements = false;
			});

			if(results && results.Success) {
				return results.Data;
			}

			return null;
		}
	}
}