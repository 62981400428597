import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../Core/BusyIndicator";
import NumberField from "../../../../Core/Forms/NumberField";
import Panel from "../../../../Core/Panel";

export default function CurrencyConversionRates({ currencyId, view, processing, recoveries }) {
	const store = useSessionStore();
	const formikContext = useFormikContext();
	const [currencies, setCurrencies] = useState(null);

	async function getCurrencyConversionRates() {
		setCurrencies(null);
		
		let currencyIds = [currencyId];

		if(formikContext.values.Currencies) {
			for(let currency of formikContext.values.Currencies) {
				if(currency.CurrencyId !== currencyId){
					currencyIds.push(currency.CurrencyId);
				}
			}
		}		

		let results = await store.CurrenciesService.GetCurrentConversionRatesForCurrency(currencyId, formikContext.values["Id"] ? currencyIds : []);
	
		if(results && results.Success) {
			let piCurrencies = [];

			if(formikContext.values["Id"]) {
				piCurrencies = results.Data.map(c => { 
					let currency = formikContext.values.Currencies.find(ac => ac.CurrencyId === c.ConvertedCurrency.Id);

					if(currency) {
						return {  
							CurrencyId: currency.CurrencyId, 
							SystemRate: c.ConvertedValue, 
							SpotRate: currency.SpotRate, 
							Abbreviation: c.ConvertedCurrency.Abbreviation,
							Symbol: c.ConvertedCurrency.Symbol
						}
					} else {
						return {
							CurrencyId: currencyId, 
							SystemRate: c.ConvertedValue, 
							SpotRate: 1, 
							Abbreviation: c.ConvertedCurrency.Abbreviation,
							Symbol: c.ConvertedCurrency.Symbol
						}
					}
				});
			} else {
				piCurrencies = results.Data.map(c => { return { 
					CurrencyId: c.ConvertedCurrency.Id, 
					SystemRate: c.ConvertedValue, 
					SpotRate: (c.ConvertedCurrency.Id === currencyId) ? 1 : null, 
					Abbreviation: c.ConvertedCurrency.Abbreviation 
				}});
			}
			
			formikContext.setFieldValue("Currencies", piCurrencies);
			setCurrencies(piCurrencies);
		}
	}
		
	useEffect(() => {
		if(currencyId) {
			getCurrencyConversionRates();
		}

	}, [currencyId]);

	let systemCurrencyIndex = -1;

	if(currencies) {
		systemCurrencyIndex = currencies.findIndex(c => c.CurrencyId === currencyId);
	}
		
	return 	<Panel title="Currency Conversion Rates">
				{
					systemCurrencyIndex > -1 &&
					<Row>
						<Col xs={6}>
							<NumberField disabled view={ view} className="mb-50" decimals={ 8 } key={ currencies[systemCurrencyIndex].CurrencyId }
												label={ `${currencies[systemCurrencyIndex].Abbreviation} System Rate (Facility)` } 
												name={ `Currencies.${systemCurrencyIndex}.SystemRate` }></NumberField>
						</Col>
					</Row>
				}
				{
					formikContext.values["Id"] &&
					<Row>
						<Col xs={6}>
						{
							currencies && currencies.length > 0 &&
							currencies.map((currency, index) => {
								if(currency.CurrencyId !== currencyId) {
									return <NumberField disabled view={ view} className="mb-50" decimals={ 8 } key={ currency.CurrencyId }
												label={ `${currency.Abbreviation} System Rate` } name={ `Currencies.${index}.SystemRate` }></NumberField>
								}

								return null;
							})
						}
						</Col>
						{
							(processing || recoveries) &&
							<Col xs={6}>
							{
								currencies && currencies.length > 0 &&
								currencies.map((currency, index) => {
									if(currency.CurrencyId !== currencyId) {
										return <NumberField view={ view } className="mb-50" decimals={ 8 } key={ currency.CurrencyId }  
															invalid={ formikContext.errors.Currencies && formikContext.errors.Currencies[index] } required label={ `${currency.Abbreviation} Spot Rate` }
															name={ `Currencies.${index}.SpotRate` } />
									} 

									return null;
								})
							}
							</Col>
						}
						<BusyIndicator show={ currencies === null }></BusyIndicator>
					</Row>
				}
				
			</Panel>
}