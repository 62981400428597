import { DateTime } from "luxon";
import { DateFormats } from "../../Utilities/Enums";

export default function DateValue(props) {
	return <span className={ props.className } style={ props.style }>
				{
					props.date && 
					DateTime.fromISO(props.date).toFormat(props.outputFormat || DateFormats.FullLongDate) 
				}
				{
					!props.date && 
					<span>N/A</span>
				}
			</span>
}