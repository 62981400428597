import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import Panel from "../../../Core/Panel";
import RoundButton from "../../../Core/RoundButton";
import BusyIndicator from "../../../Core/BusyIndicator";
import Pagination from "../../../Core/Pagination";
import EditSettingModal from "./EditSettingModal";
import { SettingTypes } from "../../../../Utilities/Enums";
import { NavLink } from "react-router-dom";
import { timer } from "d3";

export default function SettingsList(props) {
	const [settings, setSettings] = useState([]);
	const [filter, setFilter] = useState({ PageIndex: 0, PageCount: 10, Total: 0 });

	const [edit, setEdit] = useState({ Setting: null, Show: false });
	
	async function getSettings(filters) {
		setSettings(null);
		let result = await props.getSettings(filters);

		if(result && result.Success) {
			setSettings(result.Data);
			setFilter({...filters, Total: result.Total});
		}
	}

	async function handleEditSettingClicked(setting) {
		if(setting.SettingType === SettingTypes.Custom) {
			props.onEditCustomSetting(setting);
		} else {
			setEdit({ Setting: setting, Show: true });
			var localObject ={
				Setting:setting,Show: true
			}
			localStorage.setItem("SettingModal",JSON.stringify(localObject))
			
		}
	}

	function handlePageChange(pageIndex, pageCount) {
		let filters = {...filter, PageIndex: pageIndex, PageCount: pageCount};

		setFilter(filters);
		getSettings(filters);
	}

	function handleEditSettingModalClosed(update) {
		if(update) {
			getSettings(filter);
		}

		setEdit({ Setting: null, Show: false });
		
		localStorage.removeItem("SettingModal")
		
	}
	function removeTags(str) {
		if ((str===null) || (str===''))
			return false;
		else
			str = str.toString();
			  
		// Regular expression to identify HTML tags in 
		// the input string. Replacing the identified 
		// HTML tag with a null string.
		return str.replace( /(<([^>]+)>)/ig, '');
	}

	useEffect(() => {
		const setting = JSON.parse(localStorage.getItem("SettingModal") )
		if(setting)
		{
			setEdit(setting)
		
		}
		if(props.systemId || props.groupId || props.marketPositionId) {
			getSettings(filter);
			
		
		}
	}, [props.systemId, props.groupId, props.marketPositionId])
	
	return 	<Panel>
				<Table bordered size="sm" responsive>
					<thead>
						<tr>
							<th className="col-5">Setting Name</th>
							<th className="col-6">Current Value</th>
							<th className="col-1 text-center">Edit</th>
						</tr>
					</thead>
					<tbody>
						{
							settings &&
							settings.length > 0 &&
							settings.map((setting, index) =>
								<tr key={ index }>
									<td>{ setting.Name }</td>
									<td  style={{ maxWidth: "100px" }}>{ removeTags(setting.Value) }</td>										
									{
										setting.SettingType === SettingTypes.Custom &&
										<td className="text-center"><NavLink to={ (setting.Name === "Cost of Finance Terms Table") ? "FinancialTerms" :"Currencies" }><RoundButton icon={ faPencil }></RoundButton></NavLink></td>
									}
									{
										setting.SettingType !== SettingTypes.Custom &&
										<td className="text-center"><RoundButton onClick={ e => handleEditSettingClicked(setting) } icon={ faPencil }></RoundButton></td>
									}
								</tr>)
						}
					</tbody>
				</Table>
				<BusyIndicator show={ settings === null } size="2x"></BusyIndicator>
				{
					settings &&
					<Pagination filter={ filter } 
							totalResults={ settings.length } 
							onPageChanged={ handlePageChange }></Pagination>
				}
				<EditSettingModal 
					groupId={ props.groupId } marketPositionId={ props.marketPositionId } 
					onClose={ handleEditSettingModalClosed } 
					show={ edit.Show } 
					setting={ edit.Setting } 
					name={ props.name }></EditSettingModal>
			</Panel>
}