import React from "react";
import { Route } from "react-router-dom";
import { useSessionStore } from "../../Stores/SessionStore";
import { getPermissions, hasSubPermissions, Permissions } from "../../Utilities/Permissions";
import {PaymentInstruction} from "../Reports/PaymentInstruction";
import ReportsIndex from "../Reports/ReportsIndex"
function ReportRouting(){

    const store = useSessionStore();

	const reportPermissions = hasSubPermissions(Permissions.Areas.CreditManagement, Permissions.SubAreas.Reports, store.Session);
	
    return <React.Fragment>

                {
                    reportPermissions && 
                    <React.Fragment>
					    <Route path="/Reports/PaymentInstruction" element={ <PaymentInstruction></PaymentInstruction> }></Route>
                        <Route path="/Reports/CreditManagementReports" element={ <ReportsIndex></ReportsIndex> }></Route>
				    </React.Fragment>
                }

           </React.Fragment>

}

export default ReportRouting;