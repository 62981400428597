import { Form } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor  from 'ckeditor5-custom-build/build/ckeditor';
import { forwardRef } from "react";

const HTMLText = forwardRef((props, ref) => {
	
	function onEditorReady(editor) {
		if(editor) {
			editor.editing.view.change((writer) => {
				writer.setStyle("height", "350px", editor.editing.view.document.getRoot());
			});
	
			ref.current = editor
		}
	}

	return 	<Form.Group>
				<Form.Label>Value</Form.Label>
				<CKEditor config={{ height: '350px' }} editor={ Editor } data={ props.value } onReady={ editor => onEditorReady(editor) }/>
			</Form.Group>
});

export default HTMLText;