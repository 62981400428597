import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

export default function FundsIndexFilter(props){
    return <React.Fragment>
        <Form className="mb-50">
            <h6>Filter Funds</h6>
            <Row>
                <Col>
                    <Form.Group as={ Row }>
                        <Col lg={4}>
                            <Form.Control type="search" placeholder="search..." />
                        </Col>
                        <Col className="d-flex align-items-center">
                            <Col lg={1}>
                                <Form.Label className="text-end mb-0">Facility</Form.Label>
                            </Col>
                            <Col lg={5}>
                            <Form.Select>
                                <option value=""> SELECT </option>
                            </Form.Select>
                            </Col>
                        </Col>
                        <Col lg={1} className="text-end">
                            <Button>Filter</Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    </React.Fragment>
}