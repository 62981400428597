import { Col, Row } from "react-bootstrap";
import InputField from "../../../../Core/Forms/InputField";
import SelectField from "../../../../Core/Forms/SelectField";
import Panel from "../../../../Core/Panel";

export default function OtherInformation(props) {
	return 	<Panel title="Other Information">
				<Row>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="NCR Number" name="NCRNumber"></InputField>
					</Col>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="FSP Number" name="FSPNumber"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="Import Number" name="ImportNumber"></InputField>
					</Col>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="Export Number" name="ExportNumber"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField fast view={props.view} className="mb-50" label="BBBEE Level" name="BBBEELevel">
							<option value="">--SELECT--</option>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
							<option value="7">7</option>
							<option value="8">8</option>
							<option value="0">N/A</option>
						</SelectField>
					</Col>
					<Col>
						<InputField fast view={props.view} className="mb-50" label="BBBEE Certificate Number" name="BBBEECertNumber"></InputField>
					</Col>
				</Row>
			</Panel>
}