import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { useSessionStore } from "../../Stores/SessionStore";
import { useNavigate,useLocation } from "react-router-dom";
import IconButton from "./IconButton";

export default function BackButton(props) {
	const navigate = useNavigate();
	const store = useSessionStore();
	const location = useLocation();

	let back = "";

	if(location.pathname.includes("Settings")) {
		back = location.pathname.substring(0,location.pathname.lastIndexOf("/"));
	} else {
		back = location.pathname.substring(0,location.pathname.length);
	}

	if(location.pathname.includes("Groups")) {
		if(location.pathname.includes("MarketPositions")) {
			back = location.pathname.substring(0,(location.pathname.lastIndexOf("MarketPositions") -1))
		}

		if(location.pathname.includes("Stores")) {
			var parent = location.pathname.substring((location.pathname.lastIndexOf("/")+ 1),location.pathname.length);	
			var check = location.pathname.substring(0,(location.pathname.lastIndexOf("Stores") -1));

			back = check + "/MarketPositions/" + parent;
		}
	}
	
	function handleBack(table, path) {
		if (path.includes(table)) {
			store.AuditStore.ClearStoredFilters();
		}

		if((location.pathname.includes("Settings") && !location.pathname.includes("Audits")) || location.pathname.includes("Groups")) {
			navigate(back);
		} else {
			navigate(-1);
		}
	}
	
	return <IconButton icon={ faArrowLeft } onClick={ e =>  handleBack(props.table, back) }>Back</IconButton>
}