import {View, Text, Image} from '@react-pdf/renderer';
import {styles} from '../../StyleSheet';
import Crestlogo from '../../../../Images/crest management system-LOGO.png';
import { FacilityTypes } from '../../../../Utilities/Enums';

export default function Heading(props){
	let test = [];
test.slice(0, 1)

	return(
		<View styles={styles.table}>
			<View style={{display: 'flex', flexDirection: 'row', paddingBottom: 15}}>
				<View style={{ width: "25%" , paddingLeft:1}}>
					{/* {
						!props.data.AssignmentPictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-start"}} src={ Crestlogo } />
					} */}
					{
						props.data.AssignmentPictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-start"}} src={ `data:${props.data.AssignmentPictureMIMEType};base64,${props.data.AssignmentPictureBase64}` } />
					}
				</View>
				<View style={{justifyContent: "center", width: "50%"}}>
					<Text style={styles.Title}>Facility - { props.data.Facility.FacilityNumber }</Text>
					<Text style={styles.Title}>{ props.data.Facility.Name }</Text>
				</View>
				<View style={{ width: "25%", paddingRight:2, }}>
					{
						!props.data.ProfilePictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-end"}} src={ Crestlogo } />
					}
					{
						props.data.ProfilePictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-end"}} src={ `data:${props.data.ProfilePictureMIMEType};base64,${props.data.ProfilePictureBase64}` } />
					}
				</View >
			</View>

			<View style={{display: 'flex', flexDirection: 'column', paddingBottom: 20}}>
				<View style={{minWidth:'10%', maxWidth:'65%', alignSelf:'center'}}>
					<View style={{flexDirection: 'row', fontSize:7 }}>
						<Text style={{ paddingTop: 5, paddingRight: 5, width:(props.data.Facility.FacilityTypeID === FacilityTypes.TradeFinance ? 70 : 100)}}><Text style={styles.boldFont}>Type: </Text>{props.data.Facility.FacilityTypeID === FacilityTypes.TradeFinance ? "Trade Finance" : "Invoice Discounting"}</Text>
						<Text style={{ paddingTop: 5, paddingLeft: 5}}>
							<Text style={[styles.boldFont]}>
								Assign: { props.data.Assignments.slice(0, 1).map(a => <Text style={styles.normalFont}>{ [a.GroupName, a.MarketPositionName, a.StoreName].filter(s => s !== "All").join(" / ") }</Text>) } 
							</Text>
						</Text>
					</View>
					{
						props.data.Assignments.length > 1 &&
						props.data.Assignments.slice(1).map(a => 
							<View style={[styles.tableRowWrapping, { fontSize:7 }]}>
								<View style={{ paddingTop: 5, paddingRight: 5, width:(props.data.Facility.FacilityTypeID === FacilityTypes.TradeFinance ? 70 : 100)}}></View>
								<View style={{ paddingLeft: 30}}>								
									<Text style={[styles.normalFont]}>{ [a.GroupName, a.MarketPositionName, a.StoreName].filter(s => s !== "All").join(" / ") }</Text>
								</View>
							</View>)
					}
				</View>
			</View>
		</View>
	)
}