import React from "react"
import { useEffect } from "react";
import { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import swal from "sweetalert"
import { useSessionStore } from "../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../Core/BusyIndicator";

export default function AddCurrencyModal(props) {
	const store = useSessionStore();

	const [supportedCurrencies, setSupportCurrencies] = useState(null);

	const [selectedCurrency, setSelectedCurrency] = useState("");	
	const [symbol, setSymbol] = useState("");

	const [saving, setSaving] = useState(false);

	async function save() {
		if(symbol && selectedCurrency) {
			var currency = supportedCurrencies.find(c => c.Abbreviation === selectedCurrency);

			if(currency) {
				setSaving(true);
				let result = await store.CurrenciesService.AddCurrency({ Abbreviation: currency.Abbreviation, Symbol: symbol, Name: currency.Name });
				setSaving(false);
		
				if(result && result.Success) {
					swal({ title: "Success", icon: "success", text: "Currency added successfully!", closeOnClickOutside: false });
					props.onClose(true);
				} else {
					swal({ title: "Error", icon: "error", text: "An error occurred attempting to add the currency.", closeOnClickOutside: false });
				}
			}
		}
	}

	async function getSupportedCurrencies() {
		let availableCurrenciesResult = await store.CurrenciesService.GetAvailableCurrencies("", 0, 0);

		if(availableCurrenciesResult && availableCurrenciesResult.Success) {
			let availableCurrencies = availableCurrenciesResult.Data;

			let supportedCurrenciesResult = await store.CurrenciesService.GetAllSupportedCurrencies();

			if(supportedCurrenciesResult && supportedCurrenciesResult.Success) {
				//remove the available currencies from the supported currencies
				let currencies = supportedCurrenciesResult.Data;
				let filteredCurrencies = currencies.filter(c => availableCurrencies.some(ac => ac.Abbreviation === c.Abbreviation) === false);

				setSupportCurrencies(filteredCurrencies);
			}
		}
	}

	useEffect(() => {
		if(props.show === false) {
			setSymbol("");
			setSelectedCurrency("");
		} else {
			getSupportedCurrencies();
		}
	}, [props.show]);

	return <Modal show={props.show}>
				<Modal.Header>
					<Modal.Title>
						Add New Currency
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>				
						{
							supportedCurrencies &&
							<Form>
								<Form.Group className="mb-1">
									<Form.Label>Currency</Form.Label>
									<Form.Select value={ selectedCurrency } onChange={ e => setSelectedCurrency(e.target.value) }>
										<option value="">--SELECT--</option>
										{
											supportedCurrencies.map(c => <option value={ c.Abbreviation }>{ c.Name }</option>)
										}
									</Form.Select>
								</Form.Group>
								<Form.Group>
									<Form.Label>Symbol</Form.Label>
									<Form.Control value={ symbol } onChange={ e => setSymbol(e.target.value) }></Form.Control>
								</Form.Group>
							</Form>
						}
					<BusyIndicator show={ supportedCurrencies === null }></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={ saving } onClick={ save }>{ (saving ? "Saving..." : "Save") }</Button>
					<Button disabled={ saving } onClick={ () => props.onClose(false) }>Close</Button>
				</Modal.Footer>
			</Modal>
}