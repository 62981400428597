export function getAuditType(type) {
	if(type === "U") {
		return "Change"
	} else if (type === "I") {
		return "Create"
	}

	return "";
}
export function getFrequency(type) {
	if(type === "0") {
		return "N/A"
	} else if (type === "1") {
		return "Per Issuance"
	}else if (type === "2") {
		return "On Commencement"
	}else if (type === "3") {
		return "Monthly"
	}else if (type === "4") {
		return "Per Annum"
	}else if (type === "5") {
		return "Quarterly"
	}
	

	return type;
}
