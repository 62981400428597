import APIBase from "./APIBase";

/**
 * @typedef PaymentInstructionRecoveriesService
 * @type {PaymentInstructionRecoveriesService}
 */
export default class PaymentInstructionRecoveriesService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "PaymentInstructionRecoveries", sessionStore);
    }

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	async GetPaymentInstructionRecoveryState(paymentInstructionId) {
		return this.Get(`GetPaymentInstructionRecoveryState?paymentInstructionId=${paymentInstructionId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	async DeleteActiveRecoveryExport(paymentInstructionId) {
		return this.Post(`DeleteActiveRecoveryExportForPaymentInstruction?paymentInstructionId=${paymentInstructionId}`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<any> }
	 */
	async GetTransactionsForRecovery(paymentInstructionId, dateReceived, allocatedAmount) {
		return this.Get(`GetTransactionsForRecovery?paymentInstructionId=${paymentInstructionId}&dateReceived=${encodeURIComponent(dateReceived)}&allocatedAmount=${encodeURIComponent(allocatedAmount)}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async SavePaymentInstructionRecovery(paymentInstructionId, recovery) {
		return this.Post(`SavePaymentInstructionRecovery?paymentInstructionId=${paymentInstructionId}`, recovery);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<any> }
	 */
	 async ExportRecoveryAssignments(paymentInstructionId, dateReceived, recoveryAmount, theoreticalRecoveryAmount) {
		return this.Post(`ExportRecoveryAssignments?paymentInstructionId=${paymentInstructionId}&dateReceived=${encodeURIComponent(dateReceived)}
							&recoveryAmount=${encodeURIComponent(recoveryAmount)}&theoreticalRecoveryAmount=${encodeURIComponent(theoreticalRecoveryAmount)}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	async ValidateRecoveryImport(content) {
		return this.Post(`ValidateRecoveryImport`, content);
	}

	
	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	 async UploadDocumentForPaymentInstruction(paymentInstructionId, document) {
		return this.Post(`UploadDocumentForPaymentInstruction?paymentInstructionId=${paymentInstructionId}`, document);
	}
}