import { faPlus, faRefresh, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import confirmDelete from "../../../../../Utilities/Delete";
import BackButton from "../../../../Core/BackButton";
import BusyIndicator from "../../../../Core/BusyIndicator";
import ClickableText from "../../../../Core/ClickableText";
import IconButton from "../../../../Core/IconButton";
import PageHeader from "../../../../Core/PageHeader";
import Panel from "../../../../Core/Panel";
import RoundButton from "../../../../Core/RoundButton";
import CreateFinancialTermModal from "./CreateFinancialTermModal";
import TermRatesModal from "./TermRatesModal";
import UseDocumentTitle from "../../../../../Utilities/UserDocumentTitle";

export default function FinancialTerms(props) {

	UseDocumentTitle('Listing \'Financial Terms\' on CREST');

	const store = useSessionStore();

	const [financialTerms, setFinancialTerms] = useState(null);

	const [selectedRate, setSelectedRate] = useState(null);
	const [showRatesModal, setShowRatesModal] = useState(false);

	const [showAddModal, setShowAddModal] = useState(false);

	async function getRates() {
		setFinancialTerms(null);
		let result = await store.FinancialTermsService.GetCurrentFinancialTerms();

		if(result && result.Success) {
			setFinancialTerms(result.Data);
		}
	}

	async function handleTermDelete(term) {
		let result = await confirmDelete(term.Name, term.Id, (id) => store.FinancialTermsService.DeleteFinancialTerm(id));
		
		if(result) {
			getRates();
		}
	}

	function handleRatesClicked(termRate) {
		setSelectedRate(termRate);
		setShowRatesModal(true);
	}

	function handleTermRatesModalClosed(update) {
		if(update) {
			getRates();
		}

		setShowRatesModal(false);
	}

	function handleCreateModalClosed(update) {
		if(update) {
			getRates();
		}

		setShowAddModal(false);
	}

	useEffect(() => {
		getRates();
	}, []);

	return 	<React.Fragment>
				<PageHeader icon={ faGear } title={ "Cost of Finance Terms Table" }  helpItemDescriptor={ "Admin/FinancialTerms" }
							crumbs={[{ Title: "Admin" }, { Title: "Systems Settings" },{ Title: "Cost of Finance Terms Table", Active: true }]}>
					<IconButton space icon={ faPlus } onClick={ e => setShowAddModal(true) }>
						Add New
					</IconButton>
					<IconButton space icon={ faRefresh } onClick={ e => getRates() }>
						Refresh
					</IconButton>
					<BackButton></BackButton>
				</PageHeader>
				<Panel>
					<Table bordered size="sm" className="mb-2" responsive>
						<thead>
							<tr>
								<th>Name</th>
								<th>Current Rate</th>
								<th className="col-2 text-center">Edit</th>
								<th className="col-1 text-center">Delete</th>
							</tr>
						</thead>
						<tbody>
							{
								financialTerms &&
								financialTerms.length > 0 &&
								financialTerms.map((term, index) =>
									<tr key={ index }>
										<td>{ term.Name }</td>
										<td>{ term.CurrentRate ? term.CurrentRate.Rate : 0 }%</td>										
										<td className="text-center"><ClickableText onClick={ () => handleRatesClicked(term) }>Rates</ClickableText></td>
										<td className="text-center">
											{
												term.Active && <span>Active</span>
											}
											{
												!term.Active &&
												<RoundButton onClick={ e => handleTermDelete(term) } icon={ faTimes }></RoundButton>
											}
										</td>
									</tr>)
							}
						</tbody>
					</Table>
					<BusyIndicator show={ financialTerms === null }></BusyIndicator>
				</Panel>
				<TermRatesModal show={ showRatesModal } onClose={ handleTermRatesModalClosed } termId={ (selectedRate ? selectedRate.Id : null) }></TermRatesModal>
				<CreateFinancialTermModal show={ showAddModal } onClose={ handleCreateModalClosed }></CreateFinancialTermModal>
			</React.Fragment>
}