import { Document, Page, View } from "@react-pdf/renderer";
import {styles} from './StyleSheet';

import VendorHeader from "./Components/VendorPayments/VendorHeading";
import VendorTransactionDetail from '../Reports/Components/VendorPayments/VendorTransactionsDetails';
import VendorPaymentsTransactions from "./Components/VendorPayments/VendorPaymentsTransactions";

export default function VendorPayments(props){
   
    return(
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <VendorHeader data={props.data.FinanceDetails} image={props.data} /> 
                    <View style={styles.table}>
                        <VendorTransactionDetail data={props.data}/>
                        <VendorPaymentsTransactions data={props.data} faciltity={props.data.Facility.Facility} currency={props.data.Facility.Facility.CurrencySymbol}/>
                    </View>
                </View>
            </Page>
        </Document>
    )
}