import { Field, useFormikContext } from "formik";
import Panel from "../../../Core/Panel";
import TextValue from "../../../Core/TextValue";

export default function SpecialInstructions(props) {
	const formik = useFormikContext();

	return <Panel title="Special Instructions">
				{
					!props.view && !props.canoverride &&
					<Field name="SpecialInstructions">
						{ ({ field }) => ( <textarea className="form-control" { ...field }></textarea> ) }
					</Field>
				}
				{
					props.view &&			
					<TextValue value={ formik.values["SpecialInstructions"] }></TextValue>
				}
			</Panel>
}