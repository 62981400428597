import React from "react";
import { Route } from "react-router-dom";
import ApproveTransaction from "./ApproveTransaction";
import ApprovePaymentInstruction from "./PaymentInstruction/ApprovePaymentInstruction";

export default function ApprovalRoutes() {
	return 	<React.Fragment>
				<Route path="/Approve/Transaction" element={ <ApproveTransaction></ApproveTransaction> }></Route>
				<Route path="/Approve/PaymentInstruction" element={ <ApprovePaymentInstruction></ApprovePaymentInstruction> }></Route>
			</React.Fragment>
}