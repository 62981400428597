import { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-regular-svg-icons";
import DateValue from "../../../Core/DateValue";
import { useState } from "react";

export default function OutstandingExportModal(props) {
	const store = useSessionStore();
	const fileInput = useRef(null);

	const [discarding, setDiscarding] = useState(false);

	function handleImportWorksheetClicked () {
		fileInput.current.click();
	}

	async function deleteActiveExport() {
		setDiscarding(true);
		let result = await store.PaymentInstructionRecoveriesService.DeleteActiveRecoveryExport(props.paymentInstruction.PaymentInstructionId);
		setDiscarding(false);
		if(result && result.Success) {
			if(props.onImportDiscarded){
				props.onImportDiscarded();
			} else {
				swal("Discard error", "An error occurred attempting to discard the export.", "error");
			}
		}
	}

	function handleDiscardClicked () {
		swal({
			icon: "warning",
			title: "Discard Recovery Export",
			text: "Discarding this recovery export may prevent you from importing the worksheet originally exported, continue?",
			buttons: {
				cancel: true,
				confirm: true
			}, 
			closeOnClickOutside: false
		}).then((val) => {
			if(val) {
				deleteActiveExport();
			}
		});  
	}    

	async function validateImport(file) {
		let result = await store.PaymentInstructionRecoveriesService.ValidateRecoveryImport(file);

		if(result.Message) {
			swal("Import error", result.Message, "error");  
		} else {
			if(props.onRecoveryImported){
				props.onRecoveryImported(file, props.paymentInstruction.ActiveExport);
			}
		}

		fileInput.current.value = "";
	}

	function handleFileUpload(event) {
		var file = event.target.files[0];
		
		if (file) {
			var reader = new FileReader();

			reader.onload = function(e) {   
				validateImport(e.target.result);
			}

			reader.readAsText(file)
		}
	}
	
	return 	<Modal show={ props.show }>
				<Modal.Header>
					<h4>
						Recover Payments
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						props.paymentInstruction.ActiveExport &&
						<div>
							There is an outstanding Recover Payments import Worksheet for <DateValue date={ props.paymentInstruction.ActiveExport.DateReceived }></DateValue> 
						</div>
					}
					
				</Modal.Body>
				<Modal.Footer>
					<input onChange={ handleFileUpload } style={{ visibility: "hidden"}} ref={ fileInput } hidden type='file' />                     
					<Button disabled={ discarding } onClick={ handleImportWorksheetClicked }><FontAwesomeIcon icon={ faUpload }></FontAwesomeIcon> Import Worksheet</Button>
					<Button disabled={ discarding } onClick={ handleDiscardClicked }>{ discarding ? "Discarding..." : "Discard" }</Button>
					<Button disabled={ discarding } onClick={ props.onCloseClicked }>Cancel</Button>
				</Modal.Footer>
			</Modal>
}