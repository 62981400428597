import { useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { FacilityTypes } from "../../../../../Utilities/Enums";
import MonetaryValue from "../../../../Core/MonetaryValue";
import NumberValue from "../../../../Core/NumberValue";
import Panel from "../../../../Core/Panel";

export default function Transactions(props) {
	const store = useSessionStore();
	const [transactions, setTransactions] = useState(null);

	async function getTransactions() {
		let result = await store.TransactionsService.GetTransactionsForPaymentInstruction(props.paymentInstructionId);

		if(result && result.Success) {
			setTransactions(result.Data);
		}
	}

	useEffect(() => {
		if(props.paymentInstructionId) {
			getTransactions();
		}
	}, [props.paymentInstructionId]);

	return 	<Panel title={`${props.facilityTypeId === FacilityTypes.InvoiceDiscounting ? "Debt" : "Payable"} Transactions`}>
				<Table bordered size="sm">
					<thead>
						<tr>
							<th className="text-nowrap">Document No.</th>
							<th className="text-nowrap">Store</th>
							<th className="text-end">Amount (Ex. VAT)</th>
							<th className="text-end">VAT</th>
							<th className="text-end">Total (Incl. VAT)</th>
							<th>Financing Percent</th>
							<th className="text-end">Payment Amount</th>
							<th className="text-nowrap text-end">Payment Difference</th>
						</tr>
					</thead>
					<tbody>
						{
							transactions && transactions.length > 0 &&
							transactions.map((transaction, index) => 
							<tr key={ index }>
								<td>{ transaction.DocumentNumber }</td>
								<td>{ transaction.Store }</td>
								<td className="text-end"><MonetaryValue value={ transaction.DocumentAmount }></MonetaryValue></td>
								<td className="text-end"><MonetaryValue value={ transaction.VATAmount }></MonetaryValue></td>
								<td className="text-end"><MonetaryValue value={ transaction.Amount }></MonetaryValue></td>
								<td><NumberValue value={ transaction.FinancePercentage }></NumberValue>%</td>
								<td className="text-end"><MonetaryValue value={ transaction.PaymentAmount }></MonetaryValue></td>
								<td className="text-nowrap text-end"><NumberValue value={Math.abs(transaction.PaymentAmount.ConvertedValue - transaction.Amount.ConvertedValue)} currency={ transaction.PaymentAmount.ConvertedCurrency.Symbol }></NumberValue></td>
							</tr>)
						}
					</tbody>
				</Table>
			</Panel>
}