import {View, Document, Page} from '@react-pdf/renderer';
import MaturityDateHeader from './Components/MaturityDate/MaturityDateHeading';
import MaturityDateTransactions from './Components/MaturityDate/MaturityDateTransactions';
import {styles} from '../Reports/StyleSheet';

export default function MaturityDate(props){
    return(
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <MaturityDateHeader data={props.data}/>
                    <MaturityDateTransactions data={props.data}/>
                </View>
            </Page>
        </Document>
    )
}