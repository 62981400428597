import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, useFormikContext } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Panel from "../../../../Core/Panel";
import BankAccount from "./BankAccount";

export default function BankingDetails(props) {
	const formikContext = useFormikContext();

	function handleDeleteClicked(index) {
		let bankAccounts = formikContext.values.BankAccounts;

		if(bankAccounts.length > 1) {
			let mustReassignDefault = bankAccounts[index].IsDefault;

			bankAccounts.splice(index, 1);

			if(mustReassignDefault) {
				bankAccounts[0].IsDefault = true;
			}

			formikContext.setFieldValue("BankAccounts", bankAccounts);
		}
	}

	function handleIsDefaultChecked(index, checked) {
		//make sure this is the only bank account selected as default
		let bankAccounts = formikContext.values.BankAccounts;

		if(checked === true) {
			for(let i = 0; i < bankAccounts.length; i++) {
				if(i === index) {
					bankAccounts[i].IsDefault = true;
				} else {
					bankAccounts[i].IsDefault = false;
				}
			}
		}		

		formikContext.setFieldValue("BankAccounts", bankAccounts);
	}

	function handleAddBankAccountClicked(push) {
		push({ 
			Id: 0,
			AccountHolder: "", 
			Institution: "", 
			AccountNumber: "", 
			AccountType: "", 
			BranchName: "", 
			BranchCode: "", 
			IBANNumber: "", 
			SwiftCode: "", 
			IsDefault: false
		});

		setTimeout(() => {
			formikContext.validateForm();
		}, 75);
	}

	return 	<Panel title="Banking Details">				
				<FieldArray name="BankAccounts" validateOnChange={false}>
					{({ push, remove }) => (
						<React.Fragment>
							<Row>
								<Col>
								{
									formikContext.values.BankAccounts &&
									formikContext.values.BankAccounts.map((bankAccount, index) => 
									<div className="border-bottom" key={ index }>
										<BankAccount view={props.view || bankAccount.Id !== 0} 
													onDeleteClicked={ () => handleDeleteClicked(index) } 
													bankAccount={bankAccount}
													index={ index }
													onIsDefaultChanged={ e => handleIsDefaultChecked(index, e) }>
										</BankAccount>
									</div>
									)
								}
								</Col>
							</Row>
							<Row>
								<Col className="d-flex justify-content-end mt-1 fw-bold">
									<Button onClick={ e => handleAddBankAccountClicked(push)}>
										<FontAwesomeIcon icon={ faPlus }></FontAwesomeIcon>
									</Button>
								</Col>
							</Row>
						</React.Fragment>
						)}
				</FieldArray>				
			</Panel>
}