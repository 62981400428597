import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {  Table } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { getPermissions, Permissions } from "../../../../Utilities/Permissions";
import PageHeader from "../../../Core/PageHeader";
import Pagination from "../../../Core/Pagination";
import DateValue from "../../../Core/DateValue";
import NumberValue from "../../../Core/NumberValue";
import Panel from "../../../Core/Panel";
import BusyIndicator from "../../../Core/BusyIndicator";
import IconButton from "../../../Core/IconButton";
import { faFileExcel, faWallet } from "@fortawesome/pro-regular-svg-icons";
import PaymentInstructionsFilters from "../PaymentInstructions/PaymentInstructionsFilters";
import { NavLink } from "react-router-dom";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

const ReimbursementsIndex = observer(function ReimbursementsIndex(props) {

	UseDocumentTitle('Reimbursements');

	const store = useSessionStore();
	
	useEffect(() => {
		store.PaymentManagementStore.GetReimbursements();
	}, [store]);

	async function handleExportReimbursementsClicked() {
		var result = await store.PaymentManagementStore.ExportReimbursements();

		if(result) {
			//save file
			var blob = new Blob([result], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `PAYMENT_REIMBURSEMENTS_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);
		}
	}

	function handlePageChange(pageIndex, pageCount) {
		store.PaymentManagementStore.SetReimbursementsFilters({...store.PaymentManagementStore.ReimbursementsFilters, PageIndex: pageIndex, PageCount: pageCount });
		store.PaymentManagementStore.GetReimbursements();
	}

	function handleFilterUpdate(property, value) {
		let filters = { ...store.PaymentManagementStore.ReimbursementsFilters };
		filters[property] = value;

		store.PaymentManagementStore.SetReimbursementsFilters(filters);
	}

	const permissions = getPermissions(Permissions.Sections.PaymentReimbursements, Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement, 
										store.Session);
	
	return 	<React.Fragment>
				<PageHeader icon={ faWallet } title={ "Reimbursements" } 
							helpItemDescriptor={ "PaymentInstruction/PaymentReimbursements" }
								crumbs={[{ Title: "Credit Management" }, { Title: "Payment Management" }, { Title: "Reimbursements" }]}>
					{
						permissions.View &&
						<IconButton icon={ faFileExcel } disabled={ store.PaymentManagementStore.ExportingReimbursements  } onClick={handleExportReimbursementsClicked}>
							{ store.PaymentManagementStore.ExportingReimbursements ? "Exporting..." : "Export All" }
						</IconButton>
					}
				</PageHeader>
				<Panel>		
					<PaymentInstructionsFilters filters={ store.PaymentManagementStore.ReimbursementsFilters } reimbursements
												updateCallback={ handleFilterUpdate }
												fetchCallback={ e => store.PaymentManagementStore.GetReimbursements() } />			
					<Table bordered size="sm" className="mb-2" responsive>
						<thead>
							<tr>
                                <th className="text-nowrap">Document Date</th>
                                <th>Number</th>
                                <th>Name</th>
                                <th>Group</th>
                                <th className="text-end">Total (Incl VAT)</th>
                                <th>Processed Date</th>
                                <th>Status</th>
                            </tr>
						</thead>
						<tbody>
							{
								!store.PaymentManagementStore.RetrievingReimbursements  &&
								store.PaymentManagementStore.Reimbursements.length > 0 &&
								store.PaymentManagementStore.Reimbursements.map((reimbursement, index) =>
									<tr key={ index }>
										<td className="text-nowrap"><DateValue outputFormat="dd MMM yyyy" date={ reimbursement.DateTimeStamp }></DateValue></td>
										<td>
										
											{
												
												permissions.View &&
												<NavLink to={ `/PaymentManagement/Reimbursements/View/${reimbursement.PaymentInstructionId}` }>{ reimbursement.DocumentNumber }</NavLink>
											}
											{
												!permissions.View &&
												<span>{ reimbursement.DocumentNumber }</span>
											}
										</td>
										<td>{ reimbursement.PaymentInstructionName }</td>
										<td>{ reimbursement.Group }</td>
										<td className="text-nowrap text-end"><NumberValue currency={ reimbursement.Currency } value={ reimbursement.PaymentAmount }></NumberValue></td>
										<td className="text-nowrap">{( reimbursement.ProcessedDate ? <DateValue outputFormat="dd MMM yyyy" date={ reimbursement.ProcessedDate }></DateValue> : "N/A")}</td>
										<td>{reimbursement.Status}</td>
									</tr>)
							}
						</tbody>						
					</Table>
					<BusyIndicator show={ store.PaymentManagementStore.RetrievingReimbursements }></BusyIndicator>
					{
						!store.PaymentManagementStore.RetrievingReimbursements &&
						<Pagination filter={ store.PaymentManagementStore.ReimbursementsFilters } 
								totalResults={ store.PaymentManagementStore.Reimbursements.length }
								onPageChanged={ handlePageChange }></Pagination>
					}
					
				</Panel>				
			</React.Fragment>
});

export default ReimbursementsIndex;