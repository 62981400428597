import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import PageHeader from "../../../../Core/PageHeader";
import Pagination from "../../../../Core/Pagination";
import Panel from "../../../../Core/Panel";
import IconButton from "../../../../Core/IconButton";
import { faFileExcel, faPencil, faPlus, faTimes, faUsers } from "@fortawesome/pro-regular-svg-icons";
import RoundButton from "../../../../Core/RoundButton";
import BusyIndicator from "../../../../Core/BusyIndicator";
import { NavLink, useNavigate } from "react-router-dom";
import ClickableText from "../../../../Core/ClickableText";
import AssignmentsModal from "./AssignmentsModal/AssignmentsModal";
import confirmDelete from "../../../../../Utilities/Delete";
import { Link } from "react-router-dom";
import UseDocumentTitle from "../../../../../Utilities/UserDocumentTitle";

export default function UsersIndex(props) {

	UseDocumentTitle('Listing \'System Users\' on CREST');

	const store = useSessionStore();
	const navigate = useNavigate();

	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState({ PageIndex: 0, PageCount: 10, Total: 0 });
	const [users, setUsers] = useState(null);

	const [selectedSystem, setSelectedSystem] = useState(null);
	const [showAssignmentsModal, setShowAssignmentsModal] = useState(false);
	const [exportingUsers, setExportingUsers] = useState(false);

	async function getUsers(filters) {
		setUsers(null);
		let result = await store.UsersService.GetUsers(search, filters.PageIndex, filters.PageCount);

		if(result && result.Success) {
			setFilter({...filters, Total: result.Total});
			setUsers(result.Data);
		}
	}
	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
			store.FacilitiesStore.GetFacilities(true)
		}
	}

	useEffect(() => {
		getUsers(filter);
	}, []);

	async function handleExportUsersClicked() {
		setExportingUsers(true);
		var result = await store.UsersService.ExportUsers(search);

		if(result) {
			//save file
			var blob = new Blob([result.Data], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `USER_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);

			setExportingUsers(false);
		}
	}

	function handlePageChange(pageIndex, pageCount) {
		let filters = {...filter, PageIndex: pageIndex, PageCount: pageCount};

		setFilter(filters);
		getUsers(filters);
	}
	
	function handleSystemClicked(systemApp, user) {
		setSelectedSystem({ SystemApp: systemApp, User: user });
		setShowAssignmentsModal(true);
	}

	async function handleUserDelete(user) {
		let result = await confirmDelete(user.Name, user.Id, (id) => store.UsersService.DeleteUser(id));
		
		if(result) {
			getUsers(filter);
		}
	}

	return 	<React.Fragment>
				<PageHeader icon={ faUsers } title={ "Users" }  helpItemDescriptor={ "Admin/Users" }
							crumbs={[{ Title: "Admin" }, { Title: "Users" }, { Title: "List" }]}>
						<NavLink to={ "Add" }><IconButton space icon={ faPlus }>Add New</IconButton></NavLink>
						<IconButton icon={ faFileExcel } disabled={ exportingUsers } onClick={handleExportUsersClicked}>
							{ exportingUsers ? "Exporting..." : "Export All" }
						</IconButton>
				</PageHeader>
				<Panel>
					<h6>Filter Users</h6>
					<Row className="mb-50">
						<Col xs={4}>
							<Form.Group>
								<Form.Control onKeyPress={ e => handleKeyPress(e) } placeholder="Search..." value={search} onChange={ e => setSearch(e.target.value) } type="search" ></Form.Control>
							</Form.Group>
						</Col>
						<Col className="d-flex justify-content-end align-items-end">
							<Button className="text-nowrap" onClick={ e => getUsers(filter) }>Filter</Button>
						</Col>
					</Row>
					
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
                                <th>Name</th>
                                <th>User name</th>
								<th>Email address</th>
								<th>Phone number</th>
								<th>Role/s</th>
								<th>Systems</th>
                                <th className="col-1 text-center">Edit</th>
                                <th className="col-1 text-center">Delete</th>
                            </tr>
						</thead>
						<tbody>
							{
								users &&
								users.length > 0 &&
								users.map((user, index) =>
									<tr key={ index }>
										<td><NavLink to={ `/Admin/Users/View/${user.Id}` }>{ user.Name }</NavLink></td>
										<td><NavLink to={ `/Admin/Users/View/${user.Id}`}>{ user.Username }</NavLink></td>
										<td>{ user.EmailAddress }</td>
										<td>{ user.PhoneNumber }</td>
										<td>{ user.Role }</td>
										<td>
											{ user.AssociatedSystems.map((s) => 
											<ClickableText onClick={ e => handleSystemClicked(s, user) }
												title={(s.GroupAdminLevel ? "Group Admin Level Access" : s.GroupLevel ? "Group Level Access" : s.MarketPositionLevel ? "Market Position Level Access" : "Store Level Access")}>
												{s.SystemName}
											</ClickableText>) }
										</td>
										<td className="text-center"><NavLink to={ `/Admin/Users/Edit/${ user.Id }/` }><RoundButton icon={ faPencil }></RoundButton></NavLink></td>
										<td className="text-center">
											{
												user.Inactive ? <span>Inactive</span> : (!user.Hasloggedin ? <RoundButton onClick={ e => handleUserDelete(user) } icon={ faTimes }></RoundButton> : <span>Active</span>)
											}
										</td>
									</tr>)
							}
						</tbody>						
					</Table>
					<BusyIndicator show={ users === null } size="2x"></BusyIndicator>
					{
						users &&
						<Pagination filter={ filter } 
								totalResults={ users.length } 
								onPageChanged={ handlePageChange }></Pagination>
					}
					
				</Panel>
				<AssignmentsModal show={ showAssignmentsModal } selectedSystem={ selectedSystem } onClose={ e => { setShowAssignmentsModal(false); setSelectedSystem(null); } }></AssignmentsModal>
			</React.Fragment>
}