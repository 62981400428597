import { useFormikContext } from "formik";
import { useEffect } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { getCurrencySymbol,roundUp } from "../../../../Utilities/Currencies";
import InputField from "../../../Core/Forms/InputField";
import NumberField from "../../../Core/Forms/NumberField";
import NumberInput from "../../../Core/Forms/NumberInput";
import SelectField from "../../../Core/Forms/SelectField";
import NumberValue from "../../../Core/NumberValue";
import Panel from "../../../Core/Panel";
import { PaymentInstructionStatuses } from "../../../../Utilities/Enums";

export default function PaymentInformation(props) {
	const formikContext = useFormikContext();
	const documentType = formikContext.values["DocumentType"];

	useEffect(() => {
		let amount = formikContext.values["DocumentAmount"];
		let vat = formikContext.values["VATAmount"];

		if(amount && vat) {
			if(documentType === "Invoice") {
				if(amount < 0) {
					amount = amount *-1.0;
				}
				if(vat < 0) {
					vat = vat *-1.0;
				}
			} else if(documentType === "Credit Note") {
				if(amount > 0) {
					amount = amount *-1.0;
				}
				if(vat > 0) {
					vat = vat *-1.0;
				}
			}
	
			formikContext.setValues({...formikContext.values, DocumentAmount: amount, VATAmount: vat });	
		}
		
	}, [documentType]);

	function getFinalValue(value) {
		const documentType = formikContext.values.DocumentType;
		
		if(documentType && value) {
			if(documentType === "Invoice") {
				if(value < 0) {
					value = value *-1.0;
				}
				
			} else if(documentType === "Credit Note") {
				if(value > 0) {
					value = value *-1.0;
				}				
			}
		}

		return value;
	}

	useEffect(() => {
		let value = formikContext.values.DocumentAmount;
		value = getFinalValue(value);
		
		if(value !== formikContext.values.DocumentAmount) {
			formikContext.setFieldValue("DocumentAmount", value);
		}
		
	}, [formikContext.values.DocumentAmount]);

	useEffect(() => {
		let value = formikContext.values.VATAmount;
		value = getFinalValue(value);
		
		if(value !== formikContext.values.VATAmount) {
			formikContext.setFieldValue("VATAmount", value);
		}
		
	}, [formikContext.values.VATAmount]);

	function getCurrency() {
		const currencyId = formikContext.values["CurrencyId"];
		return getCurrencySymbol(currencyId, props.currencies);
	}
	
	function getDocumentTotal() {
		let amount = formikContext.values["DocumentAmount"] || 0.00;
		let vat = formikContext.values["VATAmount"] || 0.00;

		let total = amount + vat;		

		return total;
	}

	function getPaymentAmount() {
		let percentage = formikContext.values["FinancialPercentage"];
		let total = getDocumentTotal();

		if(percentage) {
			return roundUp(total * (percentage / 100.00),4);
		}

		return 0;
	}

	function getPaymentDifference() {
		let amount = getPaymentAmount();
		
		
		let total = getDocumentTotal();
		
		return total - roundUp(amount,2);
	}

	return 	<Panel title="Payment Information">
				<Row>
					<Col>
						<InputField view={props.view} disabled={props.canoverride} className="mb-50" label="Trading Terms" name="TradingTerms"></InputField>
					</Col>
					<Col>
						<InputField view={props.view} className="mb-50" label="Payment Reference" disabled={props.canoverride} name="PaymentReference"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField view={props.view} disabled={props.canoverride} className="mb-50" required label="Currency" name="CurrencyId">
							<option value="">-- Select --</option>
							{
								props.currencies && props.currencies.map((c) => 
								<option key={ c.Id } value={c.Id}>{c.Abbreviation}</option>)
							}
						</SelectField>
					</Col>
					<Col>
						<NumberField suffix="%" className="mb-50" view label="Financing Percentage"  name="FinancialPercentage"></NumberField>
					</Col>
				</Row>
				<Row>
					<Col>
						<NumberField view={props.view} prefix={ getCurrency() } disabled={props.canoverride} className="mb-50" required label="Document Amount" name="DocumentAmount"></NumberField>
					</Col>
					<Col>
						<Form.Group as={Row} className="align-items-center mb-50">
							<Form.Label column>Payment Amount</Form.Label>
							<Col xs={8} style={{ color: "#004878", cursor: "not-allowed" }}>
								<NumberValue currency={ getCurrency() }  value={ getPaymentAmount()  }></NumberValue>
							</Col>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<NumberField view={props.view} prefix={ getCurrency() } className="mb-50" disabled={props.canoverride} required label="VAT Amount" name="VATAmount"></NumberField>
					</Col>
					<Col>
						<Form.Group as={Row} className="align-items-center mb-50">
							<Form.Label column>Payment Difference</Form.Label>
							<Col xs={8} style={{ color: "#004878", cursor: "not-allowed" }}>
								<NumberValue currency={ getCurrency() }  value={ getPaymentDifference() }></NumberValue>
							</Col>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group as={Row} className="align-items-center">
							<Form.Label column>Document Total</Form.Label>
							{
								props.view &&
								<Col xs={8}>
									<span style={{ cursor: "not-allowed", color: "#004878" }}><NumberValue currency={ getCurrency() } value={ getDocumentTotal() }></NumberValue></span>
								</Col>
							}
							{
								!props.view &&
								<Col xs={8}>
									<InputGroup>
										<InputGroup.Text>{getCurrency()}</InputGroup.Text>
										<NumberInput value={ getDocumentTotal() } disabled></NumberInput>
									</InputGroup>
								</Col>
							}
						</Form.Group>
					</Col>
					<Col>
					{
						console.log("enum: ", )
					}
						{
							formikContext.values["TransactionStatusId"] === PaymentInstructionStatuses.Processed &&
								<NumberField view={props.view} className="mb-50" label="Spot Rate" name="SpotRate" decimals={ 8 }></NumberField>
						}
					</Col>
					
				</Row>
			</Panel>
}