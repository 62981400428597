import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "./Breadcrumb";

export default function PageHeader(props) {
	return 	<React.Fragment>
				<Row className="d-flex align-items-center px-1" style={{ backgroundColor: "#F8F8F8", zIndex: "3", height: "54px", position: "fixed", left: "269px", right: 0, top: 0 }}>
					<Col className="text-primary text-nowrap" xs={4}>
						<h2 className="text-primary" style={{ fontWeight: "400" }}><FontAwesomeIcon icon={ props.icon }></FontAwesomeIcon> { props.title }</h2>
					</Col>
					<Col className="d-flex justify-content-end align-items-center">
						{props.children}
					</Col>
				</Row>
				<Breadcrumb pageDescriptor={ props.helpItemDescriptor } crumbs={ props.crumbs }></Breadcrumb>
			</React.Fragment>

}