import React, { useState } from "react";
import PageHeader from "../../Core/PageHeader";
import { faFileExcel, faPlus, faSackDollar } from "@fortawesome/pro-regular-svg-icons";
import { NavLink } from "react-router-dom";
import IconButton from "../../Core/IconButton";
import UseDocumentTitle from "../../../Utilities/UserDocumentTitle";
import Panel from "../../Core/Panel";
import { Table } from "react-bootstrap";
import Pagination from "../../Core/Pagination";
import FundsIndexFilter from "./FundsIndexFilter";

export default function FundsIndex(){

    UseDocumentTitle('Listing \'Funds\' on CREST');

    const [filter, setFilter] = useState({PageIndex: 0, PageCount: 0, Total: 0});

    function handlePageChange(pageIndex, pageCount) {
		let filters = {...filter, PageIndex: pageIndex, PageCount: pageCount};

		setFilter(filters);
	}

    return <React.Fragment>
        <PageHeader helpItemDescriptor={ "Fund/Index" } icon={ faSackDollar } title="Funds" crumbs={[{ Title: "Fund Management"}, { Title:"Funds" }]}>
            <NavLink><IconButton icon={ faPlus } space>Add New</IconButton></NavLink>
            <IconButton icon={ faFileExcel }>Export All</IconButton>
        </PageHeader>
        <Panel>
            <FundsIndexFilter />
            <Table bordered size="sm" responsive>
                <thead>
                    <tr>
                        <th>Fund No.</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Linked Funders</th>
                        <th className="text-center">Assigned</th>
                        <th className="text-center">Edit</th>
                        <th className="text-center">Delete</th>
                        <th className="text-center">Preview</th>
                    </tr>
                </thead>
            </Table>
            <Pagination filter={ filter } totalResults={ 0 } onPageChanged={ handlePageChange }></Pagination>
        </Panel>
    </React.Fragment>
}