import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TransactionStatuses } from "../../../../Utilities/Enums";
import ClickableText from "../../../Core/ClickableText";
import PaymentInstructionAssignmentModal from "./PaymentInstructionAssignmentModal";

export default function PaymentInstructionAssignmentDetails(props) {
	const [showAssignmentModal, setShowAssignmentModal] = useState(false);
	const [piAssigned, setPIAssigned] = useState(false);
	
	function handlePIAssignmentModalClosed(result, pi) {
		if(result) {
			props.transaction.PaymentInstruction = pi.Name;
			props.transaction.PaymentInstructionId = pi.PaymentInstructionId;
			setPIAssigned(true);
		}

		setShowAssignmentModal(false);
	}

	return <React.Fragment>
				{
					!props.transaction.PaymentInstructionId && props.transaction.TransactionStatusId === TransactionStatuses.Verified &&
					<ClickableText onClick={ e => { setShowAssignmentModal(true) } }><FontAwesomeIcon transform="grow-2" icon={ faTimes }></FontAwesomeIcon></ClickableText>														
				}
			
				{
					!props.transaction.PaymentInstructionId && props.transaction.TransactionStatusId !== TransactionStatuses.Verified &&
					<span className="text-muted"><FontAwesomeIcon transform="grow-2"  icon={ faTimes }></FontAwesomeIcon></span>
				}                                            
				{
					props.transaction.PaymentInstructionId &&
					<OverlayTrigger overlay={ <Tooltip>Assigned to <br/> {props.transaction.PaymentInstruction}</Tooltip> }>
						<span className="text-success" style={{ cursor: "pointer" }}>
							<FontAwesomeIcon transform={ piAssigned ? "grow-20" : "grow-2"} icon={ faCheck }></FontAwesomeIcon>
						</span>
					</OverlayTrigger>
				}
				<PaymentInstructionAssignmentModal 
					transactionId={ props.transaction.Id } 
					transactionName={props.transaction.DocumentNumber}
					show={ showAssignmentModal } facilityTypeId={ props.facilityType }
					onClose={ handlePIAssignmentModalClosed }>
				</PaymentInstructionAssignmentModal>
		</React.Fragment>
}