import { Col, Row } from "react-bootstrap";
import Panel from "../../Core/Panel";
import CrestLogo from "../../../Images/crest management system-LOGO.png";

export default function AlreadyApproved(props) {
	return <Row>
				<Col className="offset-lg-3" lg={6}>
					<Panel>
						<Row>
							<Col>
								<div className="p-20">
									<Row>
										<Col lg={4}>
											<img src={CrestLogo} style={{ maxHeight :"70px" }} alt="Crest Logo" />
										</Col>
										<Col>
											<h4 className="text-end my-2">Thank you</h4>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
						<hr/>
						<Row className="mt-3 mb-2">
							<Col>
								<h3 className="text-center">
									{
										props.transaction &&
										<text>This transaction has already been attended to.</text>
									}
									{
										props.paymentInstruction &&
										<text>This payment instruction has already been attended to.</text>
									}
								</h3>
								<p className="text-center text-muted"><small>You may close this window</small></p>
							</Col>
						</Row>
					</Panel>
				</Col>
			</Row> 	
	
}