import { Formik, Form as FormikForm } from "formik";
import { DateTime } from "luxon";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import * as yup from 'yup';
import { removeModalTabIndex } from "../../../../Utilities/React";
import DateField from "../../../Core/Forms/DateField";
import NumberField from "../../../Core/Forms/NumberField";

export default function CreatePaymentRecoveryModal(props) {
	const actualAmountInput = useRef(false);

	const [recovery, setRecovery] = useState(null);
	const [theoreticalAmountSet, setTheoreticalAmountSet] = useState(false);

	function getMinDate() {
		var runDate = props.paymentInstruction.InterestStartDate === "" ? null : DateTime.fromISO(props.paymentInstruction.InterestStartDate);
		var lastRecoveryDate = props.paymentInstruction.LastRecoveredDate === "" ? null : DateTime.fromISO(props.paymentInstruction.LastRecoveredDate).plus({ days: 1 });

		var minDate = lastRecoveryDate > runDate ? lastRecoveryDate : runDate;
		
		return minDate;
	}

	useEffect(() => {
		if(props.show) {
			let recovery = {
				DateReceived: "",
				AllocatedAmount: "",
				TheoreticalAmount: "",
				LastRecoveredDate: props.paymentInstruction.LastRecoveredDate || "",
				RunDate: props.paymentInstruction.InterestStartDate
			}

			let minDate = getMinDate();
			let diff = minDate.diff(DateTime.now(), "day").hours;

			if(diff < 0)
				recovery.DateReceived = "";
			else
				recovery.DateReceived = DateTime.now().toISODate();

			setTheoreticalAmountSet(false);
			setRecovery(recovery);

			removeModalTabIndex();
		} else {
			setTheoreticalAmountSet(false);
			setRecovery(null);
		}

	}, [props.show]);

	async function handleSubmitClicked(values) {
		if(props.onSaveClicked) {
            props.onSaveClicked(values);
        }
	}

	const validationSchema = yup.object({
		DateReceived: yup.mixed().required(),
		AllocatedAmount: yup.number().positive().required(),
		TheoreticalAmount: yup.number().min(0).required()
	}).required();

	function handleActualAmountChanged(value, setValues, values) {
		actualAmountInput.current = true;

		if(theoreticalAmountSet === false) {
			values.AllocatedAmount = value;
			values.TheoreticalAmount = value;
			setValues(values, true);
			return true;
		}

		return false;
	}

	function handleTheoreticalAmountChanged() {
		if(actualAmountInput.current === false) {
			setTheoreticalAmountSet(true);
		} else {
			actualAmountInput.current = false;
		}

		return false;
	}
    
    return  <Modal show={ props.show } dialogClassName="modal-600">
			{
				recovery &&
				<Formik initialValues={recovery} enableReinitialize validateOnMount validationSchema={ validationSchema } onSubmit={ handleSubmitClicked }>
					{({ values, errors, setValues }) => (
					<FormikForm>
						<Modal.Header>
							<h4>
							Recover Payments
							</h4>
						</Modal.Header>
						<Modal.Body>
							<DateField className="mb-50" disabled label="Run Date" name="RunDate"></DateField>
							<DateField placeholder="N/A" className="mb-50" disabled label="Last Recovered Date" name="LastRecoveredDate"></DateField>
							<DateField required className="mb-50" label="Date Received" name="DateReceived" minDate={ getMinDate().toJSDate() } maxDate={ new Date() }></DateField>
							<NumberField onChange={ e => handleActualAmountChanged(e, setValues, values) } required prefix={ props.paymentInstruction.Currency } className="mb-50" label="Actual Amount Received" name="AllocatedAmount"></NumberField>
							<NumberField onChange={ handleTheoreticalAmountChanged }  required prefix={ props.paymentInstruction.Currency } className="mb-50" label="Theoretical Amount" name="TheoreticalAmount"></NumberField>
						</Modal.Body>
						<Modal.Footer className="justify-content-end">
							<Button type="submit">Save</Button>
							<Button onClick={ props.onCloseClicked }>Cancel</Button>
						</Modal.Footer>
					</FormikForm>)
					}
				</Formik>
			}
			</Modal>
}