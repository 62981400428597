import { faArrowLeft, faFileExcel, faUpload } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate,useLocation } from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import IconButton from "../../../Core/IconButton";
import PageHeader from "../../../Core/PageHeader";
import Panel from "../../../Core/Panel";
import BusyIndicator from "../../../Core/BusyIndicator"

export const BulkImport = observer(function BulkImport(props) {
	const store = useSessionStore();
	const financeStore = store.GetFinanceStore(props.facilityType);
	const {pathname} = useLocation();
	const navigate = useNavigate();

	const [gettingTemplate, setGettingTemplate] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [selectedFileName, setSelectedFileName] = useState("No File Selected");

	const [importResult, setImportResult] = useState(null);

	const fileInputRef = useRef(null);

	async function handleGetTemplateClicked() {
		setGettingTemplate(true);
		var result = await store.TransactionDocumentsService.GetImportTemplate(props.facilityType);
		setGettingTemplate(false);
		
		if(result && result.Success) {
			//save file
			const byteArray = Uint8Array.from(atob(result.Data).split('').map(char => char.charCodeAt(0)));
			var blob = new Blob([byteArray], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

			saveAs(blob, `${financeStore.GetContactType()} Document Import Template.xlsx`);
		}
	}

	function handleFileChanged(event) {
		let file = event.target.files[0];

		if(file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');

				setSelectedFileName(file.name);
				setSelectedFile(base64String)
			};

			reader.readAsDataURL(file);
		}
	}

	function handleDownloadErrorsClicked() {
		const byteArray = Uint8Array.from(atob(importResult.ResultFile).split('').map(char => char.charCodeAt(0)));
		var blob = new Blob([byteArray], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

		saveAs(blob, `${selectedFileName.substring(0, selectedFileName.indexOf("."))}_Errors.xlsx`);
	}

	async function handleImportClicked() {
		if(selectedFile) {
			setImportResult(null);
			let result = await financeStore.ImportTransactionDocuments(selectedFile);

			if(result && result.TotalSuccess > 0) {
				financeStore.GetTransactionDocuments();
				financeStore.GetTransactions();
				resetState()
			}
			
				
	
			setImportResult(result);
			
			
		}
	}

	function handleChooseFileClicked() {
		fileInputRef.current.value = null;
		fileInputRef.current.click()
	}
	const resetState = () => {
		setSelectedFile(null)
		setSelectedFileName("No File Selected");
	  };

	return 	<React.Fragment>
				<PageHeader icon={ faUpload } title={ `Import ${financeStore.GetContactType()} Documents` } 
							helpItemDescriptor={ `${financeStore.GetContactType()}Document/Import` }
							crumbs={[{ Title: "Credit Management" }, { Title: `${financeStore.GetContactType()} Documents` }, { Title: "Import" }]}>

					<IconButton space onClick={ e => navigate(-1) || navigate(pathname.substring(0,pathname.indexOf("/Import")))} icon={ faArrowLeft }>Back</IconButton>
					<IconButton disabled={ gettingTemplate } onClick={ handleGetTemplateClicked } icon={ faFileExcel }>{ gettingTemplate ? "Downloading..." : "Get Template" }</IconButton>

				</PageHeader>
				<Panel title="Document">
					<Row>
						<Col xs={5}>
							<input ref={ fileInputRef } hidden type="file" onChange={ handleFileChanged }></input>
							<InputGroup>
								<Form.Control value= {selectedFileName} disabled></Form.Control>
								<Button disabled={ financeStore.ImportingDocuments } onClick={ handleChooseFileClicked }>Choose File...</Button>
							</InputGroup>
						</Col>
						<Col xs={1}>
							<Button disabled={ selectedFile === null || financeStore.ImportingDocuments } onClick={ handleImportClicked }>Import</Button>
						</Col>
					</Row>
				</Panel>
				
				<Panel title="Summary of Import">
					<Row>
						<Col>
							<div>Total number of rows: { (importResult ? importResult.Total : 0 ) }</div>
							<div>Successfully Imported: { (importResult ? importResult.TotalSuccess : 0 ) }</div>
							<div>Duplicates: { (importResult ? importResult.TotalDuplicates : 0 ) }</div>
							<div>Failed to Import: { (importResult ? importResult.TotalFailed : 0 ) }</div>
							<Button disabled={ !importResult || importResult.Errors.length === 0 } className="mt-2" onClick={ handleDownloadErrorsClicked }>Download Errors</Button>
						</Col>
					</Row>
					<BusyIndicator show={ financeStore.ImportingDocuments }></BusyIndicator>
				</Panel>
				{
					(importResult && importResult.Errors.length > 0) &&
					<Panel title={`Errors (${importResult.Errors.length})`}>
						<ul className="text-danger">
							{
								importResult.Errors.map(error =>
									<li>{ error }</li>	
								)
							}
						</ul>
					</Panel>
				}
			</React.Fragment>

});

export default BulkImport;