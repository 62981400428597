import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FastField, Field, useField, useFormikContext } from "formik";
import NumberFormat from "react-number-format";
import NumberValue from "../NumberValue";

export default function NumberField(props) {
	const formik = useFormikContext();
	const [field] = useField(props.name);

	function handleChange(event) {
		var value = event.floatValue;

		if(isNaN(value)) {
			value = null;
		}
		
		if(props.onChange) {
			if(!props.onChange(value)) {
				formik.setFieldValue(props.name, value, true);
			}
		} else {
			formik.setFieldValue(props.name, value, true);
		}
	}

	function getClass() {
		return `form-control ${((formik.errors[props.name] !== undefined || props.invalid) ? "is-invalid" : "")}`;
	}

	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
		}
	}

	return 	<Form.Group as={Row} className={`align-items-center ${props.className || ""}`}>
				{
					props.label &&
					<Form.Label column>{ props.label }</Form.Label>
				}
				{
					props.view &&
					<Col xs={ (!props.label ? 12 : (props.col || 8)) } style={{ cursor: "not-allowed", color: "#004878" }}>
						<span>{ (props.prefix ? `${props.prefix} ` : "") }</span>
						<NumberValue decimals={props.decimals || 2} value={ field.value }></NumberValue>
						<span>{props.suffix}</span>
					</Col>
				}
				{
					!props.view &&
					<Col xs={ (!props.label ? 12 : (props.col || 8))}>
						<InputGroup>
							{
								props.prefix &&
								<InputGroup.Text>{props.prefix}</InputGroup.Text>
							}
							{
								props.name && !props.fast &&
								<NumberFormat onKeyPress={ handleKeyPress }  customInput={ Field } onValueChange={ handleChange } value={ field.value } thousandSeparator={ ' ' } name={ props.name } type={props.type || 'text'} required={ props.required }
								className={ getClass() } decimalScale={props.decimals || 2} fixedDecimalScale disabled={ props.disabled } placeholder={props.placeholder}></NumberFormat>
								
							}
							
							{
								props.name && props.fast &&
								<NumberFormat onKeyPress={ handleKeyPress } customInput={ FastField } onValueChange={ handleChange } value={ field.value } thousandSeparator={ ' ' } name={ props.name } required={ props.required }
								className={ getClass() } decimalScale={props.decimals || 2} fixedDecimalScale disabled={ props.disabled } placeholder={props.placeholder}></NumberFormat>
							}
							{
								props.suffix &&
								<InputGroup.Text>{props.suffix}</InputGroup.Text>
							}
						</InputGroup>
					</Col>
				}
			</Form.Group>
}