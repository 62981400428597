import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
export default function RecoveriesTotalsSummary(props){
    const showConversion = props.data.Totals.find((x) => x.Currency.Symbol !== props.currency);
    return(
        <View>
            <View style={styles.tableRowWrapping} fixed>
                <Text style={[styles.textHeaders, {textAlign:'left', width:'7%'}]}></Text>
                <Text style={[styles.textHeaders, {textAlign:'center', width:'5%'}]}>Currency</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Document Total</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'8%'}]}>Financing %</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Payment Amount</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'8%'}]}>Payment Diff</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Interest</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Funding Amount</Text>
                <Text style={[styles.textHeaders, {textAlign:'left', width:'10%', paddingLeft:5}]}>Recovery Date</Text>
                <Text style={[styles.textHeaders, {textAlign:'left', width:'10%'}]}>Status</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'12%'}]}>Recovered Amount (ZAR)</Text>
            </View>
            {props.data.Totals.map(value =>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.textFooters, {textAlign:'left', width:'7%', borderBottom:0.5, borderColor: 'lightgrey'}]}>Totals</Text>
                <Text style={[styles.textFooters, {textAlign:'center', width:'5%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{value.Currency.Abbreviation}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.AmountTotal)}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'8%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{(value.FinancePercentage).toFixed(2)} %</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.PaymentAmountTotal)}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'8%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,(value.AmountTotal - value.PaymentAmountTotal))}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,(value.FundingAmountTotal - value.PaymentAmountTotal))}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.FundingAmountTotal)}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%', borderBottom:0.5, borderColor: 'lightgrey'}]}></Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%', borderBottom:0.5, borderColor: 'lightgrey'}]}></Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'12%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue("R",value.RecoveriesTotal)}</Text>
            </View>)}
            {showConversion &&
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.textFooters, {textAlign:'left', width:'7%'}]}>Totals ({props.convertedTotals.Currency.Abbreviation} Conversion)</Text>
                <Text style={[styles.textFooters, {textAlign:'center', width:'5%'}]}>{props.convertedTotals.Currency.Abbreviation}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(props.convertedTotals.Currency.Symbol,props.convertedTotals.AmountTotal)}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'8%'}]}>{(props.convertedTotals.FinancePercentage).toFixed(2)} %</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(props.convertedTotals.Currency.Symbol,props.convertedTotals.PaymentAmountTotal)}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'8%'}]}>{formatCurrencyValue(props.convertedTotals.Currency.Symbol,(props.convertedTotals.AmountTotal - props.convertedTotals.PaymentAmountTotal ))}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(props.convertedTotals.Currency.Symbol,(props.convertedTotals.FundingAmountTotal - props.convertedTotals.PaymentAmountTotal))}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(props.convertedTotals.Currency.Symbol,props.convertedTotals.FundingAmountTotal)}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'20%'}]}></Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'12%'}]}>{formatCurrencyValue(props.convertedTotals.Currency.Symbol,props.convertedTotals.RecoveriesTotal)}</Text>
            </View>
            }
        </View>
    )
}