import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FieldArray } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../../Stores/SessionStore";
import { getCurrentFunderLimit } from "../../../../../../Utilities/Facilities";
import { getPermissions, Permissions } from "../../../../../../Utilities/Permissions";
import { GetRandomKey } from "../../../../../../Utilities/React";
import BusyIndicator from "../../../../../Core/BusyIndicator";
import CheckboxField from "../../../../../Core/Forms/CheckboxField";
import SelectField from "../../../../../Core/Forms/SelectField";
import RoundButton from "../../../../../Core/RoundButton";
import NumberValue from "../../../../../Core/NumberValue"
import FacilityFunderLimitField from "./FacilityFunderLimitField";

export default function FacilityFundersModal(props) {
	const [funders, setFunders] = useState(null);
	const [selectedFunder, setSelectedFunder] = useState(null);

	const store = useSessionStore();

	useEffect(() => {
		async function loadAvailableFunders() {
			let results = await store.ContactsService.GetFunderContacts();

			if(results && results.Success) {
				setFunders(results.Data);
			}
		}

		if(props.show) {
			loadAvailableFunders();
		}
	}, [props.show]);

	/**
	 * Retrieves the list of funders that can be associated with the facility, will just remove any funders that are already associated
	 */
	function getAvailableFunders() {
		var availableFunders = [];

		if(props.linkedFunders && funders) {
			for(var i = 0; i < funders.length; i++) {
				let funder = funders[i];
				let existingFunder = props.linkedFunders.find((f) => f.ContactId === funder.Id);

				if(!existingFunder) {
					availableFunders.push(funder);
				}
			}
		}

		return availableFunders;
	}

	function handleSelectedFunderChanged(funderId) {
		if(funderId) {
			var funder = funders.find((f) => f.Id === parseInt(funderId));

			if(funder) {
				setSelectedFunder(funder);
				return;
			}
		}

		setSelectedFunder(null);
	}

	function handleAddSelectedFunderClicked(pushMethod) {
		if(selectedFunder !== null) {
			pushMethod({
				ContactId: selectedFunder.Id,
				Name: selectedFunder.Name,
				Amount: 0,
				Balance: 0,
				Linked: false,
				Active: false,
				LimitHistory: []
			});

			var funderIndex = funders.findIndex((f) => f.Id === selectedFunder.Id);

			funders.splice(funderIndex, 1)
			setFunders(funders);
			setSelectedFunder(null);
		}
	}

	function handleDeleteFunderClicked(index, funderId, funderName, removeMethod) {
		funders.push({ Id: funderId, Name: funderName });
		setFunders(funders);
		removeMethod(index);
	}

	/**
	 * Returns the percentage the funder is funding based on their portion of the summed funding limits
	 */
	function getFunderPercentage(funder) {
		var totalFacilityFunding = 0;
		var funderLimit = getCurrentFunderLimit(funder);

		for(var i = 0; i < props.linkedFunders.length; i++) {
			totalFacilityFunding += getCurrentFunderLimit(props.linkedFunders[i]);
		}

		if(totalFacilityFunding > 0) {
			return (funderLimit / totalFacilityFunding) * 100.0;
		}

		return 0;
	}

	const permissions = getPermissions(Permissions.Sections.AllFacilities, Permissions.Areas.CreditManagement, Permissions.SubAreas.Facilities, store.Session);

	return 	<Modal show={props.show} dialogClassName="modal-900">
				<Modal.Header>
					<h4>
						Link Funder
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						funders !== null &&
						<FieldArray name="LinkedFunders" validateOnChange={false}>
											{({ push, remove }) => (
						<React.Fragment>
							{
								permissions.Add &&
								<React.Fragment>
									<Row>
										<Col>
											<SelectField label="Company Name" onChange={ e => handleSelectedFunderChanged(e.target.value) } disabled={ props.disabled }>
												<option value=""></option>
												{
													getAvailableFunders().map((funder) => {
														return <option key={funder.Id} value={funder.Id}>{funder.Name}</option>
													})
												}
											</SelectField>
										</Col>
									</Row>
									<Row>
										<Col className="d-flex justify-content-end my-50">
											<Button onClick={ e => handleAddSelectedFunderClicked(push) } disabled={ props.disabled }>Add</Button>
										</Col>
									</Row>
								</React.Fragment>
							}
							<b className="d-block mb-75 mt-1">Linked Funders</b>
							<Row>
								<Col>
									<Table bordered size="sm" className="align-middle" responsive>
										<thead>
											<tr>
												<th className="text-nowrap">Company Name</th>
												<th className="text-nowrap">Contact Type</th>
												<th>%</th>
												<th style={{ maxWidth: "220px", width: "220px" }}>Amount</th>
												<th className="text-end text-nowrap">Actual Bal.</th>
												{
													permissions.Edit &&
													<th>Status</th>
												}
												{
													permissions.Delete &&
													<th className="text-center">Delete</th>
												}
											</tr>
										</thead>
											<tbody>
												{ props.linkedFunders && props.linkedFunders.map((funder, index) => (
												<tr key={ GetRandomKey() }>
													<td>{funder.Name}</td>
													<td>Funder</td>
													<td ><NumberValue value={getFunderPercentage(funder)}></NumberValue>%</td>
													<td>
														<FacilityFunderLimitField limit={ getCurrentFunderLimit(funder) } onClick={ e => props.onFacilityFundersClicked(index) } col={12} currency={props.currency}></FacilityFunderLimitField>
													</td>
													<td className="text-end"><NumberValue currency={props.currency} value={funder.Balance}></NumberValue></td>
													{
														permissions.Edit &&
														<td>
															<CheckboxField col={1} swap label="Active" name={ `LinkedFunders[${index}].Active` } disabled={ props.disabled } active></CheckboxField>
														</td>
													}
													{
														permissions.Delete && funder.Linked && <td className="text-center">Linked</td>
													}
													{
														permissions.Delete && !funder.Linked &&
														<td className="text-center">
															<RoundButton onClick={ props.disabled? "" : e => handleDeleteFunderClicked(index, funder.ContactId, funder.Name, remove) } icon={ faTimes }></RoundButton>
														</td>
													}
												</tr>))}
											</tbody>
									</Table>
								</Col>
							</Row>
						</React.Fragment>
						)}
						</FieldArray>
					}
					<BusyIndicator show={funders === null} size="2x"></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={ funders === null } onClick={ props.onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}