import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FastField, Field, useField } from "formik";
import TextValue from "../TextValue";

export default function InputField(props) {
	const field = useField(props.name);

	function getClass() {
		return `form-control${((field[1].error || props.invalid) ? " is-invalid" : "")}`;
	}

	
	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
		}
	}

	return 	<Form.Group as={Row} className={`align-items-center ${props.className || ""}`}>
				{
					props.label && 
					<Form.Label column>{ props.label }</Form.Label>
				}
				{
					props.view &&
					<Col xs={ (!props.label ? 12 : props.labelCol ? 10 : (props.col || 8))}>
						<TextValue value={ field[0].value }></TextValue>
					</Col>
				}
				{
					!props.view &&
					<Col xs={ (!props.label ? 12 : props.labelCol ? 10 : (props.col || 8))}>
						<InputGroup>
							{
								props.prefix &&
								<InputGroup.Text>{props.prefix}</InputGroup.Text>
							}
							{
								props.name && !props.fast &&
								<Field autoComplete={ props.autocomplete } onKeyPress={ handleKeyPress } required={ props.required } disabled={props.disabled} type={props.type || 'text'} className={ getClass() } name={ props.name }></Field>
							}
							{
								props.name && props.fast &&
								<FastField autoComplete={ props.autocomplete } onKeyPress={ handleKeyPress }  required={ props.required } disabled={props.disabled} type={props.type || 'text'} className={ getClass() } name={ props.name }></FastField>
							}
							{
								!props.name &&
								<input autoComplete={ props.autocomplete } onKeyPress={ handleKeyPress } value={ props.value } required={ props.required } disabled={props.disabled} type={props.type || 'text'} className={ getClass() }></input>
							}
							{
								props.suffix &&
								<InputGroup.Text>{props.suffix}</InputGroup.Text>
							}
						</InputGroup>
					</Col>
				}
			</Form.Group>
}