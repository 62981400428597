import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { PaymentInstructionStatuses } from "../../../Utilities/Enums";
import BusyIndicator from "../../Core/BusyIndicator";
import ContactBalances from "../Facilities/FacilityDetails/Components/Balances/ContactBalances";
import FacilityLimits from "../Facilities/FacilityDetails/Components/Balances/FacilityLimits";
import LinkedPaymentInstructions from "../Facilities/FacilityDetails/Components/Balances/LinkedPaymentInstructions";
import TransactionFinanceDetailsTable from "./TransactionFinanceDetailsTable";

export default function PaymentInstructionFinanceDetailsModal(props) {
	const store = useSessionStore();
	
	const [retrievingData, setRetrievingData] = useState(true);
	const [financeDetails, setFinanceDetails] = useState(null);
	
	const [saving, setSaving] = useState(false);
	const [checkedTransactions, setCheckedTransactions] = useState([]);

	async function getFinanceDetails() {
		setRetrievingData(true);
		var result = await store.PaymentInstructionsService.GetPaymentInstructionFinanceDetails(props.paymentInstructionId, props.facilityId);

		if(result && result.Success) {
			setFinanceDetails(result.Data);
		}

		setRetrievingData(false);
	}

	useEffect(() => {
		if(props.show) {
			getFinanceDetails();
		}
	}, [props.show]);
	
	function handleTransactionChecked(e) {
		let value = parseInt(e.target.value);
		if (checkedTransactions.includes(value) === false) {
			setCheckedTransactions([...checkedTransactions, value]);
		} else {
			setCheckedTransactions(checkedTransactions.filter((item) => item !== value));
		}
	};

	async function handleSaveClicked() {
		if(checkedTransactions.length > 0) {
			setSaving(true);
			let result = await store.PaymentInstructionsService
									.UnlinkTransactionsFromPaymentInstruction(financeDetails.PaymentInstructionBalance.PaymentInstructionId,
																				checkedTransactions);
			setSaving(false);

			if(result && result.Success) {
				props.onClose(true);
			}
		} else {
			props.onClose(false);
		}
	}

	return 	<Modal show={ props.show } dialogClassName="modal-1200">				
				<Modal.Body>
					{
						!retrievingData && financeDetails &&
						<React.Fragment>
							<h4 className="mb-1">Transactions</h4>
							<TransactionFinanceDetailsTable paymentInstruction={ financeDetails.PaymentInstructionBalance } 
															transactions={ financeDetails.TransactionBalances } 
															currency={ financeDetails.PaymentInstructionBalance.Currency }
															onTransactionChecked={ handleTransactionChecked }
															checkedTransactions={ checkedTransactions }></TransactionFinanceDetailsTable>

							<h4 className="mb-1">Limits</h4>
							<ContactBalances facilityTypeId={ financeDetails.PaymentInstructionBalance.FacilityTypeId } contacts={ financeDetails.AssignedContacts }></ContactBalances>
							
							<div className="mt-2">
								<LinkedPaymentInstructions title="Payment Instruction Limit" currency={ financeDetails.PaymentInstructionBalance.Currency } 
														paymentInstructions={ [ financeDetails.PaymentInstructionBalance ] }></LinkedPaymentInstructions>
							</div>
							
							<div className="mt-2">
								<FacilityLimits facility={ financeDetails } currency={ financeDetails.PaymentInstructionBalance.Currency } 
												facilityBalances={ financeDetails.FacilityBalances }></FacilityLimits>
							</div>
						</React.Fragment>
					}
					<BusyIndicator show={ retrievingData }></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					{
						financeDetails && props.allowSave && (financeDetails.PaymentInstructionBalance.PaymentInstructionStatusId === PaymentInstructionStatuses.Open || (financeDetails.PaymentInstructionBalance.PaymentInstructionStatusId === PaymentInstructionStatuses.Pending && financeDetails.PaymentInstructionBalance.Submitted === false)) &&
						<Button disabled={ saving || retrievingData } onClick={ handleSaveClicked }>{ saving ? "Saving..." : "Save" }</Button>
					}
					<Button disabled={ saving || retrievingData } onClick={ e => props.onClose(false) }>Cancel</Button>
				</Modal.Footer>
			</Modal>
}