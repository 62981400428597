import APIBase from "./APIBase";

/**
 * @typedef SettingsService
 * @type {SettingsService}
 */
export default class SettingsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Settings", sessionStore);
    }

	async GetSetting(settingId) {
		return this.Get(`GetSetting?settingId=${settingId}`);
	}

	async GetSystemSettings(pageIndex, pageCount) {
		return this.Get(`GetSystemSettings?pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	async GetGroupSettings(groupId, pageIndex, pageCount) {
		return this.Get(`GetGroupSettings?groupId=${groupId}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}
	
	async GetMarketPositionSettings(marketPositionId, pageIndex, pageCount) {
		return this.Get(`GetMarketPositionSettings?marketPositionId=${marketPositionId}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	async UpdateSetting(setting) {
		return this.Post(`UpdateSetting`, setting);
	}
}