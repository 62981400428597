import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RoundButton(props) {
	var color = (props.variant && props.variant === "primary") ? "#fff" : "#999";

	return 	<span onClick={props.onClick} className="fa-layers fa-fw" style={{ fontSize: "27px",
														borderRadius: "100px",
														border: ((props.variant && props.variant === "primary") ? "" : "1px solid #999"),
														width: "27px",
														height: "27px",
														cursor: "pointer",
														backgroundColor: (props.variant && props.variant === "primary" ? "#38a7df" : "#fff") }}>
				<FontAwesomeIcon icon={ props.busy ? faSpinnerThird : props.icon } spin={ props.busy } color={ color } transform="shrink-7" />
  			</span>
}