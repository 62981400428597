import React from "react";
import { Col, Row } from "react-bootstrap";
import ApprovalValue from "../ApprovalValue";

export default function InvoiceDiscountingBankDetails(props) {
	return	<Row className="border pt-1 pb-1 mb-1">
				<Col>
					<Row className="ps-0">
						<ApprovalValue label="Pay to Group">
							{ props.paymentInstruction.GroupName }
						</ApprovalValue>
						<ApprovalValue label="Bank">
							{ props.paymentInstruction.GroupBankAccount.BankInstitution }
						</ApprovalValue>
						<ApprovalValue label="Branch Code">
							{ props.paymentInstruction.GroupBankAccount.BranchCode }
						</ApprovalValue>
						<ApprovalValue label="Account Number">
							{ props.paymentInstruction.GroupBankAccount.BankAccountNumber }
						</ApprovalValue>
					</Row>
				</Col>
			</Row> 
	
}