import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { getPermissions, Permissions } from "../../../../Utilities/Permissions";
import PageHeader from "../../../Core/PageHeader";
import Pagination from "../../../Core/Pagination";
import DateValue from "../../../Core/DateValue";
import NumberValue from "../../../Core/NumberValue";
import Panel from "../../../Core/Panel";
import BusyIndicator from "../../../Core/BusyIndicator";
import { FacilityTypes } from "../../../../Utilities/Enums";
import IconButton from "../../../Core/IconButton";
import { faFileExcel } from "@fortawesome/pro-regular-svg-icons";
import TransactionRecoveriesFilters from "./TransactionRecoveriesFilters";
import { getRecoveryStatus } from "../../../../Utilities/Transactions";
import { NavLink } from "react-router-dom";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

const TransactionRecoveriesIndex = observer(function TransactionRecoveriesIndex(props) {
	


	if(props.facilityType === 1){
		UseDocumentTitle('Listing \'Payable Transaction Recoveries\' in \'Trade Finance\' on CREST');
	}else{
		UseDocumentTitle('Listing \'Debt Transaction Recoveries\' in \'Debt Finance\' on CREST');
	}

	const store = useSessionStore();
	const financeStore = store.GetFinanceStore(props.facilityType);
		
	useEffect(() => {
		financeStore.GetTransactionRecoveries();
	}, [financeStore]);

	async function handleExportTransactionRecoveriesClicked() {
		var result = await financeStore.ExportTransactionRecoveries();

		if(result) {
			//save file
			var blob = new Blob([result], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `${financeStore.GetTransactionType().toUpperCase()}_TRANSACTIONS_RECOVERIES_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);
		}
	}

	function handlePageChange(pageIndex, pageCount) {
		financeStore.SetTransactionRecoveriesFilters({...financeStore.TransactionRecoveriesFilters, PageIndex: pageIndex, PageCount: pageCount});
		financeStore.GetTransactionRecoveries();
	}

	const permissions = getPermissions(props.facilityType === FacilityTypes.TradeFinance ? Permissions.Sections.PayableTransactions : Permissions.Sections.DebtTransactions, 
										Permissions.Areas.CreditManagement, 
										props.facilityType === FacilityTypes.TradeFinance ? Permissions.SubAreas.TradeFinance : Permissions.SubAreas.DebtorFinance, 
										store.Session);
	
	return 	<React.Fragment>
				<PageHeader icon={ financeStore.GetIcon() } title="Transaction Recoveries"
							helpItemDescriptor={ `${financeStore.GetTransactionType()}Transaction/TransactionRecoveries` }
								crumbs={[{ Title: "Credit Management" }, { Title: `${financeStore.GetFinanceType()} Finance` }, { Title: "Transaction Recoveries" }]}>
					{
						permissions.View &&
						<IconButton icon={ faFileExcel } disabled={ financeStore.ExportingTransactionRecoveries } onClick={ handleExportTransactionRecoveriesClicked }>
							{ financeStore.ExportingTransactionRecoveries ? "Exporting..." : "Export All" }
						</IconButton>
					}
				</PageHeader>
				<Panel>
					<TransactionRecoveriesFilters facilityType={ props.facilityType }></TransactionRecoveriesFilters>
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
                                <th className="text-nowrap">Document Date</th>
                                <th>Trade {financeStore.GetContactType()}</th>
                                <th>Store</th>
                                <th>Document No.</th>
                                <th className="text-end">Total (incl. VAT)</th>
                                <th>Last Recovery Date</th>
                                <th>Status</th>
                            </tr>
						</thead>
						<tbody>
							{
								!financeStore.RetrievingTransactionRecoveries &&
								financeStore.TransactionRecoveries.length > 0 &&
								financeStore.TransactionRecoveries.map((transaction, index) =>
									<tr key={ index }>
										<td className="text-nowrap"><DateValue outputFormat="dd MMM yyyy" date={ transaction.DocumentDate }></DateValue></td>
										<td>
											{
												permissions.View &&
												<NavLink to={`/${financeStore.GetFinanceType()}Finance/TransactionRecoveries/View/${transaction.Id}`}>{ transaction.ContactName }</NavLink>
											}
											{
												!permissions.View &&
												<span>{ transaction.ContactName }</span>
											}
										</td>
										<td>{ transaction.StructureName }</td>
										<td>{ transaction.DocumentNumber }</td>
										<td className="text-nowrap text-end"><NumberValue currency={ transaction.Currency } value={ transaction.DocumentTotal }></NumberValue></td>
										<td className="text-nowrap">{ transaction.LastRecoveryDate && <DateValue outputFormat="dd MMM yyyy" date={ transaction.LastRecoveryDate }></DateValue>}</td>
										<td>											
											<span>{ getRecoveryStatus(transaction) }</span>
										</td>
									</tr>)
							}
						</tbody>						
					</Table>
					<BusyIndicator show={ financeStore.RetrievingTransactionRecoveries } size="2x"></BusyIndicator>
					{
						!financeStore.RetrievingTransactionRecoveries &&
						<Pagination filter={ financeStore.TransactionRecoveriesFilters } 
								totalResults={ financeStore.TransactionRecoveries.length }
								onPageChanged={ handlePageChange }></Pagination>
					}
					
				</Panel>
			</React.Fragment>
});

export default TransactionRecoveriesIndex;