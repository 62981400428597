import { faBuildingColumns, faFileExcel } from "@fortawesome/pro-regular-svg-icons"
import { DateTime } from "luxon";
import React, { useState } from "react"
import {  Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import DateValue from "../../Core/DateValue";
import NumberValue from "../../Core/NumberValue";
import PageHeader from "../../Core/PageHeader"
import Pagination from "../../Core/Pagination";
import Panel from "../../Core/Panel"
import UnderutilisationFilters from "./UnderutilisationFilters";
import { saveAs } from "file-saver";
import IconButton from "../../Core/IconButton";
import UseDocumentTitle from "../../../Utilities/UserDocumentTitle";


export default function Underutilisation(props) {	

	UseDocumentTitle('Underutilisation');

	const [selectedFacilityId, setSelectedFacilityId] = useState(null);
	const [from, setFrom] = useState(DateTime.now().minus({ months: 1 }).startOf("month").toISODate());
	const [to, setTo] = useState(DateTime.now().endOf("month").toISODate());
	const [status, setStatus] = useState("");
	const [filter, setFilter] = useState({ PageIndex: 0, PageCount: 10, Total: 0 })
	
	const [underutilisations, setUnderutilisations] = useState(null);
	const [exporting, setExporting] = useState(false);

	const store = useSessionStore();

	async function getUnderutilisation(pageCount, pageIndex, selectedFacilityId) {
		if(selectedFacilityId) {
			setUnderutilisations(null);
			let results = await store.UnderutilisationService.GetUnderutilisationForFacility(selectedFacilityId, pageCount, pageIndex, from, to, 0, status);
			
			if(results && results.Success) {
				setUnderutilisations(results.Data);
				setFilter({...filter, Total: results.Total, PageIndex: pageIndex, PageCount: pageCount });
			}
		}
	}

	function onSelectedFacilityChanged(selectedFacilityId) {
		setSelectedFacilityId(selectedFacilityId);
		getUnderutilisation(filter.PageCount, filter.PageIndex, selectedFacilityId);
	}
	
	function handlePageChanged(pageIndex, pageCount) {
		getUnderutilisation(pageCount, pageIndex, selectedFacilityId);
	
	}

	async function handleExportAllClicked() {
		setExporting(true);
		var result = await store.UnderutilisationService.ExportUnderutilisationForFacility(selectedFacilityId, from, to, 0, status);
		setExporting(false);
		if(result && result.Success) {
			//save file
			var blob = new Blob([result.Data], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `UNDERUTILISATION_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);
		}
	}

	return <React.Fragment>
				<PageHeader icon={ faBuildingColumns } title="Underutilisation" helpItemDescriptor="Facility/Underutilisation"
							crumbs={[{ Title: "Credit Management" }, { Title: "Facilities" }, { Title: "Underutilisation" }]}>
					{
						selectedFacilityId && underutilisations &&
						<IconButton icon={ faFileExcel } disabled={ exporting } onClick={handleExportAllClicked}>
							{ exporting ? "Exporting..." : "Export All" }
						</IconButton>
					}
				</PageHeader>
					<Panel>
						<UnderutilisationFilters onFacilityChanged={ onSelectedFacilityChanged } 
												facilityId={ selectedFacilityId } 
												showFilters={ underutilisations !== null }
												status={status}
												fromDate={ from } toDate={to} onFromDateChanged={ setFrom } onToDateChanged={ setTo } 
												onFilter={ e => getUnderutilisation(filter.PageCount, 0, selectedFacilityId) }
												onStatusChanged={ e => setStatus(e) }>
												</UnderutilisationFilters>
						{
							selectedFacilityId && underutilisations &&
							<React.Fragment>
								<Table bordered size="sm" responsive>
									<thead>
										<tr>
											<th>Date</th>
											<th className="text-end">Facility Limit</th>
											<th>%</th>
											<th className="text-end no-truncation">Interest on Facility Limit</th>
											<th className="text-end">Daily Utilised Interest	</th>
											<th className="text-end no-truncation">Daily Underutilised Interest</th>
											<th>Recovered Date</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										{
											underutilisations.length > 0 &&
											underutilisations.map((uu, index) =>
												<tr key={ index }>
													<td className="text-nowrap"><DateValue date={ uu.Date }></DateValue></td>
												
													<td className="text-nowrap text-end"><NumberValue currency={ uu.Currency } value={ uu.FacilityLimit }></NumberValue></td>
													<td><NumberValue value={ uu.UnderutilisationFeePercentage }></NumberValue>%</td>
													<td className="text-nowrap text-end"><NumberValue currency={ uu.Currency } value={ uu.InterestOnLimit }></NumberValue></td>
													<td className="text-nowrap text-end"><NumberValue currency={ uu.Currency } value={ uu.UtilisedInterest }></NumberValue></td>
													<td className="text-nowrap text-end"><NumberValue currency={ uu.Currency } value={ uu.UnderutilisedInterest }></NumberValue></td>
													<td></td>
													<td>Open</td>
												</tr>
											)
										}
									</tbody>
								</Table>
								<Pagination filter={filter} onPageChanged={ handlePageChanged } totalResults={ underutilisations.length }></Pagination>
							</React.Fragment>
						}

						<BusyIndicator size="2x" show={ underutilisations === null && selectedFacilityId }></BusyIndicator>
					</Panel>
				</React.Fragment>
}