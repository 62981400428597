import { useParams } from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import StructureDetails from "./StructureDetails";
import { useState,useEffect } from "react";

export default function StoreDetails(props) {
	const [hierarchy, setHierarchy] = useState(null);
	const sessionStore = useSessionStore();
	const params = useParams();

	async function getStore(id) {
		return sessionStore.StructureService.GetStore(id);
	}
	
	useEffect(() => {
		check()
		return () => {
			setHierarchy({}); // This worked for me
		};
	}, []);

	async function check() {
		let results = await sessionStore.StructureService.GetStoresForMarketPosition(params.parentid, "", 0, 10);

		if(results && results.Success) {
			setHierarchy(results.Data)
		}
	}

	async function createStore(store) {
		var sCheck = sessionStore.StructureService.CreateStore(store, params.id);
		var wali  = await sessionStore.LoadAuthState();

		return sCheck;
	}

	async function updateStore(store) {
		return sessionStore.StructureService.UpdateStore(store);
	}

	return 	<StructureDetails 	getStructure={ getStore }
								updateStructure={ updateStore } 
								createStructure={ createStore } 
								view={ props.view } 
								title={ `Store for '${(hierarchy ? hierarchy.ParentName : "")}' in '${(hierarchy ? hierarchy.GrandparentName : "")}'` } 
								name="Store" 
								childName=""
								add={props.add}>
			</StructureDetails>
}