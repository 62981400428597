import React, { useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Formik, Form as FormikForm, Field } from "formik";
import InputField from "../Forms/InputField";

import * as yup from 'yup';
import { useEffect } from "react";
import { removeModalTabIndex } from "../../../Utilities/React";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UploadDocumentModal(props) {
  const initialFileState = [{ Name: "", Description: "", FileName: "", Base64Data: "", MIMEType: "", key: 0 }];
  const [files, setFiles] = useState(initialFileState);
  const inputRef = useRef(null);

  function handleFormSubmit() {
    if(props.onUpload) {
      props.onUpload(files);
    }
    props.onClose(true);
  }

  function handleFileChanged(event, setFieldValue, screenFile, index) {
    if (inputRef.current) {
      let file = event.target.files[0];
      const newFiles = [...files]; // create a new array of files
      if (file) {
        
        let fileReader = new FileReader();
        fileReader.onloadend = () => {
          const base64String = fileReader.result.replace('data:', '').replace(/^.+,/, '');
          const newFile = {
            Name:screenFile.Name,
            Description:screenFile.Description,
            Base64Data: base64String,
            FileName: file.name,
            MIMEType: file.type,
            key: index
          };
          newFiles[index] = newFile;
          setFieldValue(`files[${index}].Name`, newFile.Name);
          setFieldValue(`files[${index}].Description`, newFile.Description);
          setFieldValue(`files[${index}].Base64Data`, newFile.Base64Data);
          setFieldValue(`files[${index}].FileName`, newFile.FileName);
          setFieldValue(`files[${index}].MIMEType`, newFile.MIMEType);
          setFiles(newFiles); // update the files state with the new array
        };
        fileReader.readAsDataURL(file);
      } else {
        const newFile = {
          ...screenFile,
          Base64Data: '',
          FileName: '',
          MIMEType: '',
          key: index
        };
        newFiles[index] = newFile;
        setFieldValue(`files[${index}].Base64Data`, newFile.Base64Data);
        setFieldValue(`files[${index}].FileName`, newFile.FileName);
        setFieldValue(`files[${index}].MIMEType`, newFile.MIMEType);
        setFiles(newFiles); // update the files state with the new array
      }
    }
  }

  function handleAddFileClick(file, index,setFieldValue) {
    if (file.Name && file.FileName) {
        // If the file does not exist, add it to the files array
        const newFile = {
          Name: "",
          Description: "",
          FileName: "",
          Base64Data: "",
          MIMEType: "",
          key: files.length
        };
    
        setFiles([...files, newFile]);
      }
    }
  
  function handleNameChange(event, setFieldValue, index) {

    const newFiles = [...files];
    newFiles[index].Name = event.target.value;
    setFieldValue(`files[${index}].Name`, event.target.value);
    setFiles(newFiles);
  }

  function handleDescriptionChange(event, setFieldValue, index) {

    const newFiles = [...files];
    newFiles[index].Description = event.target.value;
    setFieldValue(`files[${index}].Description`, event.target.value);
    setFiles(newFiles);
  }

  function handleRemoveFileClick(event, index) {

    event.preventDefault();
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  useEffect(() => {
    if(props.show) {
      removeModalTabIndex();
    } else {
      // reset state when modal is closed
      setFiles(initialFileState);
    }
  }, [props.show])

  const validationSchema = yup.array().of(
    yup.object().shape({
      Name: yup.string().required("Name is required"),
      FileName: yup.string().required("File is required")
    })
  ).min(1, "At least one file is required").required();



  return( <Modal show={ props.show } size="lg">
    <Formik initialValues={{ files }} validateOnMount enableReinitialize validationSchema={ validationSchema } onSubmit={ handleFormSubmit }>
      {({ setFieldValue, errors, values }) => (
      <FormikForm>
      <Modal.Header style={{backgroundColor:'#38a7df'}}>
        <Modal.Title style={{color:'white', fontWeight:'300'}}>
          Upload New Document
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {values.files.map((file, index) => (
          
          <div key={file.key}>
          
          <Row>
            <Col>
              <Row className="mb-0">
              <Col xs={ 3 }>
                    <Form.Label className="mb-n1">Name</Form.Label>
                  </Col>
                  <Col className="pe-0" >
                <Field  required  label="Name" name={`files[${index}].Name`} invalid={file.Name === ""}  className={`form-control ${(file.Name === '') ? "is-invalid" : ""}`} onChange={(e) =>
                            handleNameChange(e, setFieldValue, index)
                          }></Field>
                          </Col>
              </Row>
              <Row className="mb-5">
              <Form.Group className="mt-1 align-items-center">
                <Row className="p-0">
                  <Col xs={ 3 } className="ps-1 mt-1">
                    <Form.Label>Document</Form.Label>
                  </Col>
                  <Col className="p-0 ps-1">
                    <InputGroup>
                      <input ref={ inputRef } type="file" hidden required onChange={ e => handleFileChanged(e, setFieldValue,file, index) }></input>
                      <Field className={ `form-control ${errors["files"] && errors["files"][index] && errors["files"][index]["FileName"] ||(file.FileName === '') ? "is-invalid" : ""}` }  required name={`files[${index}].FileName`}></Field>
                      <Button onClick={ e => inputRef.current.click() }>Select File</Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Form.Group>
              </Row>
              
            </Col>
            <Col>
              <Row className="">
                <Form.Group as={ Row } className="align-items-center pe-0">
                  <Col xs={ 3 }>
                    <Form.Label style={{marginBottom:50}}>Description</Form.Label>
                  </Col>
                  <Col className="pe-0" >
                    <Field as="textarea" className="form-control" name={`files[${index}].Description`}   onChange={(e) =>
                                handleDescriptionChange(
                                  e,
                                  setFieldValue,
                                  index
                                )
                              }></Field>
                  </Col>
                </Form.Group>
              </Row>
              <Row>
             { (index + 1) === files.length &&
                  <Col className="d-flex justify-content-end mt-2 fw-bold" >
                    <Button variant="danger" onClick={ e => handleRemoveFileClick(e, index) } style={{marginRight:7, padding: "6px 10px", lineHeight: "1.5", cursor: "pointer"}}><FontAwesomeIcon transform="grow-4" fixedWidth icon={ faTimes } ></FontAwesomeIcon></Button>
                    <Button onClick={ e => handleAddFileClick(file) } className="btn text-white" style={{ padding: "6px 10px", lineHeight: "1.5", backgroundColor: "#2f8ebe", cursor: "pointer"}}><FontAwesomeIcon transform="grow-4" fixedWidth icon={ faPlus } ></FontAwesomeIcon></Button>
                  </Col>
             }
                </Row>
            </Col>
             
          </Row>
							</div>
						))}
          </Modal.Body>
					<Modal.Footer>
						<Button type="submit">Save</Button>
						<Button onClick={ e => props.onClose(false) }>Cancel</Button>
					</Modal.Footer>
				</FormikForm>)
				}
				</Formik>
		</Modal>);
}