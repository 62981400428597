import APIBase from "./APIBase";

/**
 * @typedef ContactCategoriesService
 * @type {ContactCategoriesService}
 */
export default class ContactCategoriesService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "ContactCategories", sessionStore);
    }

	async GetContactCategories(search, pageIndex, pageCount) {
		return this.Get(`GetContactCategories?search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	async ExportContactCategories(search) {
		return this.Get(`ExportContactCategories?search=${encodeURIComponent(search)}`);
	}
	
	async CreateContactCategory(contactCategory) {
		return this.Post(`CreateContactCategory`, contactCategory);
	}

	async UpdateContactCategory(contactCategory) {
		return this.Post("UpdateContactCategory", contactCategory);
	}

	async DeleteContactCategory(contactCategoryId) {
		return this.Post(`DeleteContactCategory?contactCategoryId=${contactCategoryId}`);
	}

	/**
	 * @param {number} contactCategoryId The Id of the Contact Category to retrieve
	 * @returns { Promise<import("./APIBase").RequestResult<any>> }
	 */
	 async GetContactCategory(contactCategoryId) {
		return this.Get(`GetContactCategory?contactCategoryId=${contactCategoryId}`);
	}
}