import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { FacilityStatuses, UserRoles } from "../../../../../Utilities/Enums";
import { isFieldEnabled } from "../../../../../Utilities/Facilities";
import InputField from "../../../../Core/Forms/InputField";
import SelectField from "../../../../Core/Forms/SelectField";
import Panel from "../../../../Core/Panel";

export default function Status() {
	const store = useSessionStore();
	const formikContext = useFormikContext();
	const view = formikContext.values.View;

	const disabled = !isFieldEnabled([], [FacilityStatuses.Active, FacilityStatuses.Pending], formikContext.values.FacilityStatusID, store.Session) &&
						!isFieldEnabled([UserRoles.Admin],
							[FacilityStatuses.Active, FacilityStatuses.Expired, FacilityStatuses.Cancelled],
							formikContext.values.FacilityStatusID, store.Session)


	function getAvailableStatuses() {
		const canMarkActive = formikContext.values.CanMarkActive;
		var statuses = [
			{ Id: FacilityStatuses.Pending, Name: "Pending" },
			{ Id: FacilityStatuses.Active, Name: "Active" },
			{ Id: FacilityStatuses.Cancelled, Name: "Cancelled" },
			{ Id: FacilityStatuses.Expired, Name: "Expired" }
		];

		if(!view) {
			if (formikContext.values.FacilityStatusID === FacilityStatuses.Pending && canMarkActive) {
				statuses = statuses.filter(x => x.Id !== FacilityStatuses.Pending);
			}
	
			if (formikContext.values.FacilityStatusID >= FacilityStatuses.Active && canMarkActive) {
				statuses = statuses.filter(x => x.Id !== FacilityStatuses.Pending);
			} else if (formikContext.values.Id === 0 || !canMarkActive) {
				statuses = statuses.filter(x => x.Id !== FacilityStatuses.Active);
			}
	
			if (formikContext.values.FacilityStatusID !== FacilityStatuses.Expired) {
				statuses = statuses.filter(x => x.Id !== FacilityStatuses.Expired);
			}
	
			if (formikContext.values.FacilityStatusID === FacilityStatuses.Expired) {
				statuses = statuses.filter(x => x.Id !== FacilityStatuses.Active);
			}
		}

		return statuses;
	}

	return 	<Panel>
				<Row>
					<Col xs={4}>
						<SelectField view={view} disabled={disabled} fast label="Status" name="FacilityStatusID">
							{
								getAvailableStatuses().map((status) =>
								<option value={status.Id}>{status.Name}</option>
								)
							}
						</SelectField>
					</Col>
					<Col xs={8}>
						<InputField view={view} disabled={disabled} label="Comment" name="Comment" labelCol></InputField>
					</Col>
				</Row>
			</Panel>
}