import { makeAutoObservable } from "mobx";

/**
 * @typedef StructureManager
 */
export default class StructureManager {
	GroupId = "";
	MarketPositionId = "";
	StoreId = "";

	AllowAll = false;

	/**@type { Array<import("../Services/StructureService").Structure> } */
	Groups = [];

	/**@type { Array<import("../Services/StructureService").Structure> } */
	MarketPositions = [];

	/**@type { Array<import("../Services/StructureService").Structure> } */
	Stores = [];

	/**
	 * @param { Array<import("../Services/StructureService").Structure> } availableStructure
	 */
	constructor(availableStructure, allowAll) {
		this.Groups = availableStructure;
	
		this.AllowAll = allowAll;

		makeAutoObservable(this);
	}

	SetSelectedGroup(groupId) {
		this.HandleGroupChanged(groupId);
	}


	SetSelectedMarketPosition(marketPositionId) {
		this.HandleMarketPositionChanged(marketPositionId);
	}

	SetSelectedStore(storeId) {
		this.StoreId = storeId;
	}

	HandleGroupChanged(groupId) {
		this.GroupId = groupId;

		var index = this.Groups.findIndex((g) => g.Id === groupId);

		if(index !== -1) {
			var group = this.Groups[index];
			this.MarketPositions = group.Children;

		} else {
			this.MarketPositions = [];
		}

		this.MarketPositionId = "";
		this.StoreId = "";

		this.Stores = [];
	}

	HandleMarketPositionChanged(marketPositionId) {
		this.MarketPositionId = marketPositionId;
		this.StoreId = "";

		if(this.GroupId > 0 && this.MarketPositionId > 0) {
			var index = this.Groups.findIndex((g) => g.Id === this.GroupId);

			if(index !== -1) {
				var group = this.Groups[index];
				var marketPositionIndex = group.Children.findIndex((mp) => mp.Id === this.MarketPositionId);

				if(marketPositionIndex !== -1) {
					var marketPosition = group.Children[marketPositionIndex];
					var mpStores = marketPosition.Children;

					this.Stores = mpStores;
				} else {
					this.Stores = [];
				}
			}
		} else {
			this.Stores = [];
		}
	}

	GetGroupName(groupId) {
		let group = this.Groups.find((g) => g.Id === groupId);

		return (group ? group.Name : "");
	}

	GetMarketPositionName(groupId, marketPositionId) {
		let group = this.Groups.find((g) => g.Id === groupId);

		if(group) {
			let mp = group.Children.find(m => m.Id === marketPositionId);
			return (mp ? mp.Name : "");
		}

		return "";
	}

	GetStoreName(groupId, marketPositionId, storeId) {
		let group = this.Groups.find((g) => g.Id === groupId);

		if(group) {
			let mp = group.Children.find(m => m.Id === marketPositionId);

			if(mp) {
				let store = mp.Children.find(s => s.Id === storeId);
				return (store ? store.Name : "");
			}
		}

		return "";
	}
}