import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { getPermissions, Permissions } from "../../../../Utilities/Permissions";
import PageHeader from "../../../Core/PageHeader";
import Panel from "../../../Core/Panel";
import IconButton from "../../../Core/IconButton";
import { faFileExcel,  faWallet } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import PaymentInstructionsFilters from "./PaymentInstructionsFilters";
import IndexTable from "./IndexTable";
import { NavLink } from "react-router-dom";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

const PaymentInstructionsIndex = observer(function PaymentInstructionsIndex(props) {

	UseDocumentTitle('Listing \'Payment Instructions\' on CREST');

	const store = useSessionStore();
	const navigate = useNavigate();

	useEffect(() => {
		store.PaymentManagementStore.GetPaymentInstructions();
	}, []);

	async function handleExportPaymentInstructionsClicked() {
		var result = await store.PaymentManagementStore.ExportPaymentInstructions();

		if(result) {
			//save file
			var blob = new Blob([result], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `PAYMENT_INSTRUCTIONS_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);
		}
	}

	function handleFilterUpdate(property, value) {
		let filters = { ...store.PaymentManagementStore.PaymentInstructionsFilters };
		filters[property] = value;

		store.PaymentManagementStore.SetPaymentInstructionFilters(filters);
	}

	const piPermissions = getPermissions(Permissions.Sections.PaymentInstructions, Permissions.Areas.CreditManagement, Permissions.SubAreas.PaymentManagement, store.Session);
	
	return 	<React.Fragment>
				<PageHeader icon={ faWallet } title="Payment Instructions" helpItemDescriptor="PaymentInstruction/Index"
								crumbs={[{ Title: "Credit Management" }, { Title: "Payment Management" }, { Title: "Payment Instructions" }]}>
					{
						piPermissions.Add &&
						<NavLink to={ "Add" }><IconButton space icon={ faPlus }>Add New</IconButton></NavLink>
					}
					{
						piPermissions.View &&
						<IconButton icon={ faFileExcel } disabled={ store.PaymentManagementStore.ExportingPaymentInstructions } onClick={ handleExportPaymentInstructionsClicked }>
							{ store.PaymentManagementStore.ExportingPaymentInstructions ? "Exporting..." : "Export All" }
						</IconButton>
					}
				</PageHeader>
				<Panel>
					<PaymentInstructionsFilters filters={ store.PaymentManagementStore.PaymentInstructionsFilters } 
												updateCallback={ handleFilterUpdate }
												fetchCallback={ e => store.PaymentManagementStore.GetPaymentInstructions() }>
					</PaymentInstructionsFilters>
					
					<IndexTable data={ store.PaymentManagementStore.PaymentInstructions } retrievingData={ store.PaymentManagementStore.RetrievingPaymentInstructions }
								filters={ store.PaymentManagementStore.PaymentInstructionsFilters } allowSave
								updateCallback={ e => store.PaymentManagementStore.SetPaymentInstructionFilters(e) }
								fetchCallback={ e => store.PaymentManagementStore.GetPaymentInstructions() }>
					</IndexTable>
				</Panel>
				
			</React.Fragment>
});

export default PaymentInstructionsIndex;