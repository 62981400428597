import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { Form, InputGroup } from "react-bootstrap";
import Flatpickr from "react-flatpickr";

export default function DateInput(props) {
	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
		}
	}

	const BootstrapInput = ({defaultValue, value, ...props}, ref) => (
		<InputGroup>
			<InputGroup.Text><FontAwesomeIcon icon={ faCalendar }></FontAwesomeIcon></InputGroup.Text>
			<Form.Control onKeyPress={ handleKeyPress }  disabled={props.disabled} placeholder={ props.placeholder || "Select a date"} onClick={props.onClick} ref={ref} defaultValue={value}></Form.Control>
		</InputGroup>
	);

	return <Flatpickr options={{ dateFormat: props.dateFormat || "d F Y", disableMobile : true }} disabled={ props.disabled } render={ BootstrapInput } 
					 value={ props.date ? DateTime.fromISO(props.date).toJSDate() : ""} onChange={([date]) => props.onChange(DateTime.fromJSDate(date).toISO({ includeOffset: false })) }/>
	}