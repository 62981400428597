import React from "react";
import Panel from "../../../../../Core/Panel";
import ContactBalances from "./ContactBalances";
import FacilityLimits from "./FacilityLimits";
import LinkedPaymentInstructions from "./LinkedPaymentInstructions";

export default function FacilityBalances(props) {
	return 	<Panel>
				{
					props.facilityDetails &&
					<React.Fragment>
						<div className="mb-1">
							<ContactBalances facilityTypeId={ props.facilityDetails.Facility.FacilityTypeID } contacts={ props.facilityDetails.FacilityContacts }></ContactBalances>
						</div>
						<div className="mb-1">
							<LinkedPaymentInstructions currency={ props.currency } paymentInstructions={ props.facilityDetails.PaymentInstructions }
														unlinkedTransactionBalances={ props.facilityDetails.UnlinkedTransactionBalances }>
							</LinkedPaymentInstructions>
						</div>
						<FacilityLimits facility={ props.facilityDetails.Facility } currency={ props.currency } facilityBalances={ props.facilityDetails.FacilityBalances }></FacilityLimits>
					</React.Fragment>
				}
			</Panel>
}