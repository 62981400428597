import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import Recovery from "../../../../../Utilities/Recovery";
import  swal  from "sweetalert"
import { saveAs } from "file-saver";
import { Alert, Button, Modal } from "react-bootstrap";
import { faFileExcel } from "@fortawesome/pro-regular-svg-icons";
import RecoverySummary from "./RecoverySummary";
import TransactionsTable from "./TransactionsTable";
import BusyIndicator from "../../../../Core/BusyIndicator";
import { DateTime } from "luxon";
import IconButton from "../../../../Core/IconButton";

export default function AssignRecoveriesModal(props) {
    const store = useSessionStore();

	const [saving, setSaving] = useState(false);

    const [transactions, setTransactions] = useState(null);
    const [totals, setTotals] = useState({ TheoreticalReimbursement: 0.0, ReimbursementFilled: false, TotalAllocated: 0.0, TotalTheoreticalAllocated: 0.0, TheoreticalRecoveryRemaining: 0.0, RecoveryRemaining: 0.0 })
    const [recoveryState, setRecoveryState] = useState({ FullyRecovered: false, OverRecovered: false });

    const recovery = useRef(new Recovery()); 
	
	async function getTransactions() {
		let result = await store.PaymentInstructionRecoveriesService.GetTransactionsForRecovery(props.paymentInstruction.PaymentInstructionId, 
																								DateTime.fromISO(props.recoveryData.DateReceived).toISODate(), 
																								props.recoveryData.AllocatedAmount);

		if(result && result.Success) {
			recovery.current = new Recovery();

			//check if we've over or fully recovered
			var assignedRecoveryAmount = 0.0;
			result.Data.forEach((value) =>  assignedRecoveryAmount += value.Amount || 0.0);
	
			var fullyRecovered = assignedRecoveryAmount > 0 && (+props.recoveryData.AllocatedAmount.toFixed(2)) === (+assignedRecoveryAmount.toFixed(2));
			var overRecovered = assignedRecoveryAmount > 0 && (+props.recoveryData.AllocatedAmount.toFixed(2)) > (+assignedRecoveryAmount.toFixed(2));

			setRecoveryState({ FullyRecovered: fullyRecovered, OverRecovered: overRecovered });                

			if(props.importData) {
				loadImportData(result.Data);
			} else {
				for(let i = 0; i < result.Data.length; i++) { 
					result.Data[i].Filled = (fullyRecovered || overRecovered); 
					result.Data[i].UnusedAmount = result.Data[i].Amount 
				}
			}

			setTransactions(result.Data);
			
			recovery.current.Initialise(props.recoveryData.AllocatedAmount, props.recoveryData.TheoreticalAmount, result.Data);
			setTotals(recovery.current.GetTotals());
		}
	}

	useEffect(() => {
		if(props.show) {
			getTransactions();
		} else {
			setTransactions(null);
		}
		
	}, [props.show]);

	function loadImportData (data) {
		var lines = props.importData.split("\n");

		for(let i = 1; i < lines.length; i++) {
			let line = lines[i].split(",");
			if(line.length === 11) {
				let transaction = data.find((value) => value.DocumentNumber === line[2].trim() && value.Id === parseInt(line[1].trim()));

				if(transaction) {
					var amount = line[10].length > 0 ? parseFloat(line[10]) : null;

					if(amount !== null) {
						var maxAmount = parseFloat(line[8]);

						if(maxAmount > 0) {
							amount = amount > maxAmount ? maxAmount : amount;
						} else {
							amount = amount < maxAmount ? maxAmount : amount;
						}
					}

					transaction.Amount = amount;
					transaction.UnusedAmount = line[9].length > 0 ? parseFloat(line[9]) : null;
				}
			}
		}
	}
		
	function handleTransactionUpdated() {
		setTotals(recovery.current.GetTotals());
	}

	async function handleSaveClicked () {
		var totals = recovery.current.GetTotals();

		if (totals.RecoveryAmount !== 0.0 && totals.TheoreticalRecoveryAmount !== 0.0) {
			if (totals.TheoreticalRecoveryRemaining !== 0.0) {
				swal("Theoretical Amount Remaining", "The recovery cannot be submitted because the total theoretical amount has not been allocated.", "error");                
				return;
			}

			if (totals.RecoveryRemaining !== 0.0) {
				swal("Outstanding Amount Remaining", "The recovery cannot be submitted because the total outstanding amount has not been allocated.", "error");                
				return;
			}
		}
		
		var recoveryModel = {
			Amount: totals.RecoveryAmount,
			TheoreticalAmount: totals.TheoreticalRecoveryAmount,
			DateReceived: DateTime.fromISO(props.recoveryData.DateReceived).toISODate(),
			Transactions: transactions            
		}

		setSaving(true);
		let result = await store.PaymentInstructionRecoveriesService.SavePaymentInstructionRecovery(props.paymentInstruction.PaymentInstructionId, recoveryModel);
		setSaving(false);
		
		if(result && result.Success) {
			props.onCloseClicked(true);
		} else {
			swal("Save Recovery Failed", result.Message, "error");
		}
	}

	async function exportTransactions() {
		var totals = recovery.current.GetTotals();

		let result = await store.PaymentInstructionRecoveriesService.ExportRecoveryAssignments(props.paymentInstruction.PaymentInstructionId, 
																								props.recoveryData.DateReceived, totals.RecoveryAmount, 
																								totals.TheoreticalRecoveryAmount);
		if(result) {
			if(result.Success) {
				var exportData = `PaymentInstructionId,TransactionId,DocumentNumber,DocumentTotal,PaymentAmount,FundingAmount,CurrentInterest,RemainingCapital,MaxAmount,TheoreticalAmount (Remaining Amount: ${ totals.TheoreticalRecoveryRemaining }),OutstandingAmount (Remaining Amount: ${ totals.RecoveryRemaining })\n`;

				transactions.forEach((transaction) => {
					var transactionId = transaction.Id;
					var documentNumber = transaction.DocumentNumber;
					var documentTotal = transaction.DocumentTotal.toFixed(2);
					var paymentAmount = transaction.PaymentAmount.toFixed(2);
					var fundingAmount = transaction.FundingAmount.toFixed(2);
					var currentInterest = transaction.CurrentInterest + transaction.ShortfallInterest;
					var remainingCapital = transaction.RemainingCapital.toFixed(2);
					var maxAmount = transaction.MaxAmount.toFixed(2);
					var theoreticalAmount = transaction.UnusedAmount ? transaction.UnusedAmount.toFixed(2) : "";
					var outstandingAmount = transaction.Amount ? transaction.Amount.toFixed(2) : "";
							
					exportData += `${props.paymentInstruction.PaymentInstructionId},${transactionId},${documentNumber},${documentTotal},${paymentAmount},${fundingAmount},${currentInterest},${remainingCapital},${maxAmount},${theoreticalAmount},${outstandingAmount}\n`;

				});            
				
				var blob = new Blob([exportData], {type: "text/plain;charset=utf-8"});            
				saveAs(blob, `Payment Recovery - ${props.paymentInstruction.PaymentInstructionName} - ${DateTime.fromISO(props.recoveryData.DateReceived).toFormat("dd MMMM yyyy")}.csv`);

				props.onCloseClicked(true);
			} else {
				swal("Export error.", "An export for this payment recovery is already outstanding. Close this existing export before attempting to export again.", "error");
			}
		} else {
			swal("Export error.", "An error occurred attempting to export the payment recovery assignments.", "error");
		}
	}

    return  <Modal show={ props.show } size="xl">
				<Modal.Header>
					<h4>
						Recover Payments
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						transactions &&
						<React.Fragment>
							{
								(recoveryState.FullyRecovered || recoveryState.OverRecovered) &&
								<Alert variant="info" className="p-1">
									<strong>Please Note:</strong> <span className="fw-normal">The amount you have recovered is equal to or greater than the outstanding amount; therefore all the transactions have been automatically assigned their Outstanding amounts. If this is correct please select the Save option below.</span>
								</Alert>
							}
							{
								recoveryState.FullyRecovered === false && recoveryState.OverRecovered === false &&
								<Alert variant="warning" className="p-1" >
									<strong style={{ color: "#AA3510" }}>Please Note:</strong> <span className="fw-normal" style={{ color: "#AA3510" }}>The amount you have recovered is less than the outstanding balance for the Payment Instruction. Because of this you will need to manually assign transactions to the recovered amount before it can be saved. You can assign an amount manually or use the checkbox to assign the Outstanding for each transaction (as reflected below). You cannot assign more than the Outstanding of each transaction and you cannot assign more than the remaining amount of the recovered funds.</span>
								</Alert>						
							}
							<RecoverySummary currencySymbol={ props.paymentInstruction.CurrencySymbol } dateReceived={ props.recoveryData.DateReceived } recoveryTotals={ totals }></RecoverySummary>                    
							
							<TransactionsTable recovery={ recovery.current } currencySymbol={ props.paymentInstruction.CurrencySymbol } isOverRecovered={ recoveryState.OverRecovered } 
												transactions={ transactions } recoveryTotals={ totals } onTransactionUpdated={ handleTransactionUpdated }></TransactionsTable>
						</React.Fragment>
					}
					<BusyIndicator show={ transactions === null }></BusyIndicator>
				</Modal.Body>
				<Modal.Footer className="justify-content-end">
					<div className="me-auto">
						<IconButton disabled={ saving } onClick={ exportTransactions } icon={ faFileExcel }>Export Worksheet</IconButton>
					</div>
					<Button disabled={ saving } onClick={ handleSaveClicked }>{ saving ? "Saving..." : "Save" }</Button>
					<Button disabled={ saving } onClick={ () => props.onCloseClicked(false) }>Cancel</Button>
				</Modal.Footer>
            </Modal>
}